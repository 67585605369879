import React,{ Component } from 'react';
import { View, Image, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { AppContext } from '../AppContext';
import ListStyles from '../styles/ListStyles';
import HeadStyle from '../styles/HeadStyles';
import HeaderOptions from '../constants/HeaderOptions';
import CommonFunction from '../utils/CommonFunction';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class NotifInfo extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.props?.setHeader('通知設定のやり方',this.navigation);
      
      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  


  setHeader = () =>{
    let dispTitle = '通知設定のやり方';
    let headerStyle = HeaderOptions.HeaderSetting[0];
    
    this.props.navigation?.setOptions({
    headerStyle: headerStyle,
      headerTitleStyle: HeaderOptions.HeaderIineTitle,
      headerTitleAlign:'center',
    header: () => (
      <View style={HeadStyle.customHeader}>
        <View style={[HeadStyle.touchIcBack,{flexDirection:'row',left:16}]}>
          <TouchableOpacity onPress={()=> this.props.navigation?.goBack()}>
            <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack2}/>
          </TouchableOpacity>
          <LogoNoMargin/>
        </View>
        <View style={{flex:1, alignItems:'stretch'}}><Text style={[HeadStyle.headerTitlePage,{fontSize:13}]}>{dispTitle}</Text></View>
      </View>
    )
    });
  }

  render() {
    const newHeight = windowWidth * 7054 / 1080
          
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>      

        <View style={{flexDirection:'column', alignItems:'center'}}>
        <Image style={{width:'100%', height:newHeight}} source={require('../assets/images/notifi_info.jpg')} resizeMode="stretch"/>
        </View>

      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  textContainer:{
    flex:1,
    width:'auto',
    textAlign: 'center',
    justifyContent:'center',
  },
  submitBtn:{
    alignItems:"center",
    justifyContent:"center",
    padding:8,
    width:'80%',
    height:45,
    backgroundColor:'#7CD7BB',
    borderRadius:999,
  },
  textsubmitBtnWhite: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:26,
    color:'#ffffff',
    textAlign:'center',
  },
});
