import React from 'react';
import { TouchableOpacity } from 'react-native';
import HeadStyle from '../../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';

export default function GoBackIconMypage(props) {
  const { navigation } = props;
  return (
    <TouchableOpacity onPress={() => navigation.navigate('Home', {screen:'MyPageStack', params:{ screen:'MyPage'}})}>
      <Entypo name={'chevron-thin-left'} style={[HeadStyle.icBack]}/>
    </TouchableOpacity>
  );
}