import { Component } from 'react';
import ContextManager from "./ContextManager";
import { AppContext } from '../../AppContext';

export default class Context extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    ContextManager.register(this);
    
  }

  componentWillUnmount(){
    ContextManager.unregister(this);
  }

  getContext = method =>{
    if(method){
      method(this.context);
    }
  }

  render() {
    return null;
  }
}