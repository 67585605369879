import PaymentManager from "./PaymentManager";

export default class PaymentWrapper {
  static show(...args){
    const ref = PaymentManager?.getDefault();
    ref?.show(...args);
  }

  static hide(){
    const ref = PaymentManager?.getDefault();
    ref?.hide();
  }
}