import React, { Component } from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import MyPageScreen from '../../screens/MyPageScreen';
import PointShoppingScreen from '../../screens/PointShoppingScreen';
import PointShoppingScreen_ios from '../../screens/PointShoppingScreen_ios';
import PointShoppingScreen_android from '../../screens/PointShoppingScreen_android';
import PointShoppingCompleteScreen from '../../screens/PointShoppingCompleteScreen';
import NotificationScreen from '../../screens/NotificationScreen';
import IinePlanScreen from '../../screens/IinePlanScreen';
import PayPlanScreen from '../../screens/PayPlanScreen';
import PointShoppingDetailScreen from '../../screens/PointShoppingDetailScreen';
import PointListScreen from '../../screens/PointListScreen';
import SettingStack from './SettingStack';
import Footmark from '../../screens/Footmark';
import UserInfoSwiperScreen from '../../screens/UserInfoSwiperScreen';
import NotificationDetailScreen from '../../screens/NotificationDetailScreen';
import UserSettingScreen from '../../screens/UserSettingScreen';
import UserSettingImageScreen from '../../screens/UserSettingImageScreen';
import MemberStatusScreen from '../../screens/MemberStatus';
import RuleScreen from '../../screens/setting/RuleScreen';
import PolicyScreen from '../../screens/setting/PolicyScreen';
import PointScreen from '../../screens/PointScreen';
import ChatHideSettingScreen from '../../screens/chat/ChatHideSettingScreen';
import ChatRoomScreen from '../../screens/chat/ChatRoomScreen';
import ReportScreen from '../../screens/ReportScreen';
import UserInfoScreen from '../../screens/UserInfoScreen';
import TermsScreen from '../../screens/setting/TermsScreen';
import WithdrawScreen from '../../screens/withdraw/WithdrawScreen';
import WithdrawInputScreen from '../../screens/withdraw/WithdrawInputScreen';
import WithdrawConfirmScreen from '../../screens/withdraw/WithdrawConfirmScreen';
import CancelSubscriptionScreen from '../../screens/cancelsubscription/CancelSubscriptionScreen';
import SubscriptionCancelMethodScreen from '../../screens/cancelsubscription/SubscriptionCancelMethodScreen';
import CancelSubscriptionCompleteScreen from '../../screens/cancelsubscription/CancelSubscriptionCompleteScreen';
import PurchaseScreen from '../../screens/PurchaseScreen';
import PurchaseScreen_ios from '../../screens/PurchaseScreen_ios';
import PurchaseScreen_android from '../../screens/PurchaseScreen_android';
import PurchaseTrialScreen from '../../screens/PurchaseTrialScreen';
import SubscriptionCompleteScreen from '../../screens/SubscriptionCompleteScreen';
import PointPurchaseCompleteScreen from '../../screens/PointPurchaseCompleteScreen';
import IinePointScreen from '../../screens/IinePointScreen';
import PreviewUserInfoScreen from '../../screens/PreviewUserInfoScreen';
import PointShoppingStack from './PointShoppingStack';
import SecretModeScreen from '../../screens/setting/SecretModeScreen';
import ProfileImageInfo from '../../screens/ProfileImageInfoScreen';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import InquiryScreen from '../../screens/InquiryScreen';
import HelpScreen from '../../screens/HelpScreen';
import CommonFunction from '../../utils/CommonFunction';
import TransitionSpec from '../../constants/TransitionSpec';
import PaidyInfoScreen from '../../screens/PaidyInfoScreen';

const Stack = createStackNavigator();

export default class MyPageStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName="MyPage" screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen name="MyPage" 
        children={(props)=><MyPageScreen setBadge={this.props?.setBadge} setIkuyoStatus={this.props?.setIkuyoStatus} {...props} />}
        options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Footmark" 
          children={(props)=><Footmark {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Profile" 
          children={(props)=><UserInfoSwiperScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="SameProfile" 
          children={(props)=><UserInfoScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="SettingStack" 
          children={(props)=><SettingStack {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true, title:'Setting'}}
        />
        <Stack.Screen 
          name="UserSetting" 
          children={(props)=><UserSettingScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="UserSettingImage" 
          children={(props)=><UserSettingImageScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatRoom" 
          children={(props)=><ChatRoomScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Report" 
          children={(props)=><ReportScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatHideSetting" 
          children={(props)=><ChatHideSettingScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        {Platform.OS==='web'?
          <Stack.Screen 
          name="Purchase" 
          children={(props)=><PurchaseScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}/>        
        :null}
        {Platform.OS==='ios'?
          <Stack.Screen 
          name="Purchase" 
          children={(props)=><PurchaseScreen_ios {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}/>        
        :null}
        {Platform.OS==='android'?
          <Stack.Screen 
          name="Purchase" 
          children={(props)=><PurchaseScreen_android {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}/>        
        :null}
        {Platform.OS==='web'?
          <Stack.Screen 
          name="PurchaseTrial" 
          children={(props)=><PurchaseTrialScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}/>        
        :null}
        <Stack.Screen name="MemberStatus" component={MemberStatusScreen} />
        <Stack.Screen name="Rule" options={{headerShown: false}}>
          {props => <RuleScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="Policy" options={{headerShown: true}}>
          {props => <PolicyScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen 
          name="Point" 
          children={(props)=><PointScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen name="Withdraw" options={{headerShown: true}}>
          {props => <WithdrawScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="WithdrawInput" options={{headerShown: true}}>
          {props => <WithdrawInputScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="WithdrawConfirm" options={{headerShown: true}}>
          {props => <WithdrawConfirmScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="CancelSubscription" options={{headerShown: true}}>
          {props => <CancelSubscriptionScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="SubscriptionCancelMethod" options={{headerShown: true}}>
          {props => <SubscriptionCancelMethodScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen name="CancelSubscriptionComplete" options={{headerShown: true}}>
          {props => <CancelSubscriptionCompleteScreen {...props} />}
        </Stack.Screen>
        <Stack.Screen 
          name="SubscriptionComplete" 
          children={(props)=><SubscriptionCompleteScreen {...props} setBadge={this.props?.setBadge} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="PointPurchaseComplete" 
          children={(props)=><PointPurchaseCompleteScreen {...props} setBadge={this.props?.setBadge} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen name="Terms" component={TermsScreen} options={{headerShown: true}}/>
        {Platform.OS==='web'?
          <Stack.Screen name="PointShopping" component={PointShoppingScreen} options={{headerShown: true}}/>
        :null}
        {Platform.OS==='ios'?
          <Stack.Screen name="PointShopping" component={PointShoppingScreen_ios} options={{headerShown: true}}/>
        :null}
        {Platform.OS==='android'?
          <Stack.Screen name="PointShopping" component={PointShoppingScreen_android} options={{headerShown: true}}/>
        :null}
        <Stack.Screen name="PointShoppingDetail" component={PointShoppingDetailScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PointShoppingComplete" component={PointShoppingCompleteScreen} options={{headerShown: true}}/>
        <Stack.Screen name="Notify" component={NotificationScreen} options={{headerShown: true}}/>
        <Stack.Screen name="NotifyDetail" component={NotificationDetailScreen} options={{headerShown: true}}/>
        <Stack.Screen name="IinePlan" component={IinePlanScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PointList" component={PointListScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PayPlan" component={PayPlanScreen} options={{headerShown: true}}/>
        <Stack.Screen name="IinePoint" component={IinePointScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PreviewUserInfo" component={PreviewUserInfoScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
        <Stack.Screen name="SecretMode" component={SecretModeScreen} />
        <Stack.Screen name="ProfileImageInfo" component={ProfileImageInfo} />
        <Stack.Screen 
          name="Inquiry" 
          children={(props)=><InquiryScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        {Platform.OS==='web'?
          <Stack.Screen name="PaidyInfo" component={PaidyInfoScreen} options={{headerShown: true}}/>
        :null}
        <Stack.Screen name="Help" component={HelpScreen} />
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}