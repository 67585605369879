const SearchState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          { name: 'Home', state: {
            routes: [
              { name: 'Search', state: state }
            ]}
          }
        ]}
      }
    ]
  }

  return newState;
}

const ListLogin = (params={})=>{
  const routes = {
    routes: [
      { name: 'SearchList', state: {
        "index": 0,
        routes: [
          { name: 'Login', params: params },
          { name: 'Regist' }
        ]}
      }
    ]
  }

  const state = SearchState(routes);
  return state;
}

const ListRegist = (params={})=>{
  const routes = {
    routes: [
      { name: 'SearchList', state: {
        "index": 1,
        routes: [
          { name: 'Login' },
          { name: 'Regist', params: params }
        ]}
      }
    ]
  }

  const state = SearchState(routes);
  return state;
}

const Footmark = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'SearchList', state: {
        "index": 0,
        routes: [
          { name: 'Login' },
          { name: 'Regist' }
        ]} 
      },
      { name: 'Footmark', params: params }
    ]
  }
  
  const state = SearchState(routes);
  return state;
}

const Profile = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'SearchList', state: {
        "index": 0,
        routes: [
          { name: 'Login' },
          { name: 'Regist' }
        ]}
      },
      { name: 'Profile', params: params }
    ]
  }
  
  const state = SearchState(routes);
  return state;
}

const LikeProfile = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'SearchList', state: {
        "index": 0,
        routes: [
          { name: 'Login' },
          { name: 'Regist' }
        ]}
      },
      { name: 'SameProfile', params: params }
    ]
  }
  
  const state = SearchState(routes);
  return state;
}

const Report = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'SearchList' },
      //{ name: 'Profile', params: params },
      { name: 'Report', params: params }
    ]
  }
  
  const state = SearchState(routes);
  return state;
}

const ChatHideSetting = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'SearchList' },
      //{ name: 'Profile', params: params },
      { name: 'ChatHideSetting', params: params }
    ]
  }
  
  const state = SearchState(routes);
  return state;
}

const MeetState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          { name: 'Home', state: {
            "index": 1,
            routes: [
              { name: 'Search' },
              { name: 'Meet', state: state }
            ]}
          }
        ]}
      }
    ]
  }

  return newState;
}

const MeetList = (params={})=>{
  const routes = {
    routes: [
      { name: 'MeetList', params: params }
    ]
  }
  
  const state = MeetState(routes);
  return state;
}

const MeetCalendar = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MeetList' },
      { name: 'MeetCalendar', params: params }
    ]
  }
  
  const state = MeetState(routes);
  return state;
}

const IineState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          { name: 'Home', state: {
            "index": 1,
            routes: [
              { name: 'Search' },
              { name: 'Iine', state: state }
            ]}
          }
        ]}
      }
    ]
  }

  return newState;
}

const GoodOther = (params={})=>{
  const routes = {
    routes: [
      { name: 'IineList', state: {
        "index": 0,
        routes: [
          { name: 'Other', params: params },
          { name: 'My' }
        ]}
      }
    ]
  }
  const state = IineState(routes);
  return state;
}

const GoodMy = (params={})=>{
  const routes = {
    routes: [
      { name: 'IineList', state: {
        "index": 1,
        routes: [
          { name: 'Other' },
          { name: 'My', params: params }
        ]}
      }
    ]
  }
  const state = IineState(routes);
  return state;
}

const GoodSkip = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'IineList' },
      { name: 'SkipIineList', params: params }
    ]
  }
  
  const state = IineState(routes);
  return state;
}

const ChatState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          { name: 'Home', state: {
            "index": 1,
            routes: [
              { name: 'Search' },
              { name: 'Chat', state: state }
            ]}
          }
        ]}
      }
    ]
  }

  return newState;
}

const ChatMatching = (params={})=>{
  const routes = {
    routes: [
      { name: 'ChatList', state: {
        "index": 0,
        routes: [
          { name: 'Matching', params: params },
          { name: 'Conversation' }
        ]}
      }
    ]
  }
  const state = ChatState(routes);
  return state;
}

const ChatConversation = (params={})=>{
  const routes = {
    routes: [
      { name: 'ChatList', state: {
        "index": 1,
        routes: [
          { name: 'Matching' },
          { name: 'Conversation', params: params }
        ]}
      }
    ]
  }
  const state = ChatState(routes);
  return state;
}

const ChatRoom = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'ChatList' },
      { name: 'ChatRoom', params: params }
    ]
  }
  const state = ChatState(routes);
  return state;
}

const MyPageState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: { 
        routes: [
          { name: 'Home', state: {
            "index": 1,
            routes: [
              { name: 'Search' },
              { name: 'MyPageStack', state: state }
            ]}
          }
        ]}
      }
    ]
  }

  return newState;
}

const MyPage = (params={})=>{
  const routes = {
    routes: [
      { name: 'MyPage', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const MyPageFootmark = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'Footmark', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const MyPageSettingProfile = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'UserSetting', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const PreviewUserInfo = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'MyPage' },
      { name: 'UserSetting' },
      { name: 'PreviewUserInfo', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const MyPageSettingImage = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'MyPage' },
      { name: 'UserSetting' },
      { name: 'UserSettingImage', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const SecretMode = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'SecretMode', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const ProfileImageInfo = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'ProfileImageInfo', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const MemberStatus = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'MemberStatus', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const IinePlan = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'IinePlan', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const PointShopping = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'PointShopping', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const PointList = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'MyPage' },
      { name: 'PointShopping' },
      { name: 'PointList', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const Purchase = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'Purchase', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const PurchaseTrial = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'PurchaseTrial', params: params }
    ]
  }
  const state = MyPageState(routes);
  return state;
}

const AuthState = (state={})=>{
  const newState = {
    routes: [
      { name: 'Auth', state: state }
    ]
  }

  return newState;
}

const BackMyPageState = ()=>{
  const routes = { 
    name: 'Home' , 
    state: {
      "index": 1,
      routes: [
        { name: 'Search' },
        { name: 'MyPageStack' }
      ]
    }
  }

  return routes;
}

const NotifyState = (state={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 1,
    routes: [
      routesBackMyPageState,
      { name: 'Notify', state: state},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const NotifyAll = (params={})=>{
  const routes = {
    "index": 0,
    routes: [
      { name: 'All', params: params },
      { name: 'Individual' },
    ]
  }
  const newState = NotifyState(routes);
  return newState;
}

const NotifyIndividual = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'All' },
      { name: 'Individual', params: params},
    ]
  }
  const newState = NotifyState(routes);
  return newState;
}

const NotifyDetailState = (params={}, notifyState={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 2,
    routes: [
      routesBackMyPageState,
      { name: 'Notify', state: notifyState},
      { name: 'NotifyDetail', params: params},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const NotifyDetail = (params={})=>{
  const notifyState = { 
    "index": params?.type==1?1:0,
    routes: [
      { name: 'All' },
      { name: 'Individual' }
    ]
  }

  const newState = NotifyDetailState(params, notifyState);
  return newState;
}

const Help = (params={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 1,
    routes: [
      routesBackMyPageState,
      { name: 'Help', params: params},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const MyPageSettingState = (state={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'MyPage' },
      { name: 'SettingStack', state: state }
    ]
  }
  const newState = MyPageState(routes);
  return newState;
}

const MyPageSetting = (params={})=>{
  const routes = {
    routes: [
      { name: 'Setting', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const NotifInput = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'NotifInput', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const MailInput = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'MailInput', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const MailValidate = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'Setting' },
      { name: 'MailInput' },
      { name: 'MailValidate', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const PassInput = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'PassInput', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const PrivateMode = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'PrivateMode', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const FemalePrivateMode = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'Setting' },
      { name: 'PrivateMode' },
      { name: 'FemalePrivateMode', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const MalePrivateMode = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'Setting' },
      { name: 'PrivateMode' },
      { name: 'MalePrivateMode', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const HideList = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'HideList', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const BlockList = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'BlockList', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const Rule = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'Rule', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const Terms = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'Terms', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const Policy = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'Policy', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const Law = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'Law', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const Settlement = (params={})=>{
  const routes = {
    "index": 1,
    routes: [
      { name: 'Setting' },
      { name: 'Settlement', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const SmsInput = (params={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 1,
    routes: [
      routesBackMyPageState,
      { name: 'SmsInput', params: params},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const Identification = (params={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 1,
    routes: [
      routesBackMyPageState,
      { name: 'Identification', params: params},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const UploadIdentification = (params={})=>{
  const routesBackMyPageState = BackMyPageState();
  const routes = {
    "index": 2,
    routes: [
      routesBackMyPageState,
      { name: 'Identification' },
      { name: 'UploadIdentification', params: params},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const RecommendIineState = (state={})=>{
  const routes = {
    "index": 1,
    routes: [
      {
        name: 'Home', state: {
          routes: [
            { name: 'Search', state: {
              "index": 0,
              routes: [
                { name: 'Login' },
                { name: 'Regist' }
              ]} 
            }
          ]}
      },
      { name: 'RecommendIineStack', state: state},
    ]
  }
  const newState = AuthState(routes);
  return newState;
}

const RecommendIine = (params={})=>{
  const routes = {
    routes: [
      { name: 'RecommendIine', params: params},
    ]
  }
  const newState = RecommendIineState(routes);
  return newState;
}

const NotifInfo = (params={})=>{
  const routes = {
    "index": 2,
    routes: [
      { name: 'Setting' },
      { name: 'NotifInput' },
      { name: 'NotifInfo', params: params }
    ]
  }
  const state = MyPageSettingState(routes);
  return state;
}

const PaidyInfo = (params={})=>{
    const routes = {
      "index": 2,
      routes: [
        { name: 'MyPage' },
        { name: 'PointShopping' },
        { name: 'PaidyInfo', params: params }
      ]
    }
    const state = MyPageState(routes);
    return state;
}

export default {
  SearchState,
  ListLogin,
  ListRegist,
  Footmark,
  Profile,
  MeetState,
  MeetList,
  MeetCalendar,
  IineState,
  GoodOther,
  GoodMy,
  GoodSkip,
  ChatState,
  ChatMatching,
  ChatConversation,
  ChatRoom,
  MyPageState,
  MyPage,
  MyPageFootmark,
  MyPageSettingProfile,
  PreviewUserInfo,
  MyPageSettingImage,
  MemberStatus,
  IinePlan,
  SecretMode,
  ProfileImageInfo,
  AuthState,
  NotifyState,
  NotifyAll,
  NotifyIndividual,
  NotifyDetailState,
  NotifyDetail,
  Help,
  MyPageSettingState,
  MyPageSetting,
  NotifInput,
  MailInput,
  PassInput,
  PrivateMode,
  FemalePrivateMode,
  MalePrivateMode,
  HideList,
  BlockList,
  Rule,
  Terms,
  Policy,
  Law,
  Settlement,
  MailValidate,
  PointShopping,
  PointList,
  Purchase,
  PurchaseTrial,
  SmsInput,
  Identification,
  UploadIdentification,
  Report,
  ChatHideSetting,
  LikeProfile,
  RecommendIineState,
  RecommendIine,
  NotifInfo,
  PaidyInfo
};
