class ContextManager {
  _defaultContextManager = null;
  register(_ref) {
    this._defaultContextManager = _ref;
  }

  unregister(_ref) {
    this._defaultContextManager = null;
  }
  
  getDefault() {
    return this._defaultContextManager;
  }
}

export default new ContextManager();
