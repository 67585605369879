import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions, Platform } from 'react-native';
import PortalStyles from '../../styles/PortalStyles';
import Popup from '../common/Popup';
import StorageHelper from '../../utils/StorageHelper2';

const windowWidth = Dimensions.get('window').width;

export default class SettingImgPopup extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    if(parseInt(this.props?.sex)===1){
      this.popupImage = require("../../assets/images/popup-profile-image-guid-male.png"); 
    }else{
      this.popupImage = require("../../assets/images/popup-profile-image-guid-female.png"); 
    }
    this.popup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      this.getPopupStorage();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (show=true)=>{
    if(show){
      this.popup?.showPopup();
    }else{
      this.popup?.hidePopup();
    }
  }

  setPopupStorage = async()=>{
    const memberId = this.props?.memberId;
    if(!memberId) return;

    const values = { memberId: memberId };
    let settingImgPopupHistory = await StorageHelper.getObjectData('settingImgPopup');
    if(!Array.isArray(settingImgPopupHistory)) settingImgPopupHistory = [];
    
    const index = settingImgPopupHistory?.findIndex(
      item => memberId == item?.memberId
    );
    if(index==-1){
      settingImgPopupHistory.push(values);
      await StorageHelper.storeObjectData('settingImgPopup', settingImgPopupHistory);
    }
  }

  getPopupStorage = async()=>{
    const memberId = this.props?.memberId;
    if(!memberId) return;

    let showPopup = false;
    let settingImgPopupHistory = await StorageHelper.getObjectData('settingImgPopup');
    if(!settingImgPopupHistory) showPopup = true;
    if(Array.isArray(settingImgPopupHistory)){
      const index = settingImgPopupHistory?.findIndex(
        item => memberId == item?.memberId
      );
      if(index==-1){
        showPopup = true;
      }
    }
    if(showPopup){
      this.show();
      this.setPopupStorage();
    }
  }

  gotoSettingImg = ()=>{
    this.show(false);
    this.props?.navigation?.navigate("UserSettingImage");
  }

  render() {
    const popupWidth = windowWidth * 0.77;
    const newHeight = popupWidth * 943 / 864;

    return (
      <Popup ref={ref=>this.popup=ref} modal={true}>
        <View style={PortalStyles.overlay} 
          onStartShouldSetResponder={() => {
          }} />
        <View style={styles.container}>
          <ImageBackground resizeMode={'cover'} 
            style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
            source={this.popupImage}
          ></ImageBackground>
          <TouchableOpacity style={styles.btnBlue} onPress={this.gotoSettingImg}>
            <View style={styles.txtBtnBlueView}>
              <Text style={styles.txtBtnBlue}>画像を設定する</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.btnCancel} onPress={()=>this.popup?.hidePopup()}>
            <Text style={styles.txtBtnCancel}>閉じる</Text>
        </TouchableOpacity>
        </View>
      </Popup>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    paddingTop: 5, 
    width:'80%', 
    backgroundColor:'#fefefe', 
    borderRadius: 12,
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    marginBottom:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    textAlign:'center',
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:35,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#ffffff',
    textAlign:'center',
  },
  txtBtnBlueView:{
    ...Platform.select({
      web:{
      },
      android:{
        paddingBottom:2
      },
      ios:{
      }
    })
  }
});