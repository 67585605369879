import OverlayManager from "./OverlayManager";

export default class OverlayWrapper {
  static show(...args){
    const ref = OverlayManager.getDefault();
    ref?.show(...args);
  }

  static hide(){
    const ref = OverlayManager.getDefault();
    ref?.hide();
  }
}