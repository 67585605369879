import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Text, Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Camera } from 'expo-camera';
import { Entypo, AntDesign } from '@expo/vector-icons';
import PopupMedia from '../common/PopupMedia';
import PopupAppSetting from '../common/PopupAppSetting';

export default class UploadInquiryImagePicker extends Component {
  _isMounted = false;
  constructor(props){
    super(props);
    this.state = { image: null };
    this.PopupMedia = React.createRef();
    this.PopupRef = React.createRef();
  }
  
  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      //this.getPermissionAsync();
      if(this.props.image!=null){
        this.setState({ image: this.props.image });
      }
    }
  }

  getPermissionAsync = async () => {
    if (Platform.OS==='ios') {
      const permission = await ImagePicker.requestCameraPermissionsAsync();
    }
  };

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if (Platform.OS==='ios') {
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  };

  render() {
    let { image } = this.state;
    return (
      <View style={styles.photoContainer}>
        <TouchableOpacity onPress={()=>{
          if(Platform.OS==='web'){
            this._pickImage();
          }else{
            this.PopupMediaRef.show(true);
          }
        }}>
          {image ?
            <View style={styles.photoThumb}>
              <Image source={{ uri: image }} style={styles.photoImg} />
              <TouchableOpacity style={{position:'absolute', top:-3, right:-3, width:14, height:14, backgroundColor:'#fff', borderRadius:999,}}>
                <AntDesign name="closecircle" style={styles.icDel}/>
              </TouchableOpacity>
            </View>
          :
          <>
            <View style={{width:76, height:76, backgroundColor:'#eef1f2', borderRadius:5, justifyContent:'center', alignItems:'center',}}>
              <Entypo name="camera" style={styles.icCamera} />
              <View style={styles.descTxtIcCamera}>
                <Text style={styles.txtIcCamera}>画像を添付する</Text>
              </View>  
            </View>            
          </>
          }
        </TouchableOpacity>
        <PopupMedia ref={ref=>this.PopupMediaRef=ref} pickCamera={this._pickCamera} pickImage={this._pickImage}/>
        <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      </View>
    );
  }

}

const styles=StyleSheet.create({
  photoContainer:{
    flex:1,
    justifyContent:'center',
    alignItems:'center',
  },
  photoThumb:{
    position:'relative',
    width:76,
    height:76,
    borderRadius:5,
    shadowColor:"#b6b6b6",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:1,
    shadowRadius:4,
    elevation:10,
    zIndex:10
  },
  photoImg:{
    width:76,
    height:76,
    borderRadius:5, 
  },
  icCamera: {
    marginBottom:3,
    fontSize:38,
    color:'#98a3a5',
    textAlign:'center',
  },
  descTxtIcCamera: {
    width:65,
    height:15,
    backgroundColor:'#98a3a5',
    borderRadius:999,
  },
  txtIcCamera: {
    fontSize:8,
    fontWeight:'bold',
    lineHeight:15,
    color:'#fff',
    textAlign:'center',
  },
  icDel: {
    fontSize:14,
    color:'#f36556',
  }
});