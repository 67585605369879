import React  from 'react';
import { 
  View, Dimensions, Text, TouchableOpacity, Image, FlatList, ScrollView, TextInput, Animated, Platform
} from 'react-native';
import { AntDesign, FontAwesome, Ionicons, Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import SuggestUserColumnFormat from '../components/data/SuggestUserColumnFormat';
import { LinearGradient } from 'expo-linear-gradient';
import ActivityIndicator from '../components/common/ActivityIndicator';
import BottomMenu from '../components/common/BottomMenu';
import BottomMenuStyles from '../styles/BottomMenuStyles';
import OnlineStatus from '../components/common/OnlineStatus';
import Constants from '../constants/Constants';
import OptionData  from '../constants/option/OptionData.json';
import MatchComplete from '../components/common/MatchComplete';
import { Formik } from 'formik';
import PopupWrapper from "../components/common/PopupWrapper";
import PortalStyle from '../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import ImageViewer from 'react-native-image-zoom-viewer';
import Carousel from 'react-native-snap-carousel';
import {
  checkValue, Block16, Block16Male, Block17, Block18, Block18Male, Block19, Block20, Block21, 
  MeetToday, YellowCard, LeftArrow, RightArrow
} from '../components/common/UserInfo';
import CommonFunction from '../utils/CommonFunction';
import Popup from '../components/common/Popup';
import ComponentPopup from '../components/common/ComponentPopup';
import FixedMessageScreen from './userinfo/FixedMessageScreen';
import styles from '../styles/UserInfoStyles';
import ProfileScreen from './ProfileScreen';
import NoPoint from '../components/common/NoPoint';
import UsePoint from '../components/common/UsePoint';
import GoodNoPoint from '../components/common/GoodNoPoint';
import GoodUsePoint from '../components/common/GoodUsePoint';
import LogoNoMargin from '../components/common/LogoNoMargin';
import MessageTemplate from '../components/common/MessageTemplate';
import ExpoConstants from 'expo-constants';
import NoItemsBox from '../components/common/NoItemsBox';
import Msg from '../constants/option/Msg.json';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const { width, height } = Dimensions.get('window');
const isWeb = Platform.OS === 'web';
const inputAccessoryViewID = "inputAccessoryView1";

export default class UserInfoSwiperScreen extends ProfileScreen {
  constructor(props) {
    super(props);
    this.componentName = 'UserInfoSwiperScreen';//this.constructor?.name;
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this._isMounted) {
      const { appState, setAppState } = this.context;
      // search list
      this.userList = await this.getUserList();
      var result = this.userList.filter(function( item ) {
        return item.trialPlan !== '1';
      });
      this.userList = result;
      if(this.userList==undefined || this.userList?.length==0) {
        this.userList = [];
        /*
          this.navigation?.navigate('SearchList');
          return;
        */
      }

      const memberId = this.props.route?.params?.memberId;
      await this.getDataFromApi(memberId);
    
      // from back page
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        CommonFunction.setMainBottomTab(this.BottomTabNavigation, "none"); // hide main bottom tab
        if(Platform.OS==='web'){
          //this.setState({maskUp: true}, ()=>{ 
          //  if(appState.viewIndex!=undefined){
          //    //normal back screen
          //    setTimeout(function(){
          //      this.scrollToTop();
          //      this.setState({maskUp: false});
          //    }.bind(this), 250);
          //  }else{
            if(appState.viewIndex==undefined){
              // when refresh before back screen
              this.setState({userList: [], maskUp: true},()=>{
                let currentList = this.currentList?.filter(function (item) {
                  return item.memberId!=undefined;
                });
                if(currentList!=this.currentList){
                  this.initialIndex = currentList?.findIndex(
                    item => this.userList[this.index].memberId === item.memberId
                  );
                  this.currentList = currentList;
                }
                this.setState({ userList: this.currentList, index: this.initialIndex }, ()=>{ 
                  setTimeout(function(){
                    this.scrollToTop();
                    this.setState({maskUp: false});
                  }.bind(this), 250);
                });
              });
            }
          //});
        }

        // reload if sendMessageFlg change when back from chatroom
        if(appState?.reloadProfileFlg==1){ 
          const reloadMemberId = this.props.route?.params?.memberId;
          this.getDataFromApi(reloadMemberId, true);
          appState.reloadProfileFlg = null
          setAppState(appState);
        }
        this.setState({messageBody: ''});
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.currentUser.memberId!=this.state.currentUser.memberId){
      this.setHeader(this.state.currentUser);
      setTimeout(() => {
        const currentIndex = this.state.index;
        if(currentIndex!=undefined){
          if(this.suggestAction[currentIndex]){ // if no scroll
            this.getSuggestList();
          }
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }

  showPopupComplete = ()=>{
    PopupWrapper.setOptions({timeout:500});
    PopupWrapper.showMessage(Constants.MsgIine,'check', this.showPopUpMsg);
  }
  closeAction = ()=>{
    this.setShowArrowIcon(true);
  }
  
  render() {
    if(this.state.networkError){
      return (<View style={styles.container}><NoItemsBox msg={Msg.NetworkError} /></View>);
    }

    if(this.state.loading) return <ActivityIndicator/>;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[styles.container, SafeAreaStyles(insets).container]}>
        {this.state.maskUp===true && (<View style={styles.maskUp}><ActivityIndicator /></View>)}
        <MatchComplete ref={ref=>{this.MatchCompleteRef=ref}} navigation={this.navigation} />
        <ComponentPopup ref={ref=>this.popupRef.FixedMessagePopup=ref} 
          component={<FixedMessageScreen {...this.props} getPopupRef={this.getPopupRef} refname="FixedMessagePopup" callback={async()=>{
            this.getTemplateFromApi();
            //this.BottomMessageRef?.show();
            this.showFixedMessage(false);
          }} />} />
          <NoPoint ref={ref=>{this.NoPointRef=ref}} navigation={this.navigation} />
          <UsePoint ref={ref=>{this.UsePointRef=ref}} navigation={this.navigation} sendForceSubtractMessage={this.sendForceSubtractMessage} />
          <GoodNoPoint ref={ref=>{this.GoodNoPointRef=ref}} navigation={this.navigation} />
          <GoodUsePoint ref={ref=>{this.GoodUsePointRef=ref}} navigation={this.navigation} sendForceSubtractGood={this.sendForceSubtractGood} />
        <View style={HeadStyle.customHeaderTop}>
        <Carousel
          ref={swiper => {this.swiper=swiper}}
          scrollEnabled={this.state.scrollEnabled}
          sliderWidth={this.state.currentScreenWidth}
          itemWidth={this.state.currentScreenWidth}
          layout={"default"}
          onSnapToItem = { index => this.onChangeIndex(index) }
          data={this.state.userList}
          firstItem={this.state.index}
          initialScrollIndex={this.state.index}
          initialNumToRender={3}
          lockScrollWhileSnapping={true}
          removeClippedSubviews={true}
          getItemLayout={(_, index) => ({
            length: this.state.currentScreenWidth,
            offset: (this.state.currentScreenWidth) * (index),
            index,
          })}
          renderItem={({ item, index }) => {
            if(!item || item.memberId==undefined) return <></>;
            if(item.memberId==this.state.currentUser.memberId){
              item = this.state.currentUser;
            }

            let heightStyle = '';
            if(checkValue(item.height) && item.height>0){
              heightStyle = item.height + 'cm';
            }
            if(checkValue(item.style)){
              heightStyle += (heightStyle!='')?' / '+item.style:item.style;
            }
            
            let goodCount = item?.goodCount;
            if(goodCount==undefined || isNaN(goodCount)){
              goodCount = '';
            }
            if(this.myProfile?.sex==Constants.MALE && this.state.account?.subscription?.status!=1){
              goodCount = '?';
            }
            if(goodCount==''){
              goodCount=0;
            }

            const onlineItem = OptionData.Master.Online.Items.filter((element)=>{
              return element.id==item.online?.id;
            });

            let border1width = 0;
            if(item.introduction || (item.aeruList?.length>0)) border1width = 1;

            let income = '';
            if(item.sex==Constants.MEN || item.sex==Constants.MALE){
              heightStyle = '';
              if(checkValue(item.income)) income = item.income;
              if(!isWeb) income = '';
            }
            
            let yellowcardBottom = 0;
            if(item.meetToday==1){
              yellowcardBottom = 32;
            }

            return (
              <ScrollView 
                ref={ref =>this.scrollRef[index]=ref} 
                scrollToOverflowEnabled={true}
                style={styles.scrollContainer}
                contentContainerStyle={styles.scrollContentContainer}
                scrollEventThrottle={400}
                onScroll={({nativeEvent}) => {
                  if (this.isCloseToBottom(nativeEvent)) {
                    this.getSuggestList();
                  }
                }}
                onContentSizeChange={(width, height) => {
                  this.checkSuggestAction(index, height);
                }}>
                    <TouchableOpacity activeOpacity={0.9} onPress={()=>{
                      if(this.state.imagesList?.length>0 && this.state.imagesList[0]?.url!=''){
                        this.setState({showPopupImg: true});
                      }                      
                    }}>
                    <View style={{width:'100%', height: this.mainImgHeight, justifyContent: 'center', alignItems:'stretch'}}>
                      <LinearGradient
                        colors={['rgba(0,0,0,0.18)', 'rgba(255, 255, 255, 0)']}
                        style={{position: 'absolute',left: 0,right: 0,top: 0,height: 380,zIndex: 100}}
                      />

                      {this.state.mainIndex > 0 &&
                        <LeftArrow show={this.state.showArrowIcon} onPress={()=>this.showPrevUser()}/>
                      }
                      {this.state.mainIndex < (this.userList?.length-1) &&
                        <RightArrow show={this.state.showArrowIcon} onPress={()=>this.showNextUser()}/>
                      }
                      
                      <Image style={styles.myProfileImg} source={{uri : item.topMainImageUrl?item.topMainImageUrl:item.mainImageUrl}} />

                      {item.yellowcardFlg ==1 && <YellowCard yellowcardBottom={yellowcardBottom}/>} 
                      {item.meetToday==1 && <MeetToday/>}
                      </View>
                    </TouchableOpacity>

                    {item?.subImage?.list?.length>0 &&
                    <View style={{width:'100%'}}>
                      <View style={styles.thumbList}>
                        <ScrollView horizontal={true} style={{width:'100%'}} contentContainerStyle={{paddingLeft:14}}>
                        {item.mainImageUrl&&
                          <TouchableOpacity style={styles.separator} onPress={()=> {
                            this.showImg(item.mainImageUrl);
                            this.setState({currentImageIndex: 0});
                          }}>
                            <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : item.mainImageUrl}} />
                          </TouchableOpacity>
                        }
                        {item?.subImage?.list?.map((subImage,subIndex) => {
                          return ( 
                            <TouchableOpacity key={subIndex.toString()} style={styles.separator} onPress={()=> {
                              this.showImg(subImage.imageUrlOrg);
                              this.setState({currentImageIndex: subIndex+1});
                            }}>
                              <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : subImage.imageUrl}} />
                            </TouchableOpacity>
                          )
                        })}
                        </ScrollView>
                      </View>  
                    </View>                    
                    }

                    <View style={{paddingTop:12, paddingRight: 16, paddingBottom: 20, paddingLeft: 16,width:'100%'}}>
                      <View style={[styles.section,{ alignItems:'flex-start'}]}>
                        <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', marginBottom:12}}>
                          <View style={{marginRight:8, maxWidth:210}}>
                            <Text numberOfLines={1} style={{fontSize:20, fontWeight:"700", lineHeight:30,color:'#484848'}}>{item.name}</Text>
                          </View>
                          <View style={{marginRight:8, flexDirection:'row'}}>
                            <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848'}}>{item.age}</Text>
                            <Text style={{fontSize:20, fontWeight:"700", lineHeight:30, color:'#484848', marginLeft:8}}>{item.currentPref}</Text>
                          </View>
                          {item.newFlag==1? 
                          <View style={{marginLeft:7, paddingLeft:7, paddingRight:7, height:18, backgroundColor: '#ff8d63', borderRadius: 4}}>
                            <Text style={{fontSize:12, fontWeight:"700", lineHeight:18, color:'#fff', textAlign:'center'}}>NEW</Text>
                          </View>
                          :null}
                        </View>
                      </View>
                      
                      <View style={[styles.section,{borderBottomColor:'#e6e6e6', borderBottomWidth: border1width}]}>
                      {checkValue(item.tweet)?
                        <View style={{marginBottom:12}}>
                          <Text style={{fontSize:16, lineHeight:19}}>{item.tweet}</Text>
                        </View>
                      :null}
                      {checkValue(item.occupation)?
                        <View style={{}}>
                          <Text style={{fontSize:16, lineHeight:19}}>{item.occupation}</Text>
                        </View>
                      :null}
                      {checkValue(heightStyle)?
                        <View style={{}}>
                          <Text style={{fontSize:16, lineHeight:19}}>{heightStyle}</Text>
                        </View>
                      :null}
                      {checkValue(income)?
                        <View style={{}}>
                          <Text style={{fontSize:16, lineHeight:19}}>{income}</Text>
                        </View>
                       :null}
                        <View style={{alignItems:'flex-end'}}>
                          <View style={{flexDirection:'row', alignContent:'center', paddingBottom:12, paddingTop:12, justifyContent:'center'}}>
                            <View style={{justifyContent:'center'}}><AntDesign name="heart" size={15} style={{color:'#5cc8ca'}} /></View>
                            <Text style={{marginLeft:4, marginRight:8, fontSize:16}}>{goodCount}件</Text>
                            <View style={{justifyContent:'center'}}><OnlineStatus online={item.online?.id} /></View>
                            <Text style={{marginLeft:4, fontSize:16}}>{item.online?.label}</Text>
                          </View>
                        </View>
                      </View>
                    {item.introduction?
                    <View style={styles.section}>
                      <Text style={styles.h3}>自己紹介</Text>
                      <Text style={{fontSize:16}}>{item.introduction}</Text>
                    </View>
                    :null}
                    {item.aeruList?.length>0 &&
                      <View style={styles.section}>
                      {item.aeruList?.length<=3 ?
                        <>
                          <Text style={styles.h3}>デート可能日</Text>
                          {item.aeruList?.map((val,i) => {
                            return (
                              <View key={i} style={{height:this.rowHeightAeruList}}>
                                <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                              </View>
                            )
                          })}
                        </>
                      :
                      <>
                        <Text style={styles.h3}>デート可能日</Text>
                        <Animated.View style={{height:this.state.heightAeruList, overflow:'hidden'}}>
                          {item.aeruList?.map((val,i) => {
                            return (
                              <View key={i} style={{height:this.rowHeightAeruList}}>
                                <Text style={{fontSize:16}}>{val.year}年 {val.month}月 {val.day}日 ({val.wDay})</Text>
                              </View>
                            )
                          })}
                        </Animated.View>
                        <TouchableOpacity style={{flexDirection:'row', alignItems:'center'}} onPress={()=>{
                            this.showAeruList();
                          }}>
                          <Text style={{justifyContent:'center'}}>もっとみる </Text>
                          <AntDesign name={this.state.toggleAeruList?"down":"up"} size={16} style={{color:'#484848', paddingLeft:5}} />
                        </TouchableOpacity>
                        </>
                        }
                      </View>
                      }
                    </View>
              
                    <View style={[styles.insideBG]}>
                    <Block21 item={item} account={this.state.account} navigation={this.navigation} NoPointRef={this.NoPointRef}/>
                    {(item.sex==Constants.MEN || item.sex==Constants.MALE)?
                    <>
                      <Block18Male item={item} />
                      <Block17 item={item} />
                      <Block16Male item={item} />
                      <Block19 item={item} />
                      <Block20 item={item} />
                    </>
                    :
                    <>
                      <Block16 item={item} />
                      <Block17 item={item} />
                      <Block18 item={item} />
                      <Block19 item={item} />
                      <Block20 item={item} />
                    </>
                    }
                    </View>
                   
                   {this.state.suggestList?.length>0 &&
                    <View style={styles.inside}>
                      <View style={styles.section}>
                        <Text style={styles.h3}>似ている特徴を持つお相手</Text>
                        <FlatList
                          data={this.state.suggestList}
                          extraData={this.state.suggestList}
                          renderItem={({ item }) => (
                          <View style={{flex:1/3, marginTop:4, marginBottom:3, paddingLeft:4, paddingRight:4}}>
                            <View>
                              <SuggestUserColumnFormat
                                column={3}
                                name={item?.name}
                                img={item?.mainImageUrl}
                                age={item?.age} 
                                area={item?.currentPref} 
                                tweet={item?.tweet}
                                income={item?.income}
                                online={item?.online?.id}
                                sex={item?.sex}
                                memberId={item?.memberId}
                                goodFlag={item?.goodFlg}
                                introduction={item?.introduction}
                                onPress={()=>{
                                  this.navigation?.navigate('SameProfile',{memberId: item?.memberId});
                                }}
                                //onPressIine={this.handleSuggestOnIine}
                              />
                              {item.newFlag==1 &&
                              <View style={styles.newUser}>
                                <Image style={styles.newUserImg} source={require('../assets/images/badge-new.png')}/>
                              </View>
                              }
                            </View>
                          </View>
                          )}
                          numColumns={3}
                          keyExtractor={(item, index) => index.toString()}
                        />
                      </View>
                    </View>
                    }
                </ScrollView>
            )}}
          />

          <View style={styles.bottomTab}>
            <View style={{padding:10}}>
              <TouchableOpacity style={styles.skipBtn} onPress={()=>this.showNextUser()}>
                <Image style={styles.skipImg} source={require('../assets/images/icon/icon-skip.png')}/>
                <Text style={styles.skipText}>スキップ</Text>
              </TouchableOpacity>
            </View>
            <View style={{padding:10}}>
              <TouchableOpacity style={[styles.grid,styles.msgBtn]} onPress={()=>{
                this.checkActionMessageBtn();
              }}>
                <FontAwesome name={'comment-o'} size={30} color='#ffffff' />
                <Text style={[styles.textIcon,styles.whiteColor]}>メッセージ</Text>
              </TouchableOpacity>
            </View>
            {(this.state.currentUser?.matchFlg==0 && this.state.currentUser?.goodFlg==0) &&
            <View style={{padding:10}}> 
              <TouchableOpacity style={styles.likeBtn} onPress={()=>{
               this.sendGood();
              }}>
                <Image style={styles.likeImg} source={require('../assets/images/icon/icon-heart.png')}/>
                <Text style={styles.likeText}>{this.state.currentUser?.receiveGoodFlg==1?'ありがとう':'いいね！'}</Text>
              </TouchableOpacity>
            </View>
            }
          </View>
        
          {/** popup menu */}
          <Popup ref={ref=>this.errorPopup=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                this.errorPopup?.hidePopup();
                this.textInputMessage?.focus();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12,}}>
              <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center',}}>メッセージが入力されていません</Text>
              </View>
              <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
                this.errorPopup?.hidePopup();
                this.textInputMessage?.focus();
              }}>
                <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center',}}>OK</Text>
              </TouchableOpacity>
            </View>
          </Popup>
          <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
            <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
              this.BottomMenuRef?.hide();
              this.navigation?.navigate('Report', {memberId: this.state.currentUser.memberId});
            }}>
              <Text style={[BottomMenuStyles.txtListPopup]}>通報する</Text>
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
              this.BottomMenuRef?.hide();
              this.navigation?.navigate('ChatHideSetting', {memberId: this.state.currentUser.memberId});
            }}>
              <Text style={[BottomMenuStyles.txtListPopup, BottomMenuStyles.txtColorRed]}>非表示・ブロックの設定</Text>
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.btnListPopupCancel} onPress={()=>{
              this.BottomMenuRef?.showPopUp(false);
            }}>
              <Text style={BottomMenuStyles.txtBtnListPopupCancel}>キャンセル</Text>
            </TouchableOpacity>
          </BottomMenu>

          {/** popup message */}
          <BottomMenu ref={ref=>this.BottomMessageRef=ref} bottom={0} style={{paddingLeft:0, paddingRight:0}} closeAction={this.closeAction}> 
            <Formik
              innerRef={ref => {
                if(ref) this.formik = ref;
              }}
              initialValues={{ message: this.state.messageBody}}
              onSubmit={values => this.handleSubmitMessage(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
            <View style={{width:'100%'}}>
              <View style={{width:'100%'}}>
                <LinearGradient colors={['transparent','rgba(0,0,0,0.15)','rgba(0,0,0,0.65)']} style={{flexDirection:'row', width:'100%', height:35, zIndex:0}}>
                <Text style={{lineHeight:35,paddingLeft:10,fontSize:12,color:'#ffffff',fontWeight:'bold',textShadowColor:'rgba(0,0,0,1)',textShadowOffset:{width:0,height:0},textShadowRadius:7}}>
                  注目度UP! ダイレクトメッセージを送ろう
                  {this.myProfile?.sex==Constants.MALE && (
                  this.state.currentUser.goodFlg==1 || this.state.currentUser?.receiveGoodFlg==1?'(3pt)':'(3pt + 1いいね)'
                  )}
                  </Text>
                </LinearGradient>
                <View style={styles.blogIcClose}>
                  <TouchableOpacity onPress={()=>{
                    this.BottomMessageRef?.showPopUp(false);
                    this.setShowArrowIcon(true);
                  }}>
                    <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../assets/images/icon/close.png')}/>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{flex:1,paddingTop:15, paddingBottom:20, width:'100%', backgroundColor:'#ffffff'}}>
                {this.state.template?.length>0 ? (
                <View style={styles.blogMsgTemplate}>
                  <TouchableOpacity style={styles.btnIcEdit} onPress={()=>{ 
                    this.showFixedMessage(true);
                    //this.BottomMessageRef?.hide();
                  }}>
                    <AntDesign name="edit" style={styles.icEdit} />
                  </TouchableOpacity>
                  <MessageTemplate 
                    templateItemWidth={this.templateItemWidth}
                    templateSliderWidth={this.templateSliderWidth}
                    template={this.state.template}
                    onpressTemplate={(item)=>{
                      if(this.validateAuthen()){
                        this.handleChange(item.text)
                        // setFieldValue('message', item.text);
                    }
                    }}
                  />
                </View>
                ):
                <View style={[styles.blogMsgTemplate,{height:40}]}>
                  <TouchableOpacity style={styles.btnIcEdit} onPress={()=>{ this.showFixedMessage(true) }}>
                    <AntDesign name="edit" style={styles.icEdit} />
                  </TouchableOpacity>
                </View>
                }
                <View style={{paddingLeft:16, paddingRight:16}}>
                  <View style={styles.textareaBtnBottom}>
                    <TextInput 
                    ref={ref => {this.textInputMessage=ref}}
                    style={[styles.textAreaInput,{height: this.messageBox.minHeight}]} 
                    name="message" 
                    onChangeText={(text) => {this.handleChange(text)}}
                    onBlur={() => setFieldTouched('message')}
                    onFocus={()=>{ 
                      this.validateAuthen();
                    }}
                    value={values.message} 
                    multiline={true} 
                    placeholder="初回メッセージを送ってみましょう" 
                    placeholderTextColor="#9aa5a7"
                    //onContentSizeChange={(e) => this.updateTextAreaSize(e.nativeEvent?.contentSize?.height)}
                    editable={this.state.editable}
                    inputAccessoryViewID={inputAccessoryViewID}
                    />
                  </View>
                  <View style={styles.btnSendMsg}>
                  {this.validateFormMessage(values) ?
                    <TouchableOpacity style={styles.btnBtnSendMsg} onPress={handleSubmit}>
                      <Text style={styles.txtBtnSendMsg}>送信する</Text>
                    </TouchableOpacity>
                    :
                    <View style={[styles.btnBtnSendMsg, {opacity:0.4}]}><Text style={styles.txtBtnSendMsg}>送信する</Text></View>
                  }
                  </View>
                </View>
              </View>
            </View>
           )}
           </Formik>
          </BottomMenu>

          {/** popup image */}
          {this.state.showPopupImg &&
          <Portal style={PortalStyle.portal}>
            <View style={PortalStyle.portalContainer}>
              <Animated.View style={[PortalStyle.overlay, { opacity: this.state.backgroundOpacity.interpolate({
                  inputRange: [0.5, 0.95],
                  outputRange: [0.4, 0.8],
                })}
              ]} 
                onStartShouldSetResponder={() => {
                this.setState({ showPopupImg: false});
              }} />
              <View style={{position:'absolute', right:15, top:ExpoConstants?.statusBarHeight+15, zIndex:1, elevation:1}}>
                <TouchableOpacity onPress={()=>{ this.setState({ showPopupImg: false}) }}>
                  <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../assets/images/icon/close.png')}/>
                </TouchableOpacity>
              </View>
              <ImageViewer imageUrls={this.state.imagesList} enableSwipeDown={true} onCancel={()=>{
                this.setState({showPopupImg: false});
              }}
              backgroundColor="transparent"
              index={this.state.currentImageIndex}
              onMove={(e)=>{
                if(e.positionY>0){
                  this.state.backgroundOpacity.setValue(e.scale);
                }
              }} />
            </View>
          </Portal>
          }
        </View>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    )
  }
}