import React, { Component } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image, Platform, Dimensions } from "react-native";
import SwipeCards from "react-native-swipe-cards-deck";
import Card from '../../components/data/RecommendCard';
//import Card from '../../components/data/RecommendUserCardFormat';
import ActivityIndicator from '../../components/common/ActivityIndicator'
import Recommend22Api from "../../services/api/Recommend22Api";
import Constants from "../../constants/Constants";
import UserUtil from "../../utils/UserUtil";
import { AppContext } from '../../AppContext';
import IineApi from '../../services/api/Iine10Api';
import PopupWrapper from "../../components/common/PopupWrapper";
import NoItemsBox from '../../components/common/NoItemsBox';
import CommonFunction from "../../utils/CommonFunction";
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { FontAwesome, Ionicons } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let hRatio = 0.7;
let thumbnailHeight = Math.ceil(windowHeight*hRatio);
let maxThumbnailHeight = 600;
if(thumbnailHeight>maxThumbnailHeight) thumbnailHeight = maxThumbnailHeight;

export default class RecommendIineScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor() {
    super();
    this.swipeCardRef = React.createRef();
    this.state = {
      loading: true,
      data: [],
      countCard: 0,
      count: 0,
      countIndex: []
    };
    this.iineList = [];
    this.actionEnabled = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
      this.getListFromApi();

      this.subscribeFocus = this.navigation?.addListener('focus', async() => {
        await this.checkRemoveSearchList();
        this.actionEnabled.current = false;
      });
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.context;
    let { data } = this.state;
    if(appState.setChatHideMemberId || appState.iineList || this.iineList?.length>0){
      this.setState({loading: true, data: []},()=>{
        let filterData = data;
        if(appState.setChatHideMemberId){
          if(filterData!=undefined && filterData?.length > 0){
            filterData = filterData?.filter(function (item) {
              return item.memberId != appState.setChatHideMemberId;
            });
          }
        }
     
        if(appState.iineList && Array.isArray(appState.iineList)){
          appState.iineList?.forEach((val,index)=>{
            if(filterData!=undefined && filterData?.length > 0){
              filterData = filterData?.filter(function (item) {
                return item.memberId != val;
              });
            }
          });
        }

        if(this.iineList && Array.isArray(this.iineList)){
          this.iineList?.forEach((val,index)=>{
            if(filterData!=undefined && filterData?.length > 0){
              filterData = filterData?.filter(function (item) {
                return item.memberId != val;
              });
            }
          });
          this.iineList = [];
        }

        appState.setChatHideMemberId = null;
        appState.iineList = null;
        setAppState(appState);
        this.setState({data: filterData, loading: false},()=>{
          this.getCounter();
        });
      });
    }
  }

  setIineList = (memberId)=>{
    this.iineList.push(memberId);
    let { appState, setAppState } = this.context;
    appState.iineList = this.iineList;
    setAppState(appState);
  }

  getCounter = ()=>{
    let { countIndex } = this.state;
    const currentCard = this.getCurrentCard();
    if(currentCard!=undefined){
      const targetMemberId = currentCard?.memberId;
      let currentIndex = countIndex?.filter(function (item) {
        return item.memberId === targetMemberId;
      });
      if(currentIndex[0]!=undefined){
        this.setState({countCard: currentIndex[0]?.count})
      }
    }
  }

  counter = ()=>{
    let { countCard, count } = this.state;
    if(countCard!=undefined && count!=undefined) {
      countCard++;
      if(countCard > count) countCard = count;
      this.setState({countCard: countCard});
    }
  }

  async getListFromApi(){
    let response = {};
    let { appState } = this.context;
    
    try{
      if(appState?.recommendList!=undefined){
        response = appState?.recommendList;
      }else{
        const searchInfo = await CommonFunction.getSearchHistory(this.myProfile?.memberId);
        let from =  searchInfo.age[0];
        let to =  searchInfo.age[1];
        const responseRecommend = await Recommend22Api.getList(from,to);
        //check response
        if(!CommonFunction.checkResponseStatus(responseRecommend, false)) return;

        if(responseRecommend?.status==1){
          response = responseRecommend?.response?.profileList;
        }
      }

      const count = parseInt(response?.count);
      if(count > 0) {
        let countIndex = []
        response?.list?.forEach((val,index)=>{
          countIndex.push({ memberId: val.memberId, count: index+1});
        });
        this.setState({ 
          data: response?.list,
          count: count, 
          countCard: 1,
          countIndex: countIndex 
        });
      }else{
        this.navigation?.navigate('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false });
    }
  };
  
  getCurrentCard = () => {
    if(this.swipeCardRef?.current!=undefined){
      const currentCard = this.swipeCardRef?.current?.state?.card;
      return currentCard;
    }
    return {};
  }

  async skip(){
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 300)

    CommonFunction.overLay();
    const currentCard = this.getCurrentCard();
    const targetMemberId = currentCard?.memberId;
    if(targetMemberId){
      this.setIineList(targetMemberId);
      await Recommend22Api.postSkip(targetMemberId);
    }
    this.swipe('nope');
    setTimeout(() => {
      CommonFunction.hideOverlay();
    }, 700);
  }

  async iine(){
    const currentCard = this.getCurrentCard();
    const memberId = currentCard?.memberId;
    const checkIndex = this.iineList.findIndex(
      item => memberId === item
    );
    if(checkIndex!=-1) return;

    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 300)

    CommonFunction.overLay();
    const isYup = await this.iineAction(currentCard, true);
    if(isYup===false) {
      setTimeout(() => {
        this.swipe('yup');
        CommonFunction.hideOverlay();
      }, 300);
    }else{
      CommonFunction.hideOverlay();
    }
  }
  
  showPopupComplete = (yup=false)=>{
    PopupWrapper.setOptions({timeout:500});
    if(yup===true){
      PopupWrapper.showMessage(Constants.MsgIine,'check', this.swipeYup);
    }else{
      PopupWrapper.showMessage(Constants.MsgIine);
    }
  }

  iineAction = async(cardData,yup=false)=>{
    if(!cardData) return false;
    
    const targetMemberId = cardData?.memberId;
    const code = cardData?.code;
    this.setIineList(targetMemberId);
    try{
      const parameter = {
        targetMemberId: targetMemberId, 
        messageType: 1, 
        code: code
      };
      const responseSendGood = await IineApi.sendGood(parameter,1);
      if(responseSendGood?.status==1){ // success
        //this.showPopupComplete(yup);
        if(yup) this.swipeYup();
        return true;
      }else{
        return false;
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  swipe = (action='yup')=>{
    if(this.swipeCardRef.current!=undefined){
      this.counter();
      if(action==='nope'){
        this.swipeCardRef?.current?.swipeNope();
      }else{
        this.swipeCardRef?.current?.swipeYup();
      }
    }
  }

  swipeYup = ()=>{
    this.swipe('yup');
  }

  handleYup = (card) => {
    this.counter();
    // iine
    this.iineAction(card);
    return true;
  }
  
  handleNope = (card) => {
    this.counter();
    this.setIineList(card?.memberId);
    return true;
  }
  
  handleMaybe = (card) => {
    return true;
  }

  handleClick = async(cardData)=>{
    const currentCard = this.getCurrentCard();
    if(cardData?.memberId!=currentCard?.memberId) return;
    
    try{
      await UserUtil.setsearchResult(this.context, this.state.data);
      this.navigation?.navigate('Profile',{memberId: cardData?.memberId});
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  onClose = ()=>{
    if(this.navigation?.canGoBack()){
      this.navigation?.goBack();
    }else{
      this.navigation?.navigate('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
    }
  }

  renderNoMoreCards = () =>{
    return <NoItemsBox function={this.onClose} />;
  }

  renderNopeOverlay = ()=>{
    return (
      <View style={{flex:1, width:'100%', height:'100%', borderRadius:25, backgroundColor:'rgba(102,102,102,0.3)'}}>
        <View style={{flexDirection:'row', alignSelf:'flex-end', padding:15,}}>
          <Ionicons name="arrow-undo-sharp" style={{marginRight:10, fontSize:24, color:'#fff', textShadowColor:'rgba(255,255,255,1)', textShadowOffset:{width:1,height:1}, textShadowRadius:5,}} />
          <Text style={{fontSize:18, fontWeight:'bold', lineHeight:24, color:'#fff', textShadowColor:'rgba(255,255,255,1)', textShadowOffset:{width:1,height:1}, textShadowRadius:5,}}>スキップ</Text>
        </View>
      </View>
    );
  }

  renderYupOverlay = ()=>{
    return (
      <View style={{flex:1, width:'100%', height:'100%', borderRadius:25, backgroundColor:'rgba(205,99,137,0.3)'}}>
        <View style={{flexDirection:'row', alignSelf:'flex-start', padding:15,}}>
          <FontAwesome name="heart-o" style={{marginRight:10, fontSize:24, color:'#fff', textShadowColor:'rgba(255,255,255,1)', textShadowOffset:{width:1,height:1}, textShadowRadius:5,}} />
          <Text style={{fontSize:18, fontWeight:'bold', lineHeight:24, color:'#fff', textShadowColor:'rgba(255,255,255,1)', textShadowOffset:{width:1,height:1}, textShadowRadius:5,}}>いいね</Text>
        </View>
      </View>
    );
  }

  render(){
    if(this.state.loading) return <ActivityIndicator/>

    return (
      <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[styles.container,{ marginTop: insets.top}]}>

        <View style={{position:'relative', width:'100%', flexDirection:'row', justifyContent:'center',alignContent:'center', alignItems:'center',marginTop:windowHeight>=570?20:10, }}>
          <View style={{marginRight:5, position:'absolute', top: '50%', left: 10, marginTop: -10, }}>
            <Image source={require('../../assets/images/logo-txt.png')} style={{width:windowWidth<375? 56:61,height:windowWidth<375? 21:23}} resizeMode="contain"/>  
          </View>
          <View style={{paddingLeft:windowWidth<375? 10:12, paddingRight:windowWidth<375? 10:12, height:35, backgroundColor:'#5cc8ca', borderRadius:999, marginLeft: 10,}}>
            <Text style={{color:'#ffffff', fontSize:windowWidth<375? 14:18, fontWeight:'bold', lineHeight:35, textAlign:'center'}}>
            {this.myProfile?.sex==Constants.MALE ?
            <Text style={{paddingRight:10}}>本日のおすすめ女性</Text>
            :
            <Text style={{paddingRight:10}}>本日のおすすめ男性</Text>
            }
            {this.state.countCard}/{this.state.count}
            </Text>
          </View>
          <TouchableOpacity style={{ position:'absolute', top: 0, right:windowWidth<375? 10:10, height:'100%', justifyContent:'center',alignItems:'center', width:50}} onPress={ () => {
            this.onClose();
          }}>
            <Image style={{width:windowWidth<375? 20:24, height:windowWidth<375? 20:24}} source={require('../../assets/images/icon/ic-close.png')} resizeMode="cover"/>
          </TouchableOpacity>
        </View>
        {/** 
        <View style={{paddingTop: 20}}>
        {this.myProfile?.sex==Constants.MALE ?
          <Text style={styles.titleTxt}>この画面からは<Text style={{color:'#5cc8ca'}}>いいねが無料！</Text>積極的にアプローチしよう</Text>
          :
          <Text style={styles.titleTxt}>気になる男性にいいね！してアプローチしてみよう
          </Text>
        }
        </View>
        */}

        <View style={{width:'100%', alignItems:'center', alignSelf:'center'}}>
          <View style={styles.swipeContainer}>
            {this.state.count>0 && (
            <SwipeCards
              ref={this.swipeCardRef}
              cards={this.state.data}
              renderCard={(cardData) => (
                <TouchableOpacity activeOpacity={1} onPress={()=>{
                  this.handleClick(cardData);
                }}>
                  <Card {...cardData} 
                  message={cardData?.tweet?cardData?.tweet:cardData?.introduction} 
                  />
                </TouchableOpacity>
              )}
              extraData={this.state.data}
              keyExtractor={(cardData) => String(cardData?.memberId)}
              renderNoMoreCards={() => this.renderNoMoreCards()}
              actions={{
                nope: { show:false, onAction: this.handleNope },
                yup: { show:false, onAction: this.handleYup },
                maybe: { show:false, onAction: this.handleMaybe }
              }}
              stack={true}
              stackDepth={3}
              stackOffsetY={windowWidth>375?-18:-14}
              stackOffsetX={0}
              swipeThreshold={60}
              renderNopeOverlay={()=>this.renderNopeOverlay()}
              renderYupOverlay={()=>this.renderYupOverlay()}
              dragY={false}
              stackOpacity={false}
            />
            )}
          </View>
        </View>
        <View style={styles.cardContainer}>
          <TouchableOpacity style={styles.actionBtn} onPress={ () => this.skip() }>
            <Image style={{width:windowWidth<375?150:160, height:windowWidth<375?67:72}} source={require('../../assets/images/recommend/skip.png')} resizeMode="cover"/>
          </TouchableOpacity>

          <TouchableOpacity style={styles.actionBtn} onPress={ () => this.iine() }>
            {this.myProfile?.sex==Constants.MALE ?
            <Image style={{width:windowWidth<375?150:160, height:windowWidth<375?67:72}} source={require('../../assets/images/recommend/muryouiine.png')} resizeMode="cover"/>
            :
            <Image style={{width:windowWidth<375?150:160, height:windowWidth<375?67:72}} source={require('../../assets/images/recommend/iine.png')} resizeMode="cover"/>
            }
          </TouchableOpacity>
        </View>
      </View>
      }
      </SafeAreaInsetsContext.Consumer>
    )
  }
}

const calcMarginTopBtn = ()=>{
  if(windowHeight >= 570){
    let marginTop = windowHeight-570;
    if(marginTop>60) marginTop = 60;
    return marginTop;
  }
  return 0;
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:"#ffffff",
    //justifyContent:"center",
    justifyContent:"flex-start",
    alignItems:"center",
  },
  swipeContainer: {
    marginTop:20,
    ...Platform.select({
      ios: {
        position:'relative',
        maxHeight: thumbnailHeight
      },
      android: {
        position:'relative',
        maxHeight: thumbnailHeight
      },
      default: { }
    }),
  },
  cardContainer:{
    flex:1,
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:'90%', 
    ...Platform.select({
      ios:{
        width:'80%',
        //height:80,
        //marginTop: calcMarginTopBtn(),
      },
      android:{
        width:'80%',
        //height:80,
        //marginTop: calcMarginTopBtn(),
      },
      web:{
        width:'80%',
        //height:80,
        //marginTop: calcMarginTopBtn(),
      }
    })
  },
  skipBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    padding:5,
    width:'45%',
    height:45,
    backgroundColor:"#FFF",
    borderRadius:999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,   
  },
  skipImg:{
    marginBottom:8,
    width:36,
    height:26,
  },
  skipText:{
    fontSize:windowWidth<375? 14:16,
    fontWeight:'bold',
    lineHeight:22,
    color:"#9ca4a6",    
  },
  likeBtn:{
    flexDirection:'row',
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    padding:8,
    width:'47%',
    height:45,
    backgroundColor:"#5cc8ca",
    borderRadius:999,
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    ...Platform.select({
      ios:{
        shadowRadius: 2
      },
      android:{
        shadowRadius: 2
      },
      default:{
        shadowRadius: 1
      }
    }),
    elevation: 1,
  },
  likeImg:{
    marginRight:5,
    width:16,
    height:16,
  },
  likeText:{
    fontSize:windowWidth<375? 14:16,
    fontWeight:'bold',
    lineHeight:22,
    color:"#ffffff",
    textAlign:'center'
  },
  titleTxt:{
    fontSize:12, 
    fontWeight:'normal', 
    lineHeight:14,
    color:'#484848', 
    textAlign:'center'
  },
  actionBtn:{
    alignItems:"center",
    justifyContent:"center",
    width:'50%'
  }
});