import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';

export default class Reminder20Api extends ApiPappy{

  //1.2.1.パスワード変更用認証コード送信
  static async PasswordPostSendVerificationCode(mail){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Password/Post/SendVerificationCode/",
        { key: mail },
        true
      );
      if(__DEV__) console.log('/Password/Post/SendVerificationCode/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.PasswordPostSendVerificationCode));
            console.log('/Password/Post/SendVerificationCode/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
        
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.【ログイン前】パスワード変更
  static async PasswordPutVerificationCode(token, code, mail, pass){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Password/Put/VerificationCode/",
        {
          token: token,
          code: code,
          key: mail,
          pass: pass
        },
        true
      );
      if(__DEV__) console.log('/Password/Put/VerificationCode/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Password/Put/VerificationCode/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}