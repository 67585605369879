import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, StatusBar, Platform, FlatList, TouchableOpacity, ImageBackground, Image
} from 'react-native';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import ActivityIndicator from '../components/common/ActivityIndicator';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';

export default class PayPlanScreen extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      loading: true,
      Plan: []
    };
    
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      this.setHeader();
      this.initData();
      this.setState({loading: false});
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader(){
    this.navigation.setOptions({
      headerStyle: HeaderOptions.HeaderSetting,
      headerTitleAlign:'center',
      headerTitle: () => (
        <Text style={[HeadStyle.headerTitlePage,{fontSize:21,fontWeight:'normal'}]}>有料サービスのお申し込み</Text>
      ),
      headerLeft: () => (
        <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} onPress={ () => this.navigation.goBack() }/>
      ),
      headerRight: () => (
        <View/>
      )
    });
  }

  initData = ()=>{
    let Plan = [
      { id:1, name:'', month:'12', price:'64,800', sale_price:'55,200', price_month:'5,400', point_month:600, bg:'#f1cb3f' },
      { id:2, name:'', month:'6', price:'42,800', sale_price:'17,200', price_month:'7,133', point_month:300, bg:'#f1cb3f' },
      { id:3, name:'', month:'3', price:'24,800', sale_price:'5,200', price_month:'8,266', point_month:150, bg:'#f1cb3f' },
      { id:4, name:'', month:'1', price:'', sale_price:'', price_month:'10,000', point_month:50, bg:'#9b9b9b' }
    ];
    
    this.setState({
      Plan: Plan
    });
  }
  
  renderItem = (item,index) => (
    <TouchableOpacity key={index} onPress={()=>{
      //CommonFunction.overLay();
    }}>
      <View style={{flex:1, flexDirection:'row', marginBottom:15, }}>
        <View style={[styles.bgPlanLeft, {backgroundColor:item.bg,}]}>
          <View style={styles.planMonth}>
            <Text style={styles.numPlanMonth}>{item.month}<Text style={styles.txtNumPlanMonth}>ヵ月{"\n"}プラン</Text></Text>
          </View>          
        </View>
        <View style={styles.bgPlanRight}>
          <View style={{flexDirection:'row',justifyContent:'center',marginBottom:5,}}>
            <View style={{width:'70%'}}><Text style={{fontSize:26, fontWeight:'bold', lineHeight:40, color:'#484848',textAlign:'right',}}>{item.price_month}円</Text></View>
            <View style={{width:'30%'}}><Text style={{fontSize:11, lineHeight:20, color:'#484848', textAlign:'center',}}>(税込)</Text><Text style={{fontSize:14, fontWeight:'bold', lineHeight:20, color:'#484848', textAlign:'center',}}>円／月</Text></View>
          </View>
         
          {item.price ?
          <View style={{marginBottom:15}}><Text style={{fontSize:14, fontWeight:'bold', lineHeight:22, color:'#9b9b9b', textAlign:'center'}}>{item.month}ヵ月合計 ：{item.price}円 <Text style={{fontSize:9, fontWeight:'normal'}}>（税込）</Text></Text></View>
            :null
          }
          <View style={{marginBottom:10, padding:5, backgroundColor:'#cf3c4e'}}><Text style={{fontSize:14, lineHeight:20, color:'#ffffff', textAlign:'center'}}>毎月{item.point_month}ptゲット！</Text></View> 
          {item.sale_price ?
          <View style={{}}><Text style={{fontSize:14, fontWeight:'bold', lineHeight:22, color:'#cf3c4e', textAlign:'center'}}>合計<Text style={{marginLeft:5, marginRight:5, fontSize:18,}}>{item.sale_price}円</Text>のお得！</Text></View>
            :null
          }
        </View>
     
      </View>
    </TouchableOpacity>
  );

  render() {
    if (this.state.loading) {
      return <ActivityIndicator />;
    }

    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
        <View style={styles.ImageBackgroundContainer}>
          <ImageBackground source={require('../assets/images/common/plan-back.png')} resizeMode="cover" style={styles.ImageBackground}>
            <View style={styles.ImageBackgroundInside}>
              <View style={styles.txtImageBackgroundInside}>
                <View style={{}}>
                  <Text style={styles.titleImageBGTop}>会員急増中！</Text>
                </View>
                <View style={{position:'relative'}}>
                  <View style={styles.txtImageBGBottom}>
                    <Text style={styles.titleImageBGBottom}>今なら最大 <Text style={styles.titleImageBGBottom2}>50</Text><Text style={styles.titleImageBGBottom3}>%</Text>OFF</Text>  
                  </View>                  
                  <View style={styles.lineImageBG}></View>
                </View>
              </View>              
            </View>              
          </ImageBackground>
        </View>

          <View style={styles.blogInner}>

            <View style={styles.titleContainer}>
              <View style={styles.titleInner}>
                <Text style={styles.titleText}>有料会員の3つのメリット</Text>
              </View>
              <View style={styles.triangle}></View>
            </View>

            <View style={styles.centerContent}>
              <Image style={styles.imgCircle} source={require('../assets/images/icon/group-plan.png')}/>
              <Text style={styles.titleCenterContent}>アクティブ会員に絞れる！</Text>
              <Text style={styles.txtCenterContent}>相手のプロフィールページにログイン情報が表示されるため、より充実したやりとりが可能になります。</Text>
            </View>

            <View style={styles.centerContent}>
              <Image style={styles.imgCircle} source={require('../assets/images/icon/group-plan.png')}/>
              <Text style={styles.titleCenterContent}>人気具合がわかる！</Text>
              <Text style={styles.txtCenterContent}>相手のプロフィールページにもらったいいね！が表示されるため、どのくらい人気かがわかります。</Text>
            </View>

            <View style={styles.centerContent}>
              <Image style={styles.imgCircle} source={require('../assets/images/icon/group-plan.png')}/>
              <Text style={styles.titleCenterContent}>アプローチできる数が増える！</Text>
              <Text style={styles.txtCenterContent}>有料サービスの契約中は、毎月ポイントが追加されるため、より気になるお相手へアプローチできるようになります。</Text>
            </View>

            <View style={[styles.centerContent,{width:'100%', height:120, borderWidth:3, borderColor:'#f1cb3f'}]}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:20, color:'#f1cb3f', textAlign:'center'}}>理想のお相手に出会える可能性が{"\n"}確実に高まります！</Text>
            </View>

            <View style={styles.titleContainer}>
              <View style={styles.titleInner}>
                <Text style={styles.titleText}>利用プランを選択してください</Text>
              </View>
              <View style={styles.triangle}></View>
            </View>

          </View>

          <View style={{flex:1, paddingTop:30, paddingBottom:30, paddingRight:15, paddingLeft:15, width:'100%', backgroundColor:'#fff7eb'}}>
            {this.state.Plan.map((item,index) => {
              return this.renderItem(item,index);
            })}
            {/** 
            <FlatList style={{}}
              data={this.state.Plan}
              renderItem={this.renderItem}
              keyExtractor={(item,index) => item + index}
            />
            */}
          </View>

          <View style={[styles.blogInner, {paddingTop:30, paddingBottom:30}]}>
            <Text style={styles.font12}>【有料会員契約について】</Text>
            <Text style={styles.font12}>・お支払い額は、契約期間の金額を一括払いとなります。</Text>
            <Text style={styles.font12}>・お支払い方法は、指定のクレジットカードのみとなります。</Text>
            <Text style={styles.font12}>・有効期限の何日か前に、有料サービスの更新案内の通知メールが届きますので、続けて利用される方は、有料サービスを申し込んでください。</Text>
            <Text style={styles.font12}>・有料サービス更新のお申し込みは、有効期限の28日前から行うことが出来ます。</Text>
            <Text style={styles.font12}>・更新日前に解約のお手続きをなさった場合、残りの期間についての日割りでのご返金はいたしません。</Text>
            <Text style={styles.font12}>・有料プランご契約の方は、特定期間のみの解約はできません。</Text>
          </View>

        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    flex:1,
    height:'100%',
    backgroundColor:'#ffffff'
  },
  blogInner: {
    padding:15,
  },
  ImageBackgroundContainer:{
    width:'100%',
    height:220,
  },
  ImageBackground:{
    flex: 1, 
    flexDirection:'column', 
    justifyContent:'flex-start', 
    width:'100%',
    height:220,
  },
  ImageBackgroundInside: {
    width:'100%',
    height:220,
    backgroundColor:'rgba(0,0,0,0.4)',
  },
  txtImageBackgroundInside: {
    flex:1,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginBottom:30,
  },
  titleImageBGTop: {
    fontSize:18,
    fontWeight:'bold',
    lineHeight:27,
    color:'#ffffff',
    textAlign:'center',
  },
  txtImageBGBottom: {
    position:'relative',
    zIndex:1,
    height:60,
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'flex-end',
    alignSelf:'center',
  },
  titleImageBGBottom: {
    fontSize:26,
    fontWeight:'bold',
    color:'#ffffff',
    textAlign:'center',
  },
  titleImageBGBottom2: {
    paddingBottom:5,
    fontSize:50,
    fontWeight:'bold',
    color:'#f1cb3f',
  },
  titleImageBGBottom3: {
    fontSize:12,
    color:'#f1cb3f',
  },
  lineImageBG: {
    position:'absolute',
    bottom:0,
    left:'auto',
    right:'auto',
    width:250,
    height:0,
    borderBottomWidth:10,
    borderBottomColor:'rgba(241,203,63,0.3)',
    zIndex:0,
  },
  titleContainer:{
    marginTop:15,
    marginBottom:15,
    justifyContent:'center', 
    alignContent:'center',
    alignItems:'center', 
    alignSelf:'center',
    width:'100%',
  },
  titleInner:{
    width:'100%',
    height:50,
    backgroundColor:'#f1cb3f'
  },
  titleText:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 14,
    borderBottomWidth: 14,
    borderLeftWidth: 14,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#f1cb3f',
    borderLeftColor: 'transparent',
    transform: [{ rotate: "180deg" }],
  },
  centerContent:{
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center',
    marginBottom:30,
    width:'80%',
  },
  imgCircle: {
    marginBottom:15,
    width:80,
    height:80,
  },
  titleCenterContent: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:23,
    color:'#484848',
    textAlign:'center',
  },
  txtCenterContent: {
    fontSize:12,
    lineHeight:20,
    color:'#484848',
    textAlign:'center',
  },
  font12: {
    fontSize:12,
    lineHeight:15,
    color:'#484848',
    textAlign:'left',
  },
  planMonth: { },
  numPlanMonth: {
    paddingTop:5,
    fontSize:28,
    fontWeight:'bold',
    lineHeight:22,
    color:'#ffffff',
    textAlign:'center',
  },
  txtNumPlanMonth: {
    fontSize:16,
    fontWeight:'normal',
  },
  bgPlanLeft: {
    flex:1,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'stretch',
    borderTopLeftRadius:10,
    borderBottomLeftRadius:10,
  },
  bgPlanRight: {
    flex:2,
    padding:15,
    backgroundColor:'#ffffff',
    borderTopRightRadius:10,
    borderBottomRightRadius:10,
  },
});
