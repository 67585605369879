import React,{Component} from 'react';
import { 
  StyleSheet, Text, View, TouchableOpacity, Image, Dimensions, Platform, StatusBar 
} from 'react-native';
import { Card } from '../../components/data/UserCardFormat';
import ActivityIndicator from '../../components/common/ActivityIndicator'
import SwipeCards from "react-native-swipe-cards-deck";
import Register01Api from '../../services/api/Register01Api';

const screenWidth = Dimensions.get('window').width;
const thumbnailHeight = screenWidth;
const containerHeight = thumbnailHeight;
const swipeCardHeight = containerHeight - 20;

export default class FreeIine extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.swipeCardRef = React.createRef();
    this.iine_list = { 
      iine: [], 
      skip: [] 
    };
    this.state = {
      loading: true,
      data: [],
      iine_list: this.iine_list,
      counter: 0,
      limit: 5,
      buttonArea: true,
    };
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      let { item } = this.props;
      if(item.iine_list!=undefined){
        if(typeof item.iine_list =='string'){
          this.iine_list = JSON.parse(item.iine_list);
        }else if(Array.isArray(item.iine_list)){
          this.iine_list = item.iine_list;
        }
        this.setState({
          iine_list: this.iine_list, 
          counter: this.iine_list.iine.length
        });
      }

      let sex = 0, currentPref = 0;
      if(item.sex !=undefined && "id" in item.sex) sex = item.sex.id;
      if(item.currentPref !=undefined && "id" in item.currentPref) currentPref = item.currentPref.id;
      let values = { count:10, sex: sex, currentPref: currentPref };
      this.getListFromApi(values);
    }
  }

  async getListFromApi(values){
    let responseListNotMember = await Register01Api.ListNotMember(values);
    if(responseListNotMember?.status==1){
      let listNotMember = responseListNotMember?.response?.profileList;
      if(listNotMember !=undefined && listNotMember?.count > 0){
        let list = [];
        listNotMember.list.forEach((el)=>{
          if(this.state.iine_list.iine.indexOf(el.memberId) == -1 && this.state.iine_list.skip.indexOf(el.memberId) == -1){
            list.push(el);
          }
        });
        
        this.setState({ 
          data: list,
          loading: false
        });
      }
    }
  };

  getCurrentCard = () => {
    if(this.swipeCardRef.current!=undefined){
      const currentCard = this.swipeCardRef.current.state.card;
      return currentCard;
    }
    return {};
  }

  swipe = (action)=>{
    if(this.swipeCardRef.current!=undefined){
      if(action==='nope')
        this.swipeCardRef.current.swipeNope();
      else
      this.swipeCardRef.current.swipeYup();
    }
  }

  handleYup () {
    return true;
  }

  handleNope () {
    return true;
  }

  handleMaybe () {
    return true;
  }

  skip(){
    if(this.state.counter < this.state.limit){
      const currentCard = this.getCurrentCard();
      if(currentCard.memberId!=undefined){
        let list = this.state.iine_list;
        if(this.state.iine_list.skip.indexOf(currentCard.memberId) == -1){
          list.skip.push(currentCard.memberId);
        }

        this.setState({ iine_list: list });

        if(this.props.onChange!==undefined){
          this.props.onChange(JSON.stringify(list));
        }
        this.swipe('nope');
      }
    }
  }

  iine(){
    if(this.state.counter < this.state.limit){
      const currentCard = this.getCurrentCard();
      if(currentCard.memberId!=undefined){
        if(this.state.iine_list.iine.indexOf(currentCard.memberId) == -1){
          let list = this.state.iine_list;
          list.iine.push(currentCard.memberId);
          this.setState({
            counter: this.state.counter+1,
            iine_list: list,
          });

          if(this.props.onChange!==undefined){
            this.props.onChange(JSON.stringify(list));
          }
          this.swipe('yup');
        }
      }
    }
  }

  noMoreIineComponentDidMount = (value) => {
    this.setState({ buttonArea: value});
  }

  render() {
    const windowHeight = Dimensions.get('window').height*.83;
    if (this.state.loading) {
      return (
        <View style={{width: '100%',height: windowHeight,justifyContent: 'center',alignContent: 'center'}}>
          <ActivityIndicator />
        </View>
      );
    }

   return (
    <View style={[styles.container,{height: windowHeight}]}>
      <View style={styles.counter}>
        <Text style={styles.counterText}> {this.state.counter} / {this.state.limit} </Text>
      </View>  

      {this.state.data.length>0 ?
          <View style={{width:'100%',height:'90%', alignItems:'center', alignSelf:'center'}}>
            <View style={styles.swipeContainer}>
              <SwipeCards
                ref={this.swipeCardRef}
                cards={this.state.data}
                renderCard={(cardData) => <Card {...cardData} swipeCardHeight={swipeCardHeight}/>}
                renderNoMoreCards={() => <NoMoreCards componentDidMount={this.noMoreIineComponentDidMount}/>}
                keyExtractor={(cardData) => String(cardData.id)}
                actions={{
                  nope: { show:false, onAction: this.handleNope.bind(this) },
                  yup: { show:false, onAction: this.handleYup.bind(this) },
                  maybe: { show:false, onAction: this.handleMaybe.bind(this) },
                }}
                stack={true}
                stackDepth={3}
                stackOffsetY={20}
                stackOffsetX={0}
              />
            </View>
            {this.state.buttonArea &&
            <View style={styles.cardContainer}>
              <TouchableOpacity style={styles.skipBtn} onPress={ () => this.skip() }>
                <Image style={styles.skipImg} source={require('../../assets/images/icon/icon-skip.png')}/>
                <Text style={styles.skipText}>スキップ</Text>
              </TouchableOpacity>          
              <TouchableOpacity style={styles.likeBtn} onPress={ () => this.iine() }>
                <Image style={styles.likeImg} source={require('../../assets/images/icon/icon-heart.png')}/>
                <Text style={styles.likeText}>いいね！</Text>
              </TouchableOpacity>
            </View>
             }
          </View>
           
          :
          <NoMoreCards/>
          }

      </View>
    )
  }
}

class NoMoreCards extends Component{
  componentDidMount(){
    if(this.props.componentDidMount!=undefined)
      this.props.componentDidMount(false);
  }

  render(){
    const windowHeight = Dimensions.get('window').height*.6;
    return (
      <View style={{width:'100%',height:windowHeight,justifyContent:'center'}}>
        <Text style={[styles.labelText,{textAlign:'center'}]}>
        気になる女性にタイプ済です。{"\n"}
        右上の「スキップ」をタップして{"\n"}
        次の画面にお進みください。
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor: "#ffffff",
    justifyContent:"flex-start",
    alignItems:"center",
    marginTop:0,
  },
  swipeContainer: {
    backgroundColor: "#ffffff",
    width:'100%',
    height: containerHeight,
    alignItems: "center",
  },
  cardContainer:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-around',
    padding:10,
    width:'90%', 
    ...Platform.select({
      ios:{
        position:'absolute',
        bottom:10,
        height:80,
      },
      android:{
        position:'absolute',
        bottom:20,
        height:80,
      },
      web:{
        position:'fixed',
        height:80,
        bottom:20,
      }
    })
  },
  skipBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    width:80,
    height:80,
    backgroundColor:"#ffffff",
    borderRadius:9999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  skipImg:{
    marginBottom:8,
    width:36,
    height:26,
  },
  skipText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:"#9ca4a6",
  },
  likeBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    width:80,
    height:80,
    backgroundColor:"#5cc8ca",
    borderRadius:9999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  likeImg:{
    marginBottom:5,
    width:34,
    height:33,
  },
  likeText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:"#ffffff"
  },
  counter:{
    width:130,
    marginTop:10,
    marginRight:15,
    marginBottom:10,
    marginLeft:15,
    backgroundColor:"#5cc8ca",
    borderRadius:25,
    height:30,
    alignItems:"center",
    alignSelf:'center',
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  counterText:{
    fontSize:18,
    fontWeight:'bold',
    color:'#fff',   
  },
  labelView:{
    marginTop:10,
    marginBottom:10,
  },
  labelText:{
    fontSize:14,
    fontWeight:'bold',
    color:"#484848"
  },
  popupMsgNoShadow:{
    paddingTop:30,
    paddingBottom:30,
    paddingRight:20,
    paddingLeft:20,
    width:'40%',
    backgroundColor:'rgba(255,255,255,0.85)',
    borderRadius:8,
  },
  icCheck:{
    fontSize:60,
    lineHeight:48,
    color:'#484848',
  },
  txtPopupMsg2:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
  },
})
