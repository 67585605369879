import React, { Component } from 'react';
import { Text, View, StyleSheet, TextInput, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native';
//import {useField, useFormikContext } from 'formik';
//import DatePicker from '../../patches_modules/rmc-date-picker/lib/DatePicker';
//import PopPicker from '../../patches_modules/rmc-date-picker/lib/Popup';
//import PopupStyles from '../../patches_modules/rmc-picker/lib/PopupStyles';
import DatePicker from 'rmc-date-picker/lib/DatePicker';
import PopPicker from 'rmc-date-picker/lib/Popup';
import PopupStyles from 'rmc-picker/lib/PopupStyles';
import Moment from 'moment';

export default class DateInput extends Component {  
  _isMounted = false;

  constructor(props) {
    super();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setFullYear(this.maxDate.getFullYear() - 99);
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.defaultDate = new Date(this.maxDate.getFullYear(),0,1);
    this.date_select=[];
    this.date_select[0]=this.minDate.getFullYear();
    this.date_select[1]=this.minDate.getMonth();
    this.date_select[2]=this.minDate.getDate();
    this.state = {
      one: '',
      two: '',
      three: '',
      four: '', 
      m1: '',     
      m2: '',
      d1: '',     
      d2: '',
      showCalendarPicker : false,
      date: '',
      loading: true,
      selectDate:''
    };
    this.currentSelectDate = '';
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      if(this.props.value){
        let value = this.props.value.split('-');
        this.setState({
          one: value[0][0],
          two: value[0][1],
          three: value[0][2],
          four: value[0][3], 
          m1: value[1][0],     
          m2: value[1][1],
          d1: value[2][0],     
          d2: value[2][1],
          date: new Date(this.props.value)
        });
      }
      this.setState({loading: false});
    }
  }
  
  onChangeText(){
    /*
    let $dob = this.dateFormat(this.state.date);
    this.props.onChangeText($dob);
    */
  }

  onPickerDismiss = () => {
    /*
    this.setState({
      one: '',
      two: '',
      three: '',
      four: '', 
      m1: '',     
      m2: '',
      d1: '',     
      d2: '',
      date: ''
    });
    this.props.onChangeText('');
    */
    this.hideCalendarPicker();
  }

  dateFormat = (date) => {
    let mday = date.getDate();
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    mday = mday < 10 ? `0${mday}` : mday;
    return `${date.getFullYear()}-${month}-${mday}`;
  }

  onDatePickerChange = (date) => {
    if(this.date_select[1]!=date[1]){
      let minDateOfMonth = new Date(date[0],date[1],1);
      this.date_select=date;
      this.setState({ selectDate: minDateOfMonth });      
    }
  }

  onPickerChange = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear().toString();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    month = month.toString();
    day = day.toString();

    this.setState({ 
      one: year.substr(0,1),
      two: year.substr(1,1),
      three: year.substr(2,1),
      four: year.substr(3,1),
      m1: month.substr(0,1),
      m2: month.substr(1,1),
      d1: day.substr(0,1),
      d2: day.substr(1,1),
      date: date
    });

    this.hideCalendarPicker();

    let $dob = year+'-'+month+'-'+day;
    this.props.onChangeText($dob);
    this.currentSelectDate = date;
  }

  showCalendarPicker = ()=>{
    if(this.currentSelectDate instanceof Date){
      this.setState({showCalendarPicker: true, selectDate: this.currentSelectDate});
    }else{
      this.setState({showCalendarPicker: true});
    }
  }

  hideCalendarPicker = ()=>{
    this.setState({showCalendarPicker: false});
  }
 
  onPressPicker = ()=>{
    Keyboard.dismiss();
    this.showCalendarPicker();
  }

  render() {
    if(this.state.loading) return <></>;        
    if(this.props.value){
      this.defaultDate = new Date(this.props.value);
    }
    
    const datePicker = (
      <DatePicker
        rootNativeProps={{'data-xx': 'yy'}}
        minDate={this.minDate}
        maxDate={this.maxDate}
        defaultDate={this.defaultDate}
        date={this.state.selectDate}
        mode={'date'}
        formatMonth={ (month, date) => {return (month+1).toString().padStart(2, '0')+'月';}}
        formatDay={ (day, date) => {return day.toString().padStart(2, '0')+'日';}}
        onValueChange={this.onDatePickerChange}
        itemStyle={{
          ...Platform.select({
            android:{
              fontSize:18,
              height:40,
              fontWeight: 'normal'
            },
            ios:{
              fontSize: 18
            }
          })
        }}
      />
    );

    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={() => {
          this.onPressPicker();
        }}>
          <View style={styles.inputcontainer}>
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => {this.onChangeText()}}
                value={this.state.one}
                readOnly={true}
              />
            </View>
        
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}  
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.two}
                readOnly={true}
              />
            </View>
        
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.three}
                readOnly={true}
              />
            </View>
       
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.four}
                readOnly={true}
              />
            </View>
       
            <Text style={styles.seperator}>/</Text> 
       
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.m1}
                readOnly={true}
              />
            </View>
        
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.m2}
                readOnly={true}
              />
            </View>
       
            <Text style={styles.seperator}>/</Text>
        
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.d1}
                readOnly={true}
              />
            </View>
        
            <View style={styles.textField}>
              <TextInput
                onTouchStart={()=>{
                  if(Platform.OS==='ios'){
                    this.onPressPicker();
                  }
                }}
                style={styles.textInput}
                maxLength={1}
                onChangeText={(text) => { this.onChangeText(); }}
                value={this.state.d2}
                readOnly={true}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>

        <PopPicker
          datePicker={datePicker}
          styles={PopupStyles}
		      style={{fontFamily:'Segoe UI,roboto,sans-serif', fontSize:16}}
          transitionName="rmc-picker-popup-slide-fade"
          maskTransitionName="rmc-picker-popup-fade"
          title=""
          date={this.state.selectDate==null ? this.state.date : this.state.selectDate}
          okText="完了"
          dismissText="キャンセル"
          onDismiss={this.onPickerDismiss}
          onChange={this.onPickerChange} 
          visible={this.state.showCalendarPicker}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent:'center',
  }, 
  inputcontainer: {
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'flex-start',
    marginBottom:'2%',
    paddingHorizontal:'0%',
    height:52,
  },
  textField:{
    marginLeft:3,
    marginRight:3,
    paddingBottom:4,
    width:'9%',
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
  },
  textInput:{
    height:50,
    backgroundColor:'#eef1f2',
    borderRadius:6,
    fontSize:16,
    color:'#484848',
    textAlign:'center',
  },
  seperator:{
    marginLeft:3,
    marginRight:3,
    fontSize:16,
    lineHeight:50,
    color:'#5cc8ca',
  }
});