import React,{ Component } from 'react';
import { 
  StyleSheet, Text, Image, View, Dimensions, TouchableOpacity, ScrollView, SafeAreaView 
} from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import formStyles from '../../styles/FormStyles';
import Payment15Api from '../../services/api/Payment15Api';
import Leave19Api from '../../services/api/Leave19Api';
import moment from 'moment';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import HeaderOptions from '../../constants/HeaderOptions';
import ListStyles from '../../styles/ListStyles';

const windowWidth = Dimensions.get('window').width;
const {checkUndefined} = CommonFunction;

export default class CancelSubscriptionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expired: 0
    };
    this.popup = React.createRef();
    this.loadingPopup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();
      this.getDatatFromApi();
    }
  }

  setHeader = () =>{
    this.title = '有料会員';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getDatatFromApi(){
    if(this._isMounted){
      try{
        this.setState({ loading: true });

        const leaveCheckResponse = await Leave19Api.LeaveGetLeaveCheck();
        if(!CommonFunction.checkResponseStatus(leaveCheckResponse)) return;

        if(checkUndefined(leaveCheckResponse) && leaveCheckResponse?.status==1){
          if(leaveCheckResponse?.subscription?.status==1){
            let data = leaveCheckResponse?.response;
            let expire = data?.subscription?.expire;
            this.purchasePlatform = data?.subscription?.purchasePlatform;
            if(expire){
              expire = expire.replaceAll('/','-');
              let expireDate = moment(new Date(expire));
              if(expireDate.isValid()){
                var expired = expireDate.diff(moment().startOf('day'), 'days');
                this.setState({ expired: expired });
              }
            }
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({loading: false});
      }
    }
  };

  handleOnPress = async() =>{
    try{
      this.loadingPopup.showPopup();
      if(this.purchasePlatform == 1){
        const discardSubscriptionResponse = await Payment15Api.PaymentPutSubscriptionDiscard();
        if(discardSubscriptionResponse!=undefined){
          if(discardSubscriptionResponse?.status==1){
            this.navigation.replace('CancelSubscriptionComplete');
          }
        }
      }else if(this.purchasePlatform == 4){
        const discardPaidyResponse = await Payment15Api.PaymentPutPaidyDiscard();
        if(discardPaidyResponse!=undefined){
          if(discardPaidyResponse?.status==1){
            this.navigation.replace('CancelSubscriptionComplete');
          }
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.loadingPopup.hidePopup();
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    
    return (
      <SafeAreaView style={ListStyles.container}>
        <ScrollView style={ListStyles.scrollContainer} scrollToOverflowEnabled={true}>
          <View style={styles.topPart}>
            <View style={styles.whiteBox}>
              <View style={{marginLeft:2, marginBottom:24,}}>
                <Image style={styles.icExclamation} source={require('../../assets/images/cancel_subscription/ic-exclamation.png')}/>  
              </View>
              <Text style={styles.font1}>解約をすると以下の機能が{"\n"}ご利用できなくなります</Text>
              
              <View style={styles.blog01}>
                <View style={styles.blogFlex}>
                  <Text style={styles.font2}>お相手とメッセージのやりとりが{"\n"}できなくなります</Text>
                </View>
              </View>
              <Image style={styles.img01} source={require('../../assets/images/cancel_subscription/img01.png')}/>
            </View>         
          </View>

          <View style={styles.blogConfirm}>
            <View style={styles.titleBlogConfirm}>
              <View style={{marginRight:10, width:28,}}>
                <Image style={styles.icExclamation} source={require('../../assets/images/cancel_subscription/ic-exclamation.png')}/>
              </View>
              <View style={styles.blogFlex}>
                <Text style={styles.font2}>解除前にご確認下さい</Text>
              </View>
            </View>
            <View style={styles.itemNoteConfirm}>
              <View style={styles.icTitleBullet}>
                <Image style={styles.icBullet} source={require('../../assets/images/cancel_subscription/ic-bullet.png')}/>
              </View>
              <View style={styles.blogFlex}>
                <Text style={styles.font3}>あと{this.state.expired}日、有料会員の期間が{"\n"}残っています</Text>
              </View>
            </View>
            <View style={styles.itemNoteConfirm}>
              <View style={styles.icTitleBullet}>
                <Image style={styles.icBullet} source={require('../../assets/images/cancel_subscription/ic-bullet.png')}/>
              </View>
              <View style={styles.blogFlex}>
                <Text style={styles.font3}>有料会員を解約すると、お相手とメッセージ{"\n"}のやりとりができなくなります</Text>
              </View>
            </View>
            <View style={styles.itemNoteConfirm}>
              <View style={styles.icTitleBullet}>
                <Image style={styles.icBullet} source={require('../../assets/images/cancel_subscription/ic-bullet.png')}/>
              </View>
              <View style={styles.blogFlex}>
                <Text style={styles.font3}>料金の月割り・日割計算や{"\n"}払い戻しはできません</Text>
              </View>
            </View>
          </View>

          <View style={[formStyles.buttonPanelSetting,{marginTop:40, marginBottom:45, backgroundColor:'#fff', flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}]}>
            <TouchableOpacity onPress={()=>{this.navigation.goBack()}} style={[formStyles.submitBtn, {width:'75%', marginBottom:20,}]} >
              <Text style={{color:'#ffffff',fontWeight:'bold'}}>解約をやめる</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>{
                if(this.purchasePlatform == 1 || this.purchasePlatform == 4){
                  this.popup.showPopup()
                }else{
                  // 解約方法説明画面に遷移
                  this.navigation.navigate('SubscriptionCancelMethod');
                }
              }}  style={[formStyles.submitBtn, {backgroundColor:'#b1bfc2', shadowColor: "#798789", width:'75%'}]}>
              <Text style={{color:'#ffffff',fontWeight:'bold'}}>解約する</Text>
            </TouchableOpacity>
          </View>
            
          <Popup ref={ref=>this.popup=ref} modal={true}>
            <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
            <View style={styles.popupContainer}>
              <View style={styles.popupTextItem}>
                <Text style={styles.popupText}>有料会員を解約します。{"\n"}宜しいですか？</Text>
              </View>
              <View style={styles.btnContainer}>
                <TouchableOpacity style={[styles.btnItem,{borderRightWidth:1, borderRightColor:'#d8d8dc'}]} onPress={async()=>{
                  this.popup.hidePopup();
                  this.handleOnPress();
                }}>
                  <Text style={styles.btnText}>OK</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.btnItem,{borderLeftWidth:1, borderLeftColor:'#d8d8dc'}]} onPress={()=>{
                  this.popup.hidePopup();
                }}>
                  <Text style={styles.btnText}>キャンセル</Text>
                </TouchableOpacity>  
              </View>              
            </View>
          </Popup>
          <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
            <View style={PortalStyle.overlay} />
            <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
          </Popup>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  topPart:{
    paddingTop:24,
    paddingBottom:24,
    paddingLeft:12,
    paddingRight:12,
    backgroundColor:'#f8f9f9',
    minHeight: 600
  },
  whiteBox:{
    paddingTop:25,
    paddingLeft:20,
    paddingRight:20,
    paddingBottom:65,
    backgroundColor:'#ffffff',
    borderRadius:15,
  },
  icExclamation:{
    width:windowWidth<375? 26 : 28,
    height:windowWidth<375? 26 : 28,
  },
  blog01:{
    marginTop:70,
    marginBottom:30,
    flexDirection:'row'
  },
  titleNum01:{
    marginRight:15,
    width:15,
  },
  imgNum01:{
    width:windowWidth<375? 13 : 15,
    height:windowWidth<375? 28 : 32,
  },
  titleNum02:{
    marginRight:10,
    width:21,
  },
  imgNum02:{
    width:windowWidth<375? 18 : 21,
    height:windowWidth<375? 26 : 30,
  },
  blogFlex:{
    flex:1,
  },
  blogConfirm:{
    paddingBottom:15,
    backgroundColor:'#f8f9f9',
  },
  titleBlogConfirm:{
    flexDirection:'row',
    marginBottom:35,
    paddingTop:16,
    paddingBottom:15,
    paddingLeft:12,
    paddingRight:12,
    borderBottomColor:'#e2e2e2',
    borderBottomWidth:1,
  },
  itemNoteConfirm:{
    flexDirection:'row',
    paddingLeft:20,
    paddingRight:10,
    paddingBottom:20,
  },
  icTitleBullet:{
    marginTop:5,
    marginRight:20,
    width:14,
  },
  icBullet:{
    width:windowWidth<375? 13 : 14,
    height:windowWidth<375? 16 : 17,
  },
  blog02:{
    marginTop:70,
    marginBottom:25,
    flexDirection:'row'
  },
  img01:{
    width:'100%',
    height:windowWidth<375? 278 : 337,
  },
  img02:{
    width:'100%',
    height:windowWidth<375? 115 : 140,
  },
  font1:{
    fontSize:windowWidth<375? 19 : 22,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 28 : 33,
  },
  font2:{
    fontSize:windowWidth<375? 15 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 32,
  },
  font3:{
    fontSize:windowWidth<375? 12 : 15,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22 : 26,
  },
  btnContainer:{
    flexDirection:'row', 
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    width:'100%'
  },
  popupText:{
    fontSize:14, 
    fontWeight:'bold', 
    color:'#484848', 
    lineHeight:20, 
    textAlign:'center'
  },
  popupTextItem:{
    paddingTop:25, 
    paddingBottom:18, 
    paddingLeft:20, 
    paddingRight:20, 
    width:'100%', 
    borderBottomColor:'#d8d8dc', 
    borderBottomWidth:2,
  },
  popupContainer:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    padding: 0, width:'70%', 
    backgroundColor:'#fff', 
    borderRadius: 12,
  },
  btnText:{
    fontSize:14, 
    fontWeight:'bold', 
    color:'#5888f7', 
    lineHeight:20, 
    textAlign:'center'
  },
  btnItem:{
    padding:12, 
    width:'50%',
  },
});


