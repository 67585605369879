import React, { Component } from "react";
import { View } from "react-native";
import { Portal } from 'react-native-paper';
import PortalStyle from '../../styles/PortalStyles';

export default class Popup extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      show: false
    }
    this.timeout = 1000;
    this.modal = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      if(this.props.timeout!=undefined) this.timeout = this.props.timeout;
      if(this.props.modal!=undefined) this.modal = this.props.modal;
    }
  }
  
  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }

  showPopup = () => {
    if(this._isMounted){
      this.setState({show: true});
      if(!this.modal){
        // auto hide
        setTimeout(function(){
          this.setState({show: false})
        }.bind(this), this.timeout);
      }
    }
  }

  hidePopup = () => {
    if(this._isMounted){
      this.setState({show: false});
    }
  }

  isShow = ()=>{
    return this.state.show;
  }
  
  render(){
    if(this.state.show && this.props.children!=null){
      const style = this.props.style || null;
      return (
        <Portal style={PortalStyle.portal}>
          <View style={[PortalStyle.portalContainer,style]}>
          {this.props.children}
          </View>
        </Portal>
      );
    }
    
    return null;
  }
}
