import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  portalContainer:{
    position:'absolute',
    top:0,
    left:0,
    height:'100%',
    width:'100%',
    justifyContent:'center'
  },
  overlay:{
    height: '100%',
    width:'100%',
    backgroundColor:'#000000',
    opacity:0.5,
    position:'absolute',
    top:0,
    left:0,
  },
  overlayTransparent:{
    height: '100%',
    width:'100%',
    //backgroundColor:'#000000',
    //opacity:0.1,
    position:'absolute',
    top:0,
    left:0,
  },
  blocPopup: {
    alignItems:'center',
    width:'100%',
  },
  blocPopupInner: {
    padding:20,
    width:'90%',
    backgroundColor:'#ffffff',
    borderRadius:8,
  },
  txtPopup: {
    padding:10,
    textAlign:'center',
    fontSize:12, 
    lineHeight:14,
  },
  btnArea: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'nowrap',
    borderColor:'#ccc',
    borderWidth:1,
    textAlign:'center',
  },  
  btnPopupCancel: {
    flex:1,
    margin:1,
    paddingTop:15,
    paddingBottom:15,
    backgroundColor:'#f5f5f5',
  },
  txtBtnPopupCancel: {
    color:'#000000',
    fontSize:14,
    lineHeight:16,
    textAlign:'center',
  },
  btnPopupConfirm: {
    flex:1,
    margin:1,
    paddingTop:15,
    paddingBottom:15,
    backgroundColor:'#f5f5f5',
    borderLeftColor:'#cccccc',
    borderLeftWidth:1,
  },
  txtBtnPopupConfirm: {
    color:'#000000',
    fontSize:14,
    lineHeight:16,
    textAlign:'center',
  },
  popupMsgShadow:{
    paddingTop:30,
    paddingBottom:30,
    paddingRight:20,
    paddingLeft:20,
    width:'70%',
    backgroundColor:'rgba(255, 255, 255,0.9)',
    borderRadius:8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    ...Platform.select({
      android:{
        backgroundColor:'#ffffff',
        opacity:0.9,
      }
    })
  },
  popupMsgNoShadow:{
    paddingTop:30,
    paddingBottom:30,
    paddingRight:20,
    paddingLeft:20,
    width:'70%',
    backgroundColor:'rgba(255,255,255,0.85)',
    borderRadius:8,
  },
  icCheck:{
    fontSize:50,
    lineHeight:48,
    color:'#484848',
    textAlign:'center',
  },
  txtPopupMsg2:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
    textAlign:'center',
  },
});
