import { Formik, FormikConfig, FormikValues } from 'formik';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { View, TouchableOpacity, Text, Platform, Dimensions } from 'react-native';
import styles from '../../styles/FormStyles';
import StepIndicator from 'react-native-step-indicator';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import { useLinkTo } from '@react-navigation/native';
import Constants from '../../constants/Constants';
import AuthHelper from '../../utils/AuthHelper';
import StorageHelper from '../../utils/StorageHelper2';
import Register01Api from '../../services/api/Register01Api';
import Login02Api from '../../services/api/Login02Api';
import ProfileSetting12Api from '../../services/api/ProfileSetting12Api';
import CommonFunction from '../../utils/CommonFunction';
import WebUtil from '../../utils/WebUtil';
import useBackKey from './useBackKey';

const windowWidth = Dimensions.get('window').width;
const mainPath = 'Register';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];

  const [step, setStep] = useState(0);
  //const currentChild = childrenArray[step];
  const [gender, setGender] = useState('f');
  let genderChildrenArray = []; 
  for (let i = 0; i < childrenArray.length; i++) {
    if(childrenArray[i].props.label.indexOf(gender) >= 0){
      genderChildrenArray.push(childrenArray[i]);
    }
  }
  const currentChild = genderChildrenArray[step];
  const [completed, setCompleted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const isWeb = Platform.OS === 'web';
  const linkTo = useLinkTo();

  //detect browser back
  if(isWeb){
    const onBackPress = ()=>{
      if(__DEV__) console.log('onBackPress',step);
      if(step>0){
        const backStep = step - 1;
        const backChild = genderChildrenArray[backStep];
        handleBackButton(formValues, backChild?.props?.id);
        const backPath = `/${mainPath}/`+getPath(backStep);
        setTimeout(() => {
          window.history.replaceState(null, '', backPath);
        }, 1000);
      }
    }
    useBackKey(onBackPress);
  }

  function isLastStep() {
    return step === genderChildrenArray.length - 1;
  }

  function setWebUrl(step,gen){
    if(isWeb){
      const Path = getPath(step);
      if(Path){
        window.history.pushState({step:step}, 'Pappy', '/Register/'+Path);
        linkTo('/Register/'+Path);
      }
    }
  }

  function setWebUrlForBackHandler(step, gen){
    if(isWeb){
      const Path = getPath(step);
      if(Path){
        linkTo('/Register/'+Path);
      }
    }
  }

  function getPath(step){
    if(isWeb){
      let Path = '';
      switch(step){
        case 0:
          Path = 'user-info';
        break; 
        case 1:
          Path = 'purpose';
        break; 
        case 2:
          Path = 'profile-image';
        break; 
        case 3:
          Path = 'input-mail';
        break; 
        case 4:
          Path = 'activate';
        break;
      }
      return Path;
    }
  }

  function saveExpire(values){
    let registProfile = values;
    registProfile.expire = new Date().getTime();
    StorageHelper.storeObjectData('registProfile', registProfile);
  }

  function handleBack(values, formId){
    saveExpire(values);
    props.stepHandler(values, step, formId);

    let gen = (values?.sex?.id==Constants.FEMALE)?'f':'m';   
    setGender((gender)=>gen);
    
    if(step>0){
      setWebUrl(step-1, gen);
      return setStep((s) => s - 1);
    }else{
      // goto index
      return props?.navigation?.navigate('Index');
    }
  }

  function handleBackButton(values, formId){
    //props.stepHandler(values, step, formId);

    let gen = (values?.sex?.id==Constants.FEMALE)?'f':'m';   
    //setGender((gender)=>gen);
    
    if(step>0){
      //setWebUrl(step-1, gen);
      setWebUrlForBackHandler(step-1, gen);
      return setStep((s) => s - 1);
    }
  }

  async function handleNext(values, formId, skip, helpers){
    saveExpire(values);
    setFormValues(values);

    if(formId=='step4'){
      CommonFunction.overLay();
      try{
        // call Api EntryCheckMail
        const responseEntryCheckMail = await Register01Api.EntryCheckMail(values);
        if(responseEntryCheckMail?.status!=1) {
          return;
        }
      
        // call Api SendVerificationCodeMail
        const responseSendVerificationCodeMail = await Register01Api.SendVerificationCodeMail(values);
        if(responseSendVerificationCodeMail?.status!=1) {
          return;
        }

        const token = responseSendVerificationCodeMail.response.token;
        values.token = token;
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        CommonFunction.hideOverlay();
      }
    }else if(formId=='step5' && values?.back_step==''){ 
      try{
        CommonFunction.overLay();
        // call Api VerficationCode
        const responseVerficationCode = await Register01Api.VerificationCode(values);
        if(responseVerficationCode?.status!=1) {
          let errorMessage = responseVerficationCode?.response?.errorMessage;
          if(errorMessage) helpers?.setErrors({code: errorMessage});
          return;
        }
        const validateResult = responseVerficationCode?.response?.result;
        if(validateResult!=0) {
          let errorMessage = "入力されたコードが間違っています";
          if(validateResult==99){
            errorMessage = "入力されたコードは無効です。";
          }
          helpers?.setErrors({code: errorMessage});
          return;
        }else{
          helpers?.setErrors({code: null});
        }

        // call Api EntryMail
        const responseEntryMail = await Register01Api.EntryMail(values);
        if(responseEntryMail?.status!=1) {
          return;
        }
        const firstLoginKey = responseEntryMail?.response?.firstLoginKey;
        if(!firstLoginKey) {
          return;
        }
      
        // not check accessTime
        Login02Api.setCheckAccessFlg(false);
        // call Api LoginByFirstLoginKey
        const responseLoginByFirstLoginKey = await Login02Api.LoginByFirstLoginKey(firstLoginKey);
        if(responseLoginByFirstLoginKey?.status!=1) {
          return;
        }
        const accessToken = responseLoginByFirstLoginKey?.accessToken;
        if(accessToken=='') {
          return;
        }
        
        // ログイン情報を初期化
        const removeKeys = ['registProfile','userProfile'];
        StorageHelper.removeItems(removeKeys);
        if(Platform.OS === 'web'){
          StorageHelper.removeItem('deviceToken_web');
        }
        if(Platform.OS !== 'web'){
          StorageHelper.removeItem('deviceToken');
        }

        // not check accessTime
        ProfileSetting12Api.setCheckAccessFlg(false);
        //check user profile
        if(props?.appContext!=undefined){
          await ProfileSetting12Api.ProfileGetCheck(props.appContext);
        }else{
          await ProfileSetting12Api.ProfileGetCheck();
        }
        
        // ログイン情報を初期化
        const removeLoginKeys = ['LoginInfoMail','LoginInfoPass'];
        StorageHelper.removeItems(removeLoginKeys);
        
        // reset api check access time
        ProfileSetting12Api.resetCheckAccessFlg();
        Login02Api.resetCheckAccessFlg();

        // navigate to search
        //return props?.navigation?.replace('Auth', {screen:'Home'});
        return props?.navigation?.replace('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        CommonFunction.hideOverlay();
      }
    }

    let gen = (values?.sex?.id==Constants.FEMALE)?'f':'m';
    setGender((gender)=>gen);
    
    if(values?.back_step!=''){
      setStep((s) => s - values?.back_step);
      setWebUrl(step-values?.back_step, gen);
      values.back_step = '';
      values.code = '';
      return;
    }

    props.stepHandler(values, step, formId, skip);

    if((step===1 || step===2) && skip===true && values?.mainImage==''){
      return;
    }

    setWebUrl(step+1, gen);
    return setStep((s) => s + 1);
  }

  const gotoStep = (step)=>{
    return setStep(step);
  }

  const customStyles = {
    stepIndicatorSize: 21,
    currentStepIndicatorSize:21,
    separatorStrokeWidth: 2,
    currentStepStrokeWidth: 3,
    stepStrokeCurrentColor: '#34bedb',
    stepStrokeWidth: 3,
    stepStrokeFinishedColor: '#34bedb',
    stepStrokeUnFinishedColor: '#a5deea',
    separatorFinishedColor: '#34bedb',
    separatorUnFinishedColor: '#a5deea',
    stepIndicatorFinishedColor: '#34bedb',
    stepIndicatorUnFinishedColor: '#f4f4f4',
    stepIndicatorCurrentColor: '#f4f4f4',
    stepIndicatorLabelFontSize: 13,
    currentStepIndicatorLabelFontSize: 13,
    stepIndicatorLabelCurrentColor: '#f4f4f4',
    stepIndicatorLabelFinishedColor: '#34bedb',
    stepIndicatorLabelUnFinishedColor: '#f4f4f4',
    labelColor: '#999999',
    labelSize: 13,
    currentStepLabelColor: '#fe7013'
  }

  const renderStepIndicator = (params: any) => {
    if(params.stepStatus=== 'finished'){
     return ( 
      <Entypo name="check" style={{color:'#fff',fontSize:14}}/>
     )
    }
  }

  function checkFormStep1(values){
    if(values?.name=='' || values?.currentPref?.id=='' || values?.sex?.id==''|| values?.birthDate==''|| values?.accept==false){
      return false;
    }
    return true;
  }

  function checkFormStep2(values){
    if(values?.dateHope?.id=='' || values?.meetHope?.id==''){
      return false;
    }
    return true;
  }

  function checkFormStep3(values){
    if(values?.mainImage==''){
      return false;
    }
    return true;
  }

  function checkFormStep4(values){
    if(values?.mail=='' || values?.pass=='' || values?.agree==false){
      return false;
    }
    return true;
  }

  function checkFormStep5(values){
    if(values?.code==''){
      return false;
    }
    return true;
  }

  const checkForm = (values, formId) => {
    switch(formId){
      case 'step1':
        return checkFormStep1(values);
      break;
      case 'step2':
        return checkFormStep2(values);
      break;
      case 'step3':
      case 'step3f':
        return checkFormStep3(values);
      break;
      case 'step4':
        return checkFormStep4(values);
      break;
      case 'step5': 
        return checkFormStep5(values);
      break;
      default:
        return true;
      break;
    }
  }

  const checkDisableBtn=(values, id)=>{
    if(!checkForm(values,id)){
      return true;
    }else{
      return false;
    }
  }

  const getBtnMarginTop = ()=>{
    let marginTop = 20;
    if(currentChild?.props?.id=='step1'){
      if(windowWidth<=375) marginTop = 5;
      else marginTop = 15;
    }else if(currentChild?.props?.id=='step4' && windowWidth<=375){
      marginTop = 5;
    }
    return marginTop;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, helpers) => {
        handleNext(values, currentChild?.props?.id, false, helpers);
      }}
    >
      {({ values, handleChange, errors, setFieldTouched, touched, isValid, dirty, handleSubmit, isSubmitting, validateForm, status }) => (  
        <View style={{width:'100%'}}>   
          <View style={{flexDirection:'row',marginBottom:windowWidth>375?10:0,paddingTop:15,paddingBottom:windowWidth>375?10:0,backgroundColor:'#fdfdfd'}}>
            <TouchableOpacity style={{alignSelf:'flex-start'}} onPress={() =>{
              handleBack(values, currentChild?.props?.id);
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
            </TouchableOpacity>
            <View style={{flex:1,alignSelf:'flex-end',marginLeft:10,marginRight:10}}>
              <StepIndicator
                customStyles={customStyles}
                currentPosition={step}
                labels={['','','','','']}
                renderStepIndicator={renderStepIndicator}
              />
            </View>
           
            {(currentChild?.props?.id=='step3' || currentChild?.props?.id=='step3f') &&
              <View style={styles.skipLink}>
                <TouchableOpacity onPress={() => { handleNext(values, currentChild?.props?.id, true); }}>
                  <Text style={styles.skipLinkTxt}>スキップ</Text>
                </TouchableOpacity>
              </View>
            }
          </View>
         
          {currentChild}

          <View style={[styles.buttonPanel,{ marginTop: getBtnMarginTop()}]}>
            <TouchableOpacity
              style={checkDisableBtn(values,currentChild?.props?.id)?styles.disabledBtn:styles.loginBtn}
              disabled={checkDisableBtn(values,currentChild?.props?.id)}
              onPress={handleSubmit}
            >
              <Text style={styles.loginText}>次へ</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Formik>
  );
}