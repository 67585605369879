import { Platform } from 'react-native';
import ExpoConstants from 'expo-constants';

let headerTopMenuHeight = 44;
let bottomTabHeight = 50;
let bottomChatHeight = 150;
if(Platform.OS === 'android') {
  headerTopMenuHeight = 44;
  bottomChatHeight = 150;
}else if(Platform.OS === 'ios'){
  headerTopMenuHeight = 44;
}

export default {
  HeaderNoBorder: [
    {
      height: headerTopMenuHeight+ExpoConstants?.statusBarHeight,
      backgroundColor: '#ffffff',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
    }
  ],
  HeaderIine:[
    {
      backgroundColor: '#ffffff',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
      height: headerTopMenuHeight,
    }
  ],
  HeaderIosHeight:[
    {
      ...Platform.select({
        ios:{
          height: headerTopMenuHeight+ExpoConstants?.statusBarHeight
        }
      })
    }
  ],
  HeaderIineTitle:[
    {
      fontSize: 16,
      alignSelf: 'center',
      justifyContent: 'center',
      fontWeight:'normal',
      paddingLeft:10,
      paddingRight:10,
    }
  ],
  HeaderIineTitleColor:'#484848',
  HeaderSetting:[
    {
      backgroundColor: '#ffffff',
      borderBottomWidth: 1,
      borderBottomColor: '#b2b2b2',
      shadowColor: 'transparent',
      elevation: 0,
      //zIndex:0,
      height: headerTopMenuHeight,
      ...Platform.select({
        web:{
          
        },
        android:{
          
        },
        ios:{
          
        }
      })
    }
  ],
  HeaderNoBorder2: [
    {
      ...Platform.select({
        android:{
          height: headerTopMenuHeight,
        },
        ios:{
          height: headerTopMenuHeight+ExpoConstants?.statusBarHeight,
        },web:{
          height: headerTopMenuHeight+ExpoConstants?.statusBarHeight,
        }
      }),
      backgroundColor: '#fff',
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
    }
  ],
  headerTopMenuHeight: headerTopMenuHeight,
  bottomTabHeight: bottomTabHeight,
  bottomChatHeight: bottomChatHeight
};