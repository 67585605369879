import React,{ Component } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, ScrollView, Platform } from 'react-native';
import { Entypo, Ionicons } from '@expo/vector-icons';
import HeadStyle from '../../styles/HeadStyles';
import ComponentPopup from '../../components/common/ComponentPopup';
import MyFixedMessageScreen from './MyFixedMessageScreen';
import CommonFunction from '../../utils/CommonFunction';
import FixedPhrase23Api from '../../services/api/FixedPhrase23Api';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import BottomMenu from '../../components/common/BottomMenu';
import BottomMenuStyles from '../../styles/BottomMenuStyles';
import PopupWrapper from '../../components/common/PopupWrapper';
import Msg from '../../constants/option/Msg.json';
import OverlayWrapper from '../../components/common/OverlayWrapper';
import { AppContext } from '../../AppContext';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class FixedMessageScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.popupRef = [];
    this.BottomMenuRef = React.createRef();
    this.state = {
      loading: false,
      currentPopupKey: '',
      template: { 
        defaultTemplate: [], 
        myTemplate: [] 
      }
    }
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      // popup reference
      this.ref = this.getRef();
      this.onRefresh();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  getRef = ()=>{
    if(this.props?.getPopupRef!=undefined){
      let refname = this.props?.refname;
      if(refname==undefined) refname = 'FixedMessageScreen';
      return this.props.getPopupRef(refname);
    }
  }

  getPopupRef = (key)=>{
    return this.popupRef[key];
  }

  callback = ()=>{
    if(this.props.callback!=undefined){
      this.props.callback();
    }
  }

  getTemplateFromApi = async(targetMemberId)=>{
    try{
      this.setState({loading: true});
      const parameter = { targetMemberId: targetMemberId };
      const fixedPhraseResponse = await FixedPhrase23Api.TemplateGetList(parameter);
      if(fixedPhraseResponse!=undefined && fixedPhraseResponse?.status==1){
        const template = fixedPhraseResponse?.response?.template;
        if(template!=undefined){
          this.setState({template: template});
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false});
    }
  }

  onRefresh = ()=>{
    this.targetMemberId = this.props.route?.params?.memberId;
    this.getTemplateFromApi(this.targetMemberId);
  }

  deleteTemplate = async()=>{
    const key = this.state.currentPopupKey;
    if(key){
      const popupRef = this.popupRef[key];
      if(popupRef){
        const item = popupRef?.props?.component?.props?.item;
        if(item.id!=undefined){
          try{
            OverlayWrapper.show(true);
            const parameter = { id: item.id };
            const response = await FixedPhrase23Api.deleteTemplate(parameter);
            if(response !=undefined && response?.status==1){
              PopupWrapper.setOptions({timeout:500});
              PopupWrapper.showMessage(Msg.Delete.Success,'check', ()=>{
                this.onRefresh();
                OverlayWrapper.hide();
              });
            }else{
              OverlayWrapper.hide();
            }
          }catch(e){
            OverlayWrapper.hide();
            if(__DEV__) console.log(e);
          }
        }
      }
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator />;
    const insets = this.context?.appState?.insets;

    return (
      <View style={[styles.container,SafeAreaStyles(insets).containerMargin]}>
        <View style={HeadStyle.customHeader}>
          <Text style={HeadStyle.headerTitlePage}>定型メッセージ</Text>
          <TouchableOpacity style={{position:'absolute', left:0, height:'100%', justifyContent:'center'}} onPress={()=>{
            this.callback();
          }}>
            <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
          </TouchableOpacity>
        </View>
        <ScrollView style={[HeadStyle.customHeaderTop, styles.scrollContainer]}>
          <View style={styles.msgTemplate}>
            <View style={styles.titleMsgTemplatePage}>
              <Text style={styles.txtTitleMsgTemplatePage}>マイ定型メッセージ</Text>
            </View>
            {this.state.template?.myTemplate?.map((item,index) => {
              const key = 'popupEdit'+item.id;
              return (
                <View key={'my'+item.id} style={styles.editMsgTemplate}>
                  <View style={styles.itemEditMsgTemplate}>
                    <Text numberOfLines={3} style={styles.itemEditMsgTemplateInner}>{item.text}</Text>
                  </View>
                    <TouchableOpacity style={styles.icIconPopup} onPress={()=>{
                      this.setState({currentPopupKey: key});
                      this.BottomMenuRef.showPopUp(true);
                    }}>
                      <Ionicons name="ellipsis-horizontal" style={styles.icShowPopup} />
                    </TouchableOpacity>
                    <ComponentPopup ref={ref=>this.popupRef[key]=ref} 
                    component={<MyFixedMessageScreen {...this.props} refname={key} getRef={this.getPopupRef} callback={this.onRefresh} item={item}/>} />
                </View>
              )
            })}

            <TouchableOpacity style={styles.addMsgTemplate} onPress={()=>{
              this.popupRef?.popupAdd?.show();
            }}>
              <View style={styles.itemAddMsgTemplate}>
                <Text style={styles.txtAddMsgTemplate}>定型メッセージを追加する</Text>
              </View>
              <View style={styles.icIconPopup}>
                <Entypo name="chevron-thin-right" style={styles.icShowPopup} />
              </View>
            </TouchableOpacity>
            <ComponentPopup ref={ref=>this.popupRef.popupAdd=ref} 
              component={<MyFixedMessageScreen {...this.props} refname="popupAdd" getRef={this.getPopupRef} callback={this.onRefresh}/>} />

            {this.state.template?.defaultTemplate?.length >0 && (
            <>
            <View style={styles.titleMsgTemplatePage}>
              <Text style={styles.txtTitleMsgTemplatePage}>定型メッセージ テンプレート</Text>
            </View>
            <View style={styles.listMsgTemplate}>
            {this.state.template?.defaultTemplate?.map((item,index) => {
              return (
                <View key={'default'+item.id} style={styles.itemMsgTemplate}>
                  <View style={styles.itemMsgTemplateInner}>
                    <Text numberOfLines={3} style={styles.txtMsgTemplate}>{item.text}</Text>
                  </View>              
                </View>
              )
            })}
            </View>
            </>
            )}
          </View>
        </ScrollView>
        <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
          <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
            this.BottomMenuRef.showPopUp(false);
            const key = this.state.currentPopupKey;
            if(key){
              const popupRef = this.popupRef[key];
              if(popupRef!=undefined) popupRef?.show();
            }
          }}>
            <Text style={[BottomMenuStyles.txtListPopup]}>マイ定型メッセージの編集</Text>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
            this.BottomMenuRef.hide();
            this.deleteTemplate();
          }}>
            <Text style={[BottomMenuStyles.txtListPopup, BottomMenuStyles.txtColorRed]}>削除</Text>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.btnListPopupCancel} onPress={()=>{
            this.BottomMenuRef.showPopUp(false);
          }}>
            <Text style={BottomMenuStyles.txtBtnListPopupCancel}>キャンセル</Text>
          </TouchableOpacity>
        </BottomMenu>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    backgroundColor:'#ffffff'
  },
  scrollContainer:{
    backgroundColor:'#eef0f1',
  },
  msgTemplate:{
    paddingTop:10,
    backgroundColor:'#eef1f2',
  },
  titleMsgTemplatePage:{
    padding:10,
    backgroundColor:'#eef1f2',
  },
  txtTitleMsgTemplatePage:{
    fontSize:16,
    lineHeight:21,
    fontWeight:'bold',
    color:'#333',    
  },
  editMsgTemplate:{
    flexDirection:'row',
    width:'100%',
    minHeight:75,
    height:90,
    backgroundColor:'#fff',
    borderBottomColor:'#eef1f2',
    borderBottomWidth:1
  },
  itemEditMsgTemplate:{
    flex:1,
    justifyContent:'center', 
    alignItems:'flex-start',
    height:'100%'
  },
  itemEditMsgTemplateInner:{
    paddingTop:10,
    paddingBottom:10,
    paddingRight:45,
    paddingLeft:16,
    minHeight:75,
    fontSize:13,
    lineHeight:25
  }, 
  icIconPopup:{
    right:16,
    height:'100%',
    justifyContent:'center',
    alignItems:'flex-end'
  },
  icShowPopup:{
    fontSize:24,
    color:'#9aa5a7',
  },
  addMsgTemplate:{
    flexDirection:'row',
    width:'100%',
    backgroundColor:'#fff',
    borderBottomColor:'#eef1f2',
    height:50,
  },
  itemAddMsgTemplate:{
    flex:1,
    backgroundColor:'#fff',
    justifyContent:'center',
    height:'100%',
    paddingTop:10,
    paddingBottom:10,
    paddingRight:45,
    paddingLeft:16,
  },
  txtAddMsgTemplate:{
    fontSize:13,
    fontWeight:'normal',
    //lineHeight:25,
    color:'#2f51ff',
  },
  listMsgTemplate:{
    flex:1,
  },
  itemMsgTemplate:{
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',    
    width:'100%',
    height:95,
    backgroundColor:'#fff',
    borderBottomColor:'#eef1f2',
    borderBottomWidth:1,
  },
  itemMsgTemplateInner:{
    alignSelf:'stretch',
    paddingTop:10,
    paddingBottom:10,
    paddingRight:16,
    paddingLeft:16,
  },
  txtMsgTemplate:{
    width:'100%',
    height:'100%',
    fontSize:13,
    fontWeight:'normal',
    lineHeight:25,
    color:'#484848',
  }
});