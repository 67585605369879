import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
//import Constants from '../../constants/Constants';

/**
 * API: 03_API仕様/レスポンス仕様書/11_会いたい/会いたい機能_レスポンス仕様.xlsx
 * Design: 02_画面仕様/04_会いたい
 * Document: 02_画面仕様/04_会いたい.xlsx
 */
export default class Calendar11Api extends ApiPappy{

  //1.1.1.会いたい一覧取得
  static async GetList(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Calendar/Get/List/",
        { 
          count: (values?.count!=undefined)?values.count:20,
          page: (values?.page!=undefined)?values.page:1,
          searchMonth: values?.searchMonth,
          searchDate: values?.searchDate,
          searchPref: values?.searchPref,
          ageFrom: values?.ageFrom,
          ageTo: values?.ageTo,
          dateHope: values?.dateHope,
        },
        true
      );
      if(__DEV__) console.log('/Calendar/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.CalendarGetList));
            console.log('/Calendar/Get/List/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.会いたい一覧取得(対象ユーザーのみ)
  static async GetTargetList(targetMemberId){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Calendar/Get/TargetList/",
        { targetMemberId: targetMemberId },
        true
      );
      if(__DEV__) console.log('/Calendar/Get/TargetList/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.CalendarGetTargetList));
            console.log('/Calendar/Get/TargetList/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.会える日設定
  static async PostRegist(aeruMonthArray){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Calendar/Post/Regist/",
        { aeruMonthArray: aeruMonthArray },
        true
      );
      if(__DEV__) console.log('/Calendar/Post/Regist/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Calendar/Post/Regist/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.2.会いたい送信
  static async PostSendMeet(values, showError=1){
    try{
      if(values.messageType==undefined) values.messageType = 5; //5:会いたい
      let parameter = {
        targetMemberId: values.targetMemberId,
        aeruId: values.aeruId,
        aeruPref: values.aeruPref,
        messageType: values.messageType
      }
      if(values?.forceSubtract!=undefined) parameter.forceSubtract = 1;

      const response = await this.post(
        ApiConfig.pappy_api_url+"/Calendar/Post/SendMeet/",
        parameter,
        true
      );
      if(__DEV__) console.log('/Calendar/Post/SendMeet/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Calendar/Post/SendMeet/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data, true, false, '');
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}