import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';

//03_API仕様\レスポンス仕様書\09_通報
export default class Report09Api extends ApiPappy{

  static async GetContactCategoryList(type=1){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Contact/Get/CategoryList/",
      {type: type},
      true
      );
      if(__DEV__) console.log('/Contact/Get/CategoryList/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField.concat(ResponseConstants.GetContactCategoryList));
            console.log('/Contact/Get/CategoryList/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.通報
  static async PostReport(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Contact/Post/Report/",
        { 
          targetMemberId: values?.targetMemberId, 
          categoryId: values?.categoryId, 
          subCategoryId: values?.subCategoryId, 
          contents: values?.contents 
        },
        true
      );
      if(__DEV__) console.log('/Contact/Post/Report/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Contact/Post/Report/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}