import React, { Component } from 'react';
import {Alert, Button, View, Modal, StyleSheet, Text, Platform} from 'react-native';
import { AppContext } from '../AppContext';
import UserUtil from '../utils/UserUtil';
import Storage from '../utils/StorageHelper2';
import CommonFunction from '../utils/CommonFunction';
import * as RootNavigation from '../navigation/RootNavigation';


export default class Notification extends Component {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.alertFlg = false;
  }

  static showAlert() {
    this.alertFlg = true;
  }

  static hideAlert() {
    this.alertFlg = false;
  }

  static async funcViewNavigait(data, rootFlg) {
    var root = rootFlg;
    var nid = data.data.nid;
    var targetMemberId = data.data.memberId;

    // アクセストークンの有無チェック
    const accessToken = await Storage.getData('accessToken');      
    if(accessToken==undefined){
      if(this.alertFlg==true){
        return;
      }
      this.showAlert();
      if(Platform.OS !== 'web'){
        Alert.alert(
          'プッシュ通知エラー', 
          'Pappyにログインしていないため表示できません',
        [
          {text: 'OK',  onPress: () => this.hideAlert()},
        ]);
      } else{
        alert('Pappyにログインしていないため表示できません');
        this.hideAlert();
      }
      return;
    }

    // memNoのチェック
    let myProfile = await UserUtil.getUserProfileLocal(this.context); 
    if(myProfile?.memberId!=targetMemberId){
      if(this.alertFlg==true){
        return;
      }
      this.showAlert();
      if(Platform.OS !== 'web'){
        Alert.alert(
          'プッシュ通知エラー', 
          'ログイン中のアカウントへの通知ではないため表示できません',
        [
          {text: 'OK',  onPress: () => this.hideAlert()},
        ]);
      } else{
        alert('ログイン中のアカウントへの通知ではないため表示できません');
        this.hideAlert();
      }
      return;
    }  

    // 時間経過でプロフ一覧に戻らないようにプッシュ受信時はアクセスタイムを更新してから画面遷移
    CommonFunction.setAccessTime();

    if(root==1) {
      RootNavigation.replace('Auth', {screen:'Home', params:{ screen:'Search', params: {screen:'SearchList'}}});
      return;
    } else {
      switch (nid) {
        case '1':
          // nid:1    あしあと通知 → あしあと一覧
          RootNavigation.replace('Footmark', {params:{ fromPushFlag:1 }});
          break;
        case '2':
          // nid:2    いいね通知 → お相手からのいいね一覧
          RootNavigation.replace('Home', {screen:'Iine', params:{ screen:'IineList'}});
          break;
        case '3':
          // nid:3    ありがとう通知 → やり取り一覧（マッチング）
          Storage.storeData('mailSortOrder', 0);
          RootNavigation.replace('Home', {screen:'Chat', params:{ screen:'ChatList'}});
          break;
        case '4':
          // nid:4    メッセージ、会いたい通知（マッチングしていない） → お相手からのいいね一覧
          RootNavigation.replace('Home', {screen:'Iine', params:{ screen:'IineList'}});
          break;
        case '5':
          // nid:4    メッセージ、会いたい通知 → やり取り一覧（マッチング中）
          Storage.storeData('mailSortOrder', 1);
          RootNavigation.replace('Home',  {screen:'Chat', params:{ screen:'ChatList'}});
          break;
        case '6':
        case '7':
          // nid:6    サポート通知 → お知らせ一覧
          // nid:7    お知らせ通知 → お知らせ一覧
          RootNavigation.replace('Auth',{screen:'Notify', params:{ fromPushFlag:1 }});
          break;
        case '8':
          // nid:8    問い合わせ通知 → 問い合わせ画面
          RootNavigation.replace('Inquiry', {params:{ fromPushFlag:1 }});
          break;
      }
    }
  }
}


