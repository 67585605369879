import React,{Component} from 'react';
import { Image, View, TouchableOpacity, StyleSheet, Text, Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import PopupAppSetting from '../common/PopupAppSetting';

export default class UploadCameraPicker extends Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = { image: null };
    this.PopupRef = React.createRef();
  }
  
  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      if(this.props?.image){
        this.setState({ image: this.props.image });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _pickImage = async () => {
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      
      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props?.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  _pickCamera = async () => {
    try {
      const permission = await ImagePicker.getCameraPermissionsAsync();
      if(!permission.granted){
        if(!permission.canAskAgain){
          this.PopupRef.show(true);
          return;
        }else{
          if(Platform.OS==='ios'){
            const { granted } = await ImagePicker.requestCameraPermissionsAsync();
            if(!granted) return;
          }
        }
      }

      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        const uri = result?.assets[0]?.uri;
        this.setState({ image: uri });
        this.props?.onChange(uri);
      }
    } catch (e) {
      if(__DEV__) console.log(e);
    }
  }

  render() {
    let { image } = this.state;
    return (
      <View style={styles.photoContainer}>
        <TouchableOpacity onPress={()=>{
          this._pickCamera();
          }} 
          style={styles.previewItem}>
          {image ?
            <View style={styles.imgItem}>
              <Image source={{ uri: image }} style={{width:'100%', height:220}} />
            </View>
          :
          <Text style={styles.selectTxt}>ファイルを選択する</Text>
          }
        </TouchableOpacity>
        <PopupAppSetting ref={ref=>this.PopupRef=ref} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  photoContainer:{
    flex:1,
    justifyContent:'center',
    alignItems:'center',
    paddingTop:30,
    paddingBottom:30,
    paddingRight:15,
    paddingLeft:15,
  },
  previewItem:{
    borderWidth:1, 
    borderColor:'#ededed', 
    width:'100%', 
    height:220, 
    backgroundColor:'#ffffff', 
    borderRadius:2, 
    justifyContent:'center'
  },
  selectTxt:{
    fontWeight:'bold', 
    color:'#dddddd', 
    fontSize:24, 
    textAlign:'center'
  },
  imgItem:{
    flex:1, 
    width:'100%', 
    height:220
  }
});