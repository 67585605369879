import React, {Component} from 'react';
import { View, Dimensions, Platform, TouchableOpacity, Text, StyleSheet, Image, SafeAreaView, StatusBar } from 'react-native';
import OrderByLogin from '../screens/search/OrderByLogin';
import OrderByRegist from '../screens/search/OrderByRegist';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import TabbarOptions from '../constants/TabbarOptions';
import Storage from '../utils/StorageHelper2';
import HeadStyle from '../styles/HeadStyles';
import Constants from '../constants/Constants';
import RightPopup from '../components/common/RightPopup';
import SearchConditionScreenPopup from './SearchConditionScreenPopup';
import { AppContext } from '../AppContext';
import CommonFunction from '../utils/CommonFunction';
import Iine10Api from '../services/api/Iine10Api';
import Recommend22Api from "../services/api/Recommend22Api";
//import ActivityIndicator from '../components/common/ActivityIndicator';
import BonusLogin from '../components/common/BonusLogin';
import PrivilegeCampaignPopup from '../components/common/PrivilegeCampaignPopup';
import LoginPrivilegeCampaignPopup from '../components/common/LoginPrivilegeCampaignPopup';
import moment from 'moment';
import UserUtil from '../utils/UserUtil';
import TabBadge from '../components/common/TabBadge';
import CustomTab from '../components/common/CustomTab';
import FireBaseCommon from '../utils/FireBaseCommon';
import PurchaseFunctionIos from '../utils/PurchaseFunction_ios';
import { queryPurchasesInApp, getDeviceReceipt, removeDeviceReceipt } from '../modules/purchase';
import Payment15Api from '../services/api/Payment15Api_android';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import RequestNofityPermissionPopup from '../components/common/RequestNofityPermissionPopup';
const { checkUndefined } = CommonFunction;
import WebUtil from '../utils/WebUtil';


const Tab = createMaterialTopTabNavigator();
const windowWidth = Dimensions.get('window').width;
const inputWidth = windowWidth - 120;

export default class SearchScreen extends Component {
  _isMounted = false;
  _isShowedServicePoint = false;
  _isShowedSuggestList = false;
  static contextType = AppContext;

  constructor() {
    super();
    this.tab = { 
      num: 2, 
      width: 90 
    };
    this.tabBarItemWidth = windowWidth/this.tab.num;
    this.left = ((windowWidth/this.tab.num)-this.tab.width)/2;
    this.sortOrder = { login: 0, regist: 1 };
    this.title = 'Search'; // web title page
    this.defaultText = '検索条件を設定する';
    this.state = {
      badge: { footmark: 0 },
      searchInfoTxt : this.defaultText,
      parentState: {},
      privilegeFlag: -1,
      suggestLoading: false
    }
    this.rightPopupRef = [];
    this.bonusLoginRef = React.createRef();
    this.PrivilegeCampaignPopupRef = React.createRef();
    this.LoginPrivilegeCampaignPopupRef = React.createRef();
    this.RequestNofityPermissionPopupRef = React.createRef();
    this.currentFocus = '';
  }
  
  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){

      this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
      this.storeName = 'lastAccess'+this.myProfile?.memberId;

      // check startup
      this.checkStartUp();
      this.setSearchCondition();

      if(Platform.OS!=='web'){
        // iOS & Android
        this.checkFcmToken();
        if(Platform.OS==='ios'){
          // ログイン直後に課金初期化、未完了トランザクションIDのチェックを実施
          PurchaseFunctionIos.purchaseInit();
          this.checkSaveTransaction();
        }
      }else {
        // if(CommonFunction.isAndroidUA()){
        //   this.checkWebFcmToken();
        // }
        WebUtil.AdjustInitial();
        let webUUID = await WebUtil.getWebUUID();
      }
      
      if(Platform.OS == 'android'){
        this.androidPurchaseCall();
      }

      // focus screen
      this.subscribeFocus = this.props.navigation?.addListener('focus', () => {
        this.setSearchCondition();
        // check startup
        this.checkStartUp();

        if(this._isShowedServicePoint && this._isShowedSuggestList){
          if(this.state.privilegeFlag > -1 && this.state.privilegeFlag == 1){
            this.checkPrivilegeCampaign();
          }
        }
        // show bottom tab
        CommonFunction.showBottomTab(this.props.navigation);
      });
    }
  }

  checkFcmToken = async () => {
    // FireBase共通処理:fcmTokenの設定
    await FireBaseCommon.registFcmToken();
  }

  checkWebFcmToken = async () => {
    setTimeout(() => {
      this.RequestNofityPermissionPopupRef.show();
    }, 700);
  }

  checkSaveTransaction = async () => {
    //トランザクションIDのチェック
    await PurchaseFunctionIos.checkStaPaymentPurchasePoint();
  }
  
  checkStartUp = async()=>{
    const isYesterday = await this.isYesterday();
    if(isYesterday){
      if(this.myProfile?.sex==Constants.MALE){
        // check login bonus
        await this.checkPostServicePoint();
      }
      //check recommend iine
      const account = await UserUtil.getAppState(this.context,'account');
      if(account?.activated!=0){
        await this.checkSuggestList();
      }
      this.setLastAccessApi();
    } else{
      this._isShowedServicePoint = true;
      this._isShowedSuggestList = true;
    }
    this.checkLoginPrivilegeCampaign();

    if(Platform.OS==='web'){
      if(CommonFunction.isAndroidUA()){
          this.checkWebFcmToken();
        }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  setTopBadge = (badge)=>{
    this.setState({badge: badge});
  }

  setPrivilegeFlag = (privilegeFlag)=>{
    this.setState({privilegeFlag: privilegeFlag})
    if(this._isShowedServicePoint && this._isShowedSuggestList){
      if(privilegeFlag > -1 && privilegeFlag == 1){
        this.checkPrivilegeCampaign();
      }
    }
  }

  clearFootmark = ()=>{
    let { badge } = this.state;
    if(badge!=undefined){
      badge.footmark = 0;
      this.setState({badge: badge});
    }
  }

  getSortOrderId = async()=>{
    let { appState } = this.context;
    let sortOrder = appState?.sortOrder;
    if(sortOrder==undefined){
      sortOrder = await Storage.getData('sortOrder');
    }
    return sortOrder;
  }
  
  getTabRef = async()=>{
    const sortOrder = await this.getSortOrderId();
    let tabRef;
    if(sortOrder==this.sortOrder?.regist){
      tabRef = this.getRegistTabRef();
    }else{
      tabRef = this.getLoginTabRef();
    }

    return tabRef;
  }

  getOtherTabRef = async()=>{
    const sortOrder = await this.getSortOrderId();
    let otherTabRef;
    if(sortOrder==this.sortOrder?.regist){
      otherTabRef = this.getLoginTabRef();
    }else{
      otherTabRef = this.getRegistTabRef();
    }

    return otherTabRef;
  }

  getRegistTabRef = ()=>{
    return this.tabRegistRef;
  }

  getLoginTabRef = ()=>{
    return this.tabLoginRef;
  }

  HeaderFootStep =()=>{ 
    return (
      <TouchableOpacity style={{alignItems:'flex-end', width: 50}} onPress={() => {
        this.clearFootmark();
        this.props.navigation?.navigate('Footmark');
      }}>
        <View style={[styles.headerFootStep, {position:'relative'}]}>
          {this.state.badge?.footmark>0 && (
          <View style={{position:'absolute', right:10, top:0, zIndex:10}}><TabBadge badge={this.state.badge?.footmark}/></View>
          )}
          <Image style={styles.imgFootprint} source={require('../assets/images/footprint_new.png')}/>
        </View>
      </TouchableOpacity>
    );
  }

  SearchHeaderIcon =()=>{
    return (
      <View style={{flexDirection:'row',justifyContent:'center', alignItems:'stretch',width: inputWidth, marginTop: 0}}>
        <RightPopup ref={ref=>this.rightPopupRef.SearchConditionScreenPopup=ref} 
          component={<SearchConditionScreenPopup {...this.props} getRightPopupRef={this.getRightPopupRef} callback={this.initialSearch} refname="SearchConditionScreenPopup" />}>
          <View style={styles.inputSearch}>
            <Image style={{marginTop:2, marginLeft:3, width:16, height:16}} source={require('../assets/images/ic-search.png')}/>
            <Text style={styles.txtSearch} numberOfLines={1}>{this.state.searchInfoTxt}</Text>
          </View>
        </RightPopup>
      </View>
    );
  }

  getRightPopupRef = (key)=>{
    return this.rightPopupRef[key];
  }

  initialSearch = async()=>{
    this.tabRef = await this.getTabRef();
    if(this.tabRef!=undefined){
      this.setSearchCondition();
      this.tabRef?.onReload();
    }
  }

  setActiveTab = (sortOrder)=>{
    try{
      let { appState, setAppState } = this.context;
      appState.sortOrder = sortOrder;
      setAppState(appState);
      Storage.storeData('sortOrder', sortOrder);
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  reloadTab = (sortOrder)=>{
    let tabRef;
    if(sortOrder==this.sortOrder?.regist){
      tabRef = this.getRegistTabRef();
    }else{
      tabRef = this.getLoginTabRef();
    }
    if(tabRef) tabRef?.onReload();
  }

  parentState = (state=null)=>{
    if(state){
      this.setState({parentState: state});
    }else{
      return this.state.parentState;
    }
  }

  setSearchCondition = async()=>{
    //const searchInfo = await Storage.getObjectData('searchInfo');
    this.myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
    const searchInfo = await CommonFunction.getSearchHistory(this.myProfile?.memberId);

    if(searchInfo){
      let mainImageText = this.myProfile?.sex==Constants.FEMALE? '画像あり' : '顔写真あり';
      let searchParams = [];
      if(searchInfo.ageTxt) 
        searchParams.push(searchInfo.ageTxt);
      if(searchInfo.mainImage) searchParams.push(mainImageText);
      if(searchInfo.addsTxt && Constants.NOTSETTEXT.indexOf(searchInfo.addsTxt)==-1) 
        searchParams.push(searchInfo.addsTxt);
      if(searchInfo.heightTxt && Constants.NOTSETTEXT.indexOf(searchInfo.heightTxt)==-1) 
        searchParams.push(searchInfo.heightTxt);
      if(searchInfo.styleTxt && Constants.NOTSETTEXT.indexOf(searchInfo.styleTxt)==-1) 
        searchParams.push(searchInfo.styleTxt);
      if(searchInfo.vibeTxt && Constants.NOTSETTEXT.indexOf(searchInfo.vibeTxt)==-1) 
        searchParams.push(searchInfo.vibeTxt);
      if(searchInfo.personalityTxt && Constants.NOTSETTEXT.indexOf(searchInfo.personalityTxt)==-1) 
        searchParams.push(searchInfo.personalityTxt);
      if(searchInfo.occupationTxt && Constants.NOTSETTEXT.indexOf(searchInfo.occupationTxt)==-1) 
        searchParams.push(searchInfo.occupationTxt);
      if(searchInfo.incomeTxt && Constants.NOTSETTEXT.indexOf(searchInfo.incomeTxt)==-1) 
        searchParams.push(searchInfo.incomeTxt);
      if(searchInfo.assetsTxt && Constants.NOTSETTEXT.indexOf(searchInfo.assetsTxt)==-1) 
        searchParams.push(searchInfo.assetsTxt);
      if(searchInfo.educationTxt && Constants.NOTSETTEXT.indexOf(searchInfo.educationTxt)==-1) 
        searchParams.push(searchInfo.educationTxt);
      if(searchInfo.bornTxt && Constants.NOTSETTEXT.indexOf(searchInfo.bornTxt)==-1) 
        searchParams.push(searchInfo.bornTxt);
      if(searchInfo.alcoholTxt && Constants.NOTSETTEXT.indexOf(searchInfo.alcoholTxt)==-1) 
        searchParams.push(searchInfo.alcoholTxt);
      if(searchInfo.cigaretteTxt && Constants.NOTSETTEXT.indexOf(searchInfo.cigaretteTxt)==-1) 
        searchParams.push(searchInfo.cigaretteTxt);
      if(searchInfo.holidayTxt && Constants.NOTSETTEXT.indexOf(searchInfo.holidayTxt)==-1) 
        searchParams.push(searchInfo.holidayTxt);
      if(searchInfo.dateHopeTxt && Constants.NOTSETTEXT.indexOf(searchInfo.dateHopeTxt)==-1) 
        searchParams.push(searchInfo.dateHopeTxt);
      if(searchInfo.meetHopeTxt && Constants.NOTSETTEXT.indexOf(searchInfo.meetHopeTxt)==-1) 
        searchParams.push(searchInfo.meetHopeTxt);
      if(searchInfo.lastLoginTimeTxt && Constants.NOTSETTEXT.indexOf(searchInfo.lastLoginTimeTxt)==-1) 
        searchParams.push(searchInfo.lastLoginTimeTxt);
      if(searchInfo.recentJoin) searchParams.push('最近入会');
        searchParams = searchParams.join(',');
      
      if(searchParams){
        this.setState({searchInfoTxt: searchParams});
      }else{
        this.setState({searchInfoTxt: this.defaultText});
      }
    }
  }

  checkPostServicePoint = async()=>{
    try{
      Iine10Api.setCheckAccessFlg(false);
      const postServicePointResponse = await Iine10Api.postServicePoint();
      Iine10Api.resetCheckAccessFlg();
      if(postServicePointResponse?.status==1){
        const continueCount = postServicePointResponse?.response?.continueCount;
        setTimeout(() => {
          this.bonusLoginRef?.show(continueCount);
        }, 500);
      } else{
        this._isShowedServicePoint = true;
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  checkSuggestList = async()=>{
    const searchInfo = await CommonFunction.getSearchHistory(this.myProfile?.memberId);
    let from =  searchInfo.age[0];
    let to =  searchInfo.age[1];
    this.setState({suggestLoading: true});
    try{
      Recommend22Api.setCheckAccessFlg(false);
      const responseRecommend = await Recommend22Api.getList(from,to);
      Recommend22Api.resetCheckAccessFlg();
      if(responseRecommend?.status==1){
        const response = responseRecommend?.response?.profileList;
        const count = parseInt(response?.count);
        if(count > 0) {
          let { appState, setAppState } = this.context;
          appState.recommendList = response;
          setAppState(appState);
          this.props.navigation?.navigate('RecommendIineStack', {screen:'RecommendIine'});
        } else{
          this._isShowedSuggestList = true;
        }
      } else{
        this._isShowedSuggestList = true;
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({suggestLoading: false});
    }
  }

  checkPrivilegeCampaign = async()=>{
    const isShowedPopup = await this.isShowedPopup();
    if(!isShowedPopup && this.myProfile?.sex==Constants.MALE){
      try{
        setTimeout(() => {
          this.PrivilegeCampaignPopupRef?.show(1);
          this.setIsShowedPopup();
        }, 500);
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }
  }

  checkLoginPrivilegeCampaign = async()=>{
    const isShowLoginPopup = await this.isShowLoginPopup();
    if(isShowLoginPopup==1){
      try{
        setTimeout(() => {
          this.LoginPrivilegeCampaignPopupRef?.show(1);
          this.setIsShowedPopup();
          this.setIsShowLoginPopup();
        }, 500);
      }catch(e){
        if(__DEV__) console.log(e);
      }
    }
  }

  isYesterday = async()=>{
    const lastAccessApi = await Storage.getData(this.storeName);
    if(lastAccessApi){
      const startOfDay = moment().startOf('day').unix();
      if(lastAccessApi < startOfDay) 
        return true;
      else 
        return false;
    }
    return true;
  }

  setLastAccessApi = async()=>{
    Storage.storeData(this.storeName, moment().unix());
  }

  isShowedPopup = async()=>{
    const isShowedPopup = await Storage.getData('isShowedPopup2024-9m');
    if(!isShowedPopup){
      return false;
    }
    return true;
  }

  setIsShowedPopup = async()=>{
    Storage.storeData('isShowedPopup2024-9m', true);
  }

  isShowLoginPopup = async()=>{
    const isShowLoginPopup = await Storage.getData('isShowLoginPopup');
    if(!isShowLoginPopup || isShowLoginPopup==0){
      return false;
    }
    return true;
  }

  setIsShowLoginPopup = async()=>{
    Storage.storeData('isShowLoginPopup', 0);
  }

  customTab = (props)=>{
    return <CustomTab {...props} />
  }
  
  androidPurchaseCall = async()=>{
    const responcs = await queryPurchasesInApp()
    console.log('queryPurchasesInApp:', JSON.stringify(responcs))
    await this.purchasePointGooglePlay();
  }

  purchasePointGooglePlay= async()=>{
    const receiptData = getDeviceReceipt()
    const receiptList = receiptData.split('##')
    if(!receiptData || !(receiptList[2] === 'true') || !(receiptList[4] === 'true')) {
      return;
    }
    try{      
      const loading = await Storage.getObjectData('loading_android_purchase')
      if(loading) return;
      await Storage.storeObjectData('loading_android_purchase', true);

      const responsePurchasePointGooglePlay = await Payment15Api.purchasePointGooglePlay(receiptList[0], receiptList[1] );

      if(!CommonFunction.checkResponseStatus(responsePurchasePointGooglePlay)) return;

      if(checkUndefined(responsePurchasePointGooglePlay) && responsePurchasePointGooglePlay?.status==1){
        removeDeviceReceipt()
        PopupWrapper.showMessage('ポイントを購入しました。', 'check');

        const point = responsePurchasePointGooglePlay?.point;
        this.setState({
          point: point
        });
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(responsePurchasePointGooglePlay)){
          const response = responsePurchasePointGooglePlay?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
          if(errorCode==ErrorCode.PurchasePointGooglePlay.incorrectReceipt || errorCode==ErrorCode.PurchasePointGooglePlay.overlappedReceipt){
            removeDeviceReceipt()
          }
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      await Storage.storeObjectData('loading_android_purchase', false);
    }
  }

  render() {
    if(this.state.suggestLoading) return <></>

    const screenOptions = {
      //title: this.title,
      lazy: true,
      swipeEnabled: false,
      tabBarLabelStyle: TabbarOptions.labelStyle,
      tabBarStyle: TabbarOptions.style,
      tabBarItemStyle:{ width: this.tabBarItemWidth },
      tabBarIndicatorStyle: [TabbarOptions.indicatorStyle, {width: this.tab.width, left: this.left}],
      tabBarActiveTintColor: TabbarOptions.activeTintColor,
      tabBarInactiveTintColor: TabbarOptions.inactiveTintColor,
      removeClippedSubviews: true,
      animationEnabled:false
    };

    return (
      <SafeAreaView style={styles.container}>
        <BonusLogin ref={ref=>{this.bonusLoginRef=ref}} />
        <PrivilegeCampaignPopup ref={ref=>this.PrivilegeCampaignPopupRef=ref} />
        <LoginPrivilegeCampaignPopup ref={ref=>this.LoginPrivilegeCampaignPopupRef=ref} />
        <RequestNofityPermissionPopup ref={ref=>this.RequestNofityPermissionPopupRef=ref} />
        <View style={[HeadStyle.customHeader2, styles.headerContainer]}>
          <View style={styles.logoTxt}>
            <Image source={require('../assets/images/logo-txt.png')} style={{width:windowWidth<375? 56:64,height:windowWidth<375? 21:24,}} resizeMode="contain"/>  
          </View>
          {this.SearchHeaderIcon()}
          {this.HeaderFootStep()}
        </View>
        <Tab.Navigator
        {...(Platform.OS==='web'?{tabBar:this.customTab}:{})}
        screenOptions={screenOptions}
        screenListeners={({ navigation, route }) => ({
          focus: (e) => {
            this.currentFocus = route?.name;
          },
          tabPress: (e) => {
            let tabRef = this.getLoginTabRef();
            let sortOrder = this.sortOrder.login;
            let otherTabRef = this.getRegistTabRef();
            if(route?.name=='Regist'){
              tabRef = this.getRegistTabRef();
              sortOrder = this.sortOrder.regist;
              otherTabRef = this.getLoginTabRef();
            }
            //clear other tab data
            if(otherTabRef){
              otherTabRef.no_more = 1;
              otherTabRef.setState({data:[]});
            }

            if(tabRef){
              e.preventDefault();
              navigation.navigate(route.name);
              if(this.currentFocus!=route.name){
                this.reloadTab(sortOrder);
              }
            }
          },
        })}
        timingConfig={{
          duration: 30, // will disable the animation
        }}
        >
          <Tab.Screen name="Login" 
          children={(props)=><OrderByLogin ref={ref=>this.tabLoginRef=ref} setPrivilegeFlag={this.setPrivilegeFlag} setIkuyoStatus={this.props?.setIkuyoStatus} setBadge={this.props?.setBadge} setTopBadge={this.setTopBadge} parentState={this.parentState} otherTabRef={this.getRegistTabRef} tabRef={this.getLoginTabRef} {...props} />}
          options={{ tabBarLabel: 'ログイン順' }} 
          listeners={{
            focus: () => {
              this.setActiveTab(this.sortOrder.login);
            },
            tabPress: (e) => {
              //e.preventDefault();
              //this.reloadTab(this.sortOrder.login);
            }
          }}
          />
          <Tab.Screen name="Regist" 
          children={(props)=><OrderByRegist ref={ref=>this.tabRegistRef=ref} setPrivilegeFlag={this.setPrivilegeFlag} setIkuyoStatus={this.props?.setIkuyoStatus} setBadge={this.props?.setBadge} setTopBadge={this.setTopBadge} parentState={this.parentState} otherTabRef={this.getLoginTabRef} tabRef={this.getRegistTabRef} {...props} />}
          options={{ tabBarLabel: '新規登録順' }}
          listeners={{
            focus: () => {
              this.setActiveTab(this.sortOrder.regist);
            },
            tabPress: (e) => {
              //e.preventDefault();
              //this.reloadTab(this.sortOrder.regist);
            }
          }}
          />
        </Tab.Navigator>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({ 
  container:{
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff'
  },
  inputSearch:{
    flexDirection:'row',
    marginLeft:10,
    marginRight:20,
    padding:4,
    backgroundColor:'#eef1f2',
    borderRadius:10,
    ...Platform.select({
      ios: {
        marginTop:0,
        height:30,
        paddingRight: 20,
      },
      android: {
        marginTop:0,
      },
      web:{
        marginTop:7,
    }}),
  },
  icSearch:{
    marginLeft:5,
    fontSize:18,
    color:'#9aa5a7',
  },
  txtSearch: {
    marginLeft:5,
    color:'#9aa5a7',
    fontSize:13,
    fontWeight:'bold',
    ...Platform.select({
      ios: {
        marginTop: 1,
        lineHeight:20
      },
      android: {
        marginTop: -1,
        lineHeight:20
      },
      web:{
        marginTop:1,
    }})
  },
  imgFootprint: {
    marginRight:24,
    width:28,
    height:28,
  },
  headerFootStep:{
    height: 28,
    justifyContent:'flex-end',
    ...Platform.select({
      ios: {
        marginTop: 0,
      },
      android: {
        marginTop: 0
      },
      web:{
        marginTop:10,
    }})
  },
  headerContainer:{
    flexDirection:'row', 
    borderBottomWidth: 0, 
    shadowColor: 'transparent', 
    elevation: 0
  },
  logoTxt:{
    marginLeft:windowWidth<375? 5:7,
    ...Platform.select({
      ios:{

      },
      android:{ 
        marginTop:windowWidth<375? 3:5
      },
      default:{
        marginTop:windowWidth<375? 3:5
      } 
    })
  }
});