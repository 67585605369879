import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import IndexScreen from '../screens/IndexScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import RuleScreen from '../screens/setting/RuleScreen';
import ReminderScreen from '../screens/reminder/ReminderScreen';
import ActiveReminderScreen from '../screens/reminder/ActiveReminderScreen';
import TermsScreen from '../screens/setting/TermsScreen';
import PolicyScreen from '../screens/setting/PolicyScreen';
import LawScreen from '../screens/setting/LawScreen';
import SettlementScreen from '../screens/setting/SettlementScreen';
import ErrorBoundary from '../components/common/ErrorBoundary';
import CommonFunction from '../utils/CommonFunction';
import InquiryNonMemberScreen from '../screens/InquiryNonMemberScreen';
import TransitionSpec from '../constants/TransitionSpec';

const Stack = createStackNavigator();

export default function NonAuthStackNavigator({ navigation, route, props }){
  return (
    <Stack.Navigator initialRouteName="Index" screenOptions={{
      gestureEnabled: CommonFunction.gestureEnabled(),
      animationTypeForReplace:'pop',
      animationEnabled: CommonFunction.animationEnabled(),
      transitionSpec: TransitionSpec
    }}>
      <Stack.Screen name="Index" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><IndexScreen {...props} /></ErrorBoundary>}
      options={{headerShown: false}}/>
      <Stack.Screen name="Login" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><LoginScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Register" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><RegisterScreen {...props} /></ErrorBoundary>}
      options={{headerShown: false}} />
      <Stack.Screen name="Reminder" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><ReminderScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen 
      name="ActiveReminder" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><ActiveReminderScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Rule" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><RuleScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Terms" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><TermsScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Policy" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><PolicyScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Law" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><LawScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="Settlement" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><SettlementScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
      <Stack.Screen name="InquiryNonMember" {...props} 
      children={(props)=><ErrorBoundary {...props} goBack={true}><InquiryNonMemberScreen {...props} /></ErrorBoundary>}
      options={{headerShown: true}} />
    </Stack.Navigator>
  );
}