import React,{ Component } from 'react';
import { View, Image, StyleSheet, Dimensions, TouchableOpacity, ScrollView, Platform } from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import ListStyles from '../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class PaidyInfoScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      // from back page
      this.subscribeFocus = this.navigation.addListener('focus', () => {
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }  

  setHeader(){
    this.title = 'あと払い(ペイディ)とは？';
    if(this.props.setHeader!=undefined){
      this.props?.setHeader(this.title, this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }
  
  render() {

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer} 
      ref={scroller => { this.scroller = scroller; }} 
      scrollToOverflowEnabled={true}
      scrollIndicatorInsets={{ right: 1 }}>      

      <View style={{flexDirection:'column', alignItems:'center'}}>
        <Image style={styles.img01} source={require('../assets/images/paidy/info/paidy_info1.png')} resizeMode="stretch"/>
        <View style={{width:windowWidth<415? '100%' : '90%', alignItems:'center'}}> 
          <View style={[styles.buttonViewArea, {width:'90%'}]}> 
            <TouchableOpacity style={styles.actionButton} onPress={()=>{
              window.open("https://my.paidy.com/", '_blank');
            }}>
              <Image style={styles.actionButtonImg} source={require('../assets/images/paidy/info/mypaidy_button.png')} resizeMode="contain"/>
            </TouchableOpacity>
            <TouchableOpacity style={styles.actionButton} onPress={()=>{
              this.props.navigation?.goBack();
            }}>
              <Image style={styles.actionButtonImg} source={require('../assets/images/paidy/info/paidy_button.png')} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={{flexDirection:'column', alignItems:'center'}}>
        <Image style={styles.img02} source={require('../assets/images/paidy/info/paidy_info2.png')} resizeMode="stretch"/>
        <View style={{width:windowWidth<415? '100%' : '90%',backgroundColor: '#fff7f9', alignItems:'center'}}> 
          <View style={[styles.buttonViewArea, {width:'90%'}]}> 
            <TouchableOpacity style={styles.actionButton} onPress={()=>{
              window.open("https://my.paidy.com/", '_blank');
            }}>
              <Image style={styles.actionButtonImg} source={require('../assets/images/paidy/info/mypaidy_button.png')} resizeMode="contain"/>
            </TouchableOpacity>
            <TouchableOpacity style={styles.actionButton} onPress={()=>{
              this.props.navigation?.goBack();
            }}>
              <Image style={styles.actionButtonImg} source={require('../assets/images/paidy/info/paidy_button.png')} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={{flexDirection:'column', alignItems:'center', width: '100%'}}>
        <Image style={styles.img03} source={require('../assets/images/paidy/info/paidy_info3.png')} resizeMode="stretch"/>
      </View>
      <View style={styles.bottomButtonArea}> 
        <TouchableOpacity style={styles.bottomButton} onPress={()=>{
          this.props.navigation?.goBack();
        }}>
          <Image style={styles.bottomButtonImg} source={require('../assets/images/paidy/info/large_paidy_button.png')} resizeMode="contain"/>
        </TouchableOpacity>
        <TouchableOpacity style={styles.bottomButton} onPress={()=>{
          window.open("https://my.paidy.com/", '_blank');
        }}>
          <Image style={styles.bottomButtonImg} source={require('../assets/images/paidy/info/large_mypaidy_button.png')} resizeMode="contain"/>
        </TouchableOpacity>
      </View>
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  img01:{
    marginTop:0,
    alignItems:'center',
    height:windowWidth<375? 750 : 864,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  img02:{
    marginTop:0,
    alignItems:'center',
    height:windowWidth<375? 1252 : 1402,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  img03:{
    marginTop:0,
    alignItems:'center',
    height:windowWidth<375? 1026 : 1165,
    ...Platform.select({
      ios:{
        width:'100%',
      },
      android:{
        width:'100%',
      },
      default:{
        width:windowWidth<375? 320 : 375,
      }
    })
  },
  buttonViewArea:{
    flexDirection: 'row',
    width:'85%',
    height:45,
    paddingTop:0,
    paddingBottom:10,
    alignItems:'flex-end',
  },
  actionButton:{
    width:'50%',
    paddingTop:10,
    textAlign:'center',
  },
  actionButtonImg:{
    width:'98%',
    height:45,
  },
  bottomButtonArea:{
    width:'100%',
    backgroundColor:'#fff',
    marginTop:5,
    marginBottom:60,
  },
  bottomButton:{
    width:'100%',
    height: 45,
    marginBottom: 20,
    paddingTop:0,
    alignSelf:'center'
  },
  bottomButtonImg:{
    width:'85%',
    height: 45,
    paddingTop:10,
    alignSelf:'center'
  },
});
