import React, { Component } from 'react';
import { Animated, View, Text, TouchableOpacity, StyleSheet, Image, Platform, Dimensions } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import Notification from '../../utils/Notification';

const windowHeight = Dimensions.get('window').height;

export default class PushPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      fadeAnim: new Animated.Value(0),
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (remoteMessage)=>{
    this.isHide = false;
    this.remoteMessage = remoteMessage
    this.setState({ show: true});
    Animated.timing(this.state.fadeAnim, { toValue: 1, duration: 300 ,useNativeDriver: true}).start();
    setTimeout(() => {
      this.hide();
    }, 3000);  

  }

  hide = ()=>{
    Animated.timing(this.state.fadeAnim, { toValue: 0, duration: 300, useNativeDriver: true }).start(event => {
      if (event.finished) { this.setState({ show: false }); }
    });
  }

  render() {
    if(!this.state.show) return <></>;

    const animatedViewStyle = StyleSheet.flatten([
      styles.container,
      { opacity: this.state.fadeAnim },
    ]);
    let message = this.remoteMessage?.notification?.body;
    if(!message){
      message = this.remoteMessage?.data?.body;
    }
    return (

      

      <Portal style={styles.portal}>
        <View style={styles.portalContainer}>
          <Animated.View style={[animatedViewStyle,{backgroundColor:'#ffffff'}]} >
            <View style={[PortalStyle.overlay,{backgroundColor:'#00000000'}]} />
            <TouchableOpacity style={{ paddingBottom:5, paddingTop:5 ,backgroundColor:'#f6f6f6',height:'auto',width:'100%',alignSelf:'center',flexDirection:'row',flex:1}} onPress={async ()=>{
              if(this.isHide) return;
              this.isHide = true;
              Notification.funcViewNavigait(this.remoteMessage, 0);
              setTimeout(() => {
                this.hide();
              })  
            }}>

              <View>
                <Image style={{margin:12,width:50,height:50,borderRadius:10}} source={require('../../assets/images/push_icon.png')} />
              </View>
              <View style={{ width: '70%',justifyContent: 'center',}}>  
                <Text style={{margin:5}}>{message}</Text>
              </View>
            </TouchableOpacity>
          </Animated.View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  portalContainer:{
    position:'absolute',
    left:0,
    height:'auto',
    width:'100%',
    justifyContent:'center', 
    ...Platform.select({
      ios: {
        top:windowHeight<780? 20:44,
      },
      android: {
        top:0,
      },
      default: {
        top:0,
      },
    })
  },
  container:{
    backgroundColor:'#f6f6f6', 
    height:'auto', 
    width:'100%',
    alignSelf:'center', 
    flexDirection:'row',
    flex:1,
  },
});