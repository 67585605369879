import React, {Component} from 'react';
import { 
  Image, StyleSheet, Text, TouchableOpacity, View, ScrollView, Dimensions, Platform, ImageBackground, AppState, PermissionsAndroid,
} from 'react-native';
import MyPageIcon from '../components/common/MyPageIcon';
import MyPage13Api from '../services/api/MyPage13Api';
import Badge14Api from '../services/api/Badge14Api';
import Setting17Api from '../services/api/Setting17Api';
import { AntDesign } from '@expo/vector-icons';
import CommonFunction from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { AppContext } from '../AppContext';
import HeadStyle from '../styles/HeadStyles';
import { RefreshControl } from 'react-native-web-refresh-control';
import Constants from '../constants/Constants';
import NotificationSMS from '../components/common/NotificationSMS';
import NotificationMail from '../components/common/NotificationMail';
import UserUtil from '../utils/UserUtil';
import UserVerify from '../utils/UserVerify';
import ApiConfig from '../constants/ApiConfig';
import Msg from '../constants/option/Msg.json';
import NoItemsBox from '../components/common/NoItemsBox';
import HeaderOptions from '../constants/HeaderOptions';
import ListStyles from '../styles/ListStyles';
import Logo from '../components/common/Logo';
import StorageHelper from '../utils/StorageHelper2';
import firebase from '@react-native-firebase/app';
import FireBaseCommon from '../utils/FireBaseCommon';

import messaging from '@react-native-firebase/messaging';
import SettingImgPopup from '../components/common/SettingImgPopup';
import PrivilegeEndPopup from '../components/common/PrivilegeEndPopup';

const { checkUndefined } = CommonFunction;
const screenWidth = Dimensions.get('window').width;
const bannerWidth = Math.floor(screenWidth/1.0);
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight -  HeaderOptions.bottomTabHeight;
import NotificationManager from 'react-native-check-notification-enable'

export default class MyPageScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor() {
    super();
    this.state = {
      loading: true,
      isRefreshing: false,
      networkError: false,
      noImageUrl: true,
      profile: {},
      mailError: '',
      privilege: '',
      privilegeEndPopupFlag: '',
      thumb_url: null,
      badge: {'good': 0, 'notification': 0, 'footmark': 0, 'contact': 0, 'message': 0},
      account: UserUtil.getDefaultAccountAppState(),
      notificationEnabled: 0,
      appState: AppState.currentState,
      token: '',
      trialPlan: false,
      isTrial: false,
      alreadyCallApi: false
    };
    this.privilegeEndPopupRef = React.createRef();
  }
  
  async componentDidMount() {
    this._isMounted = true;    
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();

      // focus screen
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        // show bottom tab
        CommonFunction.showBottomTab(this.navigation);
        // get data
        this.getDataFromApi();

        const { appState } = this.context;
        if(appState?.account){
          this.setState({ account: appState.account});
        }
      });

      if(Platform.OS!=='web'){
        // iOS & Android
        this.checkFcmToken();
      }else {
        if(CommonFunction.isAndroidUA()){
          this.checkWebFcmToken();
        }
      }

      if(Platform.OS!=='web'){
        // アプリがフォアグラウンドになった場合の処理
        this.subscription = AppState.addEventListener('change', () => {
          console.log('AppState', 'addEventListener')
          if (AppState.currentState === 'active') {
            this.getDataFromApi();
          }
        });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
    if(this.subscription)
      this.subscription.remove();
  }

  checkFcmToken = async () => {
    // FireBase共通処理:fcmTokenの設定
    await FireBaseCommon.registFcmToken();
  }

  checkWebFcmToken = async () => {
    // FireBase共通処理:fcmTokenの設定
    await FireBaseCommon.checkNotification();
  }

  setHeader(){
    this.navigation?.setOptions({
      tabBarStyle: { display: 'flex' },
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={{height:'100%',justifyContent:'center',position:'absolute', left:0}}><Logo/></View>
          <Text style={HeadStyle.headerTitlePage}>マイページ</Text>
        </View>
      )
    });
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  loadComplete = ()=>{
    this.setState({
      loading: false, 
      isRefreshing: false,
    });
  }

  getDataFromApi = async ()=>{
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }
    if (Platform.OS === 'android') {
      if(Platform.Version > 32){
        const enabled = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS);
        if(enabled){
          this.setState({notificationEnabled: 0});
        }else{
          this.setState({notificationEnabled: 1});
        }
      }else{
        const enabled = await NotificationManager.areNotificationsEnabled();
        if(enabled){
          this.setState({notificationEnabled: 0});
        }else{
          this.setState({notificationEnabled: 1});
        }
      }
    } else if(Platform.OS === 'ios'){
      const enabled = await firebase.messaging().hasPermission();
      if(enabled){
        this.setState({notificationEnabled: 0});
      }else{
        this.setState({notificationEnabled: 1});
      }
    }
 
    try{
      const profileGetMyPageProfileResponse = await MyPage13Api.GetMyPageProfile();
      if(!checkUndefined(profileGetMyPageProfileResponse)){
        this.setState({ networkError: true });
        return;
      }

      //check login
      if(!CommonFunction.checkResponseStatus(profileGetMyPageProfileResponse)) return;

      if(profileGetMyPageProfileResponse!=undefined && profileGetMyPageProfileResponse?.status==1){
        // set badge
        const badgeResponse = await Badge14Api.GetBadge();
        if(badgeResponse?.status==1){
          const badgeList = badgeResponse?.response;
          if(badgeList){
            this.setState({ badge: badgeList });
            if(checkUndefined(this.props.setBadge)){
              this.props.setBadge(badgeList);
            }
          }
        }
        StorageHelper.storeData('ikuyoStatus', Number(badgeResponse.ikuyoStatus));
        if(this.props.setIkuyoStatus!=undefined){
          this.props?.setIkuyoStatus(Number(badgeResponse.ikuyoStatus));
        }
      
        const data = profileGetMyPageProfileResponse?.response;
        if(checkUndefined(data)){
          let userProfile = data.myPageItems?.profile;
          let thumb_url = userProfile?.mainImage?.noImageUrl;
          let mailError = data.myPageItems?.mailError;
          let privilege = data.myPageItems?.privilege;
          let privilegeEndPopupFlag = data.myPageItems?.privilegeEndPopupFlag;
          let noImageUrl = true;
          let trialPlan = data.myPageItems?.trialPlan;
          let isTrial = data.myPageItems?.isTrial;
          if(userProfile?.mainImage?.certify==2 || userProfile?.mainImage?.certify==1){
            thumb_url = userProfile?.mainImage?.url;
            noImageUrl = false;
          }
          const account = UserUtil.getAccountAppState(profileGetMyPageProfileResponse, this.context);
          this.setState({
            profile: userProfile,
            thumb_url: thumb_url,
            noImageUrl: noImageUrl,
            mailError: mailError,
            privilege: privilege,
            privilegeEndPopupFlag: privilegeEndPopupFlag,
            account: account,
            loading: false, 
            isRefreshing: false,
            trialPlan: trialPlan,
            isTrial: isTrial,
            alreadyCallApi: true
          });
          StorageHelper.storeData('trialPlan', trialPlan);

          let { appState, setAppState } = this.context;
          appState.myPageItems = userProfile;
          setAppState(appState);

          if(privilegeEndPopupFlag == '1'){
            setTimeout(() => {
              this.privilegeEndPopupRef?.show();
            }, 500);
          }
        }
      }else{
        this.loadComplete();
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.loadComplete();
    }finally{
    }
  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  render() {
    let isShowNotifBunner = Platform.OS==='web' && CommonFunction.isAndroidUA();
    if(isShowNotifBunner){
      isShowNotifBunner = window.Notification.permission != "granted"
    }
    let planText;
    if (this.state.account?.subscription?.status==1 ) {
      planText = "有料会員";
      if (this.state.privilege==1) {
        planText = "キャンペーン会員";
      } else if (this.state.isTrial==true) {
        planText = "お試しプラン会員";
      }
    }
    if (this.state.networkError) {
      return (<View style={styles.container}><NoItemsBox msg={Msg.NetworkError} /></View>);
    }

    if (this.state.loading) return <ActivityIndicator />;
    
    return (
      <View style={[ListStyles.container,{ paddingBottom:Platform.OS!='web'?0:30, ...Platform.select({ios: {},android: {}}) }]}>
        {this.state.privilegeEndPopupFlag == '0'?
        <SettingImgPopup memberId={this.state.profile?.memberId} sex={this.state.profile?.sex} navigation={this.navigation} />
        :null}
      <ScrollView style={styles.scrollContainer} scrollToOverflowEnabled={true}
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
        {this.state.account?.activated==0 && (
        <NotificationSMS navigation={this.navigation}/>
        )}  
        {this.state.mailError==1 && (
          <NotificationMail navigation={this.navigation}/>
        )}
        <View style={styles.profileContainer}>
          {this.state.profile?.mainImage?.certify==1 && (
            <View style={styles.profileApplyingText}><Text>審査中</Text></View>
          )}
          <TouchableOpacity style={[styles.profileArea,{opacity:(this.state.profile?.mainImage?.certify==1) ?0.3:1}]} onPress={()=>this.navigation.navigate('UserSettingImage')}>
          {this.state.noImageUrl?
            <ImageBackground style={styles.noProfileThumbnail} source={this.state.profile?.sex==Constants.FEMALE?require('../assets/images/no-avatar-female.png'):require('../assets/images/no-avatar-male.png')} resizeMode="cover">      
              <View style={styles.addProfileImg}>
                <AntDesign name="plus" style={styles.txtAddProfileImg}/>
              </View>
            </ImageBackground>
            :
            <ImageBackground style={styles.myProfileThumbnail} imageStyle={{ borderRadius:999}} source={{uri : this.state.thumb_url}} resizeMode="cover">
              <View style={styles.addProfileImg}>
                <AntDesign name="plus" style={styles.txtAddProfileImg}/>
              </View>
            </ImageBackground>
            }
          </TouchableOpacity>
          <View style={styles.rightProfile}>
            <Text style={styles.profileNickname}>{this.state.profile?.name}</Text>
            <View style={{width:screenWidth<375? 175 : 190}}>
              <TouchableOpacity style={styles.profileBtn} onPress={()=>this.navigation.navigate('UserSetting')}>
                <View style={{alignItems:'flex-start',}}><Text style={styles.profileTextStyle}>プロフィールを編集する</Text></View>
                <View style={{alignItems:'flex-end',height:12,}}><AntDesign name="right" style={styles.profileIcRight}/></View>
              </TouchableOpacity>
            </View>
          </View>
        </View>   

        {this.state.profile?.sex==Constants.FEMALE ?
        <View style={{paddingTop:screenWidth<375? 12 : 18, paddingBottom:screenWidth<375? 12 : 18, paddingLeft:22, paddingRight:22, width:'100%', backgroundColor:'#f2f2f2'}}>{/**female */}
          <View style={{flexDirection: 'row', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
            <MyPageIcon source={require('../assets/images/icon-news.png')} text="お知らせ" badge={this.state.badge.notification} onPress={() => this.navigation.navigate('Auth',{screen:'Notify'})} topBorder={true} />
            <MyPageIcon source={require('../assets/images/icon-footsteps.png')} text="足あと" badge={this.state.badge.footmark} showBadge={true} onPress={() => this.navigation.navigate('Footmark')} topBorder={true} />
            <MyPageIcon source={require('../assets/images/icon-help.png')} text="ヘルプ" onPress={() => this.navigation.navigate('Auth',{screen:'Help'})} topBorder={true} />
          </View>
          <View style={{flexDirection: 'row', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
            <MyPageIcon source={require('../assets/images/icon-setting.png')} text="設定" badge={this.state.notificationEnabled} onPress={() => this.navigation.navigate('SettingStack')} />
            <MyPageIcon source={require('../assets/images/icon-membership-status.png')} text="会員ステータス" onPress={() => {
              this.navigation.navigate('MemberStatus');
            }} />
            <MyPageIcon source={require('../assets/images/icon-question.png')} text="お問い合わせ" badge={this.state.badge.contact} onPress={async() => {
              if(Platform.OS==='web'){
                const token = await CommonFunction.getEncryptAccessToken();
                window.location.href = ApiConfig.contact.url+'?token='+token;
              }else{
                this.navigation.navigate('Inquiry');
              }
            }} />
            {/*<MyPageIcon noBorder={false} />*/}
          </View>
        </View>
        :
        <View>        
          <View style={{justifyContent: 'space-around', flexDirection:'row', paddingBottom:screenWidth<375? 12 : 20, width:'100%', paddingLeft:22, paddingRight:22}}>
            
            <View style={{alignContent:'center', justifyContent:'center',alignItems:'center', marginLeft:screenWidth<375? 6 : 8, marginRight:screenWidth<375? 6 : 8,}}>
              <View style={{flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center', height:20,}}>
                <View style={{width:14, height:14, backgroundColor:'#5cc8ca', borderWidth:2, borderColor:'#5cc8ca', borderRadius:999, justifyContent:'center', alignItems:'center'}}>
                  <Image resizeMode={'contain'} style={{width:9, height:8}} source={require('../assets/images/icon/ic-iine.png')} />
                </View>
                <Text style={{marginLeft:3,fontSize:12,fontWeight:'bold',color:'#9c9c9c'}}>いいね！</Text>
              </View>
              <View style={{marginTop:3, marginBottom:7, height:20,}}>
                <Text style={{fontSize:16, fontWeight:'bold', color:'#434343', lineHeight:20,}}>{this.state.profile?.goodPoint}</Text>
              </View>
              <TouchableOpacity style={{width:screenWidth<375? 80 : 94, height:26, backgroundColor:'#d8fcdb', borderRadius:999, textAlign:'center'}} 
              onPress={()=>{
                if(!this.checkSMS()) return;
                if(!this.checkAge()) return;
                //this.navigation.navigate('IinePoint');
                this.navigation.navigate('IinePlan');
              }}>
                <Text style={{fontSize:12, fontWeight:'bold', color:'#47a04d',lineHeight:26, textAlign:'center'}}>いいね交換</Text>               
                <View style={styles.addPlusImg}>
                  <AntDesign name="plus" style={styles.txtAddPlusImg}/>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{alignContent:'center', justifyContent:'center',alignItems:'center', marginLeft:screenWidth<375? 6 : 8, marginRight:screenWidth<375? 6 : 8,}}>
              <View style={{flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center', height:20,}}>
                <View style={{marginRight:3, width:14, height:14, backgroundColor:'#ffd306', borderWidth:2, borderColor:'#ffd306', borderRadius:999, textAlign:'center'}}>
                  <Text style={{fontSize:10, fontWeight:'bold', color:'#ffffff', lineHeight:10, textAlign:'center'}}>P</Text>
                </View>
                <Text style={{fontSize:12,  fontWeight:'bold',color:'#9c9c9c', textAlign:'center'}}>ポイント</Text>
              </View>
              <View style={{marginTop:3, marginBottom:7, height:20,}}>
                <Text style={{fontSize:16, fontWeight:'bold', color:'#434343', lineHeight:20,}}>{this.state.profile?.point}</Text>
              </View>
              <TouchableOpacity style={{width:screenWidth<375? 80 : 94, height:26, backgroundColor:'#d8fcdb', borderRadius:999, textAlign:'center'}} 
              onPress={()=>{
                if(!this.checkSMS()) return;
                if(!this.checkAge()) return;
                this.navigation.navigate('PointShopping');
              }}>
                <Text style={{fontSize:12, fontWeight:'bold', color:'#47a04d',lineHeight:26, textAlign:'center'}}>ポイント購入</Text>
                <View style={styles.addPlusImg}>
                  <AntDesign name="plus" style={styles.txtAddPlusImg}/>
                </View>
              </TouchableOpacity>
            </View>

            {this.state.account?.subscription?.status!=1 ?
            <View style={{ alignContent:'center', justifyContent:'center',alignItems:'center', marginLeft:screenWidth<375? 6 : 8, marginRight:screenWidth<375? 6 : 8,}}>
              <View style={{height:20,}}>
                <Text style={{fontSize:12,  fontWeight:'bold',color:'#9c9c9c'}}>会員ステータス</Text>
              </View>
              <View style={{marginTop:3, marginBottom:7, height:20,}}>
                <Text style={{fontSize:13, fontWeight:'bold', color:'#434343', lineHeight:20,}}>無料会員</Text>
              </View>              
              <TouchableOpacity style={{width:115, height:26, backgroundColor:'#5cc8ca', borderRadius:999, textAlign:'center'}} 
              onPress={()=>{
                // validate SMS
                if(!this.checkSMS()) return;
                // validate Age
                if(!this.checkAge()) return;
                this.navigation.navigate('Purchase');
                
              }}>
                <Text style={{fontSize:10, fontWeight:'bold', color:'#fff',lineHeight:26, textAlign:'center'}}>プランを見る</Text>
              </TouchableOpacity>
            </View>
            :
            <View style={{ alignContent:'center', justifyContent:'center',alignItems:'center', marginLeft:screenWidth<375? 6 : 8, marginRight:screenWidth<375? 6 : 8,}}>
              <View style={{height:20,}}>
                <Text style={{fontSize:12,  fontWeight:'bold',color:'#9c9c9c'}}>会員ステータス</Text>
              </View>
              <View style={{marginTop:3, marginBottom:7, height:20,}}>
              <Text style={{fontSize:13, fontWeight:'bold', color:'#434343', lineHeight:20,}}>{planText}</Text>
              </View>              
              <TouchableOpacity style={{width:115, height:26, backgroundColor:'#5cc8ca', borderRadius:999, textAlign:'center'}} 
              onPress={()=>{
                this.navigation.navigate('MemberStatus');
              }}>
                <Text style={{fontSize:10, fontWeight:'bold', color:'#fff',lineHeight:26, textAlign:'center'}}>会員ステータスを見る</Text>
              </TouchableOpacity>
            </View>
          }
          </View> 
          <View style={{paddingTop:screenWidth<375? 12 : 18, paddingBottom:screenWidth<375? 12 : 18, paddingLeft:22, paddingRight:22, width:'100%', backgroundColor:'#f2f2f2'}}>{/**male */}
            <View style={{flexDirection: 'row', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
              <MyPageIcon source={require('../assets/images/icon-news.png')} text="お知らせ" badge={this.state.badge.notification} onPress={() => this.navigation.navigate('Auth',{screen:'Notify'})} topBorder={true} />
              <MyPageIcon source={require('../assets/images/icon-footsteps.png')} text="足あと" badge={this.state.badge.footmark} showBadge={true} onPress={() => this.navigation.navigate('Footmark')} topBorder={true} />
              <MyPageIcon source={require('../assets/images/icon-help.png')} text="ヘルプ" onPress={() => this.navigation.navigate('Auth',{screen:'Help'})} />
            </View>
            <View style={{flexDirection: 'row', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
              <MyPageIcon source={require('../assets/images/icon-setting.png')} text="設定" badge={this.state.notificationEnabled} onPress={() => this.navigation.navigate('SettingStack')} />
              <MyPageIcon source={require('../assets/images/icon-membership-status.png')} text="会員ステータス" onPress={() => {
                this.navigation.navigate('MemberStatus');
              }} />
              <MyPageIcon source={require('../assets/images/icon-question.png')} text="お問い合わせ" badge={this.state.badge.contact} onPress={async() => {
                if(Platform.OS==='web'){
                  const token = await CommonFunction.getEncryptAccessToken();
                  window.location.href = ApiConfig.contact.url+'?token='+token;
                }else{
                  this.navigation.navigate('Inquiry');
                }
              }} />
            </View>

          </View>
        </View>
        }
        {isShowNotifBunner  ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
            this.navigation.navigate('SettingStack',{screen:'NotifInput'});
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/bunner_notifi.png')} />
          </TouchableOpacity>
        : <></>}
        {/* {this.state.account?.ageVerficationStatus==2 && this.state.profile?.sex==Constants.FEMALE  ?
          <TouchableOpacity style={styles.bannerLinkBottom} onPress={()=>{
            this.navigation.navigate('SecretMode');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/secret_mode/secret-b-w.png')} />
          </TouchableOpacity>
          : <></>} */}
        {this.state.profile?.sex==Constants.MALE && this.state.trialPlan==true ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
            // // validate SMS
            // if(!this.checkSMS()) return;
            // // validate Age
            // if(!this.checkAge()) return;
            this.navigation.navigate('PurchaseTrial');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/trial/banner_trial_mypage.png')} />
          </TouchableOpacity>
          : <></>}
        {this.state.profile?.sex==Constants.FEMALE  ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
            this.navigation.navigate('SecretMode');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/secret_mode/secret-b-w.png')} />
          </TouchableOpacity>
          : <></>}
        {this.state.profile?.sex==Constants.MALE ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
          this.navigation.navigate('SecretMode');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/secret_mode/secret-b-m.png')} />
        </TouchableOpacity>
          : <></>}
        {/* {this.state.account?.ageVerficationStatus==2 && this.state.profile?.sex==Constants.FEMALE  ?
          <TouchableOpacity style={styles.bannerLinkBottom} onPress={()=>{
            this.navigation.navigate('ProfileImageInfo');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/bunner-mpw.png')} />
          </TouchableOpacity>
          : <></>}
        {this.state.account?.ageVerficationStatus!=2 &&this.state.profile?.sex==Constants.FEMALE  ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
            this.navigation.navigate('ProfileImageInfo');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/bunner-mpw.png')} />
          </TouchableOpacity>
          : <></>} */}
        {this.state.profile?.sex==Constants.MALE ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
          this.navigation.navigate('ProfileImageInfo');
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/bunner-mpm.png')} />
        </TouchableOpacity>
          : <></>}
        {this.state.account?.ageVerficationStatus!=2 && this.state.profile?.sex==Constants.FEMALE  ?
          <TouchableOpacity style={styles.bannerLinkBottom} onPress={()=>{
            // validate SMS
            if(!this.checkSMS()) return;

            this.navigation.navigate('Auth',{screen:'Identification'});
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/banner-age-female-mypage.png')} />
            </TouchableOpacity>
          : <></>
        }
        {this.state.account?.ageVerficationStatus!=2 && this.state.profile?.sex!=Constants.FEMALE ?
          <TouchableOpacity style={styles.bannerLink} onPress={()=>{
            // validate SMS
            if(!this.checkSMS()) return;

            this.navigation.navigate('Auth',{screen:'Identification'});
          }}>
            <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/banner-age-male-mypage.png')} />
            </TouchableOpacity>
          : <></>
        }
        {(this.state.profile?.sex==Constants.MALE && this.state.account?.subscription?.status!=1) ?
          <TouchableOpacity style={styles.bannerLinkBottom} onPress={()=>{
            // validate SMS
            if(!this.checkSMS()) return;
            // validate Age
            if(!this.checkAge()) return;
              this.navigation.navigate('Purchase');
          }}>
              {Platform.OS==='web'?
                <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/banner-payment-mypage-web.png')} />
                :
                <Image resizeMode={'stretch'} style={styles.bannerLinkImg} source={require('../assets/images/banner-payment-mypage.png')} />
              }
            
          </TouchableOpacity>
         : <View style={{height:30}}></View>
        } 
      </ScrollView>
      <PrivilegeEndPopup ref={ref=>this.privilegeEndPopupRef=ref} navigation={this.navigation} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    ...Platform.select({
      ios: {
        paddingTop: 70
      },
      android: {
        paddingTop: 70
      },
      default:{
        paddingTop:44
      }
    }),
    paddingBottom:25
  },
  scrollContainer: {
    width:'100%',
    backgroundColor:'#ffffff',
    height: scrollHeight
  },
  profileContainer: {
    flexDirection: 'row',
    backgroundColor: "#fff",
    marginTop: screenWidth<375? 12 : 20,
    marginBottom:screenWidth<375? 12 : 20
  },
  myProfileThumbnail:{
    width:80,
    height:80,
    borderRadius:999,
    marginLeft:screenWidth<375? 12 : 20,
    marginRight:screenWidth<375? 12 : 20
  },
  rightProfile:{
    flex:1,
    paddingRight:15
  },
  profileBtn:{
    flexDirection:'row',
    justifyContent:'space-between',
    alignContent:'center',
    alignItems:'center',
    paddingTop:6,
    paddingBottom:6,
    paddingLeft:10,
    paddingRight:10,
    width:'100%',
    backgroundColor:'#e6e6e6',
    borderRadius:999,
    borderWidth:0,
    borderColor:'#e6e6e6'
  },
  profileTextStyle:{
    fontSize:13,
    fontWeight:'bold',
    lineHeight:19,
    color:'#434343',
    textAlign:'center'
  },
  profileIcRight:{
    fontSize:12,
    lineHeight:12,
    color:'#9aa5a7'
  },
  fontMedium:{
    fontSize: 18
  },
  profileNickname:{
    marginBottom:16,
    fontSize:19,
    fontWeight:'bold',
    lineHeight:30,
    color:'#434343'
  },
  grid:{
    flex:1,
    padding:10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  profileApplyingText:{
    position: 'absolute',
    left:screenWidth<375? 12 : 20,
    top:0,
    width:80,
    height:80,
    opacity: 0.9,
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
  },
  profileArea:{},
  addProfileImg: {
    position:'absolute',
    right:-10,
    bottom:-5,
    width:30,
    height:30,
    backgroundColor:'#90dd95',
    borderColor:'#fff',
    borderWidth:2,
    borderRadius:999,
    textAlign:'center',
  },
  txtAddProfileImg: {
    color:'#fff',
    fontSize:16,
    lineHeight:26,
    textAlign:'center'
  },
  addPlusImg: {
    position:'absolute',
    right:-7,
    bottom:12,
    width:20,
    height:20,
    backgroundColor:'#90dd95',
    borderColor:'#fff',
    borderWidth:2,
    borderRadius:999,
    textAlign:'center',
  },
  txtAddPlusImg: {
    color:'#fff',
    fontSize:11,
    lineHeight:15,
    textAlign:'center',
  },
  noProfileThumbnail: {
    marginLeft:20,
    marginRight:25,
    width:80,
    height:80,
    borderRadius:999,
  },
  scrollViewBanner: {
    marginTop:screenWidth<375? 12 :20,
    marginBottom:screenWidth<375? 12 :20,
    width:'100%',
  },
  bannerLink: {
    paddingTop:10,
    width:bannerWidth,
    height:90,
    alignSelf:'center', 
  },
  bannerLinkBottom: {
    paddingTop:10,
    paddingBottom:30,
    width:bannerWidth,
    height:120,
    alignSelf:'center',
  },
  bannerLinkImg: {
    width:316,
    height:80,
    borderRadius:10,
    alignSelf:'center', 
  }
});
