import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
//import Constants from '../../constants/Constants';
import WebUtil from '../../utils/WebUtil';
import { Platform } from 'react-native';
import AppUtil from '../../utils/AppUtil';

const { resizeImage } = WebUtil;
/**
 * API: 03_API仕様\レスポンス仕様書\01_新規登録\新規登録機能 レスポンス仕様.xlsx
 */
export default class Register01Api extends ApiPappy{

  //1.1.1.非会員用おすすめリスト取得
  static async ListNotMember(values){
    if(values.count==undefined) values.count = 10;

    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Entry/Get/ListNotMember/",
        { count: values.count, currentPref: values.currentPref, sex: values.sex }
      );
      if(__DEV__) console.log('/Entry/Get/ListNotMember/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.EntryGetListNotMember));
            console.log('/Entry/Get/ListNotMember/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data, false);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.メールアドレスチェック処理
  static async EntryCheckMail(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Entry/Get/EntryCheckMail/",
        { mail: values.mail },
        false
      );
      if(__DEV__) console.log('/Entry/Get/EntryCheckMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.EntryGetEntryCheckMail);
            console.log('/Entry/Get/EntryCheckMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data, false);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.メールで認証コードを送信
  static async SendVerificationCodeMail(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Entry/Post/SendVerificationCodeMail/",
        { mail: values.mail }
      );
      if(__DEV__) console.log('/Entry/Post/SendVerificationCodeMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.EntryPostSendVerificationCodeMail);
            console.log('/Entry/Post/SendVerificationCodeMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data, false);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.2.メールアドレスでの本登録
  static async EntryMail(values){
    try{
      let postData = {
        token: values.token, 
        mail: values.mail, 
        pass: values.pass, 
        deviceToken: '', 
        verificationCode: values.code, 
        name: values.name, 
        currentPref: values.currentPref.id, 
        sex: values.sex.id, 
        birthDate: values.birthDate
      };

      if(values.mainImage){
        const [, type] = values.mainImage.split(';')[0].split('/');
        const resp = await fetch(values.mainImage);
        const fblob = await resp.blob();
        // const mainImage = new File([fblob], `profile.${type}`);
        const maxWidth = 1280;
        const config = {
          file: fblob,
          maxWidth: maxWidth
        };

        let mainImage;
        if(Platform.OS==='web'){
          mainImage = await resizeImage(config)
          if(__DEV__) console.log('resize file size',mainImage.size);
        }else{
          //app resize image
          const resizeImageFile = await CommonFunction.resizeImageApp(values.mainImage, maxWidth);
          if(resizeImageFile){
            const type = resizeImageFile?.type;
            const mainPath = resizeImageFile?.mainPath;
            mainImage = {
              uri: mainPath,
              type: fblob.type,
              name: `profile.${type}`
            };
            if(__DEV__) console.log(mainImage);
          }
        }
        
        let mainImageKey = 'mainImage';
        postData[mainImageKey] = mainImage;
      }

      if(typeof values.dateHope.id !='undefined'){
        let dateHope = 'dateHope';
        postData[dateHope] = values.dateHope.id;
      }
      if(typeof values.meetHope.id !='undefined'){
        let meetHope = 'meetHope';
        postData[meetHope] = values.meetHope.id;
      }
      
      let webUUID = await WebUtil.getWebUUID();
      if(webUUID){
        let web_uuid = 'web_uuid';
        postData[web_uuid] = webUUID;
      }
      let adid = await AppUtil.getAdid();
      if(adid){
        let app_adid = 'adid';
        postData[app_adid] = adid;
      }

      const encodeData = true;
      const encodeExcludeVar = ['mainImage'];
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Entry/Post/EntryMail/",
        postData,
        false,
        encodeData,
        encodeExcludeVar
      );
      if(__DEV__) console.log('/Entry/Post/EntryMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.EntryPostEntryMail);
            console.log('/Entry/Post/EntryMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data, false);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.認証コード検証
  static async VerificationCode(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Entry/Put/VerificationCode/",
        { token: values.token, code: values.code }
      );
      if(__DEV__) console.log('/Entry/Put/VerificationCode/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.EntryPutVerificationCode);
            console.log('/Entry/Put/VerificationCode/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            //CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}