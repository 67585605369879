class OverlayManager {
  _defaultPopup = null;
  register(_ref) {
    this._defaultPopup = _ref;
  }

  unregister(_ref) {
    this._defaultPopup = null;
  }
  
  getDefault() {
    return this._defaultPopup;
  }
}

export default new OverlayManager();
