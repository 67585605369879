import React,{ Component } from 'react';
import { StyleSheet, Text, View, Platform, Dimensions, ImageBackground } from 'react-native';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import OnlineStatus from '../common/OnlineStatus';
import Constants from '../../constants/Constants';

const isWeb = Platform.OS === 'web';
const width = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let wRatio = 0.1;
if(width>=390) wRatio = 0.05;
const percent = Math.ceil(width*wRatio);
const cardWidth = width-percent;

let hRatio = 0.7;
let thumbnailHeight = Math.ceil(windowHeight*hRatio);
let maxHeight = 600;
if(thumbnailHeight>maxHeight) thumbnailHeight = maxHeight;

export default class RecommendCard extends Component {
  constructor(props) {
    super(props);
    this.onlineId = 0;
    //this.imgHeight = thumbnailHeight-60;
    this.imgHeight = thumbnailHeight;
  }

  componentDidMount(){
    const { swipeCardHeight } = this.props;
    if(swipeCardHeight!=undefined) this.imgHeight = swipeCardHeight-60;

  }
  
  render() {
    let onlineId = 0;
    if(this.props.online!=undefined){
      onlineId = this.props.online?.id;
    }

    let income = this.props?.income;
    if(Constants.NOTSETTEXT.indexOf(income)!=-1) income = '';
    if(!isWeb) income = '';

    let message = this.props?.message;
    if(message) message = message.replaceAll("\n","");

    return (    
      <View style={styles.card}>
        <View style={{width:'100%',height: this.imgHeight}}>
          <ImageBackground resizeMode={'cover'} 
            style={[styles.thumbnail]} 
            imageStyle={{borderTopLeftRadius:25, borderTopRightRadius:25, borderRadius:25}} 
            source={{uri: this.props?.mainImageUrl}}
          >
          {this.props.newFlag==1 && <View style={styles.newUser}><Text style={styles.newUserText}>NEW</Text></View>}
          </ImageBackground>
        </View>
        <View style={styles.profileUser}>
          <View style={{paddingTop: 5,paddingLeft:10}}>
            <View style={styles.descItem}>
            <View style={{width:20, alignItems:'center'}}><OnlineStatus online={onlineId} /></View>
              <Text style={styles.textName}>{this.props?.name} </Text>
            </View>
            <Text style={[styles.textName,{marginLeft:20}]}>{this.props?.age} {this.props?.currentPref} {income}</Text>
            {/** 
            <View style={{width: cardWidth-20, paddingLeft:10}}><Text numberOfLines={1}>{message}</Text></View>
            */}
            </View>
        </View>
      </View>      
    )
  }
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    borderRadius: 25,
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    elevation: 1,
    width: cardWidth,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 1,
    ...Platform.select({
      ios:{
        height:thumbnailHeight,
      },
      android:{
        height:thumbnailHeight,
        //marginTop: 40
      },
      web:{
        height:'100%',
        marginTop: 0,
      }
    })
  },
  thumbnail: {
    width:'100%',
    height:'100%',
  },
  nowStatus: {
    marginRight:4,
    fontSize:8,
    color:'#aeaeae',
  },
  greenStatus: {
    color:'#24eb25',
  },
  orangeStatus: {
    color:'#ebba24',
  },
  redStatus: {
    color:'#eb7524',
  },
  profileInside:{
    flex:1,
    position:'absolute',
    left:0,
    bottom:0,
    padding:10,
    width:'100%',
    zIndex:10,
    elevation:10,
  },
  profileUser: {
    position:'absolute',
    bottom:0,
    justifyContent:'flex-start',
    alignItems:'flex-start',
    width:'100%',
    height:60,
    //backgroundColor:'#ffffff',
    borderBottomLeftRadius:25,
    borderBottomRightRadius:25
  },
  descItem: {
    width:'100%',
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'flex-start',
  },
  textName: {
    fontSize:16,
    fontWeight:'bold',
    color:'#ffffff',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:1,
  },
  textDesc: {
    fontSize:14,
    fontWeight:'normal',
    lineHeight:24,
    color:"#fff",
    textShadowColor:'rgba(0,0,0,0.7)',
    textShadowOffset:{width: 1,height:1},
    textShadowRadius:1,
  },
  noMoreCards: {
    flex:1,
    justifyContent:'center',
    alignItems:'center',
  },
  royal: {
    position: 'absolute',
    right: 10,
    bottom: 50,
  },
  royal_icon: {
    width: 45,
    height: 45,
    marginLeft:4,
    marginBottom: 4,
  },
  warning: {
    position: 'absolute',
    right: 10,
    bottom: 50,
  },
  warning_icon: {
    width: 45,
    height: 41,
    marginLeft:4,
    marginBottom: 4,
  },  
  newUser: {
    backgroundColor:'rgba(255, 141, 99, 0.8)', 
    opacity:0.8, 
    position:'absolute', 
    left:0, 
    top:76, 
    width:75, 
    height:36, 
    justifyContent:'center',
    textAlign:'center',
    borderTopRightRadius:24,
    borderBottomRightRadius:24,
  },
  newUserText: {
    color:"#ffffff",
    fontSize:16,    
    fontWeight:"bold",
    lineHeight:36,
    textTransform:"uppercase",
    textAlign:'center'
  },
  arrowIcon: {
    alignItems: 'flex-end',
    paddingRight: 5
  },
  arrowRight:{
    marginLeft:10,
    fontSize:24,
  },
  moreContainer:{
    flexDirection:'row',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    height:24
  },
  moreView:{
    flex:1,
    alignSelf:'stretch', 
    alignItems:'flex-start',
    justifyContent:'center',
    top:Platform.OS === 'web' ? 2:0,
  },
  photoProfile: {
    flex:1,
    flexDirection:'row',
    justifyContent:'flex-end',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  icPhotoProfile: {
    marginRight:4,
    fontSize:14,
    color:'#ffffff',
  },
  numPhotoProfile: {
    color:'#ffffff',
    fontSize:14,
    lineHeight:19,
    letterSpacing:-.4,
  }
});