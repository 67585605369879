import React, { Component } from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import ChatScreen from '../../screens/ChatScreen';
import ChatRoomScreen from '../../screens/chat/ChatRoomScreen';
import ChatHideSettingScreen from '../../screens/chat/ChatHideSettingScreen';
import ReportScreen from '../../screens/ReportScreen';
import ChatUserInfoScreen from '../../screens/UserInfoScreen';
import PurchaseScreen from '../../screens/PurchaseScreen';
import PurchaseScreen_ios from '../../screens/PurchaseScreen_ios';
import PurchaseTrialScreen from '../../screens/PurchaseTrialScreen';
import MemberStatusScreen from '../../screens/MemberStatus';
import TermsScreen from '../../screens/setting/TermsScreen';
import PointShoppingStack from './PointShoppingStack';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import CommonFunction from '../../utils/CommonFunction';
import UserSettingImageScreen from '../../screens/UserSettingImageScreen';
import PurchaseScreen_android from '../../screens/PurchaseScreen_android';
import TransitionSpec from '../../constants/TransitionSpec';

const Stack = createStackNavigator();

export default class ChatStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName='ChatList' screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen 
        name="ChatList" 
        children={(props)=><ChatScreen {...props} setBadge={this.props?.setBadge} />} 
        options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatRoom" 
          children={(props)=><ChatRoomScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
         <Stack.Screen name="ChatHideSetting" component={ChatHideSettingScreen} options={{headerShown: true}}/>
         <Stack.Screen name="Report" component={ReportScreen} options={{headerShown: true}}/>
         <Stack.Screen 
          name="Profile" 
          children={(props)=><ChatUserInfoScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        {Platform.OS==='web'?
          <Stack.Screen 
            name="Purchase" 
            children={(props)=><PurchaseScreen {...props} BottomTabNavigation={this.props.navigation} />} 
            options={{headerShown: true}}
          />
        :null}
        {Platform.OS==='ios'?
          <Stack.Screen 
            name="Purchase" 
            children={(props)=><PurchaseScreen_ios {...props} BottomTabNavigation={this.props.navigation} />} 
            options={{headerShown: true}}
          />
        :null}
        {Platform.OS==='android'?
          <Stack.Screen 
            name="Purchase" 
            children={(props)=><PurchaseScreen_android {...props} BottomTabNavigation={this.props.navigation} />} 
            options={{headerShown: true}}
          />
        :null}
        {Platform.OS==='web'?
          <Stack.Screen 
            name="PurchaseTrial" 
            children={(props)=><PurchaseTrialScreen {...props} BottomTabNavigation={this.props.navigation} />} 
            options={{headerShown: true}}
          />
        :null}
        <Stack.Screen name="MemberStatus" component={MemberStatusScreen} />
        <Stack.Screen name="Terms" component={TermsScreen} options={{headerShown: true}}/>
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
        <Stack.Screen 
          name="UserSettingImage" 
          children={(props)=><UserSettingImageScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}