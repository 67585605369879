import * as React from 'react';
import { Text, View, StyleSheet }  from 'react-native';

export default function TabBadgeExclamation(props) {
  if(props.badge>0){
    let fontSize = 8;
    if(props.badge.length==1) fontSize = 12;
    else if(props.badge.length==2) fontSize = 10;
    return (      
      <View style={styles.container}>
        <View style={styles.badge}>
          <Text style={[styles.textBadge,{fontSize: fontSize}]}>!</Text>
        </View>        
      </View>
    );
  }else{
    return null;
  }
}

const styles = StyleSheet.create({
   container:{
    position:'absolute',
    right:40,
    top:10
   },
   badge:{
    width:18,
    height:18, 
    backgroundColor:'#ff8d63', 
    borderRadius:999, 
   },
   textBadge: {
    textAlign:'center',
    color:'#fff', 
    fontSize:12,
    fontWeight:'normal',
    lineHeight:18, 
   },
});
