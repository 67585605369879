import React, { Component } from 'react';
import { Dimensions, View, StyleSheet, Image } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export default class FavoriteStatus extends Component {
  constructor(){
    super();
  }

  render(){
    let { favorite } = this.props;
    if((favorite==0) || (favorite>=4)) {
      return <></>;
    }
    
    return (
      <View style={[styles.favoriteIconBase]}>
        {favorite>0 ?
          <Image style={styles.favorite} source={require('../../assets/images/favorite/favorite_5.png')}/>
        :<></>}
        {favorite>1 ?
          <Image style={styles.favorite} source={require('../../assets/images/favorite/favorite_5.png')}/>
        :<></>}
        {favorite>2 ?
          <Image style={styles.favorite} source={require('../../assets/images/favorite/favorite_5.png')}/>
        :<></>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  favoriteIconBase: {
    marginLeft:0,
    flexDirection: 'row',
  },
  favorite: {
    marginLeft:windowWidth<375? 2:3,
    width:windowWidth<375? 10:14,
    height: windowWidth<375? 10:13,
  },
});
