import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import Colors from '../../constants/Colors';
import TabbarOptions from '../../constants/TabbarOptions';

export default class CustomTab extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    const { state, descriptors, navigation, position } = this.props;

    return (
      <View style={styles.container}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            <View key={index} style={styles.tabView}>
              <TouchableOpacity
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
                style={[styles.tab,{borderBottomColor:isFocused?Colors.TabBarBackgroundColor:'#fff'}]}
              >
                <Text style={[styles.label, { color:isFocused?Colors.TabBarBackgroundColor:'#999'}]}>
                  {label}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    flexDirection: 'row', 
    borderBottomColor:'#9aa5a7', 
    borderBottomWidth:1,
    backgroundColor:'#ffffff', 
    borderBottomLeftRadius:5, 
    borderBottomRightRadius:5, 
    shadowColor: 'transparent',
    elevation: 0,
    height:37
  },
  tabView:{
    flex: 1, 
    backgroundColor:'#fff',
    marginLeft:3,
    marginRight:3,
    justifyContent:'flex-end',
    alignItems:'center',
  },
  tab:{
    backgroundColor:'#fff', 
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    borderBottomWidth:2.5,
    height:25
  },
  label:{
    marginBottom:5, 
    textAlign:'center',
    paddingLeft:8, 
    paddingRight:8,
    fontSize:15
  }
});
