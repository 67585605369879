import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import WebUtil from '../../utils/WebUtil';
import AppUtil from '../../utils/AppUtil';

export default class MyPage13Api extends ApiPappy{

  //1.1.1.マイページ情報取得
  static async GetMyPageProfile(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/MyPageProfile/",
        {},
        true
      );
      if(__DEV__) console.log('/Profile/Get/MyPageProfile/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.ProfileGetMyProfile));
            console.log('/Profile/Get/MyPageProfile/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
  
  //1.2.1.年齢確認画像送信
  static async CertifyPostRequest(value){
    const encodeData = true;
    const encodeExcludeVar = ['certifyImage'];
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Certify/Post/Request/",
      { 
        certifyImage: value, 
        web_uuid: webUUID,
        adid: adid
      },
      true,
      encodeData,
      encodeExcludeVar
      );
      if(__DEV__) console.log('/Certify/Post/Request/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Certify/Post/Request/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // 1.1.2.会員ステータス情報取得
  static async CheckMemberStat(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Profile/Get/CheckMemberStat/",
        {},
        true
      );
      if(__DEV__) console.log('/Profile/Get/CheckMemberStat/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.CheckMemberStat));
            console.log('/Profile/Get/CheckMemberStat/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}