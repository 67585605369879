import React,{ Component } from 'react';
import { 
  View, FlatList, StyleSheet, Text, StatusBar, TouchableOpacity, Platform 
} from 'react-native';
import { Entypo } from '@expo/vector-icons'; 
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import SafeAreaStyles from '../styles/SafeAreaStyles';
import { AppContext } from '../AppContext';

// Multiple Selected
export default class AdvanceSearchListScreen extends Component { 
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.dataSource = [];
    this.values = [];
    
    if(this.props.novalue!=undefined){
      this.novalue = this.props.novalue;
    }else{
      this.novalue = '';
    }

    if(this.props.items){
      this.props.items.forEach((val,index)=>{
        val.isSelect = false;
        if(Array.isArray(this.props.values)){
          const checkIndex = this.props.values.findIndex(
            item => val.id === item.id
          );
          if(checkIndex!=-1) val.isSelect = true;
        }
      
        this.dataSource.push(val);
      });
    }

    this.defaultIndex = this.dataSource.findIndex(
      item => 0 == item.id
    );
    this.defaultValue = [];
    if(this.defaultIndex!=-1){
      this.defaultValue = this.dataSource[this.defaultIndex];
      this.defaultValue.isSelect = true;
    }

    if(Array.isArray(this.props.values)){
      if(this.props.values.length==0){
        //default id=0
        if(this.defaultIndex!=-1){
          this.values.push(this.defaultValue);
        }
      }else{
        this.props.values.forEach((val,index)=>{
          val.isSelect = true;
          this.values.push(val);
        });
      }
    }else if(typeof this.props.values==='object'){
      this.props.values.isSelect = true;
      this.values.push(this.props.values);
    }

    this.state = {
      dataSource: this.dataSource,
      selectedId: this.values
    }
    this.maxSelected = (this.props.maxselect!=undefined)?this.props.maxselect:0;
  }

  componentDidMount(){
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  resetKeys(array){
    let compactArray = array.filter(function (item) {
      return item !== undefined;
    });

    return compactArray;
  }

  checkMaxSelected = () =>{
    return new Promise(resolve => {
      let selectList = this.state.selectedId?.filter(function (item) {
        return item.isSelect == true;
      });
      
      resolve(selectList.length);
    });
  }

  selectItem = (data) => {
    let { selectedId } = this.state;
    
    const selectIndex = selectedId?.findIndex(
      item => item.id == data.item.id
    );
    if(selectIndex!= -1) data.item.isSelect = selectedId[selectIndex].isSelect;
    
    if(data.item.id==0){
      data.item.isSelect = true;
    }else{
      // data.item.isSelect = !data.item.isSelect;
      if(this.props.mustselect===true){
        let selectList = selectedId?.filter(function (item) {
          return item.isSelect === true;
        });
        data.item.isSelect = !data.item.isSelect;
        if(selectList.length==1 && data.item.isSelect===false){
          data.item.isSelect = true;
          this.popup2?.showPopup();
        }
      }else{
        data.item.isSelect = !data.item.isSelect;
      }
    }
    selectedId[selectIndex] = data.item;

    // select id = 0, clear select other
    if(data.item.id==0){
      selectedId?.forEach((val,index)=>{
        if(val.id==0){
          data.item.isSelect = true;
          selectedId[index] = data.item;
        }else{
          selectedId[index].isSelect = false;
        }
      });
    }else{
      const noValueIndex = selectedId?.findIndex(
        item => 0 == item.id
      );
      let selectList = selectedId?.filter(function (item) {
        return item.isSelect === true;
      });
   
      if(selectList.length==0){
        if(noValueIndex >=0 ){
          selectedId[noValueIndex].isSelect = true;
        }else{
          selectedId.push(this.defaultValue);
        }
      }else{
        // select id > 0, clear select id = 0
        if(noValueIndex >=0 ){
          selectedId[noValueIndex].isSelect = false;
        }
      }
    }

    const checkIndex = selectedId?.findIndex(
      item => data.item.id === item.id
    );
    if(checkIndex ==-1){
      selectedId.push(data.item);
    }else{
      selectedId[checkIndex] = data.item;
    }

    selectedId.sort(this.compareFunc);
    
    this.setState({selectedId: selectedId});
  };

  compareFunc(a, b) {
    a = a.id;
    b = b.id;
    return a - b;
  }

  handleBack(){
    let selectList = this.state.selectedId.filter(function (item) {
      return item.isSelect === true;
    });
    this.props.onChange(this.props.fieldName, selectList);
    let selectedText = [];
    selectList.map((data) => {
      selectedText.push(data.value);
    });
    let fieldNameTxt = this.props.fieldName+'Txt'
    this.props.onChange(fieldNameTxt, selectedText.join(','));

    if(this.props.setDataSource){
      this.props.setDataSource(this.state.selectedId, selectedText.join(','));
    }

    // clear 
    this.state = {
      dataSource: [],
      selectedId: []
    }
    return selectList;
  }

  handleCheck(id) {
    let $return = false;
    if(this.state.selectedId){
      const checkIndex = this.state.selectedId.findIndex(
        item => id === item.id
      );
      if(checkIndex >=0){
        $return = this.state.selectedId[checkIndex].isSelect;
      }
    }
    return $return;
  }

  renderItem = (data) => {
    let selected = this.handleCheck(data.item.id);
    let value = data.item.value;
    if(data.item.id==0 && this.novalue){
      value = this.novalue;
    }

    return (
      <View style={[styles.sectionInside, selected?styles.selectedList:styles.notSelectedList]}>
        <TouchableOpacity onPress={()=>{            
          if(selected){
            this.selectItem(data);
          }else{
            if(this.maxSelected>0 && data.item.id!=0){
              this.checkMaxSelected().then((response)=>{
                if(response < this.maxSelected){
                  this.selectItem(data);
                }else{
                  // over limit
                  this.popup?.showPopup();
                }
              }, this);
            }else{
              this.selectItem(data);
            }
          }
          }} style={[styles.sectionInsideBorder, {flexDirection:'row', width:'100%'}]}>
          <View style={styles.formLabel}>
            <Text style={styles.label}>{value}</Text>
          </View>
          <View style={styles.formSelected}>
          {selected?
            <View style={styles.circleSelectCheck}><Entypo name="check" style={styles.icSelectCheck}/></View>
          :
            null
          }
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    const insets = this.context?.appState?.insets;
    return (
      <View style={styles.container}>
        <View style={{flex:1}}>
          <FlatList
            data={this.dataSource}
            renderItem={item => this.renderItem(item)}
            keyExtractor={item => item.id}
            //extraData={this.state.selectedId}
            initialNumToRender={this.dataSource?.length}
          />
        </View>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              //this.popup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>地域は{this.maxSelected}つまで選択可能です</Text>
            <TouchableOpacity onPress={()=>this.popup?.hidePopup()} style={[styles.btnGray, {marginTop:20,}]}>
              <Text style={styles.txtBtnGray}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

        <Popup ref={ref=>this.popup2=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              //this.popup?.hidePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>地域は1つ以上選択してください</Text>
            <TouchableOpacity onPress={()=>this.popup2?.hidePopup()} style={[styles.btnGray, {marginTop:20,}]}>
              <Text style={styles.txtBtnGray}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    flex:1,
    width:'100%',
  },
  section:{
    marginTop: 20,
  },
  sectionInside:{
    backgroundColor:'#fff',    
    flexDirection:'row', 
    alignSelf:'stretch',
    minHeight:44,
    borderBottomWidth:.55,
    borderBottomColor:'#e6e6e6',
    ...Platform.select({
      android:{
        borderBottomWidth:.7
      }
    })
  },
  sectionInsideBorder:{
    marginLeft:16,
    paddingRight:16,
    /*
    borderBottomWidth:.55,
    borderBottomColor:'#e6e6e6',
    */
  },
  formLabel:{
    flex: 1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',   
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca',
    marginRight:16
  },
  label:{
    fontSize: 14,
    lineHeight: 19,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    color:'#484848'
  },
  checkIcon:{
      width:20,
      height:20
  },
  selectedList:{
    backgroundColor:'#eef1f2'
  },
  notSelectedList:{
    backgroundColor:'#fff'
  },
  circleSelectCheck: {
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:21,
    height:21,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
  },
  icSelectCheck: {
    fontSize:14, 
    color:'#fff'
  },
  btnGray:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#ebebeb',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnGray:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#666666',
    textAlign:'center'
  }
});
