import React,{Component} from 'react';
import { 
  Image, Platform, StyleSheet, Text, View, TouchableOpacity, ScrollView, ImageBackground, StatusBar, SafeAreaView
} from 'react-native';
import StorageHelper from '../utils/StorageHelper2';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
import { AppContext } from '../AppContext';
import ApiConfig from '../constants/ApiConfig';
import CommonFunction from '../utils/CommonFunction';

const isWeb = Platform.OS === 'web';

export default class IndexScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      lineWidth: 0,
      loading: false,
      iframe: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // focus screen
      this.subscribeFocus = this.props.navigation?.addListener('focus', () => {
        //this.checkAccessToken();
      });
      // listener event for iframe
      if(isWeb){
        let $this = this;
        window.addEventListener('message', function(event) {
          if(event?.data=='iframe'){
            $this.setState({iframe: true});
          }
        });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }
  
  checkAccessToken = async()=>{
    let userProfile = {};
    const accessToken = await StorageHelper.getData('accessToken');
    if(accessToken){
      this.setState({loading: true});
      // set api not check access time
      ProfileSettingApi.setCheckAccessFlg(false);
      userProfile = await ProfileSettingApi.ProfileGetCheck(this.context, false);
      if(userProfile){
        if(Object.keys(userProfile).length >0){
          // goto search screen
          this.props?.navigation?.replace('Auth', {screen:'Home', params: {screen: 'Search', params: { screen:'SearchList' }}});
        }
      }
      this.setState({loading: false});
    }
  }

  register(){
    if(isWeb){
      this.props.navigation.navigate('Register', {path:'user-info'});
    }else{
      this.props.navigation.navigate('Register');
    }
  }

  login(){
    this.props.navigation.navigate('Login');
  }

  render() {
    if(this.state.loading) return <></>;

    if(this.state.iframe) {
      return <IframeIndexScreen/>;
    }
    
    return (
      <SafeAreaView style={{flex:1, width:'100%'}}>
        <ScrollView style={styles.ScrollContainer}>
          <View style={styles.container}>            
            <View style={styles.topLogo}>
              <Text style={styles.txtTopLogo}>ワンランク上の特別な関係</Text>
            </View>
            <View style={styles.logo}>
              <Image style={styles.imgLogo} source={require('../assets/images/logo.png')} />
              <Text style={styles.txtBottomLogo}>＼ わずか1分の簡単無料登録 ／</Text>
            </View>
            <View style={styles.buttonPanel}>
              <TouchableOpacity style={styles.regBtn} onPress={ () => this.register() }>
                <Image style={styles.regImageBtn} source={require('../assets/images/register-email.png')} />
              </TouchableOpacity>
              <View style={styles.blogRemarks}>
                <Text style={styles.txtBlogRemarksRed}>18歳未満の方はご利用できません。</Text>
              </View>
              <TouchableOpacity style={styles.loginBtn} onPress={ () => this.login() }>
                <Image style={styles.loginImageBtn} source={require('../assets/images/login.png')}   />
              </TouchableOpacity>
            </View>

            <View style={styles.blocWhy}>
              <View style={styles.headerBlocWhy}>
                <Text style={styles.headerTxtBlocWhy}>Pappy（パピー）とは {"\n"}成功した男性と魅力的な女性を {"\n"} 繋ぐ特別なマッチングサービス</Text>
                <Text style={styles.txtBlocWhyRowTxt}>紳士的で成功した男性と夢を持つ魅力的な女性を {"\n"} Pappy（パピー）は真剣に応援しています。</Text>
              </View>
              <View style={styles.blocWhyTopRow}>
                <Image style={styles.imgBlocWhyTopImg} source={require('../assets/images/why-1.png')}   />
              </View>
              <View style={styles.blocWhyRow}>
                <View style={styles.blocWhyRowImg}>
                  <Image style={styles.imgBlocWhyRowImg} source={require('../assets/images/why-2.png')}   />
                </View>
                <View style={styles.blocWhyRowTxt}>
                  <Text style={styles.topicBlocWhyRowTxt}>会いたいと思ったその日に {"\n"} 出会える機能</Text>
                  <Text style={styles.txtBlocWhyRowTxt}>カレンダー機能は、会いたいと思ったその日に {"\n"} 二人で日程を合わせて出会うことができます。</Text>
                </View>
              </View>
              <View style={styles.blocWhyRow}>
                <View style={styles.blocWhyRowImg}>
                  <Image style={styles.imgBlocWhyRowImg} source={require('../assets/images/why-3.png')} />
                </View>
                <View style={styles.blocWhyRowTxt}>
                  <Text style={styles.topicBlocWhyRowTxt}>二人だけで秘密に出会える {"\n"} 誰にもバレない機能</Text>
                  <Text style={styles.txtBlocWhyRowTxt}>プライベートモードは、知り合いにバレる事なく {"\n"} 気になるお相手だけと出会うことができます。</Text>
                </View>
              </View>
            </View>

            <View style={styles.blocUseeasy}>
              <View style={styles.headerBlocUseeasy}>
                <Text style={styles.headerTxtBlocUseeasy}>シンプルで誰でも使いやすい。{"\n"}使い方に迷う必要もありません。</Text>
              </View>
              <ScrollView horizontal={true} style={styles.scrollViewInside}>
                <View style={styles.stepBlocUseeasy}>
                  <View style={styles.stepHeadBlocUseeasy}>
                    <View style={styles.stepHeadBlocUseeasyInside}><Text style={styles.stepHeadBlocUseeasyInsideTxt}>STEP1</Text></View>
                    <View ref={(ref)=>{this.lineStepBlocUseeasyRef}} style={[styles.lineStepBlocUseeasy,{transform:[{translateX:this.state.lineWidth}]}]} onLayout={(event) => {
                      var {x, y, width, height} = event.nativeEvent.layout;
                      this.setState({lineWidth: width});
                    }}>
                    </View>
                  </View>
                  <ImageBackground resizeMode='contain' source={require('../assets/images/arrow.png')} style={styles.arrowBlocUseeasy} />
                  <Text style={styles.txtHeadBlocUseeasy}>見つけて</Text>
                  <Text style={styles.txtDescBlocUseeasy}>まずは会いたいと思う{"\n"}理想のお相手を探しましょう。</Text>
                  <Image style={styles.imgBlocUseeasy} source={require('../assets/images/mobile-1.png')} />
                </View>
                <View style={styles.stepBlocUseeasy}>
                  <View style={styles.stepHeadBlocUseeasy}>
                  <View style={styles.stepHeadBlocUseeasyInside}><Text style={styles.stepHeadBlocUseeasyInsideTxt}>STEP2</Text></View>
                    <View style={[styles.lineStepBlocUseeasy,{transform:[{translateX:this.state.lineWidth}]}]}></View>
                  </View>
                  <ImageBackground resizeMode='contain' source={require('../assets/images/arrow.png')} style={styles.arrowBlocUseeasy} />
                  <Text style={styles.txtHeadBlocUseeasy}>いいねして</Text>
                  <Text style={styles.txtDescBlocUseeasy}>お相手が見つかれば{"\n"}いいねをしましょう。</Text>
                  <Image style={styles.imgBlocUseeasy} source={require('../assets/images/mobile-2.png')} />
                </View>
                <View style={[styles.stepBlocUseeasy,{marginRight:25}]}>
                  <View style={styles.stepHeadBlocUseeasyInside}><Text style={styles.stepHeadBlocUseeasyInsideTxt}>STEP3</Text></View>
                  <Text style={styles.txtHeadBlocUseeasy}>メッセージ。</Text>
                  <Text style={styles.txtDescBlocUseeasy}>自由にやり取りをして{"\n"}会う約束をしましょう。</Text>
                  <Image style={styles.imgBlocUseeasy} source={require('../assets/images/mobile-3.png')} />
                </View>
              </ScrollView>
            </View>

            <View style={styles.blocUsesafe}>
              <View style={styles.headerBlocUsesafe}>
                <Text style={styles.headerTopicBlocUsesafe}>安心・安全にご利用いただける{"\n"}４つの取り組み</Text>              
                <Text style={styles.headerTxtBlocUsesafe}>皆さまにとって安心で安全なサービスを提供するために{"\n"}さまざまな取り組みを行っております。</Text>
              </View>
              <View style={styles.blocUsesafeRow}>
                <View style={styles.blocUsesafeRowImg}><Image style={styles.imgBlocUsesafeRowImg} source={require('../assets/images/usesafe-1.png')} /></View>
                <View style={styles.blocUsesafeRowTxt}>
                  <Image style={styles.checkImgBlocUsesafe} source={require('../assets/images/check.png')} />
                  <Text style={styles.txtDescBlocUsesafe}>実名はもちろん不要!{"\n"}ニックネームでご利用可能です。</Text>
                </View>
              </View>
              <View style={styles.blocUsesafeRow}>
                <View style={styles.blocUsesafeRowImg}><Image style={styles.imgBlocUsesafeRowImg} source={require('../assets/images/usesafe-2.png')} /></View>
                <View style={styles.blocUsesafeRowTxt}>
                  <Image style={styles.checkImgBlocUsesafe} source={require('../assets/images/check.png')} />
                  <Text style={styles.txtDescBlocUsesafe}>専任スタッフによる監視体制で{"\n"}業者や不適切利用者に対して{"\n"}強制退会などの処置を行います。</Text>
                </View>
              </View>          
              <View style={styles.blocUsesafeRow}>
                <View style={styles.blocUsesafeRowImg}><Image style={styles.imgBlocUsesafeRowImg} source={require('../assets/images/usesafe-3.png')} /></View>
                <View style={styles.blocUsesafeRowTxt}>
                  <Image style={styles.checkImgBlocUsesafe} source={require('../assets/images/check.png')} />
                  <Text style={styles.txtDescBlocUsesafe}>徹底した年齢確認チェックで{"\n"}児童の利用を防いでいます。</Text>
                </View>
              </View>
              <View style={styles.blocUsesafeRow}>
                <View style={styles.blocUsesafeRowImg}><Image style={styles.imgBlocUsesafeRowImg} source={require('../assets/images/usesafe-4.png')} /></View>
                <View style={styles.blocUsesafeRowTxt}>
                  <Image style={styles.checkImgBlocUsesafe} source={require('../assets/images/check.png')} />
                  <Text style={styles.txtDescBlocUsesafe}>強固なセキュリティ環境で{"\n"}個人情報をしっかり保護します。</Text>
                </View>
              </View>
            </View>  

            <View style={styles.buttonPanel}>
              <Text style={styles.txtButtonPanel}>いますぐ無料で{"\n"}特別な関係を作りましょう</Text>
              <TouchableOpacity style={styles.regBtn} onPress={ () => this.register() }>
                <Image style={styles.regImageBtn} source={require('../assets/images/register-email.png')}   />
              </TouchableOpacity>
              <View style={styles.blogRemarks}>
                <Text style={styles.txtBlogRemarksRed}>18歳未満の方はご利用できません。</Text>
              </View>
              <TouchableOpacity style={styles.loginBtn} onPress={ () => this.login() }>
                <Image style={styles.loginImageBtn} source={require('../assets/images/login.png')}   />
              </TouchableOpacity>
            </View> 
            <View style={styles.bottomPage}>
              <Image style={styles.imgLogoBottomPage} source={require('../assets/images/bottom-logo.png')} />
              <View style={styles.rowBottomPageTop}>
                <TouchableOpacity onPress={()=>this.props.navigation.navigate('Terms')}>
                  <Text style={styles.txtBottomPage}>利用規約 </Text>
                </TouchableOpacity>
                <Text style={styles.txtBottomPage}> ｜ </Text>
                <TouchableOpacity onPress={()=>this.props.navigation.navigate('Policy')}>
                  <Text style={styles.txtBottomPage}>プライバシーポリシー </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.rowBottomPageMiddle}>
                <TouchableOpacity onPress={()=>this.props.navigation.navigate('Law')}>
                  <Text style={styles.txtBottomPage}>特定商取引法に基づく表示</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.rowBottomPageMiddle}>
                <TouchableOpacity onPress={async()=>{
                  if(isWeb){
                    const token = await CommonFunction.getEncryptAccessToken();
                    if(token){
                      window.location.href = ApiConfig.contact.url+'?token='+token;
                    }else{
                      window.location.href = ApiConfig.contact.url+'contact-nonmember';
                    }
                  }else{
                    this.props.navigation.navigate('InquiryNonMember');
                  }
                }}>
                  <Text style={styles.txtBottomPage}>お問い合わせ</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.rowBottomPageBottom}>
                <Text style={styles.txtsmallBottomPage}>
                ※18歳未満の方はご利用できません。{"\n"}
                ※パパ活・P活などの金銭のやりとりを前提とした{"\n"}
                出会いを推奨するサイト・アプリではありません。{"\n"}
                ※警察へのインターネット異性事業届出済 {"\n"}
                (届出番号 : 2022112100468)
                </Text>
              </View>
            </View>

          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}

// for iframe
class IframeIndexScreen extends Component{

  register(){
    if(isWeb) window.open('/Register/user-info?static=1', '_top');
  }

  login(){
    if(isWeb) window.open('/Login?static=1', '_top');
  }

  render(){
    return (
      <View style={styles.containerIframe}>
        <View style={{paddingTop:20, paddingBottom:20}}>
          <View style={styles.logo}>
            <Text style={styles.txtBottomLogo}>＼ わずか1分の簡単無料登録 ／</Text>
          </View>
          <View style={styles.buttonPanel}>
            <TouchableOpacity style={styles.regBtn} onPress={ () => this.register() }>
              <Image style={styles.regImageBtn} source={require('../assets/images/register-email.png')} />
            </TouchableOpacity>
            <View style={styles.blogRemarks}>
              <Text style={styles.txtBlogRemarksRed}>18歳未満の方はご利用できません。</Text>
            </View>
            <TouchableOpacity style={styles.loginBtn} onPress={ () => this.login() }>
              <Image style={styles.loginImageBtn} source={require('../assets/images/login.png')}   />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    alignItems:'center',
    backgroundColor:'#ffffff',
    width:'100%',
    zIndex:-1,
  },
  ScrollContainer: {
    width:'100%',
    height:'100%',
  },
  bgTopImg: {
    width:'100%',
    height:200,
  },
  overlay: {
    justifyContent:'center',
    alignContent:'center',
    position:'absolute',
    left:0,
    top:0,
    opacity:0.6,
    width:'100%',
    height:'100%',
    backgroundColor:'#cccccc',
  },
  overlayHeader:{
    top:0,
    width:'100%',
    height:200,    
    backgroundColor:'rgba(0, 0, 0, 0.3)',
  },
  headerTextArea:{
    alignItems: 'center',
    position: 'absolute',
    top:110,
    width:'100%',
  },
  headerTextTop:{
    color:'#ffffff',
    fontSize:24,
    lineHeight:24,
    fontWeight:'bold'
  },
  headerTextBottom:{
    marginTop:12,
    color:'#ffffff',
    fontSize:30,
    lineHeight:30,
    fontWeight:'bold'
  },
  topLogo:{
    marginTop:32,
  },
  txtTopLogo:{
    fontSize:20,
    fontWeight:'bold',
    lineHeight:22,
    color:'#5cc8ca',
  },
  logo:{
    alignItems:'center',
  },
  imgLogo:{
    marginTop:20,
    marginBottom:27,
    width:230, 
    height:59,
  },
  txtBottomLogo:{
    color:'#4a4a4a',
    fontSize:17,
    lineHeight:17,
    fontWeight:'bold'
  },
  buttonPanel:{
    alignItems:'center',
    width:'100%',    
  },
  regBtn:{
    alignItems:'center',
    width:"100%",    
  },
  regImageBtn:{
    alignItems:'center',
    margin:15,    
    width:304, 
    height:52,    
    zIndex:1
  },
  buttonPanelRow:{
    flexDirection:'row'
  },
  txtButtonPanel:{
    marginBottom:5,
    fontSize:15,
    fontWeight:'bold',
    lineHeight:22,
    color:'#de5b8a',
    textAlign:'center',
  },
  socialBtnLeft:{
    width:"50%",
    alignItems:'flex-end',
  },
  socialBtnRight:{
    width:"50%",
    alignItems:'flex-start',
  },
  socialImageBtn:{
    alignItems:'center',
    margin:5,
    width:145, 
    height:52,    
  },
  blogRemarks:{
    alignItems:'center',
  },
  txtBlogRemarks:{
    marginTop:5,
    fontSize:10,
    lineHeight:15,
    color:'#4a4a4a',
  },
  txtBlogRemarksRed:{
    marginTop:5,
    fontSize:10,
    lineHeight:15,
    color:'#dc0000',
  },
  loginBtn:{
    alignItems:'center',
    marginTop:15,
    width:"100%",    
  },
  loginImageBtn:{
    alignItems:'center',
    width:174, 
    height:36,
  },
  blocWhy:{
    justifyContent:'center',
    paddingTop:50,
    paddingBottom:40,
    paddingRight:16,
    paddingLeft:16,
    width:'100%',
    ...Platform.select({
      ios:{
        paddingRight:0,
        paddingLeft:0,
      },
      android:{
        paddingRight:0,
        paddingLeft:0,
      }
    })
  },
  headerBlocWhy:{
    alignItems:'center',
    paddingBottom:10,
  },
  headerTxtBlocWhy:{
    fontSize:18,
    fontWeight:'bold',
    lineHeight:28, 
    color:'#4a4a4a',
    textAlign:'center'   
  },
  blocWhyRow:{
    alignItems:'center',
    marginTop:45,
  },
  blocWhyTopRow:{
    alignItems:'center',
    width:'100%',
  },
  blocWhyRowImg:{
    alignItems:'center',
    marginBottom:15,
  },
  imgBlocWhyTopImg:{
    width:375,
    height:389,
    ...Platform.select({
      ios:{
        width:'100%',
        maxWidth:412
      },
      android:{
        width:'100%',
        maxWidth:412
      }
    })
  },
  imgBlocWhyRowImg:{
    width:136,
    height:136
  },
  blocWhyRowTxt:{
    alignItems:'center',
    textAlign:'center',
  },
  topicBlocWhyRowTxt:{
    fontSize:18,
    fontWeight:'bold',
    lineHeight:30,
    color:'#4a4a4a',
    textAlign:'center'
  },
  txtBlocWhyRowTxt:{
    marginTop:15,
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#4a4a4a',
    textAlign:'center'
  },
  blocUseeasy:{
    flex:1,
    paddingTop:40,
    paddingBottom:40,
    width:'100%',
    backgroundColor:'#5cc8ca',
  },
  headerBlocUseeasy:{
    alignItems:'center',
    paddingLeft:16,
    paddingRight:16,
    textAlign:'center',
  },
  headerTxtBlocUseeasy:{
    fontSize:19,
    fontWeight:'bold',
    lineHeight:30, 
    color:'#ffffff',   
  },
  scrollViewInside:{
    marginTop:50,
    paddingLeft:25,
  },
  stepBlocUseeasy:{
    paddingLeft:20,
    paddingRight:20,
    alignItems:'center',
  },
  stepHeadBlocUseeasy:{
    flexDirection:'row',
    justifyContent:'center',
    height:40,
  },
  stepHeadBlocUseeasyInside:{
    alignItems:'center',
    justifyContent:'center',
    width:166,
    height:40,
    backgroundColor:'#5cc8ca',
    borderWidth:3,
    borderColor:'#ffffff',
    borderRadius:9999,
    lineHeight:34,
    color:'#ffffff',
  },
  stepHeadBlocUseeasyInsideTxt:{
    justifyContent:'center',
    textAlign:'center',
    fontSize:16,
    fontWeight:'bold',
    color:'#ffffff',
  },
  lineStepBlocUseeasy:{
    alignItems:'flex-end',
    position:'absolute',
    top:'50%',
    right:0,
    width:113,
    height:3, 
    backgroundColor:'#ffffff'
  },
  arrowBlocUseeasy:{
    position:'absolute',
    top:'50%',
    right:-12,
    marginTop:-200,
    width:24,
    height:27,
  },
  txtHeadBlocUseeasy:{
    marginTop:24,
    marginBottom:15,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:24,
    color:'#ffffff',
    textAlign:'center',
  },
  txtDescBlocUseeasy:{
    marginBottom:15,
    fontSize:12,
    fontWeight:'normal',
    lineHeight:20,
    color:'#ffffff',
    textAlign:'center',
  },
  imgBlocUseeasy:{
    width:235,
    height:470,
  },
  blocUsesafe:{
    marginTop:100,
    marginBottom:40,
    marginLeft:16,
    marginRight:16,
  },
  headerBlocUsesafe:{
    alignItems:'center',
    textAlign:'center',
  },
  headerTopicBlocUsesafe:{
    fontSize:19,
    fontWeight:'bold',
    lineHeight:30, 
    color:'#4a4a4a',
    textAlign:'center'
  },
  headerTxtBlocUsesafe:{
    marginTop:30,
    marginBottom:15,
    fontSize:12,
    fontWeight:'normal',
    lineHeight:20, 
    color:'#4a4a4a',
    textAlign:'center'
  },
  blocUsesafeRow:{
    flexDirection:'column',
    padding:20,
    width:'100%',
    alignItems:'center',
    alignSelf:'center'
  },
  blocUsesafeRowImg:{
    alignItems:'center',
    marginBottom:35,
  },
  imgBlocUsesafeRowImg:{
    width:80,
    height:64,
  },
  blocUsesafeRowTxt:{
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'flex-start',
    alignItems:'flex-start',
    alignSelf:'flex-start',
  },
  checkImgBlocUsesafe:{
    marginTop:12,
    marginRight:18,
    width:20,
    height:20,
  },
  txtDescBlocUsesafe:{
    fontSize:17,
    fontWeight:'bold',
    lineHeight:24, 
    color:'#4a4a4a',
  },
  bottomPage:{
    alignItems:'center',
    marginTop:35,
    paddingTop:25,
    paddingBottom:12,
    paddingLeft:16,
    paddingRight:16,
    width:'100%',
    backgroundColor:'#5cc8ca',
    textAlign:'center',
  },
  imgLogoBottomPage:{
    marginBottom:30,
    width:132, 
    height:49,
  },
  rowBottomPageTop:{
    flexDirection:'row',
  },
  rowBottomPageMiddle:{
    marginTop:10,
    textAlign:'center',
  },
  rowBottomPageBottom:{
    marginTop:20,
    textAlign:'center',
  },
  txtBottomPage:{
    fontSize:11,
    fontWeight:'normal',
    lineHeight:15,
    color:'#ffffff',
  },  
  txtsmallBottomPage:{
    fontSize:9,
    fontWeight:'normal',
    lineHeight:15,
    color:'#ffffff',
    textAlign:'center'
  },
  containerIframe: {
    alignItems:'center',
    backgroundColor:'#ffffff',
    width:'100%',
    overflow:'hidden'
  },
});
