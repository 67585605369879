import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';

//03_API仕様\レスポンス仕様書\08_非表示
export default class Hide08Api extends ApiPappy{

  //1.1.1.非表示ユーザー一覧取得
  static async GetList(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Hide/Get/List/",
        { 
          count: (values?.count!=undefined)?values.count:20,
          page: (values?.page!=undefined)?values.page:1,
        },
        true
      );
      if(__DEV__) console.log('/Hide/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.HideGetList));
            console.log('/Hide/Get/List/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.非表示追加
  static async PostHide(targetMemberId){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Hide/Post/Hide/",
        { targetMemberId: targetMemberId },
        true
      );
      if(__DEV__) console.log('/Hide/Post/Hide/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Hide/Post/Hide/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1.非表示解除
  static async DeleteHide(targetMemberId){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Hide/Delete/Hide/",
        { targetMemberId: targetMemberId },
        true
      );
      if(__DEV__) console.log('/Hide/Delete/Hide/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Hide/Delete/Hide/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

}