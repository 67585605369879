import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import Constants from '../../constants/Constants';
import * as RootNavigation from '../../navigation/RootNavigation';

export default class AgeVerify2 extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      partnerMsg: false,
      myProfile: { sex: 0 }
    }
    this.hideAction = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;

      const { appState } = this.context;
      const myProfile = await UserUtil.getUserProfileLocal(appState);
      if(myProfile){
        this.setState({ myProfile: myProfile });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true});
  }

  showPartner = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true, partnerMsg: true});
  }

  hide = ()=>{
    this.setState({ show: false, partnerMsg: false});
  }

  render() {
    if(!this.state.show) return <></>;

    if(this.state.partnerMsg){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.hide();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
              <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center'}}>
                  お相手の年齢確認がお済みでないためメッセージを送ることができません
                </Text>
              </View>
              <TouchableOpacity onPress={()=>{
                this.hide();
              }} style={{padding:15, width:'100%', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Portal>
      );
    }

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <ImageBackground resizeMode={'cover'} 
              style={{width: '100%', height: 201}} 
              imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
              source={require('../../assets/images/bg-openchat.jpg')}
            >
              <View style={{position: 'absolute', bottom: 0, padding: 10, backgroundColor: 'rgba(51,189,218, 0.85)', width: '100%', height: 65}}>
              {this.state.myProfile?.sex==Constants.FEMALE?
              <Text style={{fontSize: 16, fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 22}}>
                メッセージの開封・返信には{"\n"}年齢確認が必要です</Text>
              :
              <Text style={{fontSize: 16, fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 22}}>
                メッセージの開封・返信には{"\n"}年齢確認と有料会員登録が必要です</Text>
              }
              </View>
            </ImageBackground>
            <View style={styles.btnArea}>
              <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                this.hide();
                this.navigation.navigate('Auth',{ screen:'Identification'});
              }}>
                <Text style={styles.txtBtnBlue}>年齢確認をする</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btnCancel} onPress={()=>this.hide()}>
                {/*<Text style={styles.txtBtnCancel}>キャンセル</Text>*/}
                <Text style={styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:332, 
    width:'77%',
    alignSelf:'center', 
    borderRadius:15
  },
  btnBlue:{
    marginBottom:10,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'100%',
    padding:8,
    height:42,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#a2aaab',
    textAlign:'center',
  },
  btnArea: {
    paddingTop:25,
    paddingBottom:25,
    paddingRight:27,
    paddingLeft:27,
  }
});