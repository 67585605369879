import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, ImageBackground, Dimensions
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Payment15Api from '../services/api/Payment15Api';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import ListStyles from '../styles/ListStyles';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';
import UserVerify from '../utils/UserVerify';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PurchaseTrialScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
    this.prevRoute = ['ChatRoom'];
    this.popup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      this.getDataFromApi();
      CommonFunction.hideBottomTab(this.navigation);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.checkBottomTab();
  }  

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.showBottomTab(this.navigation);
    }
  }

  getDataFromApi = async ()=>{
    try{
      const checkAccessTimeFlg = await CommonFunction.checkAccessTime();
      if(!checkAccessTimeFlg) return;

      const orderResponse = await Payment15Api.AuthCreditCardUrlTelecom();

      if(!CommonFunction.checkResponseStatus(orderResponse)) return;

      if(checkUndefined(orderResponse) && orderResponse?.status==1){
        let data = orderResponse?.response;
        let account = UserUtil.getAccountAppState(orderResponse, this.context);
        this.setState({
          account: account,
          data: data
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false});
    }
  }

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, 'お試しプラン', true);
  }
  
  noItemDisplay = () => {
    return <></>;
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.props.navigation,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.props.navigation
    });
  }

  //お試しプランの購入処理
  purchaseTrialAction = async ()=>{
    if(this.state.data?.mail==0){
      this.popup?.showPopup();
      return;
    }

    if(!this.checkSMS()) return;
    if(!this.checkAge()) return;

    if(Platform.OS==='web'){
      // check api
      const checkSubscriptionStatResopnse = await Payment15Api.CheckSubscriptionStat(0,1);
      if(!CommonFunction.checkResponseStatus(checkSubscriptionStatResopnse)) return;

      if(checkSubscriptionStatResopnse?.status==1){
  
        let form = document.createElement('form');
        form.method = "POST";
        form.action = this.state.data?.orderUrl;
        const element1 = this.createInputElement('hidden', 'clientip', this.state.data?.clientip);
        const element2 = this.createInputElement('hidden', 'sendid', this.state.data.sendid);
        const element3 = this.createInputElement('hidden', 'redirect_url', this.state.data.redirectUrl);
        const element4 = this.createInputElement('hidden', 'ordertype', this.state.data?.orderType);
        form.appendChild(element1);
        form.appendChild(element2);
        form.appendChild(element3);
        form.appendChild(element4);
        console.log(form);
  
        document.body.appendChild(form);
        form.submit();
        
        if(__DEV__) console.log(form);
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(checkSubscriptionStatResopnse)){
          if(checkSubscriptionStatResopnse.response!=undefined){
            const res = checkSubscriptionStatResopnse.response;
            const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
            const errorMessage = res?.errorMessage;
            if(errorCode==ErrorCode.CheckSubscriptionStat.Activated){ // sms
              SMSWrapper.showSMS(this.navigation);
            }else if(errorCode==ErrorCode.CheckSubscriptionStat.AgeVerfication){ // age
              AgeWrapper.showAge(this.navigation);
            }else{
              if(errorMessage) PopupWrapper.showMessage(errorMessage, 'warning');
            }
          }
        }
      }
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>
          <View style={styles.blogInner}>
            <ImageBackground source={require('../assets/images/trial/trial_image_01.png')} resizeMode="contain" style={styles.trialImgCom01}></ImageBackground>
            <ImageBackground source={require('../assets/images/trial/trial_image_02.png')} resizeMode="contain" style={styles.trialImgCom02}></ImageBackground>
            <ImageBackground source={require('../assets/images/trial/trial_image_03.png')} resizeMode="contain" style={styles.trialImgCom03}></ImageBackground>
            <View style={styles.trialBtnArea}>
              <TouchableOpacity style={{}} onPress={()=>{
                this.purchaseTrialAction();
              }}>
                <Image style={styles.trialBtnImg} source={require('../assets/images/trial/web/trial_buy_app_button_web.png')} resizeMode="contain"/>
              </TouchableOpacity>
            </View>
            <View style={styles.blogNote}>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNoteCenter}>お試しプランの期間が終了するまでに解約されない場合</Text>
                  <Text style={styles.txtBlogNoteCenter}>1ヶ月プランに自動更新されます</Text>
                  <Text style={[styles.txtBlogNoteCenter, {paddingTop:10}]}>お試しプランへの加入をされる場合、</Text>
                  <Text style={styles.txtBlogNoteCenter}>ページ下部にある「注意事項」に同意されたものとします</Text>
                </View>
              </View>
            </View>
            <ImageBackground source={require('../assets/images/trial/web/trial_image_app_web.png')} resizeMode="contain" style={styles.trialImgWeb01}></ImageBackground>
            <ImageBackground source={require('../assets/images/trial/trial_image_04.png')} resizeMode="contain" style={styles.trialImgCom04}></ImageBackground>
            <ImageBackground source={require('../assets/images/trial/trial_image_05.png')} resizeMode="contain" style={styles.trialImgCom05}></ImageBackground>
            <View style={styles.trialBtnArea}>
              <TouchableOpacity style={{}} onPress={()=>{
                this.purchaseTrialAction();
              }}>
                <Image style={styles.trialBtnImg} source={require('../assets/images/trial/web/trial_buy_app_button_web.png')} resizeMode="contain"/>
              </TouchableOpacity>
            </View>
            <View style={styles.blogNote}>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNoteCenter}>お試しプランの期間が終了するまでに解約されない場合</Text>
                  <Text style={styles.txtBlogNoteCenter}>1ヶ月プランに自動更新されます</Text>
                  <Text style={[styles.txtBlogNoteCenter, {paddingTop:10}]}>お試しプランへの加入をされる場合、</Text>
                  <Text style={styles.txtBlogNoteCenter}>ページ下部にある「注意事項」に同意されたものとします</Text>
                </View>
              </View>
            </View>
            <View style={[styles.blogRatePlanInside, {paddingBottom:10}]}> 
              <View style={styles.blogNote}>
                <View style={styles.blogNoteTitle}><Text style={styles.txtBlogNoteTitle}>●注意事項（必ずご確認ください）</Text></View>
                <View style={styles.blogNoteInside}>
                  <View style={styles.blogNoteInsideRight}>
                    <Text style={styles.txtBlogNote}>お試しプランは、お一人様一度のみ加入できる特別プランとなっており、加入から1ヶ月間は有料プランと同じ条件でご利用いただけます。</Text>
                    <Text style={styles.txtBlogNote}>有料プランに加入した事がある方、お試しプランに加入した事がある方は、お試しプランに加入することができません。</Text>
                    <Text style={styles.txtBlogNote}>お試しプランの期間（加入から1ヶ月間） 終了日までにお試しプランの解約をされない場合は、お試しプラン期間終了後に1ヶ月プラン/6,800円が自動継続されます。</Text>
                    <Text style={styles.txtBlogNote}>お試しプラン加入日の翌日から会員ステータス画面にてプランの解約が可能となりますので、自動継続をご希望でない場合は、お試しプラン期間終了日までにお手続きを完了させてください。</Text>
                    <Text style={styles.txtBlogNote}>お試しプランを無料期間の途中で解約した場合、即時にお試しプランの加入資格が失われ、残りの期間は無料会員となりますのでご了承ください。</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
    </View>
  }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner:{
    width:'100%',    
  },
  trialImgCom01:{
    width:'100%',
    height:windowWidth<375? 160:187,
  },
  trialImgCom02:{
    width:'100%',
    height:windowWidth<375? 90:96,
  },
  trialImgCom03:{
    width:'100%',
    height:windowWidth<375? 124:137,
  },
  trialImgCom04:{
    width:'100%',
    height:windowWidth<375? 28:32,
    alignSelf:'center',
  },
  trialImgCom05:{
    width:'100%',
    height:windowWidth<375? 28:32,
    alignSelf:'center',
  },
  trialImgWeb01:{
    width:'100%',
    height:windowWidth<375? 677:749,
  },
  trialBtnArea: {
    width:'100%',
    marginTop:5,
    marginBottom:5,
  },
  trialBtnImg: {
    width:'80%',
    height:windowWidth<375? 81:93,
    alignSelf:'center'
  },
  blogRatePlanInside:{
    backgroundColor:'#f0f5f6',
    paddingTop:10,
  },
  blogNote:{
    paddingTop:30,
    paddingBottom:30,
    paddingLeft:16,
    paddingRight:16,
  },
  blogNoteTitle:{
    marginBottom:10,
  },
  txtBlogNoteTitle:{
    fontSize:windowWidth<375? 11:13,
    fontWeight:'bold',
    lineHeight:16,
    color:'#555',
  },
  blogNoteInside:{
    flexDirection:'row',
  },
  blogNoteInsideRight:{
    flex:1,
    flexDirection:'column',
    paddingLeft:3,
  },
  txtBlogNote:{
    fontSize:windowWidth<375? 10:12,
    fontWeight:'normal',
    lineHeight:windowWidth<375? 17:20,
    color:'#555',
  },  
  txtBlogNoteCenter:{
    fontSize:windowWidth<375? 10:12,
    fontWeight:'normal',
    lineHeight:windowWidth<375? 17:20,
    color:'#555',
    textAlign:'center',
  },
});

