const tintColor = '#90dd95';
const activeColor = '#90dd95';
const inactiveColor = '#9aa5a7';

export default {
  tintColor: tintColor,
  activeColor: activeColor,
  inactiveColor: inactiveColor,
  TabBarBackgroundColor: activeColor,
  TabBarBackgroundInActiveColor: inactiveColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',

};
