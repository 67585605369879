import React from 'react';
import { TouchableOpacity } from 'react-native';
import HeadStyle from '../../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';

const GoBackIcon =(props)=> {
  const { navigation } = props;
  return (
    <TouchableOpacity onPress={() => navigation?.goBack()}>
      <Entypo name={'chevron-thin-left'} style={[HeadStyle.icBack]}/>
    </TouchableOpacity>
  );
}

const MemoizedGoBackIcon = React.memo(GoBackIcon);
export default MemoizedGoBackIcon;
