import React, { useState, useEffect } from "react";
import PushPopup from '../components/common/PushPopup';
import { Platform } from "react-native";
import Storage from '../utils/StorageHelper2';


const Notification = () => {
  let pushPopupRef = React.useRef();
  const [notification, setNotification] = useState({ payload: null });
  useEffect(() => {
    if (notification?.payload) {
      pushPopupRef?.show(notification.payload);
    }
  }, [notification]);


  if(Platform.OS === 'web'){
    const { onMessageListener } = require('./firebase');
    onMessageListener()
    .then((payload) => {
      setNotification({ payload: payload });
    })
    .catch((err) => console.log("failed: ", err));
  }


  return <PushPopup ref={ref=>{pushPopupRef=ref}} />;
};
async function requestPermission() {
  if(Platform.OS === 'web'){
    const { requestForToken } = require('./firebase');
    if (!window.Notification) {
      return;
    }
    const isPushRequest = await Storage.getData('isPushRequest');
    if(!isPushRequest){
      if(confirm("Pappyからのお知らせを受け取りますか？")){
        // OKボタンがクリックされた場合の処理
        window.Notification.requestPermission().then(async(permission) => {
          if (permission === 'granted') {
            requestForToken();
          }
          await Storage.storeData('isPushRequest',true);
        });
      } else {
        // キャンセルボタンがクリックされた場合の処理
        await Storage.storeData('isPushRequest',true);
      }
    }
  }
  
}
export default Notification;