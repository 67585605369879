import React, { Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet, Image, Platform } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width;

export default class FavoriteSearch extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.keyFavoriteSearch = 'FavoriteSearch';
    this.inputFavorite = 0;
    this.InputFavoriteNames = [];
    this.nonFavoriteName = '名称未設定';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
      
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (params)=>{
    this.inputFavorite = Number(params.selected);
    this.InputFavoriteNames = params.favoriteNames;
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  setFavoriteItem = (selectFavorite)=>{
    if(this.inputFavorite!=selectFavorite) {
      const params = { favoriteFilter: selectFavorite };
      this.props.filterUpdata(params);
    }
    this.hide();
  }

  favoriteNameChange = (slectNameChange)=>{
    this.hide();
    const params = {selected: this.state.selected, slectNameChange: slectNameChange};
    this.props.nameSetting(params);
  }

  changeDispText = (textData)=>{
    if (textData.length==0) {
      textData = this.nonFavoriteName;
    }
    return textData;
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <View style={styles.textArea}>
              <View style={{bottom: 0, width: '100%', height: 65}}>
                <Text style={{fontSize: 20, fontWeight: 'bold', color: '#333', textAlign: 'center', lineHeight: 45}}>
                  お気に入りマーク別に絞り込む
                </Text>
                <Text style={{fontSize:windowWidth<375? 10 : 11, color: '#333', textAlign: 'center', lineHeight: 20}}>
                  お相手を設定した三段階のお気に入りマークで絞り込めます。{"\n"}
                  一覧画面で一人ずつ左スワイプしても設定可能です。
                </Text>
              </View>
            </View>
            <View style={styles.btnArea}>
              <View style={styles.btnFavoriteBase}>
                <TouchableOpacity style={styles.btnFavorite} onPress={()=>{
                  this.setFavoriteItem(0);
                }}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{width: 40}}>
                      {this.inputFavorite==0?
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOn.png')} />
                      :
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOff.png')} />
                      }
                    </View>
                    <View style={{width: 240}}>
                      <Text style={[styles.txtBtnFavorite, {paddingLeft: 8, fontSize:16, lineHeight:40}]}>すべてを表示する</Text>
                    </View>  
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.btnFavoriteBase}>
                <TouchableOpacity style={styles.btnFavorite} onPress={()=>{
                  this.setFavoriteItem(4);
                }}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{width: 40}}>
                      {this.inputFavorite==4?
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOn.png')} />
                      :
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOff.png')} />
                      }
                    </View>
                    <View style={{width: 240}}>
                      <Text style={[styles.txtBtnFavorite, {paddingLeft: 8, fontSize:16, lineHeight:40}]}>未返信のみ表示する</Text>
                    </View>  
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.btnFavoriteBase}>
                <TouchableOpacity style={styles.btnFavorite} onPress={()=>{
                  this.setFavoriteItem(3);
                }}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{width: 40}}>
                      {this.inputFavorite==3?
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOn.png')} />
                      :
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOff.png')} />
                      }
                    </View>
                    <View style={{width: 240, flexDirection: 'row'}}>
                      <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Image style={[styles.checkImg, {paddingLeft: 5}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Image style={[styles.checkImg, {paddingLeft: 5}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Text style={[styles.txtBtnFavorite, {fontSize:windowWidth<375 ? 11:13, paddingTop: 8, paddingLeft: 5, lineHeight:20, alignSelf:'center'}]}>{this.changeDispText(this.InputFavoriteNames.triple)}</Text>
                    </View>  
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangBase} onPress={()=>{
                  this.favoriteNameChange(3);
                }}>
                  <Text style={styles.nameChangBtn}>変更</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.btnFavoriteBase}>
                <TouchableOpacity style={styles.btnFavorite} onPress={()=>{
                  this.setFavoriteItem(2);
                }}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{width: 40}}>
                      {this.inputFavorite==2?
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOn.png')} />
                      :
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOff.png')} />
                      }
                    </View>
                    <View style={{width: 240, flexDirection: 'row'}}>
                      <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Image style={[styles.checkImg, {paddingLeft: 5}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Text style={[styles.txtBtnFavorite, {fontSize:windowWidth<375 ? 11:13, paddingTop: 8, paddingLeft: 35, lineHeight:20, alignSelf:'center'}]}>{this.changeDispText(this.InputFavoriteNames.double)}</Text>
                    </View> 
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangBase} onPress={()=>{
                  this.favoriteNameChange(2);
                }}>
                  <Text style={styles.nameChangBtn}>変更</Text>
                </TouchableOpacity>
              </View>
              <View style={[styles.btnFavoriteBase, {borderBottomWidth: 1}]}>
                <TouchableOpacity style={styles.btnFavorite} onPress={()=>{
                  this.setFavoriteItem(1);
                }}>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{width: 40}}>
                      {this.inputFavorite==1?
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOn.png')} />
                      :
                        <Image style={styles.checkImgBlocUsesafe} source={require('../../assets/images/favorite/favoriteIconOff.png')} />
                      }
                    </View>
                    <View style={{width: 240, flexDirection: 'row'}}>
                      <Image style={[styles.checkImg, {paddingLeft: 0}]} source={require('../../assets/images/favorite/favorite_6.png')} />
                      <Text style={[styles.txtBtnFavorite, {fontSize:windowWidth<375 ? 11:13, paddingTop: 8, paddingLeft: 65, lineHeight:20, alignSelf:'center'}]}>{this.changeDispText(this.InputFavoriteNames.single)}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.nameChangBase} onPress={()=>{
                  this.favoriteNameChange(1);
                }}>
                  <Text style={styles.nameChangBtn}>変更</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                this.hide();
              }}>
                <Text style={styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:445, 
    width:'90%',
    alignSelf:'center', 
    borderRadius:8
  },
  btnFavoriteBase:{
    flexDirection: 'row',
    width:'100%',
    height:55,
    borderTopWidth: 1,
    borderColor: "#a2aaab",
  },
  btnFavorite:{
    paddingLeft:0,
    paddingTop:8,
    width:'82%',
    flexDirection: 'column',
    height:55,
  },
  txtBtnFavorite:{
    fontSize:windowWidth<375 ? 12 : 14,
    color:'#333',
    textAlign: 'left',
  },
  btnCancel:{
    width:'100%',
    height:50,
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:13,
    lineHeight:26,
    color:'#333',
    textAlign:'center',
  },
  textArea: {
    paddingTop: 10,
    width:'100%',
    itemAlign:'center',
  },
  btnArea: {
    paddingTop:40,
    width:'100%',
    itemAlign:'center',
  },
  checkImgBlocUsesafe:{
    marginTop:10,
    width:20,
    height:20,
    alignSelf: 'center',
    justifyContent:"center",
  },
  checkImg:{
    marginTop:6,
    marginLeft:6,
    width:25,
    height:24,
  },
  nameChangBase:{
    width:56,
    height:50,
    justifyContent:"center",
    ...Platform.select({
      web:{
        alignItems: 'right',
        alignSelf: 'right',
      }
    })
  },
  nameChangBtn:{
    fontSize:12,
    color: '#333',
    textAlign: 'center',
    lineHeight: 18,
    width:40,
    height:18,
    alignSelf: 'center',
    backgroundColor:'#eef1f2',
    borderColor:'#eef1f2',
    borderWidth:1,
    borderRadius:2,
    ...Platform.select({
      web:{
        alignItems: 'right',
      }
    })
  },
});