import SearchOrder from './SearchOrder';

export default class OrderByRegist extends SearchOrder {
  async getListFromApi(page){
    if(this._isMounted){
      //const sortOrder = 1;
      this.sortOrder = 1;
      await this.getSearchList(page, this.sortOrder);
      //not use
      /*
      // back from profile screen, restore state data
      let parentState = this.props?.parentState();
      if(parentState!=undefined && parentState.sortOrder==this.sortOrder){ // unmount tab
        if(parentState?.page!=undefined){
          this.page = parentState.page;
        }
        if(parentState?.numColumns!=undefined){
          this.numColumns = parentState.numColumns;
        }
        this.setState(parentState);
        this.loadComplete();
        this.props?.parentState({}); // clear parent state
      }else{ // get new data
        await this.getSearchList(page, this.sortOrder);
      }
      */
    }
  }
}
