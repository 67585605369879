import React from 'react';
import { View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import Hide08Api from '../../services/api/Hide08Api';
import CommonFunction from '../../utils/CommonFunction';
import { RefreshControl } from 'react-native-web-refresh-control';
import LoadMore from '../../components/screens/LoadMore';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import HideUserRowFormat from '../../components/data/HideUserRowFormat';
import PopupWrapper from "../../components/common/PopupWrapper";
import Msg from '../../constants/option/Msg.json';
import ListStyles from '../../styles/ListStyles';
import NoItemsBox from '../../components/common/NoItemsBox';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class HideListScreen extends LoadMore {

  setHeader = () =>{
    this.title = '非表示リスト';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.props.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.props.navigation, this.title);
  }

  componentDidMount(){
    this.setHeader();
    super.componentDidMount();    
  }

  componentWillUnmount(){
    super.componentWillUnmount();
  }
  
  async getListFromApi(page=1){
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }
      
      try{
        const params = {page: page};
        const hideListResponse = await Hide08Api.GetList(params);
        //check response
        if(!CommonFunction.checkResponseStatus(hideListResponse)) return;

        if(hideListResponse!=undefined && hideListResponse?.status==1){
          const hideList = hideListResponse?.response?.hide;
          let { data } = this.state;
          let longRefreshControl = true;

          if(hideList!=undefined){
            if(hideList.list?.length > 0){
              if(page > 1){
                data = data.concat(hideList.list);
              }else{
                data = hideList.list;
              }
            }
            if(data?.length>0) longRefreshControl = false;
            this.no_more = (hideList?.nextVal == 0)?1:0
          }
          this.setState({ 
            data: data, 
            longRefreshControl: longRefreshControl 
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }

  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;

    return <NoItemsBox msg="非表示にしたユーザーはいません"/>
  }

  async handleDeleteClick (item){   
    const memberId = item.memberId;
    if(memberId==undefined) return;
    try{
      CommonFunction.overLay();
      const deleteResponse = await Hide08Api.DeleteHide(memberId);
      //check response
      if(!CommonFunction.checkResponseStatus(deleteResponse)) return;

      if(deleteResponse!=undefined && deleteResponse?.status==1){
        PopupWrapper.setOptions({timeout:500});
        PopupWrapper.showMessage(Msg.Hide.Success,'check',()=>{
          CommonFunction.hideOverlay();
        }); // complete popup

        const filterData = this.state.data?.filter(function (items) {
          return items.memberId != memberId;
        });
        this.setState({data: filterData});
      }else{
        CommonFunction.hideOverlay();
      }
    }catch(e){
      CommonFunction.hideOverlay();
      if(__DEV__) console.log(e);
    } 
  }

  getItemLayout = (data, index) => {
    const length = 81; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  renderItem = ({ item }) => {
    return (
      <HideUserRowFormat onPress={()=>this.handleClick(item)} item={item} />
    )
  }

  render() {
    if (this.state.loading && this.page===1) return <ActivityIndicator />

    let DATA = [{title:'', data: this.state.data}];
    let length = this.state.data?.length;
    if(length<15) length = 15;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <SwipeListView  
          contentContainerStyle={ListStyles.scrollContainer}
          listViewRef={(ref)=>this.scrollRef=ref}
          useSectionList={true}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          sections={this.state.data.length > 0 ? DATA:[] }
          ListEmptyComponent={this.noItemDisplay}
          renderItem={({ item, index }) => (
            <View style={{ flex:1,flexDirection: 'row' }}>
              <HideUserRowFormat item={item} 
                onPressDelete={()=>this.handleDeleteClick(item)}
              />
            </View>
          )}
          extraData={this.state.data}
          disableRightSwipe={true}
          closeOnRowPress={true}
          rightOpenValue={-75}
          keyExtractor={(item, index) => item?.memberId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={2}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          scrollIndicatorInsets={{ right: 1 }}
          initialNumToRender={length}
        />
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}