import messaging from '@react-native-firebase/messaging';
import Storage from '../utils/StorageHelper2';
import Setting17Api from '../services/api/Setting17Api';
import firebase from '@react-native-firebase/app';
import { Platform } from 'react-native';

import CommonFunction from './CommonFunction';

// Firebase設定(起動時)
async function requestFireBaseSetup() {
  await this.requestUserPermission();
}

// プッシュ設定の初期化
async function requestUserPermission() {
  const authStatus = await messaging().requestPermission();
  const enabled =
    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
    authStatus === messaging.AuthorizationStatus.PROVISIONAL;

  if (enabled) {
    await this.getDeviceToken()
  } else {
  }
}

// fcmTokenの取得処理
async function getDeviceToken() {
  const deviceToken = await this.getFcmToken();
  console.log('getDeviceToken',deviceToken);
  return deviceToken;
}

// fcmTokenの取得処理
async function getFcmToken() {
  const fcmToken = await messaging().getToken();
  return fcmToken;
}

// fcmTokenの登録処理(プロフ一覧とマイページで実施)
async function registFcmToken() {
  if(Platform.OS === 'ios')
    await this.requestUserPermission();
  const deviceToken = await this.getDeviceToken();
  const tokenData = await this.strageGetToken();

  const enabled = (Platform.OS === 'ios') ? await firebase.messaging().hasPermission() : true;
  if (enabled) {
    if(deviceToken!=undefined) {
      if (deviceToken!=tokenData) {
        await this.upLoadDeviceToken(deviceToken) 
      }
    }
  }
}

// PutDeviceToken APIを実施
async function upLoadDeviceToken(deviceToken){
  try{
    const checkPutDeviceTokenResponse = await Setting17Api.PutDeviceToken(deviceToken);
    //check response
    if(!CommonFunction.checkResponseStatus(checkPutDeviceTokenResponse)) return;

    if(checkPutDeviceTokenResponse!=undefined){
      if(checkPutDeviceTokenResponse?.status==1){
        console.log('PutDeviceToken',checkPutDeviceTokenResponse);
      }
    }
  }catch(e){
    if(__DEV__) console.log(e);
  }finally{
  }
}

// 端末に保存してあるTokenを取得
async function strageGetToken() {
  const tokenData = await Storage.getData('deviceToken');  
  return tokenData;
}

// fcmTokenの取得(WEB)
async function getWebDviceToken(){
  if(Platform.OS === 'web'){
    const {getWebToken} = require('../firebase/firebase');
    return await getWebToken();
  }
 
}

// 端末に保存してあるTokenを取得
async function strageGetWebToken() {
  const tokenData = await Storage.getData('deviceToken_web');  
  return tokenData;
}

// fcmTokenの登録処理(WEB)
async function setupWebDeviceToken(){
  if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
    const deviceToken = await getWebDviceToken();
    const storageDeviceToken = await strageGetWebToken();
    if(deviceToken!=undefined) {
      if (deviceToken!=storageDeviceToken) {
        await upLoadDeviceToken(deviceToken) 
      }
    }
  }
}

async function checkNotification(){
  if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
    if(Notification.permission === "granted"){
      setupWebDeviceToken();
    }
  }
}

async function requestPermission(){
  if(Platform.OS === 'web' && CommonFunction.isAndroidUA()){
    window.Notification.requestPermission().then(async(permission) => {
      if (permission === 'granted') {
        const { requestForToken } = require('../firebase/firebase');
        const token = await requestForToken();
        if(token)
          setupWebDeviceToken();
      }
    });
  }
}

export default {
  requestFireBaseSetup,
  requestUserPermission,
  getDeviceToken,
  getFcmToken,
  registFcmToken,
  upLoadDeviceToken,
  strageGetToken,
  getWebDviceToken,
  setupWebDeviceToken,
  checkNotification,
  requestPermission,
  strageGetWebToken
}
