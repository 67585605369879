import IineList from '../../screens/iine/IineList';
import IineApi from '../../services/api/Iine10Api';
import UserUtil from "../../utils/UserUtil";
import CommonFunction from '../../utils/CommonFunction';

export default class MyIine extends IineList {

  async getListFromApi(page){
    if(this._isMounted){
      this.iineType = 1; //0 = from other iine, 1 = my iine

      let parentState = this.props?.parentState();
      if(parentState!=undefined && parentState.type==this.iineType){ // unmount tab
        if(parentState?.page!=undefined){
          this.page = parentState.page;
        }
        if(parentState?.no_more!=undefined){
          this.no_more = parentState.no_more;
        }
        this.setState(parentState);
        this.props.parentState({}); // clear parent state
      }else{ // get new data

        if(!this.state.isRefreshing){
          this.setState({ loading: true });
        }
        
        try{
          const params = {page: page, type: this.iineType};
          const goodGetListResponse = await IineApi.getList(params);
          //check login
          if(!CommonFunction.checkResponseStatus(goodGetListResponse)) return;

          if(goodGetListResponse!=undefined && goodGetListResponse?.status==1){
            const goodList = goodGetListResponse?.response?.good;
            const account = UserUtil.getAccountAppState(goodGetListResponse, this.appContext);
            let { data } = this.state;
            let longRefreshControl = true;

            if(goodList!=undefined){
              if(goodList?.count > 0){
                if(page > 1){
                  data = data.concat(goodList?.list);
                }else{
                  data = goodList?.list;
                }
              }
              if(data?.length>0) longRefreshControl = false;
              this.no_more = (goodList?.nextVal == 0)?1:0
            }

            this.setState({ 
              account: account, 
              data: data, 
              type: this.iineType,
              longRefreshControl: longRefreshControl
            });
            
            let otherTabRef = this.props?.otherTabRef();
            if(otherTabRef){
              if(typeof otherTabRef?.setState=='function') otherTabRef?.setState({account: account});
            }
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          this.setState({
            loading: false,
            isRefreshing: false
          });
        }
      }
    }
  }
}
