import React from 'react';
import { View, TouchableOpacity, StyleSheet, Text, Platform } from 'react-native';
import NoItemsBox from '../../components/common/NoItemsBox';
import UserRowFormat from '../../components/data/UserRowFormat';
import SkipUserRowFormat from '../../components/data/SkipUserRowFormat';
import styles from '../../styles/CommonStyles';
import LoadMore from '../../components/screens/LoadMore';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { RefreshControl } from 'react-native-web-refresh-control';
import { SwipeListView } from 'react-native-swipe-list-view';
import NotificationSMS from '../../components/common/NotificationSMS';
import HideApi from '../../services/api/Hide08Api';
import UserUtil from '../../utils/UserUtil';
import CommonFunction from '../../utils/CommonFunction';
import UserVerify from "../../utils/UserVerify";
import HeaderOptions from '../../constants/HeaderOptions';

export default class IineList extends LoadMore {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      maskUp: false,
      maskUpStateData: false,
      skipIineList: false,
      scrollHeight: 0
    }
  }

  componentDidMount() {
    super.componentDidMount();

    // from back page, if use await in componentDidMount, focus listener not fire when first mount
    this.subscribeFocus = this.props.navigation?.addListener('focus', async() => {
      CommonFunction.checkAccessTime();
      await this.checkRemoveSearchList();
      this.scrollToIndex();
    });
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.props.navigation,
    });
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.appContext;
    if(appState.setChatHideMemberId || appState.matchList){
      this.setState({maskUpStateData: true},()=>{
        const data = this.state.data;
        let filterData = data;
        if(appState.setChatHideMemberId){
          filterData = data?.filter(function (item) {
            return item.memberId != appState.setChatHideMemberId;
          });
          const findIndex = data?.findIndex(
            items => appState.setChatHideMemberId == items.memberId
          );
          if(findIndex!=-1){
            this.indexSetChatHideMemberId = findIndex;
          }
        }
     
        if(appState.matchList && Array.isArray(appState.matchList)){
          appState.matchList?.forEach((val,index)=>{
            filterData = filterData?.filter(function (item) {
              return item.memberId != val;
            });
          });
        }

        appState.setChatHideMemberId = null;
        appState.matchList = null;
        setAppState(appState);
        this.setState({data: filterData, maskUpStateData: false});
      });
    }
  }

  scrollToIndex = ()=>{
    let { appState, setAppState } = this.appContext;
    const viewIndex = appState?.iineViewIndex;
    if(viewIndex && this.state.data?.length >0){
      let maxIndex = this.state.data?.length - 1;
      if(maxIndex <0) maxIndex = 0;
      
      let gotoIndex = viewIndex;
      if(gotoIndex > maxIndex) gotoIndex = maxIndex;

      if(this.indexSetChatHideMemberId){
        if(gotoIndex >= this.indexSetChatHideMemberId) gotoIndex = gotoIndex - 1; 
      }

      if(gotoIndex>0){
        try{
          this.setState({maskUp: true}, ()=>{
            setTimeout(() => {
              this.scrollRef?.scrollToLocation({ animated:false, itemIndex: gotoIndex, sectionIndex: 0, viewPosition: 0 });
              this.setState({maskUp: false});
            }, 350);
          });
        }catch(e){
          if(__DEV__) console.log(e);
          this.setState({maskUp: false});
        }
      }
      this.indexSetChatHideMemberId = null;
      appState.iineViewIndex = null;
      setAppState(appState);
    }
  }

  async getListFromApi(page){
    await this.getListFromApi(page);
  };
  
  noItemDisplay = () => {
    if(this.state.loading || this.state.isRefreshing) return <></>;
   
    let msg = "あなたがいいねしたお相手が表示されます";
    if(this.iineType==2){ // skip
      msg = "スキップしたお相手はいません";
    }
    return (
      <NoItemsBox msg={msg} />
    );
  }

  setParentState = ()=>{
    // skip list
    if(this.iineType==2) return;

    // other tab reference
    let otherTabRef = this.props?.otherTabRef();
    if(otherTabRef){
      let otherTabState = otherTabRef?.state;
      if(otherTabState!=undefined){
        if(otherTabRef.page!=undefined){
          otherTabState.page = otherTabRef.page;
        }
        if(otherTabRef.iineType!=undefined){
          otherTabState.type = otherTabRef.iineType;
        }
        if(otherTabRef.no_more!=undefined){
          otherTabState.no_more = otherTabRef.no_more;
        }
        otherTabRef?.props?.parentState(otherTabState);
      }
    }
  }

  handleClick = async(item, index) => {
    if(item.memberId==undefined) return;

    let params = {memberId: item.memberId };
    if(this.iineType!=undefined){
      params.type = this.iineType;
    }

    let {appState, setAppState} = this.appContext;
    appState.iineViewIndex = index;
    setAppState(appState);

    try{
      await UserUtil.setsearchResult(this.appContext, this.state.data);
    }catch(e){
      if(__DEV__) console.log(e);
    }

    this.setParentState();
    this.props.navigation?.navigate("Profile", params);
  }

  handleClickGotoChat = async(item, index) => {
    if(item.memberId==undefined) return;

    let params = {memberId: item.memberId };
    if(this.iineType!=undefined){
      params.type = this.iineType;
    }

    let {appState, setAppState} = this.appContext;
    appState.iineViewIndex = index;
    setAppState(appState);

    try{
      await UserUtil.setsearchResult(this.appContext, this.state.data);
    }catch(e){
      if(__DEV__) console.log(e);
    }

    this.setParentState();
    this.props.navigation?.navigate('ChatRoom', { memberId:item.memberId, type:params.type });
  }

  handlerHide = async(rowData,rowMap)=>{
    const validateAge = this.checkAge();
    if(!validateAge){
      return;
    }

    const { item } = rowData;
    const memberId = item?.memberId;
    const rowIndex = memberId+rowData.index;

    if(memberId!=undefined){
      try{
        CommonFunction.overLay();
        const response = await HideApi.PostHide(memberId);
        const success = response?.status;
        if(success==1){
          if(rowIndex!=undefined){
            rowMap[rowIndex]?.closeRow();
          }

          let {data} = this.state; 
          if(data.length > 0){
            data = data.filter(item => item.memberId !== memberId);
            this.setState({data: data});
          }
        }
      }catch(e){
        if(__DEV__) console.log(e)
      }finally{
        CommonFunction.hideOverlay();
      }
    }
  }

  isSectionsEmpty(){
    if(this.state.data?.length >0) return false;
    else return true;
  }

  showNotificationSMS = () => {
    if(this.state.account?.activated==0){
      return true;
    }
    return false;
  }

  removeData = (memberId)=>{
    let { data } = this.state;
    data = data.filter(item => item.memberId !== memberId);
    this.setState({data: data});
  }
  
  getItemLayout = (data, index) => {
    const length = 81; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  onRightAction=(rowKey, rowMap)=>{
    // validate Age
    const validateAge = this.checkAge();
    if(!validateAge){
      rowMap[rowKey]?.closeRow();
    }
  }

  render() {
    let showLoading = false;
    if (this.state.loading && this.page === 1) showLoading = true;
    if (this.state.maskUpStateData===true) showLoading = true;
    if (showLoading) {
      return <ActivityIndicator />
    }

    let DATA = [{title:'', data: this.state.data}];
    let length = this.state.data?.length;
    if(length<15) length = 15;

    return (
      <View style={[styles.container,{paddingBottom:Platform.OS!='web'?0:HeaderOptions.bottomTabHeight-1, backgroundColor:'#fff'}]} onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        if(this.state.scrollHeight==0 && height>0){
          const scrollHeight = height - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomTabHeight;
          this.setState({scrollHeight: scrollHeight});
        }
      }}>
        {this.state.maskUp===true && (<View style={innerStyles.maskUp}><ActivityIndicator /></View>) }
        <SwipeListView 
          contentContainerStyle={
            Platform.OS!=='web'?{ 
              flexGrow:1 
            }:{}
          }
          style={
            Platform.OS==='web'?{ 
              height: this.state.scrollHeight
            }:{}
          }
          listViewRef={(ref)=>this.scrollRef=ref}
          useSectionList={true}
          refreshControl={
            <RefreshControl
              refreshing={this.state.isRefreshing}
              onRefresh={this.onRefresh}
              long={this.state.longRefreshControl}
            />
          }
          renderSectionHeader={() => (
            <>
            {/* {this.showNotificationSMS() && <NotificationSMS navigation={this.props.navigation} />} */}
            </>
          )}
          sections={this.isSectionsEmpty() ? [] : DATA}
          ListEmptyComponent={this.noItemDisplay}
          renderItem={({ item, index}) => (
            <>
            {this.state.skipIineList===true?
            <SkipUserRowFormat onPress={()=>this.handleClick(item, index)} item={item} activated={this.state.account?.activated} navigation={this.props.navigation} removeData={this.removeData} />
            :
            <UserRowFormat onPress={()=>this.handleClick(item, index)} onPressGotoChat={()=>this.handleClickGotoChat(item, index)} item={item} />
            }
            </>
          )}
          renderHiddenItem={(data,rowMap) => {
            return (
              <TouchableOpacity style={innerStyles.hideContainer} onPress={()=>{ this.handlerHide(data,rowMap)}}>
                <View style={innerStyles.hideWrapper}><Text style={innerStyles.txtHide}>非表示</Text></View>
              </TouchableOpacity>
            );
          }}
          extraData={this.state.data}
          //onRowOpen={this.onRightAction}
          //onRightAction={this.onRightAction}
          rightActivationValue={-75}
          rightActionValue={-75}
          disableRightSwipe={true}
          closeOnRowPress={true}
          rightOpenValue={-75}
          keyExtractor={(item, index) => item?.memberId+index.toString()}
          ListFooterComponent={this.renderFooter.bind(this)}
          onEndReachedThreshold={0.5}
          onEndReached={this.handleLoadMore.bind(this)}
          getItemLayout={this.getItemLayout}
          initialNumToRender={length}
        />
      </View>
    );
  }
}

const innerStyles = StyleSheet.create({
  hideContainer:{
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'flex-end',
    backgroundColor:'#e6e6e6',
    width:75, 
    height:'100%',
  },
  hideWrapper:{
    backgroundColor:'#484848',
    width:'98%',
    height:'98%',
    justifyContent:'center',
    alignContent:'center',
  },
  txtHide:{
    color:'#ffffff', 
    textAlign:'center',
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  }
});