import React, { Component } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Keyboard, InputAccessoryView, Platform } from 'react-native';

export default class KeyboardAccessory extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.inputAccessoryViewID  = 'inputAccessoryView1';
    this.state = {
      show: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.showSubscription = Keyboard.addListener('keyboardDidShow', () => {
        this.setState({show: true});
      });
      this.hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
        this.setState({show: false});
      });
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    this.showSubscription.remove();
    this.hideSubscription.remove();
  }

  render(){
    if(Platform.OS!=='ios') return null;
  
    return (
      <InputAccessoryView nativeID={this.inputAccessoryViewID}>
        <View style={styles.container} >
        <TouchableOpacity style={styles.inner} onPress={()=>{
          Keyboard.dismiss();
        }}><Text style={styles.txt}>完了</Text>
        </TouchableOpacity>
        </View>
      </InputAccessoryView>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#f8f8f8', 
    width:'100%',
    height:43,
    justifyContent:'center', 
    alignItems:'flex-end', 
    borderTopWidth:1, 
    borderTopColor:'#ebebeb',
    paddingRight:20,
    zIndex:1000,
    elevation:1000
  },
  inner:{
    padding:10,
  },
  txt:{
    fontWeight:700, 
    color:"#3478f6",
    fontSize:16
  }
});
