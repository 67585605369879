import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from '../../styles/UserInfoStyles';
import Carousel from 'react-native-snap-carousel';

export default class MessageTemplate extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.templateSwiper = React.createRef();
    this.templateItemWidth = this.props.templateItemWidth;
    this.templateSliderWidth = this.props.templateSliderWidth;
    this.myTemplateTitle = "マイ定型メッセージ";
    this.defaultTemplateTitle = "定型メッセージテンプレート";
    this.state = {
        template: this.props.template||[],
        titleTemplate: this.getDefaultTitleTemplate(this.props.template),
        imgCheckMsg: 0
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {

    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.template && prevProps && this.props.template!=prevProps.template){
      this.setState({template: this.props.template});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  getDefaultTitleTemplate = (template)=>{
    let title = '';
    const currentTemplate = template[0];
      if(currentTemplate!=undefined){
        if(currentTemplate?.myTemplate==1){
            title = `${this.myTemplateTitle}${currentTemplate?.counter}`;
        }else{
            title = `${this.defaultTemplateTitle}${currentTemplate?.counter}`;
        }
    }

    return title;
  }

  setTitleTemplate = ()=>{
    let title = '';
    if(this.templateSwiper!=undefined){
      const currentIndex = this.templateSwiper.currentIndex;
      const currentTemplate = this.state.template[currentIndex];
      if(currentTemplate!=undefined){
        if(currentTemplate?.myTemplate==1){
            title = `${this.myTemplateTitle}${currentTemplate?.counter}`;
        }else{
            title = `${this.defaultTemplateTitle}${currentTemplate?.counter}`;
        }
      }
    }

    this.setState({titleTemplate: title});
  }

  render() {
    if(!this.state.template || this.state.template.length==0) return <></>;
    
    return (
        <View>
            <View style={styles.blogTxtTitleMsg}>
                <Text style={[styles.txtTitleMsg,{height:20}]}>{this.state.titleTemplate}</Text>
            </View>
            <Carousel
                contentContainerCustomStyle={{paddingLeft:0, paddingRight:this.templateItemWidth}}
                ref={swiper => {this.templateSwiper=swiper}}
                sliderWidth={this.templateSliderWidth}
                itemWidth={this.templateItemWidth}
                activeSlideAlignment="custom"
                activeSlideOffset={1}
                data={this.state.template}
                initialNumToRender={3}
                onSnapToItem = { index => {
                    this.setTitleTemplate();
                }}
                renderItem={({ item, index }) => {
                    return (
                    <TouchableOpacity onPress={()=>{
                        if(this.props.onpressTemplate){
                            this.setState({imgCheckMsg: item.id});
                            this.props.onpressTemplate(item);
                        }
                    }}>
                    <View style={styles.blogTxtDescMsg}>
                        <Text numberOfLines={2} style={styles.txtDescMsg}>{item.text2}</Text>
                        {this.state.imgCheckMsg==item.id ?
                        <Image style={styles.imgCheckMsg} source={require('../../assets/images/icon/check-on.png')}/>
                        :
                        <Image style={styles.imgCheckMsg} source={require('../../assets/images/icon/check-off.png')}/>
                        }
                    </View>
                    </TouchableOpacity>
                )}}
            />
        </View>
      );
    }
}