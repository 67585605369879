import React,{ Component, Fragment } from 'react';
import { StyleSheet, Text, View, Platform, StatusBar, TouchableOpacity, TextInput, Dimensions, ScrollView } from 'react-native';
import HeadStyle from '../../styles/HeadStyles';
import { Formik } from 'formik';
import CommonFunction from '../../utils/CommonFunction';
import OptionData  from '../../constants/option/OptionData.json';
import formStyles from '../../styles/FormStyles';
import HeaderOptions from '../../constants/HeaderOptions';
import PopupWrapper from "../../components/common/PopupWrapper";

const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;
const inputAccessoryViewID = "inputAccessoryView1";

export default class WithdrawInputScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      txtBoxAreaHeight: 125,
      reasonCategory: '',
      category_txt: '',
      reasonDetail:'',
      opinionImprovement:'',
      opinionFunction:'',
      opinionOther:'',      
    };
    this.initialValues = {
      reasonDetail:'',
      opinionImprovement:'',
      opinionFunction:'',
      opinionOther:'',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();

      const reasonCategory = this.props?.route?.params?.reasonCategory; 
      let category_txt = "";
      OptionData.Master.Witdrawal.Items.forEach((item) => {
        if(item.id==reasonCategory) category_txt=item.value;
      });
      this.setState({
        reasonCategory:reasonCategory,
        category_txt: category_txt
      });
    }
  }

  setHeader = () =>{
    this.title = '退会手続き';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }    

  handleOnSubmit = async(values) =>{
    try{
      CommonFunction.overLay();
      if(values['reasonDetail'].length>500){
        PopupWrapper.showMessage('具体的な退会理由は５００文字以内で入力してください','warning');
      }else if(values['opinionImprovement'].length>500){
        PopupWrapper.showMessage('Pappyに改善してほしいポイントは５００文字以内で入力してください','warning'); 
      }else if(values['opinionFunction'].length>500){
        PopupWrapper.showMessage('Pappyに欲しかった機能は５００文字以内で入力してください','warning');
      }else if(values['opinionOther'].length>500){
        PopupWrapper.showMessage('その他、ご意見ご感想は５００文字以内で入力してください','warning');
      }else{
        this.navigation.navigate('WithdrawConfirm',
          {
            reasonCategory:this.state.reasonCategory,
            reasonDetail: values['reasonDetail'],
            opinionImprovement: values['opinionImprovement'],
            opinionFunction: values['opinionFunction'],
            opinionOther: values['opinionOther']
          }
        );
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    return (
      <View style={[styles.container, HeadStyle.customHeaderTop]}>
        <ScrollView style={styles.scrollContainer}>
          <View style={[styles.desc]}>
            <Text style={styles.font12}>今後のサービス改善のため、退会アンケートのご協力を</Text>
            <Text style={styles.font12}>お願い致します。</Text>
          </View>
          <Formik
            initialValues={this.initialValues}
            onSubmit={values => this.handleOnSubmit(values) }
          >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, dirty, handleSubmit, setFieldValue }) => (
            <Fragment>
              <View>
                <View style={{paddingTop:20, paddingBottom:10}}>
                  <Text style={[styles.font13, styles.title, {height:32}]}>お客様が退会される理由</Text>                
                  <Text style={styles.font13}>{this.state.category_txt}</Text>
                </View>          

                <View style={{paddingTop:30}}>
                  <View style={{flexDirection:'row'}}>
                    <View style={[styles.title, {flexDirection:'row'}]}>
                      <Text style={[styles.font13, {fontWeight:'bold'}]}>具体的な退会理由</Text>
                      <Text style={[styles.font13, {fontWeight:'bold',color:'red'}]}>（必須）</Text>
                    </View>
                    
                    <Text style={[styles.txtLimit]}>※500文字まで</Text>
                  </View>                  
                  <View style={[styles.textareaBtnBottom,{height: this.state.txtBoxAreaHeight, borderWidth:0}]}>
                    <TextInput 
                      name="reasonDetail"
                      style={[styles.textAreaStyle,{height: this.state.txtBoxAreaHeight}]} 
                      multiline={true} 
                      placeholder="内容を入力してください"
                      onChangeText={handleChange('reasonDetail')}
                      onBlur={() => setFieldTouched('reasonDetail')}                      
                      value={values.reasonDetail}
                      placeholderTextColor="#9aa5a7"
                      inputAccessoryViewID={inputAccessoryViewID}
                      />
                  </View>
                </View>  

                <View style={{paddingTop:30}}>
                  <View style={{flexDirection:'row'}}>
                    <View style={[styles.title]}>
                      <Text style={[styles.font13, {fontWeight:'bold'}]}>Pappyに改善してほしいポイントを</Text>
                      <Text style={[styles.font13, {fontWeight:'bold'}]}>教えてください</Text>
                    </View>                    
                    <Text style={[styles.txtLimit]}>※500文字まで</Text>
                  </View>                  
                  <View 
                    name="opinionImprovement"
                    style={[styles.textareaBtnBottom,{height: this.state.txtBoxAreaHeight, borderWidth:0}]}>
                    <TextInput style={[styles.textAreaStyle,{height: this.state.txtBoxAreaHeight}]} 
                      multiline={true} 
                      placeholder="内容を入力してください"
                      onChangeText={handleChange('opinionImprovement')}
                      onBlur={() => setFieldTouched('opinionImprovement')}
                      value={values.opinionImprovement}
                      placeholderTextColor="#9aa5a7"
                      inputAccessoryViewID={inputAccessoryViewID}
                      />
                  </View>
                </View>

                <View style={{paddingTop:30}}>
                  <View style={{flexDirection:'row'}}>
                    <View style={[styles.title]}>
                      <Text style={[styles.font13, {fontWeight:'bold'}]}>Pappyに欲しかった機能があれば</Text>
                      <Text style={[styles.font13, {fontWeight:'bold'}]}>教えてください</Text>
                    </View>
                    <Text style={[styles.txtLimit]}>※500文字まで</Text>
                  </View>                  
                  <View 
                    name="opinionFunction"
                    style={[styles.textareaBtnBottom,{height: this.state.txtBoxAreaHeight, borderWidth:0}]}>
                    <TextInput style={[styles.textAreaStyle,{height: this.state.txtBoxAreaHeight}]} 
                      multiline={true} 
                      placeholder="内容を入力してください"
                      onChangeText={handleChange('opinionFunction')}
                      onBlur={() => setFieldTouched('opinionFunction')}
                      value={values.opinionFunction}
                      placeholderTextColor="#9aa5a7"
                      inputAccessoryViewID={inputAccessoryViewID}
                      />
                  </View>
                </View>

                <View style={{paddingTop:30}}>
                  <View style={{flexDirection:'row'}}>
                    <Text style={[styles.font13, styles.title]}>その他、ご意見ご感想</Text>
                    <Text style={[styles.txtLimit]}>※500文字まで</Text>
                  </View>                  
                  <View 
                    name="opinionOther"
                    style={[styles.textareaBtnBottom,{height: this.state.txtBoxAreaHeight, borderWidth:0}]}>
                    <TextInput style={[styles.textAreaStyle,{height: this.state.txtBoxAreaHeight}]} 
                      multiline={true} 
                      placeholder="内容を入力してください"
                      onChangeText={handleChange('opinionOther')}
                      onBlur={() => setFieldTouched('opinionOther')}
                      value={values.opinionOther}
                      placeholderTextColor="#9aa5a7"
                      inputAccessoryViewID={inputAccessoryViewID}
                      />
                  </View>
                </View>
              </View>
              
              <View style={[formStyles.buttonPanelSetting, styles.submitButtonPanel]}>
                <TouchableOpacity disabled={!(values.reasonDetail.length>0)} onPress={handleSubmit} style={[formStyles.submitBtn, styles.submitBtn, {opacity:(values.reasonDetail.length>0) ?1:0.4}]} >
                  <Text style={styles.txtBtnBlue}>確認画面へ</Text>
                </TouchableOpacity>
              </View>

            </Fragment>
          )} 
          </Formik>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
    ...Platform.select({
      ios: { },
      android: { },
      default: { }
    }),
  },
  scrollContainer: {
    backgroundColor:'#ffffff',
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:15,
    paddingRight:15,
    ...Platform.select({
      ios: {
        flexGrow:1,
      },
      android: {
        flexGrow:1,
      },
      web:{
        height: scrollHeight,
    }})
  },
  desc: {
    width:'100%',
    paddingBottom:20,
  },
  title:{
    fontWeight:'bold',
    width: '70%'
  },
  txtLimit:{
    fontSize:13,
    color: '#98a3a5',
    width: '30%',
    textAlign:'right',
    alignItems:'flex-end',
    alignSelf:'flex-end',
  },  
  font13: {
    fontSize:13,    
    lineHeight:21,
    color:'#454545'
  },
  font12: {
    fontSize:12,
    lineHeight:17,
    color:'#444444',
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  textareaBtnBottom:{
    marginTop:10,
    marginBottom:10,
    padding:5,
    width:'100%',
    height:50,
    backgroundColor:'#eef1f2',
    borderWidth:2,
    borderColor:'#dbdbdc',
    borderRadius:5,
  },
  textAreaStyle: {
    height:50,
    ...Platform.select({
      ios: { },
      android: { textAlignVertical:'top', },
      default: { },
    }),
  },
  textareaBtnBottom2:{
    justifyContent:'flex-start',
    padding:5,
    width:285,
    height:75,
    backgroundColor:'#f2f2f2',
    borderRadius:8,
  },
  submitButtonPanel:{
    ...Platform.select({
      ios:{marginBottom: 50},
      android:{marginBottom: 50}
    })
  },
  submitBtn:{
    width:'90%', 
    borderRadius:0, 
    shadowOffset: {width: 0, height: 0, }, 
    shadowOpacity: 0, 
    shadowRadius: 0, 
    elevation: 0,
  }
});