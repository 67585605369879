import React, { Component } from 'react';
import { Animated, View, StyleSheet, Dimensions, TouchableOpacity, Platform }  from 'react-native';
import { Portal } from 'react-native-paper';

const screenWidth = Dimensions.get('window').width;

export default class RightPopup extends Component {
  _isMounted = false;

  constructor(){
    super();
    this.state = {
      LeftPosition: new Animated.Value(screenWidth),
      visible: false
    }
    this.ref = React.createRef();
    this.duration = 0;
  }
  
  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      if(this.props.duration!=undefined) this.duration = this.props.duration;
    }
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  ShowPopUp = (show=true) => {   
    if(show){ 
      this.setState({ visible:true });
    }

    Animated.timing(this.state.LeftPosition, {
      duration: this.duration,
      toValue: show ? 0 : screenWidth,
      useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){
        if(!show){
          this.setState({ visible: false });
        }
      }
    });
    
  }

  render(){
    if(this.state.visible){
      return (
        <View style={styles.touch}>
          <Portal>
            <Animated.View style={[styles.container,{
              left:this.state.LeftPosition
            }]}>
              {this.props?.component}
            </Animated.View>
          </Portal>
          {this.props?.children}
        </View>
      );
    }
    
    return (
      <TouchableOpacity onPress={() => {this.ShowPopUp(true)}} style={styles.touch}>
        {this.props.children}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width:'100%',
    height:'100%'
  },
  touch:{
    flex:1,
    width:'100%',
    alignItems:'stretch',
    justifyContent:'center',
    alignContent:'center',
    alignSelf:'center',
  }
});