import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import AppUtil from '../../utils/AppUtil';


// iOS用のポイント購入、継続課金関連のAPI
export default class Payment15Api_ios extends ApiPappy{
  // 1.2.1.ポイント購入(iOS)  /Payment/Post/PurchasePointAppStore
  static async PurchasePointAppStore(values){
    try{
      let adid = await AppUtil.getAdid();
      const errorFlg = values.errorFlg;
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Payment/Post/PurchasePointAppStore/",
      { 
        transactionId: values.transactionId,
        adid: adid
      },
      true
      );
      if(__DEV__) console.log('/Payment/Post/PurchasePointAppStore/',response);
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
          const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(data, ResponseConstants.CommonResponseField));
          console.log('/Payment/Post/PurchasePointAppStore/', responseField?.join(','));
          }
          $return = data;
          if(errorFlg!=1) {
            if(data?.status!=1){
              CommonFunction.checkErrorCode(data);
            }          
          }
        }
      }else AuthHelper.showNetworkError();
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // iOS有料会員プラン 初回購入開始API（再購入も含む）   /Payment/Post/SubscriptionAppStore/
  static async SubscriptionAppStore(values){
    try{
      let adid = await AppUtil.getAdid();
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Payment/Post/SubscriptionAppStore/",
      { 
        originalTransactionId: values.transactionId,
        adid: adid
      },
      true
      );
      if(__DEV__) console.log('/Payment/Post/SubscriptionAppStore/',response);
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
          const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(data, ResponseConstants.CommonResponseField));
          console.log('/Payment/Post/SubscriptionAppStore/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            CommonFunction.checkErrorCode(data);
          }          
        }
      }else AuthHelper.showNetworkError();
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // iOS有料会員プラン リストアAPI   /Payment/Put/RestoreAppStore/
  static async RestoreAppStore(values){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Payment/Put/RestoreAppStore/",
      { 
        originalTransactionId: values.transactionId
      },
      true
      );
      if(__DEV__) console.log('/Payment/Put/RestoreAppStore/',response);
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
          const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(data, ResponseConstants.CommonResponseField));
          console.log('/Payment/Put/RestoreAppStore/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            CommonFunction.checkErrorCode(data);
          }          
        }
      }else AuthHelper.showNetworkError();
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}