import React,{ Component, Fragment } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, StatusBar, SafeAreaView } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Formik } from 'formik';
import RightSlidePopup from '../components/common/RightSlidePopup';
import DisplayText from '../components/common/DisplayText';
import OptionData  from '../constants/option/OptionData.json';
import ActivityIndicator from '../components/common/ActivityIndicator'
import { FormSwitchToggleInput } from '../components/form/FormInput';
import AdvanceSearchSliderScreen from '../screens/AdvanceSearchSliderScreen';
import Storage from '../utils/StorageHelper2';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import Constants from '../constants/Constants';
import HeadStyle from '../styles/HeadStyles';
import OverlayWrapper from '../components/common/OverlayWrapper';
import LogoNoMargin from '../components/common/LogoNoMargin';
import HeaderOptions from '../constants/HeaderOptions';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const isWeb = Platform.OS === 'web';

export default class SearchConditionScreenPopup extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props){
    super(props);
    
    this.state = {
      userProfile: { sex: 0 },
      loading: true,
      searchInfo: this.getInitialValues()
    }
  }

  // ticket_24427の対応で男性はCommonFunction.getSearchHistoryでデフォの検索設定を持つのでこの値を使うのはクリアの時のみ
  getInitialValues = ()=>{
    let mainImage = this.state?.userProfile?.sex==Constants.MALE?  true : false;  
    if(__DEV__) console.log('mainImage', mainImage)
    return {
      mainImage: mainImage,         //メイン写真
      recentJoin: false,        //最近入会
      adds: [],                 //居住地
      age: [],                  //年齢
      height: null,             //身長
      style: [],                //スタイル
      vibe: [],                 //雰囲気
      personality: [],          //性格・タイプ
      occupation: [],           //お仕事
      education: [],            //学歴
      born:[],                  //出身地
      alcohol: [],              //お酒
      cigarette: [],            //タバコ
      dateHope: [],             //デートでしたいこと
      meetHope: [],             //出会うまでの希望
      lastLoginTime: [],        //出会うまでの希望
      assets: [],
      income: [],
      holiday: []               //休日
    }
  }

  async componentDidMount(){
    this._isMounted = true;
    if (this._isMounted) {
      //popup reference
      if(this.props.getRightPopupRef!=undefined){
        let refname = this.props?.refname;
        if(refname==undefined) refname = 'SearchConditionScreenPopup';
        this.rightPopupRef = this.props.getRightPopupRef(refname);
      }

      const userProfile = await CommonFunction.getProfile();
      //const searchInfo = await Storage.getObjectData('searchInfo');
      const searchInfo = await CommonFunction.getSearchHistory(userProfile?.memberId);
      if(searchInfo){
        this.setState({searchInfo: searchInfo});
      }
      this.setState({userProfile: userProfile, loading: false});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  handleSubmit = async (values) => {
    try{
      OverlayWrapper.show(true);
      //await Storage.storeObjectData('searchInfo', values);
      await CommonFunction.setSearchHistory(this.state?.userProfile?.memberId, values);
      this.rightPopupRef?.ShowPopUp(false);
      if(this.props.callback!=undefined){
        this.props.callback();
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      OverlayWrapper.hide();
    }
  }

  resetForm = () => {
    this.setState({searchInfo: this.getInitialValues()});
    this.formik?.setValues(this.getInitialValues());
    //Storage.storeObjectData('searchInfo', this.getInitialValues());
    CommonFunction.setSearchHistory(this.state.userProfile?.memberId, this.getInitialValues());
  }

  render() {
    if(this.state.loading) return <ActivityIndicator />;
    

    let mainImageText =this.state.userProfile.sex== Constants.FEMALE? '画像あり' : '顔写真あり';
    const insets = this.context?.appState?.insets;
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.header}>
          <View style={[styles.headerLeft,{flexDirection:'row',justifyContent:'center',alignItems:'center'}]}>
            <TouchableOpacity onPress={()=>{
              this.rightPopupRef?.ShowPopUp(false);
            }}>
              <AntDesign name={'close'} style={[HeadStyle.icBack, {paddingLeft:14, paddingRight:4}]} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
            <Text style={HeadStyle.headerTitlePage}>絞り込み検索</Text>
          </View>
        </View>

        <Formik
          initialValues={this.state.searchInfo}
          onSubmit={values => this.handleSubmit(values) }
          innerRef={p => (this.formik = p)}
        >
        {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
          <Fragment>
            <ScrollView style={styles.scrollContainer}>
              <View style={styles.section}>
                <View style={styles.sectionTitle}>
                  <Text>プロフィール写真</Text>
                </View>
                <View style={styles.sectionWrapper}>
                  <View style={styles.sectionInsideNoBorder}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>{mainImageText}</Text>
                    </View>
                    <View style={styles.formSelected}>
                      <View style={styles.formSwitchSlide}>
                        <FormSwitchToggleInput fieldName="mainImage" values={values.mainImage} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.section}>
                <View style={styles.sectionTitle}>
                  <Text>基本プロフィール</Text>
                </View>
                <View style={styles.sectionWrapper}>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>居住地</Text>
                    </View>
                    <RightSlidePopup fieldName={'adds'} title={'居住地'} items={OptionData.Master.Area.Items} onChange={setFieldValue} values={values.adds} mustselect={false} maxselect={8}>
                      <DisplayText values={values.addsTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>年齢</Text>
                    </View>

                    <RightSlidePopup title={'年齢'} component={
                      <AdvanceSearchSliderScreen 
                        fieldName={'age'} 
                        onChange={setFieldValue} values={values.age} 
                        min={this.state.userProfile.sex==Constants.FEMALE?OptionData.Master.Age2.FeMale.min:OptionData.Master.Age2.Male.min} 
                        max={this.state.userProfile.sex==Constants.FEMALE?OptionData.Master.Age2.FeMale.max:OptionData.Master.Age2.Male.max} 
                        unit={OptionData.Master.Age2.unit} 
                        postfix_min={'以上'} 
                        postfix_max={'以下'} 
                      />
                    }>
                      <DisplayText values={values.ageTxt}/>
                    </RightSlidePopup>

                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>身長</Text>
                    </View>
                    
                    <RightSlidePopup title={'身長'} component={
                      <AdvanceSearchSliderScreen 
                        fieldName={'height'} 
                        onChange={setFieldValue} 
                        values={values.height} 
                        min={this.state.userProfile.sex==Constants.FEMALE?OptionData.Master.Height2.FeMale.min:OptionData.Master.Height2.Male.min} max={this.state.userProfile.sex==Constants.FEMALE?OptionData.Master.Height2.FeMale.max:OptionData.Master.Height2.Male.max} unit={OptionData.Master.Height2.unit} 
                        postfix={false} 
                        postfix_min={'以上'} 
                        postfix_max={'以下'}
                      />
                    }>
                      <DisplayText values={values.heightTxt}/>
                    </RightSlidePopup>
                  
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>スタイル</Text>
                    </View>
                    <RightSlidePopup fieldName={'style'} title={'スタイル'} items={OptionData.Master.Style.Items} onChange={setFieldValue} values={values.style} mustselect={false}>
                      <DisplayText values={values.styleTxt}/>
                    </RightSlidePopup>
                  </View>

                  {/**male */}
                  {this.state.userProfile.sex==Constants.MALE &&
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>雰囲気</Text>
                    </View>
                    <RightSlidePopup fieldName={'vibe'} title={'雰囲気'} items={OptionData.Master.Vibe.FeMale.Items} onChange={setFieldValue} values={values.vibe} mustselect={false}>
                      <DisplayText values={values.vibeTxt}/>
                    </RightSlidePopup>
                  </View>
                  }

                  {/**male */}
                  {this.state.userProfile.sex== Constants.MALE &&
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>性格・タイプ</Text>
                    </View>
                    <RightSlidePopup fieldName={'personality'} title={'性格・タイプ'} items={OptionData.Master.Personality.FeMale.Items} onChange={setFieldValue} values={values.personality} mustselect={false}>
                      <DisplayText values={values.personalityTxt}/>
                    </RightSlidePopup>
                  </View>
                  }

                  <View style={this.state.userProfile.sex==Constants.FEMALE?styles.sectionInside:styles.sectionInsideNoBorder}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>お仕事</Text>
                    </View>
                    <RightSlidePopup fieldName={'occupation'} title={'お仕事'} items={OptionData.Master.Occupation.Items} onChange={setFieldValue} values={values.occupation} mustselect={false}>
                      <DisplayText values={values.occupationTxt}/>
                    </RightSlidePopup>
                  </View>

                  {/**female */}
                  {this.state.userProfile.sex== Constants.FEMALE &&
                  isWeb && (
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>年収</Text>
                    </View>
                    <RightSlidePopup fieldName={'income'} title={'年収'} items={OptionData.Master.Income.Male.Items} onChange={setFieldValue} values={values.income} mustselect={false}>
                      <DisplayText values={values.incomeTxt}/>
                    </RightSlidePopup>
                  </View>
                  )
                  }

                  {/**female */}
                  {this.state.userProfile.sex== Constants.FEMALE &&
                  isWeb && (
                  <View style={styles.sectionInsideNoBorder}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>資産</Text>
                    </View>
                    <RightSlidePopup fieldName={'assets'} title={'資産'} items={OptionData.Master.Assets.Male.Items} onChange={setFieldValue} values={values.assets} mustselect={false}>
                      <DisplayText values={values.assetsTxt}/>
                    </RightSlidePopup>
                  </View>
                  )
                  }
                </View>
              </View>

              <View style={styles.section}>
                <View style={styles.sectionTitle}>
                  <Text>詳細プロフィール</Text>
                </View>
                <View style={styles.sectionWrapper}>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>学歴</Text>
                    </View>
                    <RightSlidePopup fieldName={'education'} title={'学歴'} items={OptionData.Master.Education.Items} onChange={setFieldValue} values={values.education} mustselect={false}>
                      <DisplayText values={values.educationTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>出身地</Text>
                    </View>
                    <RightSlidePopup fieldName={'born'} title={'出身地'} items={OptionData.Master.Area.Items} onChange={setFieldValue} values={values.born} mustselect={false}>
                      <DisplayText values={values.bornTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>お酒</Text>
                    </View>
                    <RightSlidePopup fieldName={'alcohol'} title={'お酒'} items={OptionData.Master.Alcohol.Items} onChange={setFieldValue} values={values.alcohol} single={true}>
                      <DisplayText values={values.alcoholTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>タバコ</Text>
                    </View>
                    <RightSlidePopup fieldName={'cigarette'} title={'タバコ'} items={OptionData.Master.Cigarette.Items} onChange={setFieldValue} values={values.cigarette} single={true}>
                      <DisplayText values={values.cigaretteTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInsideNoBorder}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>休日</Text>
                    </View>
                    <RightSlidePopup fieldName={'holiday'} title={'休日'} items={OptionData.Master.Holiday.Items} onChange={setFieldValue} values={values.holiday} mustselect={false}>
                      <DisplayText values={values.holidayTxt}/>
                    </RightSlidePopup>
                  </View>            
                </View>
              </View>

              <View style={styles.section}>
                <View style={styles.sectionTitle}>
                  <Text>その他のプロフィール</Text>
                </View>
                <View style={styles.sectionWrapper}>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>デートでしたいこと</Text>
                    </View>
                    <RightSlidePopup fieldName={'dateHope'} title={'デートでしたいこと'} items={OptionData.Master.DateHope.Items} onChange={setFieldValue} values={values.dateHope} mustselect={false}>
                      <DisplayText values={values.dateHopeTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>出会うまでの希望</Text>
                    </View>
                    <RightSlidePopup fieldName={'meetHope'} title={'出会うまでの希望'} items={OptionData.Master.MeetHope.Items} onChange={setFieldValue} values={values.meetHope} mustselect={false}>
                      <DisplayText values={values.meetHopeTxt}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.sectionInside}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>最終ログイン</Text>
                    </View>
                    <RightSlidePopup fieldName={'lastLoginTime'} title={'最終ログイン'} items={OptionData.Master.LastLoginTime.Items} onChange={setFieldValue} values={values.lastLoginTime} single={true}>
                      <DisplayText values={values.lastLoginTimeTxt}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.sectionInsideNoBorder}>
                    <View style={styles.formLabel}>
                      <Text style={styles.label}>最近入会</Text>
                    </View>
                    <View style={styles.formSelected}>
                      <View style={styles.formSwitchSlide}>
                        <FormSwitchToggleInput fieldName="recentJoin" values={values.recentJoin} />
                      </View>
                    </View>
                  </View>

                </View>
              </View>
            </ScrollView>
            <View style={[styles.footer,{}]}>
              <TouchableOpacity style={styles.btnReset} onPress={ () => {this.resetForm()}}>
                <Text style={styles.btnResetText}>クリア</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btnSubmit} onPress={handleSubmit}>
                <Text style={styles.btnSubmitText}>この条件で検索</Text>
              </TouchableOpacity>
            </View>
          </Fragment>
      )} 
      </Formik>
    </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff',
  },
  scrollContainer:{
    backgroundColor:'#eef1f2',
    ...Platform.select({
      ios:{
        //marginBottom: 70,
      },
      android:{
        //marginBottom: 70,
      },
      default:{
        paddingBottom:100
      }
    })
  },
  section:{
    marginTop:20,
  },
  sectionTitle:{
    marginBottom:8,
    paddingLeft:16
  },
  sectionWrapper:{
    backgroundColor:'#fff',
    borderTopWidth:1,
    borderTopColor:'#e6e6e6',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  sectionInside:{
    flexDirection:'row',
    alignSelf:'stretch',
    marginLeft:16,
    paddingRight:20,
    minHeight:44,
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },  
  sectionInsideWithBorder:{
    flexDirection:'row', 
    alignSelf:'stretch',
    marginLeft:16,
    paddingRight:20,
    minHeight:44,
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',   
  },
  sectionInsideNoBorder:{
    flexDirection:'row', 
    alignSelf:'stretch',
    marginLeft:16,
    paddingRight:20,
    minHeight:44,
    backgroundColor:'#fff',
  },
  formLabel:{
    alignItems:'flex-start'
  },
  label:{
    //flex:1,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    fontSize: 14,
    lineHeight: 19,
    color:'#484848'
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca',
    flex:1
  },
  btn:{
    marginTop:5,
    marginBottom:5,
    marginLeft:3,
    paddingTop: 10,
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15,
    fontSize:14,
    lineHeight:19,
    letterSpacing:-0.4,
    borderWidth: 1,
    borderRadius: 1,
    justifyContent:'center'
  },
  btnSelected:{
    backgroundColor:'#5cc8ca',
    borderColor:'#5cc8ca',
  },
  btnSelectedText:{
    color:'#fff',
  },
  btnUnselected:{
    backgroundColor:'#fff',
    borderColor:'#9aa5a7'
  },
  btnUnselectedText:{
    color:'#9aa5a7',
  },
  newBallon:{
    marginLeft:8,
    paddingTop:2,
    paddingRight:4,
    paddingBottom:2,
    paddingLeft:4,
    backgroundColor:'#ff8d63',
    borderRadius:4,
    fontSize:14,
    fontWeight:"normal",
    color:'#fff',    
    lineHeight:19,
  },
  footer:{
    ...Platform.select({
      ios: {
        //position:'absolute',
      },
      android: {
        //position:'absolute',
      },
      default:{
        position:'fixed'
      }
    }),
    flexDirection:'row',
    justifyContent:'space-evenly',
    alignItems:'center',
    width:'100%',
    backgroundColor:'#eef1f2',
    height:70,
    bottom:0,
  },
  btnSubmit:{
    justifyContent:'center',
    alignItems:'center',
    width:'45%',
    height:45,
    backgroundColor:"#5cc8ca",
  },
  btnSubmitText:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:40,
    color:'#fff',
    textAlign:'center',
  },
  btnReset:{
    justifyContent:'center',
    alignItems:'center',
    width:'45%',
    height:45,
    backgroundColor:"#5cc8ca",
  },
  btnResetText:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:40,
    color:'#ffffff',
    textAlign:'center',
  },
  royal:{
    flexDirection:'row',
    justifyContent:'center',
    paddingRight:6,
    paddingLeft:6,
    borderWidth:2,
    borderColor:'#a8973e',
    borderRadius:4,
  },
  royalLabel:{
    justifyContent:'center',
    fontSize:12,
    fontWeight:'bold',
    color:'#a8973e',
  },
  royal_icon:{
    marginRight:4,
    width: 20,
    height: 20,    
  },
  touch:{
    justifyContent:'center'
  },
  inputView:{
    justifyContent:"center",
    width:"100%",
    height:50,
    backgroundColor:"#eef1f2",
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  inputText:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    fontSize:16,
    lineHeight:20,
    color:"#000",
  },
  BodyFormSection:{
    paddingLeft:32,
    paddingRight:32,
    width:'100%',
  },
  formGroup:{
    marginBottom:15,
  },
  submitBtn:{
    justifyContent:'center',
    alignItems:'center',
    margin:15,
    width:"90%",
    height:42,
    backgroundColor:"#5cc8ca",
    borderRadius:9999,
    shadowColor:"#10a4c3",
    shadowOffset:{
      width:2,
      height:2,
    },
    shadowOpacity:1,
    shadowRadius:1,
    elevation:1,
  },
  submitText:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:42,
    color:'#fff',    
  },
  resetBtn:{
    justifyContent:'center',
    alignItems:'center',
    margin:15,
    width:"90%",
    height:42,
    backgroundColor:"#f2f2f2",
    borderRadius:9999,
    shadowColor:"#c5c5c5",
    shadowOffset:{
      width:2,
      height:2,
    },
    shadowOpacity:1,
    shadowRadius:1,
    elevation:1,
  },
  resetText:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:42,
    color:'#484848',    
  },
  btnArea: {
    flex:1,
    marginTop:25,
    width:'100%',
    ...Platform.select({
      ios: { },
      android: { },
      default: {
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
        alignSelf:'center', 
      }
    }),
  },
  formSwitchSlide: {
    alignItems:'flex-end',
    ...Platform.select({
      ios: { },
      android: { },
      default: { 
        marginRight:10,
      },
    }),
  },
  icClose:{
    position:'absolute',
    left: 0,
    elevation: 1,
    zIndex: 1
  },
  header:{
    flexDirection:'row',
    borderBottomWidth:1,
    backgroundColor:'#ffffff',
    borderBottomColor:'#b2b2b2',
    shadowColor: 'transparent',
    width:'100%',
    height:HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      ios: {
        marginTop:0,
       },
      android: { 
        marginTop:0, 
      },
      default: {
        
      }
    })
  },
  headerCenter:{
    flex:1, 
    alignItems:'stretch',
    justifyContent:'center',
    height:'100%', 
  },
  headerLeft:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    left:0, 
    top:0, 
    zIndex:1,
    elevation:1
  },
  headerRight:{
    position:'absolute', 
    justifyContent:'center', 
    height:'100%', 
    right:0, 
    top:0, 
    zIndex:1,
    elevation:1
  }
});