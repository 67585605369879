import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import base64 from 'react-native-base64';
import AppUtil from '../../utils/AppUtil';
/**
 * 02_プラットフォーム共通APIレスポンス仕様
 * 05_テレコム用APIレスポンス仕様
 */
export default class Payment15Api_android extends ApiPappy{
  //1.2.2.ポイント購入(Android)
  static async purchasePointGooglePlay(receipt, signature, showError=1){
    try{
      const encRecept = base64.encode(receipt)
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Post/PurchasePointGooglePlay/",
        {
          receipt: encRecept,
          signature: signature,
          adid: adid
        },
        true
      );
      if(__DEV__) console.log('/Payment/Post/PurchasePointGooglePlay/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Post/PurchasePointGooglePlay/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //有料会員プラン購入チェックAPI
  static async checkSubscriptionStat(showError=1){
    try{
      const encRecept = base64.encode(receipt)
      
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Get/CheckSubscriptionStat/",
        {
          receipt: encRecept,
          signature: signature
        },
        true
      );
      if(__DEV__) console.log('/Payment/Post/PurchasePointGooglePlay/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Post/PurchasePointGooglePlay/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //Android有料会員プラン購入開始API（初回購入/再購入用）(Android)
  static async subscriptionGooglePlay(receipt, signature, showError=1){
    try{
      const encRecept = base64.encode(receipt)
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Post/SubscriptionGooglePlay/",
        {
          receipt: encRecept,
          signature: signature,
          adid: adid
        },
        true
      );
      if(__DEV__) console.log('/Payment/Post/SubscriptionGooglePlay/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Post/SubscriptionGooglePlay/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //AndroidリストアAPI(Android)
  static async restoreGooglePlay(receipt, signature, showError=1){
    try{
      console.log('RestoreGooglePlay',receipt);
      const encRecept = base64.encode(receipt)
      
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Payment/Put/RestoreGooglePlay/",
        {
          receipt: encRecept,
          signature: signature
        },
        true
      );
      if(__DEV__) console.log('/Payment/Put/RestoreGooglePlay/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Payment/Put/RestoreGooglePlay/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}