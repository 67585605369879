import IineList from '../../screens/iine/IineList';
import IineApi from '../../services/api/Iine10Api';
import CommonFunction from '../../utils/CommonFunction';
import UserUtil from "../../utils/UserUtil";

export default class SkipIine extends IineList {
  componentDidMount(){
    super.componentDidMount();

    if(this._isMounted){
      this.iineType = 2; // skip
      this.setState({skipIineList: true});
      this.setHeader();

      let {appState, setAppState} = this.appContext;
      appState.iineViewIndex = null;
      setAppState(appState);
    }
  }

  setHeader = () =>{
    CommonFunction.setHeaderTitleWithLogo(this.props.navigation, 'スキップしたお相手');
  }

  async getListFromApi(page){
    if(this._isMounted){
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }

      try{
        const params = {page: page};
        const goodGetListResponse = await IineApi.getSkipList(params);

        //check login
        if(!CommonFunction.checkResponseStatus(goodGetListResponse)) return;

        if(goodGetListResponse!=undefined && goodGetListResponse?.status==1){
          const goodList = goodGetListResponse?.response?.skip;
          const account = UserUtil.getAccountAppState(goodGetListResponse, this.appContext);
          let { data } = this.state;
          let longRefreshControl = true;
          if(goodList!=undefined){
            if(goodList?.list?.length > 0){
              if(page > 1){
                data =  data.concat(goodList?.list)
              }else{
                data = goodList?.list;
              }
            }
            if(data?.length>0) longRefreshControl = false;
            this.no_more = (goodList?.nextVal==0)?1:0;
          }
         
          this.setState({ 
            account: account, 
            data: data, 
            type: this.iineType,
            longRefreshControl: longRefreshControl
          });
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({
          loading: false,
          isRefreshing: false
        });
      }
    }
  }
}
