import React, { Component } from 'react';
import { Dimensions, Text, View, Platform } from 'react-native';
import AllNotification from './notification/AllNotification';
import IndividualNotification from './notification/IndividualNotification';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import TabbarOptions from '../constants/TabbarOptions';
import HeaderOptions from '../constants/HeaderOptions';
import HeadStyle from '../styles/HeadStyles';
import TabBadgeExclamation from '../components/common/TabBadgeExclamation';
import GoBackIcon from '../components/common/GoBackIcon';
import GoBackIconMypage from '../components/common/GoBackIconMypage';
import LogoNoMargin from '../components/common/LogoNoMargin';
import CommonFunction from '../utils/CommonFunction';

const Tab = createMaterialTopTabNavigator();
const windowWidth = Dimensions.get('window').width;

export default class NotificationScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.tab = { 
      num: 2, 
      width: 100, 
    };
    this.tabBarItemWidth = windowWidth / this.tab.num;
    this.left = ((windowWidth / this.tab.num) - this.tab.width) / 2;
    this.tab1 = 'お知らせ';
    this.tab2 = '運営より';
    this.title = 'Notify'; // web title page
    this.state = {
      badge: {
        unreadAllCount: 0, 
        unreadIndividualCount: 0
      },
      parentState: {}
    }
    this.fromPushFlag = 0;
  }

  async componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      this.navigation = this.props?.navigation;
      await this.getRoorFlg();
      this.setHeader();
    }
  }
  
  getRoorFlg = async()=>{
    if(Platform.OS!=='web' || (Platform.OS==='web' && CommonFunction.isAndroidUA)){
      if(this.props?.route?.params?.fromPushFlag != undefined) {
        this.fromPushFlag = this.props?.route?.params?.fromPushFlag;
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setHeader(title){
    if(title==undefined) title = this.tab1;

    this.navigation.setOptions({
      headerStyle: [HeaderOptions.HeaderIine,HeaderOptions.HeaderIosHeight],
      headerTintColor: HeaderOptions.HeaderIineTitleColor,
      headerTitleStyle: HeaderOptions.HeaderIineTitle,
      headerTitleAlign:'center',
      headerTitle: () => (
        <Text style={HeadStyle.headerTitlePage}>{title}</Text>
      ),
      headerLeft:() => (
        <View style={{flexDirection:'row'}}>
          {this.fromPushFlag==1?
            <GoBackIconMypage navigation={this.navigation}/>
          :
            <GoBackIcon navigation={this.navigation}/>
          }
          <LogoNoMargin/>
        </View>
      ),
      headerRight:() => (<View/>)
    });
  }

  setBadge = (values)=>{
    this.setState({badge: values});
  }

  getAllTabRef = ()=>{
    return this.tabAllRef;
  }

  getIndividualTabRef = ()=>{
    return this.tabIndividualRef;
  }

  parentState = (state=null)=>{
    if(state){
      this.setState({parentState: state});
    }else{
      return this.state.parentState;
    }
  }

  render() {
    return (
      <Tab.Navigator
        screenOptions={{
          title: this.title,
          lazy: true,
          swipeEnabled: false,
          tabBarLabelStyle: TabbarOptions.labelStyle,
          tabBarStyle: TabbarOptions.style2,
          tabBarItemStyle:{ width: this.tabBarItemWidth },
          tabBarIndicatorStyle: [TabbarOptions.indicatorStyle, {width: this.tab.width, left: this.left}],
          tabBarActiveTintColor: TabbarOptions.activeTintColor,
          tabBarInactiveTintColor: TabbarOptions.inactiveTintColor,
          animationEnabled: false
        }}
        >
        <Tab.Screen name="All" 
          children={()=><AllNotification ref={ref=>this.tabAllRef=ref} {...this.props} setBadge={this.setBadge} parentState={this.parentState} otherTabRef={this.getIndividualTabRef} />} 
          options={{tabBarLabel: this.tab1, tabBarBadge:()=>
            <View style={{position:'absolute', top:-6, right:5}}>
              <TabBadgeExclamation badge={this.state.badge.unreadAllCount}/>
            </View> 
          }}
        />
        <Tab.Screen name="Individual" 
          children={()=><IndividualNotification ref={ref=>this.tabIndividualRef=ref} {...this.props} setBadge={this.setBadge} parentState={this.parentState} otherTabRef={this.getAllTabRef} />} 
          options={{tabBarLabel: this.tab2, tabBarBadge:()=> 
            <View style={{position:'absolute', top:-6, right:5}}>
              <TabBadgeExclamation badge={this.state.badge.unreadIndividualCount}/>  
            </View>
          }} 
        />
      </Tab.Navigator>
    );
  }
}