import React,{ Component } from 'react';
import { View, FlatList, StyleSheet, Text, StatusBar, TouchableOpacity,Image } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; 

export default class OptionListSingleSelected extends Component { 
    constructor(props) {
        super(props);
        this.state = {
          dataSource: []
        }
    }
    
    componentDidMount(){
      this.props.item.map( (s, i) => {
        if(s.isSelect==true){
          this.props.item[i].isSelect = false;
          this.props.item[i].selectedClass = styles.notSelectedList;
        }
      });
      
      this.props.dailogSelected.map( (s, i) =>  {
        let index = this.props.item.findIndex(
          item => s === item.id
        );
        this.props.item[index].isSelect = true;
        this.props.item[index].selectedClass = styles.selectedList;
      });

      this.setState({dataSource: this.props.item});
      
    }

    selectItem = (data) => {
      /*let index1 = this.state.dataSource.findIndex(
        item => true === item.isSelect
      );
      console.log(index1);
      */
      if(!this.props.multiple){
        this.state.dataSource.map( (s, i) =>  {
          if(s.isSelect==true){
            this.state.dataSource[i].isSelect = false;
            this.state.dataSource[i].selectedClass = styles.notSelectedList;
          }
        });
      }
      
      data.item.isSelect = !data.item.isSelect;
      data.item.selectedClass = data.item.isSelect ? styles.selectedList : styles.notSelectedList;
      
      const index = this.state.dataSource.findIndex(
        item => data.item.id === item.id
      );

      this.state.dataSource[index] = data.item;
      this.setState({
          dataSource: this.state.dataSource,
      });
      this.props.setDataSource(this.state.dataSource);
    };

    renderItem(data){
      //let checkOn = require('../assets/images/icon/check-on.png');
      //let checkOff = require('../assets/images/icon/check-off.png');
      return (
        <View style={data.item.selectedClass}>
          <View style={[styles.sectionInside,data.item.selectedClass]}>
            <TouchableOpacity onPress={()=>{this.selectItem(data)}} style={{flexDirection:'row', width:'100%'}}>
                <View style={[styles.formLabel,{marginLeft:16}]}>
                  <Text style={styles.label}>{data.item.value}</Text>
                </View>
                <View style={[styles.formSelected,{marginRight:20}]}>
                {data.item.isSelect &&
                  //<Image style={styles.checkIcon} source={checkOn}/>
                  <FontAwesome name="check" size={24} color="#999999" />
                }       
                </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

  render() {
    return (
      <FlatList
        data={this.state.dataSource}
        renderItem={item => this.renderItem(item)}
        keyExtractor={item => item.id}
        extraData={this.state}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    marginTop: 20,
    flex:1,
    width:'100%',
    marginTop: 0,
  },
  section:{
    marginTop: 20,
  },
  sectionInside:{
    backgroundColor:'#fff',
    borderBottomWidth:.55,
    borderBottomColor:'#e6e6e6',
    flexDirection:'row', 
    alignSelf:'stretch',
    minHeight:44,
    //marginLeft:16,
    //marginRight:20,
  },
  formLabel:{
    flex: 1,  
    alignSelf:'stretch', 
    alignItems:'flex-start'
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca'
  },
  label:{
    fontSize: 14,
    lineHeight: 19,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    color:'#484848'
  },
  checkIcon:{
    width:20,
    height:20
  },
  selectedList:{
    backgroundColor:'#eef1f2'
  },
  notSelectedList:{
    backgroundColor:'#fff'
  }
});
