import * as React from 'react';
import { Text, View, StyleSheet, Image, TouchableOpacity}  from 'react-native';

const IineHeaderRight = (props)=> {
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.containerHeaderIcon}>
      <Image style={styles.headerIcon} source={require('../../assets/images/icon/icon-list.png')}/>
    </TouchableOpacity>
  );
}
export default IineHeaderRight;

const styles = StyleSheet.create({
    containerHeaderIcon:{
      marginRight:10, 
      justifyContent:'flex-end', 
      alignContent:'flex-end', 
      alignSelf:'flex-end',
      justifyContent:'center',
      height:40
    },
    headerIcon:{
      width:26, 
      height:26,
    }
});