import CommonFunction from "../utils/CommonFunction";

const stageUrl = 'https://www-stage.pappy.jp';
const productionUrl = 'https://www.pappy.jp';

const stageConfig = {
  mainUrl: stageUrl,
  pappy_api_url: '//api-stage.pappy.jp/Web',
  pappy_list_url: stageUrl+'/List/Login',
  pappy_image_url: 'https://image-stage.pappy.jp',
  contact: {
    url: stageUrl+'/contact/',
    crypto_key: 'sBfd01er5',
  },
  noActivityTime:120
};

const productionConfig = {
  mainUrl: productionUrl,
  pappy_api_url: '//api.pappy.jp/Web',
  pappy_list_url: productionUrl+'/List/Login',
  pappy_image_url: 'https://image.pappy.jp',
  contact: {
    url: productionUrl+'/contact/',
    crypto_key: 'sBfd01er5',
  },
  noActivityTime:3600
};

let apiConfig = stageConfig;

if(!CommonFunction.checkWebStage()){
  apiConfig = productionConfig;
}

export default apiConfig;
