import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import ApiConfig from '../../constants/ApiConfig';

/**
 * API: 03_API仕様\レスポンス仕様書\10_いいね\いいね機能_レスポンス仕様.xlsx
 * Design:
 * Document:
 */
export default class Iine10Api extends ApiPappy{
  //1.1.1. いいね一覧取得
  static async getList(values){
    if(values.count==undefined) values.count = 15;
    if(values.page==undefined) values.page = 1;
    if(values.type==undefined) values.type = 0;

    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Get/List/",
        {count: values.count, page: values.page, type: values.type},
        true
      );
      if(__DEV__) console.log('/Good/Get/List/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.GoodGetList));
            console.log('/Good/Get/List/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.スキップ一覧取得
  static async getSkipList(values){
    try{
      if(values.count==undefined) values.count = 15;
      if(values.page==undefined) values.page = 1;

      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Get/SkipList/",
        {count: values.count, page: values.page},
        true
      );
      if(__DEV__) console.log('/Good/Get/SkipList/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.GoodGetList));
            console.log('/Good/Get/SkipList/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.いいね送信
  static async sendGood(props, showError=0, modal=0){
    try{
      if(props.messageType==undefined) props.messageType = 1;
      let parameter = {
        targetMemberId: props?.targetMemberId,
        messageType: props?.messageType,
      }
      if(props?.code!=undefined) parameter.code = props.code;
      if(props?.forceSubtract!=undefined) parameter.forceSubtract = 1;

      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Post/SendGood/",
        parameter,
        true
      );
      if(__DEV__) console.log('/Good/Post/SendGood/',response);
      
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.GoodPostSendGood));
            console.log('/Good/Post/SendGood/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              if(modal){
                CommonFunction.checkErrorCode(data, true, true, '');
              }else{
                CommonFunction.checkErrorCode(data, true, false, '');
              }
              //CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }//else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.2. スキップ登録
  static async postSkip(props,showError=0){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Post/Skip/",
        {targetMemberId: props.targetMemberId},
        true
      );
      if(__DEV__) console.log('/Good/Post/Skip/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Good/Post/Skip/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }//else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1. スキップ解除
  static async deleteSkip(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Delete/Skip/",
        {targetMemberId: values.targetMemberId},
        true
      );
      if(__DEV__) console.log('/Good/Delete/Skip/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Good/Delete/Skip/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }//else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.3.無料いいね付与
  static async postServicePoint(showError=0){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Good/Post/ServicePoint/",
        {},
        true
      );
      if(__DEV__) console.log('/Good/Post/ServicePoint/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Good/Post/ServicePoint/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}