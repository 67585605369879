import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
//import { AntDesign } from '@expo/vector-icons';

export default function NoItemsBox(props) {
  useEffect(() => {
    if(props.function!=undefined){
      props.function();
    }
  },[]);

 /*
  return (
  <View style={styles.container}>
    <View style={styles.msgBox}>
      <AntDesign name="infocirlceo" size={17} color="black" style={styles.msgIc} />
      <Text style={styles.msgTxt}>{props.msg}</Text>
    </View>
  </View>
 );
 */

  if(props.msg || props.msg_line2){
    return (
    <View style={styles.container}>
      <View style={styles.msgBox}>
        <Text style={styles.msgTxt}>{props.msg}</Text>
        <Text style={styles.msgTxt}>{props.msg_line2}</Text>
      </View>
    </View>
    );
  }

  return <></>;
}

const styles = StyleSheet.create({
  container: {
    margin:15, 
    height:130, 
    //borderColor:'#ededed', 
    //borderWidth:1
  },
  msgBox: {
    flex:1, 
    alignItems:'center', 
    justifyContent:'center', 
    alignContent:'center', 
    width:'100%'
  },
  msgIc:{
    marginBottom:7
  },
  msgTxt:{
    textAlign:'center', 
    fontSize:12, 
    //fontWeight:'bold', 
    lineHeight:18, 
    //color:'#484848'
    color:'#9aa5a7'
  }
});