import React,{ Component, Fragment } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform 
} from 'react-native';
import { UploadImageInput } from '../../components/form/FormInput';
import { Formik } from 'formik';
import Validation from '../../constants/Validation';
import CommonFunction from '../../utils/CommonFunction';
import MyPage13Api from '../../services/api/MyPage13Api';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import { AppContext } from '../../AppContext';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import ListStyles from '../../styles/ListStyles';
import WebUtil from '../../utils/WebUtil';

const { resizeImage } = WebUtil;

export default class UploadIdentificationScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    
    this.initialValues = {
      image: ''
    };
    this.state = {
      formData: []
    };
    this.popup = React.createRef();
    this.loadingPopup = React.createRef();
    this.actionEnabled = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props.navigation;
      this.setHeader();
      this.actionEnabled.current = false;
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, '年齢確認');
  }

  setProfileImageRef(ref){
    this.ProfileImageRef = ref;
  }

  handleSubmit = async (values) => {
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    try{
      this.loadingPopup.showPopup();

      const stateValue = values['image'];
      const [, type] = stateValue.split(';')[0].split('/');
      const resp = await fetch(stateValue);
      const fblob = await resp.blob();
      // const postFile = new File([fblob], `profile.${type}`);
      const maxWidth = 1280;
      const config = {
        file: fblob,
        maxWidth: maxWidth
      };

      let postFile;
      if(Platform.OS==='web'){
        postFile = await resizeImage(config)
        if(__DEV__) console.log('resize file size',postFile.size);
      }else{
        const resizeStateValue = await CommonFunction.resizeImageApp(stateValue, maxWidth);
        if(resizeStateValue){
          const type = resizeStateValue?.type;
          const mainPath = resizeStateValue?.mainPath;
          postFile = {
            uri: mainPath,
            type: fblob.type,
            name: `profile.${type}`
          };
        }
      }

      if(postFile){
        // call Api
        let responseCertifyPostRequest = await MyPage13Api.CertifyPostRequest(postFile);
        
        //check login
        if(!CommonFunction.checkResponseStatus(responseCertifyPostRequest)) {
          this.loadingPopup.hidePopup();
          return;
        }

        if(responseCertifyPostRequest!=undefined && responseCertifyPostRequest?.status==1){
          this.loadingPopup.hidePopup();
          this.popup?.showPopup();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.loadingPopup.hidePopup();
    }
  }

  gotoMypage = ()=>{
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 3];
      if(prevRoute?.name=='RecommendIineStack'){
        this.navigation?.pop(2);
        return;
      }
    }
    this.props.navigation?.navigate('Home', {screen:'MyPageStack', params: {screen: 'MyPage'}});
  }

  render() {
    return (
      <View style={ListStyles.container}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            <Formik
              initialValues={this.initialValues}
              validationSchema={Validation.UploadIdentificationSchema}
              onSubmit={values => this.handleSubmit(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
            <View style={[styles.labelView, {marginTop:0, marginBottom:15}]}>
              {errors.image ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c'}]}>※{errors.image}</Text>) : null}
            </View>  
            <View style={{}}>
              <UploadImageInput fieldName="image" image={this.state.formData.image} setRef={this.setProfileImageRef.bind(this)} type="identification" />
            </View>
            <View style={styles.btnArea}>
              <TouchableOpacity disabled={!(dirty&&isValid)} onPress={handleSubmit} style={[styles.btnBlue, {opacity:(dirty&&isValid) ?1:0.4}]}>
                <Text style={styles.txtBtnBlue}>年齢確認写真を提出する</Text>
              </TouchableOpacity>
            </View>
            </Fragment>
            )}  
            </Formik>
          </View>
        </ScrollView>

        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.popup?.hidePopup();
              this.gotoMypage();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848', textAlign:'center'}}>審査完了までしばらくお待ちください</Text>
            </View>
            <TouchableOpacity onPress={()=>{
              this.popup?.hidePopup();
              this.gotoMypage();
              }} style={{padding:15}}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7'}}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        
        <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
        </Popup>

    </View>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:0
  },
  btnArea: {
    paddingTop:60,
    paddingBottom:30,
    paddingRight:15,
    paddingLeft:15,
  },
  btnBlue: {
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    textAlign:'center',
     alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
});
