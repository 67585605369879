import React, { Component } from 'react';
import { 
  View, Text, TouchableOpacity, StyleSheet, Image, ImageBackground, Dimensions 
} from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';

const windowWidth = Dimensions.get('window').width;

export default class BonusLogin extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.continueCount = 1;
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      if(this.props?.continueCount!=undefined){
        this.continueCount = parseInt(this.props.continueCount);
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (continueCount)=>{
    if(continueCount!=undefined) {
      this.continueCount = parseInt(continueCount);
    }
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
    this.continueCount = 1;
    if(this.props.onClose!=undefined){
      this.props.onClose();
    }
  }

  isShow = ()=>{
    return this.state.show;
  }

  getPointImg = ()=>{
    let image;
    switch(this.continueCount){
      case 3:
        image = require('../../assets/images/bonus-login/day-3.png');
      break;
      case 2:
        image = require('../../assets/images/bonus-login/day-2.png');
      break;
      case 1:
      default:
        image = require('../../assets/images/bonus-login/day-1.png');
      break;
    }
    return image;
  }

  getPoint = ()=>{
    let point;
    switch(this.continueCount){
      case 3:
        point = 3;
      break;
      case 2:
      case 1:
      default:
        point = 1;
      break;
    }
    return point;
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal>
        <View style={[PortalStyle.portalContainer, styles.container]}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
          <View style={{padding:0, width:'80%', backgroundColor:'#ffffff', borderRadius:8, shadowColor: "#000", shadowOffset: { width: 0, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5}}>
            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop: 15,}}>
              <Image source={require('../../assets/images/logo-txt.png')} style={{width:windowWidth<375? 69:80,height:windowWidth<375? 26:30,}} resizeMode="contain"/>  
            </View>
            <View style={{paddingLeft:18, paddingRight:18, paddingBottom:15, paddingTop: 10,}}>
              <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', height:32, backgroundColor:'#ffd200', borderRadius:999, lineHeight:32}}> 
                <Text style={styles.font16}>連続ログインボーナス<Text style={styles.font21}>{this.continueCount}</Text>日目</Text>
              </View>
            </View>
            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%',}}>
              <Image source={require('../../assets/images/bonus-login/bg-left.png')} style={{marginRight:12, width:20, height:30}} resizeMode="contain"/>
              <Text style={[styles.font16, {color:'#333', lineHeight:30}]}><Text style={styles.font21}>{this.getPoint()}</Text>いいね!をGETしました!</Text>
              <Image source={require('../../assets/images/bonus-login/bg-right.png')} style={{marginLeft:12, width:20, height:30}} resizeMode="contain"/>
            </View>
            <Image source={this.getPointImg()} style={styles.imageDay} resizeMode="contain"/>
            <View style={{paddingLeft:26, paddingRight:26}}>
              <View style={{padding:5, backgroundColor:'#f5f5f5', borderRadius:10}}>
                <Text style={styles.font13}>
                  <Text style={styles.font21}>3</Text>日連続ログインをすると{"\n"}
                  合計<Text style={styles.font21}>5</Text>いいね!をGETできます!!
                </Text>
              </View>  
            </View> 
            <TouchableOpacity onPress={()=>this.hide()}>
              <Text style={styles.txtClose}>閉じる</Text>
            </TouchableOpacity>  
          </View>
        </View>
      </Portal> 
    );
  }
}

const styles = StyleSheet.create({
  container:{
    justifyContent:'center',
    alignItems:'center'
  },
  bgFlag:{
    position:'absolute',
    top:0,
    width:106,
    height:40,
    zIndex:1
  },
  FlagLeft:{
    left:0
  },
  FlagRight:{
    right:0
  },
  font13:{
    fontSize:13,
    fontWeight:'bold',
    color:'#333',
    lineHeight:24,
    textAlign:'center'
  },
  font16:{
    fontSize:16,
    fontWeight:'bold',
    color:'#070600',
    lineHeight:32,
    textAlign:'center'
  },
  font21:{
    fontSize:21,
    lineHeight:32,
  },
  imageDay:{
    marginTop:12,
    marginBottom:16,
    width:'100%',
    height:windowWidth<375? 112:131
  },
  txtClose:{
    fontSize:16,
    fontWeight:'bold',
    color:'#9f9f9f',
    lineHeight:50,
    textAlign:'center',
  }
});