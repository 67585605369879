import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Dimensions, ScrollView, Platform } from 'react-native';
import styles from '../../styles/FormStyles';
import HeaderOptions from '../../constants/HeaderOptions';
import HeadStyle from '../../styles/HeadStyles';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import HideApi from '../../services/api/Hide08Api';
import BlockApi from '../../services/api/Block07Api';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import ProfileApi from '../../services/api/Profile04Api';
import CommonFunction from '../../utils/CommonFunction';
import { AppContext } from '../../AppContext';
import LogoNoMargin from '../../components/common/LogoNoMargin';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;

export default class ChatHideSettingScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    
    this.state = {
      profileInfo: {},
      loading: true,
      isConfirm: false,
      form: {
        hide: true,
        block: false
      }
    }
    this.prevRoute = ['ChatRoom'];
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();

      this.memberId = this.props?.route?.params?.memberId;
      this.recommendIine = this.props?.route?.params?.recommendIine;
      this.getListFromApi(this.memberId);
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  getListFromApi = async(memberId)=>{
    try{
      const getListResponse = await ProfileApi.GetList(memberId);
      //check login
      if(!CommonFunction.checkResponseStatus(getListResponse)) return;

      if(getListResponse!=undefined && getListResponse?.status==1){
        const profileInfo = getListResponse?.response?.profileList;
        if(profileInfo?.count>0 && profileInfo?.list[0]!=undefined){
          this.setState({profileInfo: profileInfo?.list[0]});
        }else{
          this.goBack();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false });
    }
  }
  
  setHeader = () =>{
    this.navigation?.setOptions({
      header:()=>(
        <View style={HeadStyle.customHeader}>
          <View style={{position:'absolute',justifyContent:'center',alignItems:'center',flexDirection:'row',left:0,zIndex:1,elevation:1, height:'100%'}}>
            <TouchableOpacity style={HeadStyle.icBack} onPress={()=>this.navigation?.goBack()}>
              <Entypo name={'chevron-thin-left'} style={[HeadStyle.icBack,{ paddingLeft:0}]} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
            <Text style={[HeadStyle.headerTitlePage,{fontSize:18,fontWeight:'normal',marginLeft:windowWidth<=375?30:0}]}>非表示・ブロックの設定</Text>
          </View>
        </View>
      )
    });
  }

  selectRadio = (fieldName)=>{
    let { form } = this.state;
    
    if(fieldName=='hide'){
      form.hide = true;
      form.block = false;
    }else{
      form.hide = false;
      form.block = true;
    }
    this.setState({form: form});
  }

  goBack = ()=>{
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      let length = routes?.length - 3;
      if(length > 0 && this.prevRoute?.indexOf(routes[length].name)!=-1) {
        length = length - 1;
      }
      if(length <0) length = 0

      this.navigation.navigate(routes[length]);
    }
  }

  submitForm = async()=>{
    const { form } = this.state;
    try{
      CommonFunction.overLay();
      const memberId = this.state.profileInfo?.memberId;
      let response = {};
      if(form.hide==true){
        // hide
        response = await HideApi.PostHide(memberId);
      }else{
        // block
        response = await BlockApi.PostBlock(memberId);
      }

      //check login
      if(!CommonFunction.checkResponseStatus(response)) return;

      const success = response?.status;
      if(success==1){
        const {appState, setAppState} = this.context;
        appState.setChatHideMemberId = this.state.profileInfo?.memberId;
        setAppState(appState);
        
        if(this.recommendIine==1){
          this.props.navigation?.navigate('RecommendIine');
        }else{
          this.goBack();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  handleSubmit = async()=>{
    const { form } = this.state;

    if(form.hide==true){
      // hide
      this.submitForm();
    }else{
      // block
      this.setState({isConfirm: true});
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;
    
    let profileImg = this.state.profileInfo?.mainImage?.url;
    if(!profileImg){
      profileImg = this.state.profileInfo?.mainImage?.noImageUrl;
    }

    return (
      <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
      <ScrollView style={ListStyles.scrollContainer}>
        <View style={[styles.BodyContent, {padding:0}]}>
          <View style={{flexDirection: 'row', justifyContent:'flex-start', alignContent:'center', alignItems:'flex-start', alignSelf:'flex-start', padding: 10, width: '100%', backgroundColor: '#f2fbfc'}}>
            <View style={{marginRight: 10, }}>
              <Image style={{width: 48, height: 48, borderRadius: 5}} source={{uri:profileImg}}/>
            </View>
            <View style={{}}>
              <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:'#333333'}}>{this.state.profileInfo.name}{"\n"}
               {this.state.profileInfo.age} {this.state.profileInfo.currentPref}</Text>
            </View>
          </View>
          <View style={{paddingTop: 30, paddingBottom: 30, paddingLeft: 25, paddingRight: 25}}>
            <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 24, color:'#333333', textAlign: 'center'}}>
            お相手を非表示またはブロックの設定ができます。{"\n"}
            設定したい内容を下記から選択してください。
            </Text>
          </View>
          <View style={{padding: 5, width:'100%',}}>
            <View style={selfStyles.listRadio}>
              <TouchableOpacity style={selfStyles.radioContainer} onPress={()=>{
                this.selectRadio('hide');
              }}>
                <View style={{marginLeft: 5, marginRight:10}}>
                {this.state.form.hide?
                  <FontAwesome name="check-circle" size={24} color="#5cc8ca" />
                  :
                  <FontAwesome name="circle-o" size={24} color="#cccccc" />
                }
                </View>
                <View style={{flexDirection:'column', paddingTop: 5, paddingBottom: 5,}}>
                  <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:this.state.form.hide?'#34bddc':'#333333'}}>非表示にする</Text>
                  <Text style={{fontSize: 12, fontWeight:'normal', lineHeight:18, color:'#333333'}}>
                    自分の画面にお相手を表示しないようにします{"\n"}各種設定から解除することができます
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={selfStyles.listRadio}>
              <TouchableOpacity style={selfStyles.radioContainer} onPress={()=>{
                this.selectRadio('block');
              }}>
                <View style={{marginLeft: 5, marginRight:10}}>
                {this.state.form.block?
                  <FontAwesome name="check-circle" size={24} color="#5cc8ca" />
                  :
                  <FontAwesome name="circle-o" size={24} color="#cccccc" />
                }
                </View>
                <View style={{flexDirection: 'column', paddingTop: 5, paddingBottom: 10}}>
                  <Text style={{fontSize: 14, fontWeight:'bold', lineHeight: 20, color:this.state.form.block?'#34bddc':'#333333'}}>ブロックする</Text>
                  <Text style={{fontSize: 12, fontWeight:'normal', lineHeight:18, color:'#333333'}}>自分とお相手の画面にお互いを表示しないようにします</Text>
                  <Text style={{fontSize: 12, fontWeight:'normal', lineHeight:18, color:'#f04a54'}}>※一度、ブロックすると解除できません</Text>
                </View>
              </TouchableOpacity>
            </View>     
          </View>
          <View style={[styles.buttonPanelSetting, {marginTop: 70}]}>
            <TouchableOpacity 
              style={[styles.submitBtn,{opacity:1, width:'80%'}]} 
              onPress={()=>{
                this.handleSubmit();
              }}
            >
            <Text style={styles.loginText}>設定する</Text>
          </TouchableOpacity>
        </View>
      </View>

      {this.state.isConfirm &&
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} onStartShouldSetResponder={()=>{}} />
            <View style={PortalStyle.blocPopup}>
              <View style={PortalStyle.blocPopupInner}>
                <View style={styles.popupInside}>
                  <Text style={{textAlign:'center', color:'#484848'}}>本当にブロックしますか？{"\n"}一度ブロックすると解除できません。</Text>
                  <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <View style={{flex:1, padding:5}}>
                      <TouchableOpacity style={[selfStyles.modalButton,{backgroundColor:'#5cc8ca'}]} onPress={()=>{
                        this.submitForm();
                        this.setState({isConfirm: false});
                      }}>
                        <Text style={[selfStyles.modalButtonText,,{ color:'#ffffff'}]}>はい</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{flex:1, padding:5}}>
                      <TouchableOpacity style={selfStyles.modalButton} onPress={()=>{ this.setState({isConfirm: false}) }}>
                        <Text style={selfStyles.modalButtonText}>いいえ</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Portal>
      }
      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const selfStyles = StyleSheet.create({
  listRadio:{
    marginBottom: 10, 
    width: '100%',
    maxHeight:90,    
  },
  radioContainer:{
    flexDirection:'row',
    alignItems: 'center',
    paddingTop: 10, 
    paddingBottom:10, 
    paddingLeft:5, 
    paddingRight:5,
    borderRadius: 15, 
    shadowColor: "#9ea5a7", 
      shadowOffset:{ 
      width: 0, 
      height: 0,
    }, 
    shadowOpacity: 0.80, 
    elevation: 10,
    ...Platform.select({
      ios:{
        justifyContent:'flex-start', 
        backgroundColor:'#fff',
        shadowRadius: 2, 
      },
      android:{
        justifyContent:'flex-start', 
        backgroundColor:'#fff',
        shadowRadius: 2, 
      },
      default:{
        width: '100%', 
        alignContent: 'center', 
        alignSelf: 'center', 
        marginBottom: 10, 
        shadowRadius: 4, 
      }
    })
  },
  modalButton: {
    marginTop:10, 
    width:'100%', 
    height:50, 
    backgroundColor:'#eeeeee', 
    borderRadius:8, 
    textAlign:'center'
  },
  modalButtonText: {
    fontSize:14, 
    lineHeight:50, 
    color:'#aaaaaa', 
    textAlign:'center',
    fontWeight:'bold'
  },
});