import IineApi from "../services/api/Iine10Api";
import Constants from "../constants/Constants";
import PopupWrapper from "../components/common/PopupWrapper";

export default class Iine {
  static async sendGood(props){
    try{
      if(props.messageType==undefined) props.messageType = 1;
    
      let params = {targetMemberId: props.targetMemberId, messageType: props.messageType}
      let apiResponse = await IineApi.sendGood(params);
      let success = apiResponse?.status;
      if(success==1){
        PopupWrapper.showMessage(Constants.MsgIine);
      }
      return success;
    }catch(e){
      if(__DEV__) console.log(e);
    }

    return 0;
  }

  static async skip(props){
    try{
      let params = {targetMemberId: props.targetMemberId}
      let apiResponse = await IineApi.postSkip(params);
      let success = apiResponse?.status;
      return success;
    }catch(e){
      if(__DEV__) console.log(e);
    }

    return 0;
  }

  static async deleteSkip(props){
    try{
      let params = {targetMemberId: props.targetMemberId}
      let apiResponse = await IineApi.deleteSkip(params);
      let success = apiResponse?.status;
      return success;
    }catch(e){
      if(__DEV__) console.log(e);
    }

    return 0;
  }
}