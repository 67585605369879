import Storage from '../utils/StorageHelper2';
import { getProductionData } from '../modules/purchase';
import { getProductDetail } from '../modules/purchase';
import { pointPurchase } from '../modules/purchase';
import { purchaseModuleInit } from '../modules/purchase';
import CommonFunction from '../utils/CommonFunction';
import Payment15Api_ios from '../services/api/Payment15Api_ios';
import ErrorCode from '../constants/ErrorCode';
import { getSubscProductionData } from '../modules/purchase';
import { subscPurchase } from '../modules/purchase';
import { restoreTransaction } from '../modules/purchase';

// 初期化：トランザクション処理用オブザーバー（ログイン状態で実施）
function purchaseInit() {
  purchaseModuleInit();
}

// 初期化：ポイント購入用のプロダクトIDを取得する（ポイント購入画面遷移時に実施）
async function checkPointProductIds() {
  purchaseModuleInit();
  // 購入可能なプロダクトIDをAppleに問い合わせ(プロダクトIDはネイティブ側で管理)
  getProductionData();
}

// 課金アイテムの詳細定義を取得（バナー画像のURL）
function getPointProductDetail(productId) {
  var getProductDetailData = getProductDetail(productId);
  return getProductDetailData;
}

// 【ポイント】購入処理を実施する（ポイント購入ボタンタップ時）
async function pointPurchaseAction(productId) {
  // 購入処理を実施する
  pointPurchase(productId);
}

// 起動時に保存されたトランザクションIDがないかチェック
async function checkStaPaymentPurchasePoint() {
  let getTransaction = await this.getPointTransaction();
  if (getTransaction!=undefined) {
    await this.staPaymentPurchasePointIos();    
  }
}

// 起動時に保存されたトランザクションIDがないかチェック
async function staPaymentPurchasePointIos() {
  try{
    let saveTransactionId = await this.getPointTransaction();
    const params = {transactionId: saveTransactionId, errorFlg: 1};
    const response = await Payment15Api_ios.PurchasePointAppStore(params);
    if(!CommonFunction.checkResponseStatus(response)) return;
    if(response?.status==1){
      // ポイント購入成功、トランザクションIDを消去する
      this.deletePointTransaction();
    } else {
      const errorCode = `${response?.response?.errorGroup}-${response?.response?.errorCode}`;
      if(errorCode==ErrorCode.PurchasePointAppStore.Duplication){
        this.deletePointTransaction();
      }
    }
  }catch(e){
    if(__DEV__) console.log(e);
  }finally{
  }
}

// 初期化：サブスク用のプロダクトIDを取得する（有料会員登録画面遷移時に実施）
async function checkSubscProductIds(envFlg) {
  purchaseModuleInit();
  // 購入可能なプロダクトIDをAppleに問い合わせ(プロダクトIDはネイティブ側で管理)
  getSubscProductionData(envFlg);
}

// 【サブスク】購入処理を実施する（サブスク購入ボタンタップ時）
async function subscPurchaseAction(productId) {
  // 購入処理を実施する
  subscPurchase(productId);
}

// 【サブスク】購入処理を実施する（サブスク購入ボタンタップ時）
async function restoreTransactionAction() {
  // 購入処理を実施する
  restoreTransaction();
}

// 【ポイント】ストアの購入処理が完了したtransactionIdをストレージに保存する
async function savePointTransaction( transactionId ) {
  Storage.storeData('PointTransaction', transactionId);
}

// 【ポイント】ストレージに保存中の処理中のtransactionIdを取得する
async function getPointTransaction() {
  const transactionId = await Storage.getData('PointTransaction');
  console.log("getPointTransaction:",transactionId);
  return transactionId;
}

// 【ポイント】ストレージに保存中のtransactionIdを消去する
function deletePointTransaction() { 
  const removeKeys = ['PointTransaction'];
  Storage.removeItems(removeKeys);
}

export default {
  purchaseInit,
  checkPointProductIds,
  getPointProductDetail,
  pointPurchaseAction,
  checkStaPaymentPurchasePoint,
  staPaymentPurchasePointIos,
  checkSubscProductIds,
  subscPurchaseAction,
  restoreTransactionAction,
  savePointTransaction,
  getPointTransaction,
  deletePointTransaction
}
