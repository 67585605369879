
const Constants = {
  CommonResponseField: ['status','accessToken','verified','activated','response','ikuyoStatus'],
  ProfileGetCheck: ['memberId','name','introduction','tweet','profileImage','income','assets','height','vibe','style','personality','currentPref','birthCity','dateHope','meetHope','education','occupation','alcohol','cigarette','holiday','sex'],
  DefaultProfileGetCheck: {
    memberId: null,
    name: '',
    introduction: { body: '', certify: 0},
    tweet: { body: '', certify: 0},
    profileImage: { count: 0, noImageUrl: '', list:[]},
    income: { id: '', label: ''},
    assets: { id: '', label: ''},
    height: 0,
    vibe: { id: '', label: ''},
    style: { id: '', label: ''},
    personality: { id: '', label: ''},
    currentPref: { id: '', label: ''},
    birthCity: { id: '', label: ''},
    dateHope: { id: '', label: ''},
    meetHope: { id: '', label: ''},
    education: { id: '', label: ''},
    occupation: { id: '', label: ''},
    alcohol: { id: '', label: ''},
    cigarette: { id: '', label: ''},
    holiday: { id: '', label: ''},
    sex: null
  },
  ProfileGetList: ['memberId','verified','name','sex','age','introduction','tweet','receiveMessageFlg','sendMessageFlg','matchFlg','goodFlg','goodCount','online','mainImage','subImage','currentPref','birthCity','income','height','vibe','personality','style','dateHope','meetHope','registReason','education','occupation','holiday','alcohol','cigarette'],
  ProfileGetSuggest: ['profileList','count','list','memberId','verified','name','sex','age','introduction','tweet','online','currentPref','mainImageUrl','imageCount'],
  SearchProfileGetList: ['memberId','verified','name','sex','age','introduction','tweet','goodFlg','goodCount','online','mainImageUrl','imageCount','currentPref','birthCity','income','height','vibe','personality','style','dateHope','meetHope','registReason','education','occupation','holiday','alcohol','cigarette','futureDream','newFlag'],
  LoginPutLoginByFirstLoginKey:['status','accessToken','response'],
  EntryPostEntryMail:['firstLoginKey'],
  EntryPutVerificationCode:['result'],
  EntryPostSendVerificationCodeMail:['token'],
  EntryGetEntryCheckMail:['status','response'],
  ActivateGetCheckTel:['status','response'],
  ActivatePostSendVerificationCodeSMS:['status','response','token'],
  ActivatePutVerificationCode:['status','response'],
  GoodGetList:['memberId','newFlag','mainImageUrl','imageCount','leaveFlag','online','age','currentPref','name','introduction','tweet','message','messageDaysAgo'],
  GoodPostSendGood:['status','accessToken','response'],
  EntryGetListNotMember:['memberId','mainImageUrl','age','currentPref','sex','introduction','tweet'],
  ProfileGetBadge:['footmark','good','message','notification', 'contact'],
  MailBoxGetMailBoxList:['mailbox','nextVal','type','count','unreadMatchCount','unreadReplyCount','subscription','status','list','messageBoxId','favorite','memberId','mainImageUrl','leaveImageUrl','replyStat','receiveDate','nickName','sex','age','currentPref','online','msgText','messageStatus','unreadCount'],
  MailBoxGetMailBoxDetail:['profile','memberId','verified','nickName','sex','mainImageUrl','count','nextVal','list','messageBoxId','favorite','messageId','messageOwner','messageType','dateTime','alreadyType','message','stamp','imageLimitFlag','attacheImageUrl'],
  HideGetList:['hide','nextVal','list','memberId','name','age','introduction','tweet','mainImageUrl','currentPref'],
  BlockGetList:['block','nextVal','list','memberId','name','age','introduction','tweet','mainImageUrl','currentPref'],
  CalendarGetList:['aeru','nextVal','searchMonth','searchDate','searchPref','list','memberId','aeruId','mainImageUrl','imageCount','online','age','currentPref','introduction','tweet'],
  CalendarGetTargetList:['aeru','list','aeruId','year','month','day','wDay','aitaiFlag','pref'],
  FootmarkGetList:['footmark','nextVal','list','footmarkDateTime','label','unix','memberId','newFlag','name','age','introduction','tweet','matchFlg','goodFlg','online','mainImageUrl','imageCount','currentPref','occupation'],
  SettingGetNoticeReceive:['footmarkReceive','goodReceive','matchingReceive','messageReceive','noticeReceive'],
  SettingGetMail:['mail'],
  NotificationGetNotification:['notification'],
  NotificationGetNotificationDetail:['notificationDetail'],
  ProfileGetMyProfile:['myPageItems'],
  PasswordPostSendVerificationCode:['token'],
  CheckMemberStat:['subscription','purchasePlatform','status','expire','sendid','cardInfoModify','overview','amountDetail','renewalPeriod','telecom','changeCardInfoUrl','clientip','sex','point','addDate','addPoint'],
  SettingGetPrivate:['flag'],
  SettingGetSecret:['flag'],
  SubscriptionOrderUrlTelecom:['clientip','sendid','orderUrl','redirectUrl','list','rebillParamId','money'],
  OrderUrlTelecom:['clientip','sendid','orderUrl','redirectUrl','list','money','point'],
  PaymentGetOrder:['telecom','clientip','sendid','orderUrl','redirectUrl','list','money','point','paidy','productId'],
  LeaveGetLeaveCheck:['checkResult','point','subscription'],
  GetContactCategoryList:['categoryList'],
  SuggestGetList:['memberId','mainImageUrl','age','currentPref','sex','introduction','tweet'],
  MailBoxPutFavorite:['status','response'],
  FavoriteNameList:['status','respons','favorite','list','id','name'],
  FavoriteName:['status','respons'],
  AuthCreditCardUrlTelecom:['clientip','sendid','orderUrl','redirectUrl','list','productId','money'],
  SubscriptionUrlTelecom:['clientip','orderType','sendid','orderUrl','redirectUrl','list','productId','money'],
  SubscriptionUrl:['telecom','paidy','clientip','orderType','sendid','orderUrl','redirectUrl','list','productId','money']
};

export default Constants;
