import React,{ Component, Fragment } from 'react';
import { 
  Image, StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, Dimensions
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { Formik } from 'formik';
import RightSlidePopup from '../components/common/RightSlidePopup';
//import OptionData from '../constants/option/OptionDataProfile.json';
import OptionData from '../constants/option/OptionData.json';
import UserSettingComponent  from '../components/common/UserSetting';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import Constants from '../constants/Constants';
import UserSetting from '../components/common/UserSetting';
import PopupWrapper from '../components/common/PopupWrapper';
import UserUtil from '../utils/UserUtil';
import Msg from '../constants/option/Msg.json';
import { RefreshControl } from 'react-native-web-refresh-control';
import ProfileSettingApi from '../services/api/ProfileSetting12Api';
import PortalStyle from '../styles/PortalStyles';
import Popup from '../components/common/Popup';
import ListStyles from '../styles/ListStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;
const maxThumbnailWidth = 120;
let thumbnailWidth = Math.floor(windowWidth/4);
if(thumbnailWidth > maxThumbnailWidth) thumbnailWidth = maxThumbnailWidth;

const isWeb = Platform.OS === 'web';

export default class UserSettingScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      thumbnail: null,
      certify: 0,
      certify_tweet: 0,
      certify_introduction: 0,
      loading: true,
      isRefreshing: false,
      sex: 0,
      checkUserProfile: {},
      showWarning: false,
      alreadyCallApi: false
    };
    this.Area = {};
    this.inputRef = {
      name: React.createRef(),
      introduction: React.createRef(),
      tweet: React.createRef(),
    }

    // reset check access
    ProfileSettingApi.resetCheckAccessFlg();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      // remove Area id 0
      this.Area = OptionData.Master?.Area?.Items?.filter(function(item){
        return item.id > 0;
      });

      // focus screen
      this._unsubscribe = this.navigation?.addListener('focus', () => {
        this.getDataFromApi();
        CommonFunction.setMainBottomTab(this.props?.BottomTabNavigation, "none"); // hide main bottom tab
      });
    }
  }

  getDataFromApi = async ()=>{
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }

    try{
      // call Api
      const userProfileResponse = await ProfileSettingApi.ProfileGetCheck(this.context);
      
      //check login
      if(!CommonFunction.checkResponseStatus(userProfileResponse)) return;

      if(userProfileResponse!=undefined && userProfileResponse?.status==1){
        let userProfile = userProfileResponse?.userProfile;
        let certify_tweet = userProfile.tweet.certify;
        let certify_introduction = userProfile.introduction.certify;
        let sex = userProfile.sex;

        // convert response from api to values in form
        if(Object.keys(userProfile).length > 0){
          userProfile = UserUtil.convertUserProfile(userProfile);

          let mainProfileImage = {};
          let {thumbnail, certify} = this.state;
          if(userProfile?.profileImage?.count > 0){
            mainProfileImage = CommonFunction.getMainImage(userProfile?.profileImage?.list);
            thumbnail = mainProfileImage?.url;
            certify = mainProfileImage?.certify;
          }else{
            thumbnail = userProfile?.profileImage?.noImageUrlBig;
          }

          userProfile.mainProfileImage = mainProfileImage;
          UserUtil.setAppStateUserProfile(this.context, userProfile);
          this.setState({
            userProfile: userProfile, 
            checkUserProfile: userProfile,
            thumbnail: thumbnail, 
            certify: certify,
            certify_tweet: certify_tweet,
            certify_introduction: certify_introduction,
            sex: sex,
            loading: false, 
            isRefreshing: false,
            alreadyCallApi: true
          });
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }
  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true},()=>{
      this.getDataFromApi();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe !=undefined){
      this._unsubscribe();
    }
    CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
  }

  setHeader(){
    this.navigation.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={[styles.icClose,{flexDirection:'row'}]}>
            <TouchableOpacity onPress={() => {
              CommonFunction.setMainBottomTab(this.props.BottomTabNavigation, "flex");
              this.navigation.goBack();
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch', justifyContent:'center', flexDirection:'row'}}>
            <Text style={HeadStyle.headerTitlePage} numberOfLines={1}>プロフィールを編集</Text>
          </View>
          <TouchableOpacity style={styles.btnPreview} onPress={()=>{
            let values = this.Formik?.values;
            if(values!=undefined){
              values = this.checkValueUserProfile(values);
              const checkUserProfile = this.checkValueUserProfile(this.state.checkUserProfile);
              if(JSON.stringify(values)!=JSON.stringify(checkUserProfile)){
                this.warningPopup?.showPopup();
                return;
              }
            }
            
            this.navigation.navigate('PreviewUserInfo');
          }}>
            <Text style={{fontSize:12, fontWeight:'bold', color:'#32bedb', textAlign:'center'}}>プレビュー</Text>
          </TouchableOpacity>
        </View>
      )
    });
  }

  checkValueUserProfile = (values)=>{
    let userProfile = {};
    if(Object.keys(values)?.length > 0){
      Object.keys(values)?.map((key,index)=>{
        if(values[key]?.value!=undefined){
          userProfile[key] = values[key]?.value;
        }
      });
    }
    return userProfile;
  }

  handleSubmit = async (values) => {
    try{
      CommonFunction.overLay();
      let postValues = {};
      Object.keys(values)?.map((key,index)=>{
        if(values[key].id!=undefined){
          postValues[key] = values[key].id;
        }else{
          postValues[key] = values[key];
        }
      });
      
      // Multiple select
      postValues.vibe = CommonFunction.makeTabData(postValues.vibe);
      postValues.personality = CommonFunction.makeTabData(postValues.personality);
      postValues.sex = this.state.sex;

      // call Api
      const responseProfilePutProfile =  await ProfileSettingApi.ProfilePutProfile(postValues);
      const success = responseProfilePutProfile?.status;
      if(success==1){
        PopupWrapper.showMessage(Msg.Update.Success);
        this.setState({checkUserProfile: values});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  getRef = (fieldName)=>{
    return this.inputRef[fieldName];
  }

  render() {
    if(this.state.loading) return <ActivityIndicator />

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container, SafeAreaStyles(insets).containerMargin]}>
        <Formik
          initialValues={this.state.userProfile}
          onSubmit={values => this.handleSubmit(values) }
          innerRef={ref=>this.Formik=ref}
          >
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
          <Fragment>
            <ScrollView style={[ListStyles.scrollContainer,styles.scrollContainer]} scrollToOverflowEnabled={true}
            contentContainerStyle={{flexGrow:1}}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={this.onRefresh}
              />
            }>
              <View style={styles.myProfile}>
                <Image style={styles.myProfileImg} source={{uri : this.state.thumbnail}} />
                {this.state.certify==1 && (<UserSetting.UnderReviewImgLayer/>) }
                <LinearGradient
                  colors={['transparent','rgba(0,0,0,0.8)']}
                  style={{position:'absolute',left:0,right:0,bottom:0,width:'100%',height:70,zIndex:10}}
                >
                <View style={styles.editPhoto}>
                  <TouchableOpacity style={styles.btnEditPhoto} onPress={()=>{ 
                    this.navigation.navigate('UserSettingImage');
                  }}>
                    <Text style={styles.txtEditPhoto}>プロフィール写真を編集</Text>
                  </TouchableOpacity>
                </View>
                </LinearGradient>
              </View>
            
              {this.state.userProfile?.profileImage?.count>0 && 
              <View style={{backgroundColor:'#ffffff'}}>
                <View style={styles.thumbList}>
                  <ScrollView horizontal={true} style={{width:'100%'}} contentContainerStyle={{paddingLeft:14,paddingRight:14}}>
                  {this.state.userProfile?.profileImage?.list?.map((item,indexImg) => {
                    return (
                      <TouchableOpacity key={indexImg.toString()} style={styles.separator} onPress={ () => {
                        this.setState({thumbnail:item.url, certify:item.certify});
                      }}>
                        <Image resizeMode={'cover'} style={styles.thumbImg} source={{uri : item.url}} />
                        {item.certify==1 &&
                        <UserSetting.UnderReviewImgLayer borderRadius={4}/>
                        }
                      </TouchableOpacity>
                    )
                  })}
                  </ScrollView>
                </View>
              </View>
              }

              <View style={styles.inside}>
                {this.state.userProfile?.sex==Constants.MALE ?
                isWeb &&(
                <View style={styles.section}>{/** male */}
                  <View style={styles.headerSection}>
                    <Text style={styles.h3}>年収</Text>
                    <View style={styles.upSectionBallon}><Text style={styles.txtUpSectionBallon}>マッチング率UP</Text></View>
                  </View>
                  <RightSlidePopup fieldName={'income'} title={'年収'} items={OptionData.Master.Income.Male.Items} onChange={setFieldValue} values={values.income} single={true} showChild={true} novalue={'未設定'}>
                    <UserSettingComponent.DisplayLineText values={values.income}/>
                  </RightSlidePopup>
                </View>
                )
                :
                <View style={styles.section}>{/** female */}
                  <View style={styles.headerSection}>
                    <Text style={styles.h3}>スタイル</Text>
                    <View style={styles.upSectionBallon}><Text style={styles.txtUpSectionBallon}>マッチング率UP</Text></View>
                  </View>
                  <RightSlidePopup fieldName={'style'} title={'スタイル'} items={OptionData.Master.Style.Items} onChange={setFieldValue} values={values.style} showChild={true} single={true} novalue={'未設定'}>
                    <UserSettingComponent.DisplayLineText values={values.style}/>
                  </RightSlidePopup>
                </View>
                }

                <View style={styles.section}>
                  <View style={styles.headerSection}>
                    <Text style={styles.h3}>今日のひとこと</Text>
                    {this.state.certify_tweet!='1' &&
                    <View style={styles.labelUnderReview}><Text style={styles.txtUnderReview}>審査中</Text></View>
                    }
                    
                    <View style={{position:'absolute',right:10,top:2}}>
                      <RightSlidePopup title={'禁止事項について'} showChild={true} component={<Notice {...this.props} />}>
                      <Text style={styles.notice}>※掲載NG事項</Text>
                      </RightSlidePopup>
                    </View>
                  </View>
                  <RightSlidePopup ref={ref=>this.inputRef.tweet=ref} title={'今日のひとこと'} showChild={true}
                  component={<UserSettingComponent.WordOfDayInput fieldName="tweet" placeholder="未入力です" onChange={setFieldValue} onBlur={setFieldTouched} values={values.tweet} maxInput="30" reference={this.refTweet} getRef={this.getRef} callback={this.onRefresh} />}>
                    <UserSettingComponent.DisplayLineTextRightIcon values={values.tweet} placeholder="未入力です"/>
                  </RightSlidePopup>
                </View>
          
                <View style={styles.section}>
                  <View style={styles.headerSection}>
                    <Text style={styles.h3}>自己紹介</Text>
                    <View style={styles.upSectionBallon}><Text style={styles.txtUpSectionBallon}>マッチング率UP</Text></View>
                    {this.state.certify_introduction!='1' &&
                    <View style={styles.labelUnderReview}><Text style={styles.txtUnderReview}>審査中</Text></View>
                    }
                    <View style={{position:'absolute',right:10,top:2}}>
                      <RightSlidePopup title={'禁止事項について'} showChild={true} component={<Notice {...this.props} />}>
                      <Text style={styles.notice}>※掲載NG事項</Text>
                      </RightSlidePopup>
                    </View>
                  </View>
                  <RightSlidePopup ref={ref=>this.inputRef.introduction=ref} title={'自己紹介'} showChild={true}
                  component={<UserSettingComponent.SelfIntroInput fieldName="introduction" placeholder="未入力です" onChange={setFieldValue} onBlur={setFieldTouched} values={values.introduction} navigation={this.navigation} maxInput="1000" getRef={this.getRef} callback={this.onRefresh} />}>
                    <UserSettingComponent.DisplayLineTextRightIcon values={values.introduction} placeholder="未入力です" />
                  </RightSlidePopup>
                </View>

                <View style={[styles.section,{ backgroundColor:'#fff', marginTop:32}]}>
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>ニックネーム :</Text>
                    </View>
                    <RightSlidePopup ref={ref=>this.inputRef.name=ref} title={'ニックネーム'} showChild={true}
                    component={<UserSettingComponent.NicknameInput fieldName="name" placeholder="ニックネームを入力..." onChange={setFieldValue} onBlur={setFieldTouched} values={values.name} maxInput="10" getRef={this.getRef} />}>
                      <UserSettingComponent.DisplayText values={values.name} icon={'right'} />
                    </RightSlidePopup>
                  </View>
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>身長 :</Text>
                    </View>
                    <RightSlidePopup fieldName={'height'} title={'身長'} items={CommonFunction.listHeight()} onChange={setFieldValue} values={values.height} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.height} checkZero={true} unit={'cm'} />
                    </RightSlidePopup>
                  </View>

                  {/** female */}
                  {this.state.userProfile?.sex==Constants.FEMALE &&
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>雰囲気 :</Text>
                    </View>
                    <RightSlidePopup fieldName={'vibe'} title={'雰囲気'} items={OptionData.Master.Vibe.FeMale.Items} onChange={setFieldValue} values={values.vibe} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.vibe} icon={'right'}/>
                    </RightSlidePopup>
                  </View>
                  }

                  {/** female */}
                  {this.state.userProfile?.sex==Constants.FEMALE &&
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>性格・タイプ :</Text>
                    </View>
                    <RightSlidePopup fieldName={'personality'} title={'性格・タイプ'} items={OptionData.Master.Personality.FeMale.Items} onChange={setFieldValue} values={values.personality} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.personality} icon={'right'}/>
                    </RightSlidePopup>
                  </View>
                  }

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>居住地 :</Text>
                    </View>
                    <RightSlidePopup fieldName={'currentPref'} title={'居住地'} items={this.Area} onChange={setFieldValue} values={values.currentPref} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.currentPref}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <Text style={[styles.label,styles.labelGray]}>出身地 :</Text>
                    </View>
                    <RightSlidePopup fieldName={'birthCity'} title={'出身地'} items={OptionData.Master.Area.Items} onChange={setFieldValue} values={values.birthCity} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.birthCity}/>
                    </RightSlidePopup>
                  </View>
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline',flexWrap:'nowrap'}}>
                      <Text style={[styles.label,styles.labelGray]}>デートでしたいこと :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'dateHope'} title={'デートでしたいこと'} items={OptionData.Master.DateHope.Items} onChange={setFieldValue} values={values.dateHope} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.dateHope}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline',flexWrap:'nowrap'}}>
                      <Text style={[styles.label,styles.labelGray]}>出会うまでの希望 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'meetHope'} title={'出会うまでの希望'} items={OptionData.Master.MeetHope.Items} onChange={setFieldValue} values={values.meetHope} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.meetHope}/>
                    </RightSlidePopup>
                  </View>

                  {/**male */}
                  {this.state.userProfile?.sex==Constants.MALE &&
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>スタイル :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'style'} title={'スタイル'} items={OptionData.Master.Style.Items} onChange={setFieldValue} values={values.style} showChild={true} single={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.style}/>
                    </RightSlidePopup>
                  </View>
                  }

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>学歴 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'education'} title={'学歴'} items={OptionData.Master.Education.Items} onChange={setFieldValue} values={values.education} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.education}/>
                    </RightSlidePopup>
                  </View>

                  {/** male */}
                  {this.state.userProfile?.sex==Constants.MALE &&
                  isWeb &&(
                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>資産 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'assets'} title={'資産'} items={OptionData.Master.Assets.Male.Items} onChange={setFieldValue} values={values.assets} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.assets}/>
                    </RightSlidePopup>
                  </View>
                  )
                  }

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>お仕事 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'occupation'} title={'お仕事'} items={OptionData.Master.Occupation.Items} onChange={setFieldValue} values={values.occupation} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.occupation}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>お酒 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'alcohol'} title={'お酒'} items={OptionData.Master.Alcohol.Items} onChange={setFieldValue} values={values.alcohol} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.alcohol}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>タバコ :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'cigarette'} title={'タバコ'} items={OptionData.Master.Cigarette.Items} onChange={setFieldValue} values={values.cigarette} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.cigarette}/>
                    </RightSlidePopup>
                  </View>

                  <View style={styles.infoList}>
                    <View style={styles.infoLabel}>
                      <View style={{flexDirection:'row',justifyContent:'center',alignItems:'baseline'}}>
                      <Text style={[styles.label,styles.labelGray]}>休日 :</Text>
                      </View>
                    </View>
                    <RightSlidePopup fieldName={'holiday'} title={'休日'} items={OptionData.Master.Holiday.Items} onChange={setFieldValue} values={values.holiday} single={true} showChild={true} novalue={'未設定'}>
                      <UserSettingComponent.DisplayText values={values.holiday}/>
                    </RightSlidePopup>
                  </View>

                </View>
                {/** end section */}
              </View>
              
            </ScrollView>
            <View style={styles.bottomTab}>
              <View style={styles.btnTab}>
                <TouchableOpacity style={styles.btnExsample} onPress={handleSubmit}>
                  <Text style={styles.txtBtnExsample}>プロフィールを更新</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Fragment>
          )}          
        </Formik> 

        <Popup ref={ref=>this.warningPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              setTimeout(() => {
                this.warningPopup?.hidePopup();
              }, 150);
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'85%', backgroundColor:'#fff', borderRadius:12,}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:10, paddingRight:10, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',}}>
              プロフィールが更新されています。{"\n"}
              更新ボタンを押してから確認してください。
              </Text>
            </View>
            <TouchableOpacity style={{padding:15, width:'100%', textAlign:'center'}} onPress={()=>{
              this.warningPopup?.hidePopup();
            }}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7', textAlign:'center'}}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>

      </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

class Notice extends Component{
  render(){
    return (
      <View style={{backgroundColor:'#ffffff',flex:1,paddingTop:10,paddingRight:10,paddingLeft:10,}}>
        <Text style={{fontSize:14}}>
        以下のような項目をプロフィールに記載することは禁止されています。{"\n"}
        記載された場合、審査の際に否認させて頂きますので、ご了承ください。{"\n\n"}

        ×<Text style={styles.colorRed}>アダルトなワード</Text>を含む内容{"\n"}
        ×具体的な金銭や<Text style={styles.colorRed}>公序良俗に反する</Text>可能性のある内容{"\n"}
        ×<Text style={styles.colorRed}>SNSのID</Text>などの特定情報{"\n"}
        ×相手が見て<Text style={styles.colorRed}>不愉快に感じられる</Text>文章{"\n"}
        ×<Text style={styles.colorRed}>他のサービス</Text>や<Text style={styles.colorRed}>ビジネスへの勧誘</Text>、またはそれを想起させる文章{"\n"}
        ×その他<Text style={styles.colorRed}>利用規約に反する</Text>内容など
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  info:{
    position: 'absolute',
    top: 315,
    left: 0,
    width: '100%',
    zIndex: 100,
    paddingBottom:10
  },
  fontWhite:{
    color: '#FFF',
    paddingLeft: 10,
    paddingBottom: 0,
    fontSize: 16
  },
  fontSmall:{
    fontSize: 12,
    paddingBottom: 0,
  },
  linearGradient: {
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 5,
    marginTop:16,
    width:340,
  },
  btnUpdate:{
    position:'absolute',
    right:0,
    marginRight:10,
    paddingTop:3,
    paddingBottom:3,
    paddingLeft:5,
    paddingRight:5,
    borderColor:'#5cc8ca',
    borderWidth:1,
    borderRadius:999,
  },
  txtBtnUpdate:{
    fontSize:12,
    fontWeight:'normal',
    color:'#5cc8ca',
    lineHeight:18,
  },
  scrollContainer: {
    backgroundColor:'#eef1f2',
    ...Platform.select({
      ios: { marginBottom:70 },
      android: { paddingBottom:35 },
      default: { paddingBottom:35 }
    }),
  },
  myProfile:{
    justifyContent:'center',
    alignItems:'stretch',
    width:'100%',
    height:380,
  },
  myProfileImg:{
    height:380
  },
  editPhoto:{
    alignItems:'center',
    position:'absolute',
    bottom:10,
    left:0,
    width:'100%',
    height:40,
    zIndex:11,
  },
  btnEditPhoto:{
    alignItems:'center',
    justifyContent:'center',
    width:'80%',
    height:'100%',
    backgroundColor:'#ffffff',
    borderRadius:999,
  },
  txtEditPhoto:{
    fontSize:14,
    fontWeight:'bold',
    color:'#5cc8ca',
    textAlign:'center',
  },
  inside:{
    ...Platform.select({
      ios: { },
      android: { paddingBottom: 70, },
      default: { }
    }),
  },
  section:{
    justifyContent:'center',
    alignContent:'center',
  },
  headerSection:{
    flexDirection:'row',
    alignItems:'center',
    marginTop:16,
    marginBottom:16,
    paddingLeft:16,    
    backgroundColor:'#eef1f2',
  },
  h3:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
  },
  upSectionBallon:{
    marginLeft:8,
    padding:5,
    backgroundColor:'#ff8fb9',
    borderRadius:4,
  },
  txtUpSectionBallon:{
    fontSize:12,
    fontWeight:'normal',
    lineHeight:16,
    color:'#ffffff',
    textAlign:'center',
  },
  text:{
    margin:8,
    fontSize:14,
    maxWidth:180,
  },  
  infoList:{
    flexDirection:'row', 
    alignSelf:'stretch',
    backgroundColor:'#fff',
    justifyContent:'center',
    flex:1,
  },
  infoLabel:{
    flex:1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',
    justifyContent:'center'
  },
  label:{
    marginTop:11,
    marginRight:8,
    marginBottom:11,
    marginLeft:16,
    fontSize:14,
    fontWeight:'normal',
    lineHeight:22,
    color:'#484848',
  },
  labelGray:{
    fontSize:16,
    color:'#9aa5a7',
  },
  infoText:{
    maxWidth:200,
    alignItems:'flex-end',
    justifyContent:'center'
  },
  txtSelect:{
    marginRight:30,
    fontSize:16,
    fontWeight:'normal',
    lineHeight:22,
    color:'#5cc8ca',
    justifyContent:'center'
  },
  downIcon:{
    padding:10,
    fontSize:12,
    color:'#999',
  },
  rightIcon:{
    padding:10,
    fontSize:16,
    color:'#484848',
  },
  icShowPopup:{
    marginRight:16,
    fontSize:30,
    color:'#484848',
  },
  blocSelfIntro:{
    padding:16,
    width:'100%',
    backgroundColor:'#fff'
  },
  headBlocSelfIntro:{
    fontSize:14,
    fontWeight:'bold',
    color:'#5cc8ca',
  },
  blocSelfIntroArrow:{
    marginLeft:8,
    width:15,
    height:14,
  },
  blocSelfIntroInside:{
    marginTop:-6,
    paddingTop:8,
    paddingBottom:8,
    paddingLeft:12,
    paddingRight:12,
    backgroundColor:'#5cc8ca',
    borderRadius:4,
    color:'#fff',
  },
  txtBlocInsideNormal:{
    fontSize:14,
    fontWeight:'normal',
    color:'#fff',
  },
  txtBlocInsideLarge:{
    fontSize:20,
    fontWeight:'bold',
    lineHeight:30,
    color:'#fff'
  },
  txtDescNote:{
    marginBottom:16,
    paddingLeft:16,
    paddingRight:16,
    fontSize:14,
    fontWeight:'normal',
    lineHeight:19,
  },
  btnExsample:{
    width:'100%',
    padding:8,
    height:45,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    ...Platform.select({
      ios:{
        shadowRadius: 2
      },
      android:{
        shadowRadius: 2
      },
      default:{
        shadowRadius: 1
      }
    }),
    elevation: 1,
  },
  txtBtnExsample:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center'
  },
  tagLabel:{
    margin:4,
    paddingTop:4,
    paddingBottom:4,
    paddingLeft:8,
    paddingRight:8,
    backgroundColor:'#fff',
    borderColor:'#9aa5a7',
    borderWidth:1,
    borderRadius:4,
  },
  tagLabelSelect:{
    margin:4,
    paddingTop:4,
    paddingBottom:4,
    paddingLeft:8,
    paddingRight:8,
    backgroundColor:'#5cc8ca',
    borderColor:'#5cc8ca',
    borderWidth:1,
    borderRadius:4,
  },
  txtTagLabel:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:19,
    color:'#9aa5a7',
  },
  txtTagLabelSelect:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:19,
    color:'#fff',
  },
  infoTextContainer:{
    flexDirection:'row'
  },
  infoTextInner:{
    justifyContent:'center'
  },
  thumbImg:{
    width: thumbnailWidth,
    height: thumbnailWidth,
    borderRadius:4,
  },
  thumbList:{
    marginTop:14,
    marginBottom:14,
    width:'100%',
    height: thumbnailWidth+2,
  },
  separator: {
    marginRight:4,
    marginLeft:4,
  },
  labelUnderReview: {
    marginLeft:8,
    padding:5,
    backgroundColor:'#e9fbff',
    borderColor:'#74d2e5',
    borderWidth:1,
    borderRadius:4,
  },
  txtUnderReview: {
    fontSize:12,
    fontWeight:'bold',
    lineHeight:14,
    color:'#74d2e5',
  },
  bottomTab:{
    ...Platform.select({
      ios: {
        position:'absolute',
      },
      android: {
        position:'absolute',
      },
      default:{
        position:'fixed'
      }
    }),
    height: 70,
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    width:'100%',
    flexDirection:'row',
    backgroundColor:'#eef1f2', 
    bottom: 0,
  },
  btnTab:{
    width:'90%',
    ...Platform.select({
      ios: {
        position:'absolute',
      },
      android: {
        position:'absolute',
      },
      default:{
        position:'fixed'
      }
    }),
  },
  icClose:{
    position:'absolute',
    left: 0,
    elevation: 1,
    zIndex: 1
  },
  notice:{
    color:'#ff0000',
    textDecorationLine:'underline',
    lineHeight:19,
  },
  colorRed:{
    color:'#ff0000',
  },
  btnPreview:{
    position:'absolute', 
    right:5, 
    paddingLeft:7, 
    paddingRight:7, 
    backgroundColor:'#fff', 
    borderWidth:1, 
    borderColor: '#32bedb',
    borderRadius:999,
    ...Platform.select({
      android:{
        paddingBottom:3,
      }
    })
  }
});