import PopupManager from "./PopupManager";

export default class PopupWrapper {
  static showMessage(...args){
    const ref = PopupManager.getDefault();
    ref?.showPopup(...args);
  }

  static showModalMessage(...args){
    const ref = PopupManager.getDefault();
    ref?.showModalPopup(...args);
  }

  static hideMessage(){
    const ref = PopupManager.getDefault();
    ref?.hidePopup();
  }

  static setOptions(...args){
    const ref = PopupManager.getDefault();
    ref?.setOptions(...args);
  }
}