import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Dimensions } from 'react-native';
//import { FontAwesome, EvilIcons } from '@expo/vector-icons';
import OnlineStatus from '../common/OnlineStatus';
import moment from 'moment';
import Constants from '../../constants/Constants';

const windowWidth = Dimensions.get('window').width;

export const checkValue = (val)=>{
  if(Constants.NOTSETTEXT.indexOf(val)==-1 && val!='' && val!=undefined){
    return true;
  }
  return false;
}

export default function FootStepUserRowFormat(props) {
  const { item } = props;
  let onlineId = 0;
  let time = '';
  if(item?.online!=undefined) onlineId = item.online?.id;
  if(item?.footmarkDateTime?.unix !=undefined){
    time = moment.unix(item.footmarkDateTime.unix).format('HH:mm');
  }
  let msg = props?.msg;
  if(msg) msg = msg.replaceAll("\n","");

  return (
    <View style={styles.profileList}>
      <View style={styles.timeDetail}>
        <Text style={styles.txtTime}>{time}</Text>
      </View>
      <View style={styles.profileItems}>
        <View style={styles.profileContainer}>
          <Image style={styles.myProfileThumbnail} source={{uri : props.img}} />
          {item?.newFlag==1?
          <View style={styles.newUser}><Text style={styles.txtNewUser}>NEW</Text></View>
          : null
          }
        </View>  
        <View style={styles.rightProfile}>
          <View style={styles.profileDesc}>
          <OnlineStatus online={onlineId} />
            <Text style={styles.txtProfileName}>{props?.nickname}</Text> 
          </View>
          <View style={styles.profileDesc}>
            <Text style={styles.txtProfileDesc}>{props?.age}</Text>
            <Text style={styles.txtProfileDesc}>{props?.area}</Text>
            {checkValue(item.occupation)?
            <Text style={styles.txtProfileDesc}>{item?.occupation}</Text>
            :null}
          </View>
          <View style={styles.profileMsg}>
            <Text numberOfLines={2} style={styles.txtMsg}>{msg}</Text>
          </View>
         
          <View style={styles.btnLike}>
            <Text style={styles.txtBtn}>プロフィールを見る</Text>
          </View>

          {/** 
          <TouchableOpacity style={styles.btnSendMessage}>
            <EvilIcons name="envelope" style={styles.icBtnSendMessage}/>
            <Text style={styles.txtBtn}>メッセージする</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.btnLike}>
            <FontAwesome name="heart" style={styles.icBtn} />
            <Text style={styles.txtBtn}>{item.goodFlg==1?'いいね':'いいね'}</Text>
          </TouchableOpacity>
          */}
        </View>  
      </View>
    </View>
  );
}

let style = {
  profileList:{
    flexDirection:'column',
    paddingTop:windowWidth<375? 6 : 8,
    paddingBottom:windowWidth<375? 8 : 10,
    paddingLeft:windowWidth<375? 20 : 22,
    paddingRight:windowWidth<375? 20 : 22,
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  profileItems:{
    flexDirection:'row',
  },
  profileContainer: {
    flexDirection:'row',
    paddingRight:windowWidth<375? 12 : 14,
  },
  myProfileThumbnail:{
    width:windowWidth<375? 69 : 80,
    height:windowWidth<375? 69 : 80,
    borderRadius:5,
  },
  rightProfile:{
    flex:2,
  },
  profileDesc: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  onlineStatus:{
    marginRight:5,
    fontSize:8,
    color:'#24eb25',
  },
  txtProfileName:{
    marginRight:10,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#262626',
  },
  txtProfileDesc:{
    marginRight:10,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  profileMsg:{
    marginTop:5,
    marginBottom:5,
  },
  txtMsg:{
    fontSize:windowWidth<375? 13 : 16,
    fontWeight:'normal',
    color:'#484848',
    lineHeight:windowWidth<375? 16 : 19
  },
  btnSendMessage:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    height:38,
    backgroundColor:'#ff8d63',
    borderRadius:8,
    textAlign:'center',
  },
  btnLike:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    height:windowWidth<375? 34 : 38,
    backgroundColor:'#5cc8ca',
    borderRadius:8,
    textAlign:'center',
  },
  icBtnSendMessage:{
    marginRight:8,
    fontSize:28,
    lineHeight:20,
    color:'#ffffff',
    textAlign:'center',
  },
  icBtn:{
    marginRight:8,
    fontSize:16,
    lineHeight:20,
    color:'#ffffff',
    textAlign:'center',
  },
  txtBtn:{
    fontSize:windowWidth<375? 12 : 15,
    fontWeight:'bold',
    color:'#ffffff',
    textAlign:'center',
  },
  newUser:{
    position:'absolute', 
    top:-12,
    left:-8, 
    width:28, 
    height:28, 
    backgroundColor:'rgba(255, 141, 99, 0.9)', 
    opacity:0.8,    
    borderRadius:999, 
    justifyContent:'center',
    textAlign: 'center',
  },
  txtNewUser:{
    fontSize:8,
    fontWeight:'bold',
    color:'#fff',
    textAlign:'center',
  },
  timeDetail:{
    alignSelf:'flex-end',
    marginBottom:4,
  },
  txtTime: {
    fontSize:windowWidth<375? 9 : 10,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 9 : 10,
    color:'#9ca4a6',
  },
};

if(windowWidth>375 && windowWidth<=414){
  style.profileList = {
    ...style.profileList,
    paddingTop:10,
    paddingBottom:11,
    paddingLeft:26,
    paddingRight:26,
  };
  style.profileContainer = {
    ...style.profileContainer,
    paddingRight:17,
  };
  style.myProfileThumbnail = {
    ...style.myProfileThumbnail,
    width:88,
    height:88,
  };
  style.btnLike = {
    ...style.btnLike,
    height:43,
  };
  style.txtBtn = {
    ...style.txtBtn,
    fontSize:16,
  };
  style.txtTime = {
    ...style.txtTime,
    fontSize:11,
    lineHeight:11,
  };
}

const styles = StyleSheet.create(style);