import React,{ Component } from 'react';
import { StyleSheet, Text, View, ScrollView, Platform, StatusBar, Dimensions } from 'react-native';
import HeadStyle from '../styles/HeadStyles';
import CommonFunction from '../utils/CommonFunction';
import HeadStyles from '../styles/HeadStyles';
import ActivityIndicator from '../components/common/ActivityIndicator';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import TitleSetting from '../components/common/TitleSetting';
import MyPage13Api from '../services/api/MyPage13Api';
import Badge14Api from '../services/api/Badge14Api';
import Moment from 'moment';

const { checkUndefined } = CommonFunction;
const windowHeight = Dimensions.get('window').height;

export default class SubscriptionCompleteScreen extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      badge: {},
      account:{},
      paymentDate:''
    };
    this.date = Moment().format('D')
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      CommonFunction.showBottomTab(this.navigation);
      this.setHeader();
      this.getDataFromApi();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  getDataFromApi = async ()=>{
    try{
      const response = await MyPage13Api.CheckMemberStat();
      if(response?.status==1){
        // get badge
        let badgeResponse = await Badge14Api.GetBadge();
        const badgeList = badgeResponse?.response;
        if(checkUndefined(badgeList)){
          if(this.props.setBadge!=undefined){
            this.props.setBadge(badgeList);
          }
        }
        
        let account = UserUtil.getAccountAppState(response, this.context);
        let data = response?.response?.subscription;
        let paymentDate = response?.response?.paymentDate;
        this.setState({
          account: account,
          data: data,
          paymentDate: paymentDate,
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false});
    }
  }

  setHeader = () =>{
    this.navigation.setOptions({
      header: () => (
        <View style={HeadStyles.customHeader}>
          <View style={{flex:1, alignItems:'stretch'}}><TitleSetting title="有料会員" numberOfLines={1} /></View>
        </View>
      )
    });
  }
  
  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    let expire = '';
    // if(this.state.data?.expire){
    //   expire = Moment.unix(this.state.data.expire).format('YYYY/MM/DD');
    // }
    if(this.state.paymentDate){
      expire = Moment.unix(this.state.paymentDate).format('YYYY/MM/DD');
    }

    return (
      <View style={[styles.container, HeadStyle.customHeaderTop]}>
        <ScrollView style={styles.scrollContainer} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            <View style={styles.blogInnerInside}>
              <View style={styles.titlePage}>
                <Text style={styles.txtTitlePage}>有料会員への加入が完了しました。</Text>
              </View>              
              <Text style={styles.txtDesc}>
              ご登録ありがとうございます。{"\n"}
              万が一ステータスが更新されない場合は、{"\n"}<Text style={{color:'#00b4d8'}}>Pappyカスタマーサポート</Text>までお問い合わせください。</Text>
            </View>
            <View style={styles.blogItem}>
              <View style={styles.itemTitle}>
                <Text style={styles.txtItemTitle}>決済内容</Text>
              </View>
              <Text style={styles.txtItemDesc}>{this.state.data?.overview}</Text>
            </View>
            <View style={styles.blogItem}>
              <View style={styles.itemTitle}>
                <Text style={styles.txtItemTitle}>金額</Text>
              </View>
              <Text style={styles.txtItemDesc}>{this.state.data?.amountDetail}</Text>
            </View>
            <View style={styles.blogItem}>
              <View style={styles.itemTitle}>
                <Text style={styles.txtItemTitle}>期限</Text>
              </View>
              <Text style={styles.txtItemDesc}>{this.state.data?.renewalPeriod}</Text>
            </View>
            <View style={styles.blogItem}>
              <View style={styles.itemTitle}>
                <Text style={styles.txtItemTitle}>次回更新日</Text>
              </View>
              <Text style={styles.txtItemDesc}>{expire}</Text>
            </View>

          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
    paddingBottom:50
  },
  scrollContainer: {
    backgroundColor:'#ffffff',
  },
  blogInner: {
    paddingTop:35,
    paddingฺBottom:35,
    paddingLeft:16,
    paddingRight:16,
  },
  blogInnerInside: {
    marginBottom:30,
    paddingBottom:20,
    borderBottomColor:'#e7ebec',
    borderBottomWidth:1,
  },
  titlePage:{
    marginBottom:18,
  },
  txtTitlePage:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  txtDesc:{
    fontSize:12,
    fontWeight:'bold',
    lineHeight:18,
    color:'#555',
  },
  blogItem:{
    marginBottom:windowHeight<=667? 15:30,
  },
  itemTitle:{
    marginBottom:16,
  },
  txtItemTitle:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  txtItemDesc:{
    fontSize:12,
    fontWeight:'bold',
    lineHeight:18,
    color:'#484848',
  },

  tabBarContainer:{
    backgroundColor: '#FFFFFF',
    ...Platform.select({
      ios: {
        position:'absolute',
      },
      android: {
        position:'absolute',
      },
      default:{
        position:'fixed'
      }
    }),
    bottom:0, 
    width:'100%', 
    height:50, 
    flexDirection:'row',
    borderTopWidth:1,
    borderTopColor:'#cccccc'
  },
  tabBarLabelStyle:{
    fontSize:10, 
    fontWeight:'bold', 
    lineHeight:18,
    color:'#9aa5a7'
  },
  tabBarStyle: {
    flex: 1,
  },
  tabStyle: {
    alignItems:'center',
    alignSelf:'center',
    alignContent:'center',
  },
  tabIcon:{
    marginTop: 5, 
    width:24, 
    height:24
  },
  textIcon:{
    color:'#9aa5a7',
    width:'100%',
    height:'100%',
    justifyContent:'center',
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    display:'flex'
  },
  badgeContainer:{
    position: 'absolute',
    right: 0,
    width:22, 
    height:22, 
    backgroundColor:'#ff8d63', 
    borderRadius:9999,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    textAlign:'center',
    zIndex: 1,
    elevation:1
   },
   badgeContainerDot:{
    position: 'absolute',
    right: 10,
    top: 2,
    width:10, 
    height:10, 
    backgroundColor:'#ff8d63', 
    borderRadius:9999,
    zIndex: 1,
    elevation:1
   },
   badge:{
    textAlign:'center',
    paddingTop:2,
    paddingBottom:2,
    width:'100%', 
    height:'100%', 
    fontWeight:'700',
    fontSize:12,
    lineHeight:18, 
    color:'#fff',
   }
});

