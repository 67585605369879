import React,{ Component, Fragment } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, StatusBar, Dimensions, Platform, Image, ImageBackground 
} from 'react-native';
import HeadStyle from '../styles/HeadStyles';
import GoBackIcon from '../components/common/GoBackIcon';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { UploadProfileImageInput } from '../components/form/FormInput';
import { Formik } from 'formik';
import HeaderOptions from '../constants/HeaderOptions';
import BottomMenu from '../components/common/BottomMenu';
import BottomMenuStyles from '../styles/BottomMenuStyles';
import CommonFunction from '../utils/CommonFunction';
import PopupWrapper from '../components/common/PopupWrapper';
import Msg from '../constants/option/Msg.json';
import { AppContext } from '../AppContext';
import ProfileSetting12Api from '../services/api/ProfileSetting12Api';
import PortalStyle from '../styles/PortalStyles';
import WebUtil from '../utils/WebUtil';
import LogoNoMargin from '../components/common/LogoNoMargin';
import ExpoConstants from 'expo-constants';
import Popup from '../components/common/Popup';
import UserUtil from '../utils/UserUtil';
import Constants from '../constants/Constants';
import { AntDesign } from '@expo/vector-icons';
import PortalStyles from '../styles/PortalStyles';

const { resizeImage } = WebUtil;
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;
const windowWidth = Dimensions.get('window').width;

export default class UserSettingImageScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      imgList: {},
      actionEnabled: false,
      myProfile: { sex: 0 }
    };

    this.submitField = {};
    this.ProfileImageRef = [];
    this.BottomMenuMainRef = React.createRef();
    this.BottomMenuRef = React.createRef();
    this.Formik = React.createRef();
    this.loadingPopup = React.createRef();
    this.guidPopup = React.createRef();
    
    // reset check access
    ProfileSetting12Api.resetCheckAccessFlg();
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      CommonFunction.hideBottomTab(this.navigation);

      const {appState, setAppState} = this.context;
      const account = appState?.account;
      let myProfile = await UserUtil.getUserProfileLocal(appState);
      this.setState({ myProfile: myProfile });
      console.log('myProfile', JSON.stringify(myProfile));
      this.setHeader();
      this.getDataFromApi();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  loadComplete = ()=>{
    this.setState({loading: false});
  }

  getDataFromApi = async () => {
    try{
      const profileGetCheckResponse = await ProfileSetting12Api.ProfileGetCheck(this.context);
      //check login
      if(!CommonFunction.checkResponseStatus(profileGetCheckResponse)) return;
      
      if(profileGetCheckResponse!=undefined && profileGetCheckResponse?.status==1){
        let userProfile = profileGetCheckResponse?.userProfile;
        if(userProfile!=undefined && Object.keys(userProfile).length > 0){
          let profileImage = userProfile?.profileImage;
          let List = {};

          if(profileImage?.count>0){
            let listId = 1;
            profileImage?.list?.forEach((obj, index) => {
              if(obj?.mainFlag==1){
                List.mainPicture = obj;
              }else{
                let keyName = 'picture'+listId;
                List[keyName] = obj;
                listId++;
              }
            });
          }

          List.mainPicture = CommonFunction.checkProfileImage(List.mainPicture);
          List.picture1 = CommonFunction.checkProfileImage(List.picture1);
          List.picture2 = CommonFunction.checkProfileImage(List.picture2);
          List.picture3 = CommonFunction.checkProfileImage(List.picture3);
          List.picture4 = CommonFunction.checkProfileImage(List.picture4);
          List.picture5 = CommonFunction.checkProfileImage(List.picture5);
          List.picture6 = CommonFunction.checkProfileImage(List.picture6);
          List.picture7 = CommonFunction.checkProfileImage(List.picture7);
          this.setState({imgList: List, loading: false, actionEnabled: false},()=>{
            setTimeout(() => {
              if(this.Formik !=undefined){
                this.Formik?.setValues(List);
              }
            }, 1000);
            
          });
        }
      }else{
        this.loadComplete();
      }
    }catch(e){
      if(__DEV__) console.log(e);
      this.loadComplete();
    }finally{
      
    }
  }

  setHeader(){
    let headerStyle = HeaderOptions.HeaderSetting[0];
    if(Platform.OS==='ios'){
      //headerStyle.height = ExpoConstants.statusBarHeight+HeaderOptions.headerTopMenuHeight;
      headerStyle = {
        ...HeaderOptions.HeaderSetting[0],
        height: ExpoConstants?.statusBarHeight + HeaderOptions?.headerTopMenuHeight
      }
    }

    this.navigation?.setOptions({
      headerStyle: headerStyle,
      headerTitleAlign:'center',
      headerTitle: () => <View><Text style={HeadStyle.headerTitlePage}>編集する</Text></View>,
      headerLeft: () => <View style={{flexDirection:'row'}}><GoBackIcon navigation={this.navigation} /><LogoNoMargin/></View>,
      headerRight: () => <View/>
    });
  }

  setProfileImageRef(ref, fieldName){
    this.ProfileImageRef[fieldName] = ref;
  }

  deleteImg = async () => {
    const pictureId = this.submitField?.pictureId;
    if(pictureId){
      try{
        CommonFunction.overLay();
        // call Api
        let responseProfileDeletePicture = await ProfileSetting12Api.ProfileDeletePicture(pictureId);
        //check login
        if(!CommonFunction.checkResponseStatus(responseProfileDeletePicture)) return;

        if(responseProfileDeletePicture!=undefined && responseProfileDeletePicture?.status==1){
          // load new image from api
          await this.getDataFromApi();
          if(this.state.imgList?.mainPicture?.url==null){
            PopupWrapper.showMessage('削除しました','check',()=>{
              CommonFunction.hideOverlay();
            });
          }else{
            PopupWrapper.showMessage('設定しました','check',()=>{
              CommonFunction.hideOverlay();
            });
          }          
          //this.Formik.setValues(this.state.imgList);
        }else{
          CommonFunction.hideOverlay();
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        //CommonFunction.hideOverlay();
      }
    }
  }

  setMainImage = async () => {
    const pictureId = this.submitField?.pictureId;
    if(pictureId){
      try{
        CommonFunction.overLay();
        // call Api
        let responseProfilePutMainPicture = await ProfileSetting12Api.ProfilePutMainPicture(pictureId);
        //check login
        if(!CommonFunction.checkResponseStatus(responseProfilePutMainPicture)) return;

        if(responseProfilePutMainPicture!=undefined && responseProfilePutMainPicture?.status==1){
          PopupWrapper.showMessage(Msg.Save.Success,'check',()=>{
            CommonFunction.hideOverlay();
          });
          // load new image from api
          await this.getDataFromApi();
        }else{
          CommonFunction.hideOverlay();
        }
      }catch(e){
        CommonFunction.hideOverlay();
        if(__DEV__) console.log(e);
      }finally{
        //CommonFunction.hideOverlay();
      }
    }
  }

  setPrivateImage = async () => {
    const pictureId = this.submitField?.pictureId;
    if(pictureId){
      try{
        CommonFunction.overLay();
        const response = await ProfileSetting12Api.ProfilePutImagePrivateStatus(pictureId);
        if(response!=undefined && response?.status==1){
          PopupWrapper.showMessage(Msg.Save.Success,'check',()=>{
            CommonFunction.hideOverlay();
          });
          // load new image from api
          await this.getDataFromApi();
          //this.Formik.setValues(this.state.imgList);
        }else{
          CommonFunction.hideOverlay();
        }
      }catch(e){
        CommonFunction.hideOverlay();
        if(__DEV__) console.log(e);
      }finally{
        //CommonFunction.hideOverlay();
      }
    }
  }

  actionEnableSet = async () => {
    this.setState({actionEnabled: true});
    this.loadingPopup?.showPopup();
  }

  handleSubmit = async (values) => {
    if(values && this.submitFieldName !=''){
      try{

        const stateKey = this.submitFieldName;
        const stateValue = values[stateKey];
        const [, type] = stateValue.split(';')[0].split('/');
        const resp = await fetch(stateValue);
        const fblob = await resp.blob();

        const maxWidth = 1280;
        const config = {
          file: fblob,
          maxWidth: maxWidth
        };
        
        let postFile;
        if(Platform.OS==='web'){
          postFile = await resizeImage(config)
          if(__DEV__) console.log('resize file size',postFile.size);
        }else{
          //app resize image
          const resizeStateValue = await CommonFunction.resizeImageApp(stateValue, maxWidth);
          if(resizeStateValue){
            const type = resizeStateValue?.type;
            const mainPath = resizeStateValue?.mainPath;
            postFile = {
              uri: mainPath,
              type: fblob.type,
              name: `profile.${type}`
            };
            if(__DEV__) console.log(postFile);
          }
        }

        if(postFile){
          // call Api
          let responseProfilePostPicture = await ProfileSetting12Api.ProfilePostPicture(postFile);

          //check login
          if(!CommonFunction.checkResponseStatus(responseProfilePostPicture)) {
            this.loadingPopup?.hidePopup();
            return;
          }

          if(responseProfilePostPicture?.status==1){
            PopupWrapper.showMessage(Msg.Save.Success);
            // load new image from api
            await this.getDataFromApi();
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({actionEnabled: false});
        this.loadingPopup?.hidePopup();
      }
    }
  }

  handleOnPress = (values, mainImage=false)=>{
    if(values!=undefined){
      if(values?.certify==2){
        this.submitField = values;
        if(mainImage){
          this.BottomMenuMainRef?.showPopUp(true);
        }else{
          this.BottomMenuRef?.showPopUp(true);
        }
      }
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    const imageWidth = windowWidth * (959 / 1125)
    const imageHeight = windowWidth * (133 / 1125)

    const isNoImage = !this.state.imgList?.mainPicture?.url

    const popupWidth = windowWidth * 0.77;
    const newHeight = popupWidth * 943 / 864;

    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} scrollToOverflowEnabled={true}>
          <Formik
            innerRef={ref => this.Formik=ref}
            initialValues={this.state.imgList}
            onSubmit={(values, { resetForm }) => {
              this.handleSubmit(values);
              //resetForm();
          }}>
          {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => (
            <Fragment>
              <View style={{marginTop:32,width:'100%'}}>
                {!values.mainPicture?.url && this.state.myProfile?.sex==Constants.FEMALE?
                  <Image source={require('../assets/images/profimg_2.png')} style={{width: imageWidth, height: imageHeight, alignSelf:'center', marginBottom:10, marginTop:10}}  resizeMode="contain"/> 
                :null}
                {!values.mainPicture?.url && this.state.myProfile?.sex==Constants.MALE?
                  <Image source={require('../assets/images/profimg.png')} style={{width: imageWidth, height: imageHeight, alignSelf:'center', marginBottom:10, marginTop:10}}  resizeMode="contain"/> 
                :null}
                {this.state.myProfile?.sex==Constants.FEMALE?
                  <Text style={{fontSize:14,fontWeight:'normal',lineHeight:21,color:'#484848',textAlign:'center'}}>メイン写真</Text>
                  :
                  <Text style={{fontSize:14,fontWeight:'normal',lineHeight:21,color:'#484848',textAlign:'center'}}>メイン画像</Text>
                }
                  </View>
                <UploadProfileImageInput 
                  fieldName="mainPicture"
                  item={values.mainPicture}
                  image={values.mainPicture?.url}
                  setRef={this.setProfileImageRef.bind(this)}  
                  mainImg={true}
                  onPress={()=>{
                    this.handleOnPress(values.mainPicture, true);
                  }}
                  setSubmitField={()=>{
                    this.submitFieldName = 'mainPicture';
                  }}
                  preview={false}
                  handleSubmit={handleSubmit}
                  isNoImage={!this.state.imgList?.mainPicture?.url}
                  actionEnabled={this.state.actionEnabled}
                  actionEnableSet={this.actionEnableSet}
                />
                {this.state.myProfile?.sex==Constants.FEMALE?
                <View style={{marginTop:16,marginBottom:20,width:'100%'}}>
                  <TouchableOpacity style={{flexDirection:'row',alignSelf:'center',justifyContent:'center',alignContent:'center',alignItems:'center'}} onPress={async()=>{
                    this.guidPopup?.showPopup();
                  }}>
                    <AntDesign name="questioncircle" style={styles.questIc}/>
                    <Text style={[styles.questTxt,{textDecorationLine:'underline'}]}>メイン写真の選び方について</Text>
                  </TouchableOpacity>
                  <View style={{flexDirection:'row',alignSelf:'center',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                    <Text style={styles.questTxt}>メイン写真に顔写真以外を設定すると{"\n"}
                    男性に表示されにくい状態となりますので{"\n"}
                    お顔が分かる写真の設定を推奨しております
                    </Text>
                  </View>
                </View>
              :
              <View style={{marginTop:16,marginBottom:20,width:'100%'}}>
                <View style={{flexDirection:'row',alignSelf:'center',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                    <Text style={styles.questTxt}>メイン画像にあなたの顔が{"\n"}
                      わかる写真を登録するとお相手との{"\n"}
                      マッチング率がUPします
                    </Text>
                  </View>
                </View>
              }
                  

            
                <View style={styles.inside}>
                  {/*<View><Text style={styles.headerTxtInside}>サブ写真</Text></View>*/}
                  <View style={styles.section}>
                    <View style={{flexDirection:'row', width:'100%', flex:1}}> 
                      <UploadProfileImageInput 
                        fieldName="mainPicture"
                        item={values.mainPicture}
                        image={values.mainPicture?.url}
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.mainPicture, true);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'mainPicture';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture1"
                        item={values.picture1}
                        image={values.picture1?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture1);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture1';
                        }}
                        preview={true}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture2"
                        item={values.picture2}
                        image={values.picture2?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture2);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture2';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture3"
                        item={values.picture3}
                        image={values.picture3?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture3);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture3';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                    </View>
                    <View style={{flexDirection:'row', width:'100%', flex:1}}>
                      <UploadProfileImageInput 
                        fieldName="picture4"
                        item={values.picture4}
                        image={values.picture4?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture4);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture4';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture5"
                        item={values.picture5}
                        image={values.picture5?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture5);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture5';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture6"
                        item={values.picture6}
                        image={values.picture6?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture6);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture6';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                      <UploadProfileImageInput 
                        fieldName="picture7"
                        item={values.picture7}
                        image={values.picture7?.url} 
                        setRef={this.setProfileImageRef.bind(this)} 
                        mainImg={false}
                        onPress={()=> {
                          this.handleOnPress(values.picture7);
                        }}
                        setSubmitField={()=>{
                          this.submitFieldName = 'picture7';
                        }}
                        preview={false}
                        handleSubmit={handleSubmit}
                        isNoImage={isNoImage}
                        actionEnabled={this.state.actionEnabled}
                        actionEnableSet={this.actionEnableSet}
                      />
                    </View>
                  </View>
                </View>
              
              {/** popup main image */}
              <BottomMenu ref={ref=>this.BottomMenuMainRef=ref}>
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
                  this.deleteImg();
                  this.BottomMenuMainRef?.showPopUp(false);
                }}>
                  <Text style={[BottomMenuStyles.txtListPopup,BottomMenuStyles.txtColorRed]}>削除する</Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
                  this.BottomMenuMainRef?.showPopUp(false);
                }}>
                  <Text style={BottomMenuStyles.txtBtnListPopupCancel}>キャンセル</Text>
                  </TouchableOpacity>
              </BottomMenu>

              {/** popup sub image */}
              <BottomMenu ref={ref=>this.BottomMenuRef=ref}>
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupFirst} onPress={()=>{
                  this.setMainImage();
                  this.BottomMenuRef?.showPopUp(false);
                }}>
                  <Text style={[BottomMenuStyles.txtListPopup]}>メイン画像に設定する</Text>
                </TouchableOpacity>
                {/** 
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupItem} onPress={()=>{
                  //this.setPrivateImage();
                  this.BottomMenuRef?.showPopUp(false);
                }}>
                  <Text style={[BottomMenuStyles.txtListPopup]}>メッセージしたお相手のみ公開</Text>
                </TouchableOpacity>
                
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupItem} onPress={()=>{
                  //this.setPrivateImage();
                  this.BottomMenuRef?.showPopUp(false);
                }}>
                  <Text style={[BottomMenuStyles.txtListPopup]}>常に公開</Text>
                </TouchableOpacity>
                */}
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.listPopupLast} onPress={()=>{
                  this.deleteImg();
                  this.BottomMenuRef?.showPopUp(false);
                }}>
                  <Text style={[BottomMenuStyles.txtListPopup,BottomMenuStyles.txtColorRed]}>削除する</Text>
                </TouchableOpacity>
                
                <TouchableOpacity activeOpacity={0.85} style={BottomMenuStyles.btnListPopupCancel} onPress={()=>{
                  this.BottomMenuRef?.showPopUp(false);
                }}>
                  <Text style={BottomMenuStyles.txtBtnListPopupCancel}>キャンセル</Text>
                </TouchableOpacity>
              </BottomMenu>
              
            </Fragment>
          )}  
        </Formik>
      </ScrollView>
      <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
        <View style={PortalStyle.overlay}/>
        <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
      </Popup>
      <Popup ref={ref=>this.guidPopup=ref} modal={true}>
        <View style={PortalStyles.overlay} 
          onStartShouldSetResponder={() => {
          }} />
        <View style={styles.guidPopupContainer}>
          <ImageBackground resizeMode={'cover'} 
            style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
            source={require("../assets/images/popup-profile-image-guid-female.png")}
          ></ImageBackground>
          <TouchableOpacity style={styles.guidPopupBtnCancel} onPress={()=>this.guidPopup?.hidePopup()}>
            <Text style={styles.guidPopupTxtBtnCancel}>閉じる</Text>
        </TouchableOpacity>
        </View>
      </Popup>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    marginTop:0,
    backgroundColor:'#eef1f2',
  },
  scrollContainer: {
    height:scrollHeight,
    backgroundColor:'#eef1f2',
  },
  inside:{
    padding:12,
    paddingTop:30,
    alignSelf:'center'
  },
  headerTxtInside:{
    marginTop:32,
    marginBottom:12,
    marginLeft:4,
    marginRight:4,
    fontSize:14,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848',
  },
  section:{
    justifyContent:'space-between',
    alignItems:'center',
    alignContent:'center',
    width:'100%',
  },
  questIc:{
    paddingTop:2,
    marginRight:4,
    fontSize:14,
    lineHeight:21,
    color:'#484848',
  },
  questTxt:{
    textAlign:'center',
    fontSize:14,
    fontWeight:'bold',
    lineHeight:21,
    color:'#484848',
  },
  guidPopupContainer:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    paddingTop: 5, 
    width:'80%', 
    backgroundColor:'#fefefe', 
    borderRadius: 12,
  },
  guidPopupBtnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    marginBottom:15,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  guidPopupTxtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    textAlign:'center',
  },
});
