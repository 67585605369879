import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { Formik } from 'formik';
import styles from '../../styles/FormStyles';
import { FormTextInputBorder } from '../../components/form/FormInput';
import CommonFunction from '../../utils/CommonFunction';
import Setting17Api from '../../services/api/Setting17Api';
import Msg from '../../constants/option/Msg.json';
import Validation from '../../constants/Validation';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import { AppContext } from '../../AppContext';
import ListStyles from '../../styles/ListStyles';

export default class PassInputScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.initialValues = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
    this.formik = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props.navigation;
      this.props?.setHeader('パスワードの変更', this.navigation);
    }
  }

  resetFormMessage = ()=>{
    this.formik?.setValues(this.initialValues);
  }

  async updateData(values){
    try{
      CommonFunction.overLay();
      const changePassResponse = await Setting17Api.PutResetPassword(
        values['currentPassword'], 
        values['newPassword']
      );

      //check response
      if(!CommonFunction.checkResponseStatus(changePassResponse)) return;

      if(changePassResponse!=undefined && changePassResponse?.status==1){
        this.resetFormMessage();
        this.popup?.showPopup();
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  onClosePopup = ()=>{
    this.popup?.hidePopup();
    setTimeout(() => {
      this.navigation.navigate('Setting');
    }, 300);
  }

  render() {
    return (
      <View style={ListStyles.container}>
        <ScrollView style={styles.ScrollContainer}>
        <Formik
          innerRef={p => (this.formik = p)}
          initialValues={this.initialValues}
          validationSchema={Validation.SettingPassword}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.updateData(values) }
        >
          {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
            <Fragment>
              <View style={[styles.BodyContent, {padding:30}]}>
                <View style={styles.labelView}><Text style={styles.labelText}>現在のパスワード</Text></View>
                <FormTextInputBorder fieldName="currentPassword" secureTextEntry={true} placeholder="" autoComplete="off"/>
                <View style={[styles.labelView, {marginBottom:0,}]}>
                  <View style={{marginTop:15, marginBottom:5,}}>
                    {errors.newPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12}]}>※{errors.newPassword}</Text>) : null}
                    {errors.confirmPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12}]}>※{errors.confirmPassword}</Text>) : null}
                  </View>
                  <Text style={styles.labelText}>新しいパスワード</Text>
                  <Text style={{fontSize:12, lineHeight:18}}>※4～8文字の英数字で設定してください</Text>
                </View>
                <FormTextInputBorder fieldName="newPassword" secureTextEntry={true} showerror={false} autoComplete="off"/>

                <View style={[styles.labelView, {marginTop:30}]}><Text style={styles.labelText}>新しいパスワードの確認</Text></View>
                <FormTextInputBorder fieldName="confirmPassword" secureTextEntry={true} showerror={false} autoComplete="off"/>

                <View style={[styles.labelView, {marginTop:30}]}>
                  <Text style={{fontSize:12, fontWeight:'normal', lineHeight: 19, color:'#484848'}}>※「1111」のようなゾロ目のパスワード、{"\n"}連番のパスワードはご利用いただけません。</Text>
                </View>

                <View style={[styles.buttonPanelSetting, {marginTop:40}]}>
                  <TouchableOpacity 
                    style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} 
                    onPress={handleSubmit} 
                    disabled={!(dirty)}
                  >
                    <Text style={styles.loginText}>変更する</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Fragment>
          )}          
        </Formik>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.onClosePopup();
          }} />
          <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'70%', backgroundColor:'#fefefe', borderRadius: 12}}>
            <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24}}>{Msg.PasswordChange.Success}</Text>
            <TouchableOpacity onPress={()=>{this.onClosePopup()}} style={[styles.btnGray, {marginTop:20}]}>
              <Text style={styles.txtBtnGray}>OK</Text>
            </TouchableOpacity>
          </View>
        </Popup>
        </ScrollView>
      </View>
    );
  }
}
