import React, { useState, useEffect, useRef, useContext } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, Dimensions, Platform} from 'react-native';
import Constants from '../../constants/Constants';
import { AntDesign } from '@expo/vector-icons';
import UserVerify from '../../utils/UserVerify';
import Profile04Api from '../../services/api/Profile04Api';
import ErrorCode from '../../constants/ErrorCode';
import PopupWrapper from "../../components/common/PopupWrapper";
import CommonFunction from '../../utils/CommonFunction';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ConfirmPopupStyles from '../../styles/ConfirmPopupStyles';
import UserInfoStyles from '../../styles/UserInfoStyles';
import { AppContext } from '../../AppContext';
import formStyles from '../../styles/FormStyles';

const screenWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

const checkList = (item)=>{
  let data;
  if(item?.list!=undefined){
    let dataList = item.list;
    if(Object.keys(dataList).length > 0){
      data = [];
      Object.keys(dataList).map((key,index)=>{
        if(dataList[key]?.label!=undefined){
          data.push(dataList[key].label);
        }
      });
      data = data.join(',');
    }
  }else{
    data = item;
  }

  return data;
}

export const checkValue = (val)=>{
  if(Constants.NOTSETTEXT.indexOf(val)==-1 && val!='' && val!=undefined){
    return true;
  }
  return false;
}

export const Block16 = (props) => {
  let { item } = props;
  let data = [];
  if(checkValue(item.height) && item.height>0){
    data.push({title: '身長', value: item.height+'cm'});
  }

  let vibe = checkList(item.vibe);
  if(checkValue(vibe)){
    data.push({title: '雰囲気', value: vibe});
  }
  let personality = checkList(item.personality);
  if(checkValue(personality)){
    data.push({title: '性格・タイプ', value: personality});
  }
  let style = checkList(item.style);
  if(checkValue(style)){
    data.push({title: 'スタイル', value: style});
  }
  return <SectionInfo data={data} title="外見・容姿" />;
}

export const Block16Male = (props) => {
  let { item } = props;
  let data = [];
  if(checkValue(item.height) && item.height>0){
    data.push({title: '身長', value: item.height+'cm'});
  }
  let style = checkList(item.style);
  if(checkValue(style)){
    data.push({title: 'スタイル', value: style});
  }
  return <SectionInfo data={data} title="外見・容姿" />;
}

export const Block17 = (props) => {
  let { item } = props;
  let data = [];
  let dateHope = checkList(item.dateHope);
  if(checkValue(dateHope)){
    data.push({title: 'デートでしたいこと', value: dateHope});
  }
  let meetHope = checkList(item.meetHope);
  if(checkValue(meetHope)){
    data.push({title: '出会うまでの希望', value: meetHope});
  }

  return <SectionInfo data={data} title="デート" />;
}

export const Block18 = (props) => {
  let { item } = props;
  let data = [];
  let education = checkList(item.education);
  if(checkValue(education)){
    data.push({title: '学歴', value: education});
  }
  let occupation = checkList(item.occupation);
  if(checkValue(occupation)){
    data.push({title: 'お仕事', value: occupation});
  }
  let holiday = checkList(item.holiday);
  if(checkValue(holiday)){
    data.push({title: '休日', value: item.holiday});
  }

  return <SectionInfo data={data} title="仕事・学歴" />;
}

export const Block18Male = (props) => {
  let { item } = props;
  let data = [];
  let education = checkList(item.education);
  let title = "年収・仕事・学歴";
  if(!isWeb) title = "仕事・学歴";

  if(checkValue(education)){
    data.push({title: '学歴', value: education});
  }
  if(isWeb){
    let income = checkList(item.income);
    if(checkValue(income)){
      data.push({title: '年収', value: income});
    }
    let assets = checkList(item.assets);
    if(checkValue(assets)){
      data.push({title: '資産', value: assets});
    }
  }
  let occupation = checkList(item.occupation);
  if(checkValue(occupation)){
    data.push({title: 'お仕事', value: occupation});
  }
  let holiday = checkList(item.holiday);
  if(checkValue(holiday)){
    data.push({title: '休日', value: holiday});
  }

  return <SectionInfo data={data} title={title} />;
}

export const Block19 = (props) => {
  let { item } = props;
  let data = [];
  let alcohol = checkList(item.alcohol);
  if(checkValue(alcohol)){
    data.push({title: 'お酒', value: alcohol});
  }
  let cigarette = checkList(item.cigarette);
  if(checkValue(cigarette)){
    data.push({title: 'タバコ', value: cigarette});
  }

  return <SectionInfo data={data} title="生活" />;
}

export const Block20 = (props) => {
  let { item } = props;
  let data = [];
  let currentPref = checkList(item.currentPref);
  if(checkValue(currentPref)){
    data.push({title: '居住地', value: currentPref});
  }
  let birthCity = checkList(item.birthCity);
  if(checkValue(birthCity)){
    data.push({title: '出身地', value: birthCity});
  }

  return <SectionInfo data={data} title="基本情報" />;
}

export const Block21 = (props) => {
  let { item, account, navigation, NoPointRef } = props;
  const [isShowing, setIsShowing] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const confirmPopup = React.useRef();
  const [isBrowse, setIsBrowse] = useState(false);
  const confirmRegProfileImagePopup = React.useRef();

  const defaultSecurityCheck = {
    violentCount: 0,
    cancelCount: 0,
    impersonationCount: 0,
    invitationCount: 0,
  };
  const [securityCheck, setSecurityCheck] = useState(defaultSecurityCheck);
  const context = useContext(AppContext);
  
  useEffect(() => {
    setIsShowing(false);
    setIsClick(false);
    if(item?.safetyCheckFlg==1){
      setIsBrowse(true);
    } else {
      setIsBrowse(false);
    }
    setSecurityCheck(defaultSecurityCheck);
  }, [item]);

  const valid = ()=>{
    return (
      <View style={{flexDirection:'row', justifyContent:'center'}}>
        <AntDesign name="checkcircle" color="#5cc8ca" style={styles.rightIcon}/>
        <Text>違反なし</Text>
      </View>
    );
  }

  const notValid = (count)=>{
    return (
      <View style={{flexDirection:'row', justifyContent:'center'}}>
        <AntDesign name="warning" color="#ff0000" style={styles.rightIcon} />
        <Text style={{color:'#ff0000',marginRight:5}}>{count}</Text><Text>件</Text>
      </View>
    );
  }

  const footer = ()=>{
    return (
      <View style={{width:'100%', borderRadius:999,backgroundColor:'#f9f1f1',paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10}}>
        <Image style={{width:'100%', height:screenWidth<375? 9 :11}} source={require('../../assets/images/display-content.png')} />
      </View>
    );
  }

  const hideBlock = ()=>{
    setIsShowing(false);
  }

  const checkSMS = ()=>{
    if(context){
      if(UserVerify.checkSMSAppState(context)) return true;
    }

    return UserVerify.checkSMS({
      activated: account?.activated, 
      navigation: navigation,
      account: account
    });
  }

  const checkAge = ()=>{
    if(context){
      if(UserVerify.checkAgeAppState(context)) return true;
    }

    return UserVerify.checkAge({
      ageVerficationStatus: account?.ageVerficationStatus,
      navigation: navigation
    });
  }

  const checkPayment = ()=>{
    if(context){
      if(UserVerify.checkPaymentAppState(context)) return true;
    }

    return UserVerify.checkPayment({
      paymentFlag: account?.subscription?.status,
      navigation: navigation
    });
  }

  const safetyCheck = async()=>{
    try{
      const checkAccess = await CommonFunction.checkAccessTime();
      if(!checkAccess) return;
      
      const responseGetSafetyCheck = await Profile04Api.GetSafetyCheck(item.memberId, 0);
      if(!CommonFunction.checkResponseStatus(responseGetSafetyCheck)) return;

      if(responseGetSafetyCheck!=undefined && responseGetSafetyCheck?.status==1){
        const securityCheck = responseGetSafetyCheck?.response;
        if(securityCheck!=undefined){
          setSecurityCheck(securityCheck);
          setIsShowing(true);
          setIsClick(true);
          setIsBrowse(true);
          return true;
        }
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(responseGetSafetyCheck)){
          const response = responseGetSafetyCheck?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
          const errorMessage = response?.errorMessage;
          if(errorCode==ErrorCode.ProfileGetSafetyInfo.NoPoint){
            if(NoPointRef!=undefined){
              NoPointRef?.setMessage(errorMessage);
              NoPointRef?.show();
            }
          }else if(errorCode==ErrorCode.ProfileGetSafetyInfo.NoMember){
            PopupWrapper.showMessage('お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。','warning');
          }else{
            if(errorMessage)
              PopupWrapper.showMessage(errorMessage,'warning');
          }
        }
      }
      return false;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  const gotoSettingImage = ()=>{
    try{
      navigation.navigate('UserSettingImage');
    }catch(e){
      navigation.navigate('MyPageStack',{screen:'UserSettingImage'});
    }
  }

  let data = [];
  let violentCount = valid();
  let cancelCount = valid();
  let impersonationCount = valid();
  let invitationCount = valid();
  
  if(securityCheck?.violentCount!=0){
    violentCount = notValid(securityCheck.violentCount);
  }
  if(securityCheck?.cancelCount!=0){
    cancelCount = notValid(securityCheck.cancelCount);
  }
  if(securityCheck?.impersonationCount!=0){
    impersonationCount = notValid(securityCheck.impersonationCount);
  }
  if(securityCheck?.invitationCount!=0){
    invitationCount = notValid(securityCheck.invitationCount);
  }

  data.push({title: '暴言など不適切なメッセージ', value: violentCount});
  data.push({title: '当日のキャンセル', value: cancelCount});
  data.push({title: 'プロフィールの詐称', value: impersonationCount});
  data.push({title: '勧誘などの不適切な利用', value: invitationCount});

  if(!isShowing){ 
    return (
      <View style={styles.section}>
        <TouchableOpacity onPress={async()=>{
          if(!checkSMS()) return;
          //if(!checkAge()) return;
 
          if(item.sex==Constants.WOMEN || item.sex==Constants.FEMALE){ // view female profile
            //if(!checkPayment()) return;

            if(item?.safetyCheckFlg==0 && !isClick){
              confirmPopup.current.showPopup();
              return;
            }else{
              safetyCheck();
            }
          }else{ // view male profile
            if(account?.regProfileImage!=1){
              confirmRegProfileImagePopup?.current?.showPopup();
            }else{
              safetyCheck();
            }
          }
        }}>
          <Text style={[styles.h3,{ paddingBottom:0}]}>セーフティ チェック</Text>
          {(isBrowse && item.sex==Constants.WOMEN) &&
            <View style={styles.browseIcon}><Text style={styles.browseText}>閲覧可</Text></View>
          }
          <AntDesign name={'down'} style={styles.downIcon} />
        </TouchableOpacity>

        <Popup ref={confirmPopup} modal={true}>
          <View style={PortalStyle.overlay} />
          <View style={[ConfirmPopupStyles.popupContainer,{ width:'85%'}]}>
            <View style={ConfirmPopupStyles.popupTextItem}>
              <Text style={ConfirmPopupStyles.popupText}>
              セーフティチェックで通報がないかを3ポイント消費して確認しますか？{"\n"}※1週間確認ができます
              </Text>
            </View>
            <View style={ConfirmPopupStyles.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderRight]} onPress={()=>{
                confirmPopup?.current.hidePopup();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>キャンセル</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderLeft]} onPress={()=>{
                confirmPopup?.current.hidePopup();
                safetyCheck();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>確認する</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>

        <Popup ref={confirmRegProfileImagePopup} modal={true}>
          <View style={PortalStyle.overlay} />
          <View style={[ConfirmPopupStyles.popupContainer,{ width:'85%'}]}>
            <View style={[ConfirmPopupStyles.popupTextItem,{ padding: 15, borderBottomWidth:0}]}>
              <Text style={[ConfirmPopupStyles.popupText,{fontSize:18, fontWeight:'bold',paddingTop:15,paddingBottom:15}]}>セーフティチェック</Text>
              <Text style={[ConfirmPopupStyles.popupText,{textAlign:'left'}]}>
              他ユーザーから通報された内容を確認することができます。{"\n"}
              この機能は、あなたと分かるプロフィール画像を設定することでご利用いただけます。
              </Text>
            </View>
            <View style={[ConfirmPopupStyles.btnContainer,{flexDirection:'column'}]}>
              <TouchableOpacity style={[formStyles.submitBtn, {marginTop:10, width:'80%'}]} onPress={()=>{
                confirmRegProfileImagePopup?.current.hidePopup();
                gotoSettingImage();
              }}>
                <Text style={{color:'#ffffff',fontWeight:'bold', fontSize:16}}>プロフィール画像を設定する</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[formStyles.submitBtn, {backgroundColor:'#b1bfc2', shadowColor: "#798789", marginTop:25, marginBottom:25, width:'50%', height:40}]} 
                onPress={()=>{
                confirmRegProfileImagePopup?.current.hidePopup();
              }}>
                <Text style={{color:'#ffffff',fontWeight:'bold'}}>閉じる</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>

      </View>
    );
  }

  return <SectionInfoDropdown data={data} title="セーフティ チェック" footer={footer()} onpressDropdown={hideBlock} isBrowse={isBrowse} item={item}/>;
}

export const SectionInfo = (props) => {
  let {data, title, footer } = props;
  if(data==undefined || !Array.isArray(data)) data = [];
  if(title==undefined) title = '';
  if(footer==undefined) footer = '';

  if(data.length==0) return <></>;
 
  return (
    <View style={styles.section}>
      <Text style={styles.h3}>{title}</Text>
      {data.map((item, i) => {
        return(
          <View key={i.toString()}  style={[styles.infoList,{borderBottomWidth:((i+1)==data.length)?0:0.55}]}>
            <View style={styles.infoLabel}>
              <Text style={styles.label}>{item.title}</Text>
            </View>
            <View style={styles.infoText}>
              <Text style={styles.text}>{item.value}</Text>
            </View>
          </View>
        )
      })}
      {footer? footer:null }
    </View>
  );
}

export const SectionInfoDropdown = (props) => {
  let {data, title, footer, onpressDropdown, isBrowse, item} = props;
  if(data==undefined || !Array.isArray(data)) data = [];
  if(title==undefined) title = '';
  if(footer==undefined) footer = '';

  if(data.length==0) return <></>;
 
  return (
    <View style={styles.section}>
      <TouchableOpacity onPress={()=>{
        if(onpressDropdown!=undefined){
          onpressDropdown();
        }
      }}>
        <Text style={styles.h3}>{title}</Text>
        {(isBrowse && item.sex==Constants.WOMEN) &&
          <View style={styles.browseIcon}><Text style={styles.browseText}>閲覧可</Text></View>
        }
        <AntDesign name={'up'} style={styles.downIcon} />
      </TouchableOpacity>

      {data.map((item, i) => {
        return(
          <View key={i.toString()}  style={[styles.infoList,{borderBottomWidth:((i+1)==data.length)?0:0.55}]}>
            <View style={styles.infoLabel}>
              <Text style={styles.label}>{item.title}</Text>
            </View>
            <View style={styles.infoText}>
              <Text style={styles.text}>{item.value}</Text>
            </View>
          </View>
        )
      })}
      {footer? footer:null }
    </View>
  );
}

export const YellowCard = (props)=>{
  let { yellowcardBottom } = props;
  if(!yellowcardBottom) yellowcardBottom = 0;

  return (
    <View style={{ justifyContent: 'center', alignContent: 'center', alignItems:'center', position: 'absolute', bottom: yellowcardBottom, left: 0, width: '100%', marginBottom: 10 }}>
      <Image resizeMode={'stretch'} style={styles.yellowCard} source={require('../../assets/images/yellowcard2.png')} />
    </View>
  );
}

export const MeetToday = (props)=>{
  return (
    <View style={{ backgroundColor: 'rgba(255,50,92,.6)', height: 32, justifyContent: 'center', alignContent: 'center', position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
      <Text style={{ fontWeight: 'bold', color: '#ffffff', textAlign: 'center', fontSize: 15 }}>今日会えるかも</Text>
    </View>
  );
}

export const LeftArrow = (props)=>{
  const { show, onPress } = props;
  if(show){
    return (
    <TouchableOpacity style={UserInfoStyles.prevIcon} onPress={()=>{
      if(onPress) onPress();
    }}>
      <Image resizeMode={'cover'} style={{width:24, height:38}} source={require('../../assets/images/icon/arrow-prev.png')}/>
    </TouchableOpacity>
    )
  }
}

export const RightArrow = (props)=>{
  const { show, onPress } = props;
  if(show){
    return (
      <TouchableOpacity style={UserInfoStyles.nextIcon} onPress={()=>{
        if(onPress) onPress();
      }}>
        <Image resizeMode={'cover'} style={{width:24, height:38,}} source={require('../../assets/images/icon/arrow-next.png')}/>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  section:{
    marginBottom:12,
    padding:12,
    backgroundColor: '#fff',
    borderRadius:8,
    justifyContent:'center',
    alignContent:'center',    
  },
  h3:{
    fontSize: 16,
    paddingBottom:12,
    color:'#484848',
    fontWeight:"700",
    margin:0
  },
  label:{
    marginTop:8,
    marginRight:8,
    marginBottom:8,
    color:'#484848',
    fontSize:16,
    lineHeight:22,
  },
  text:{
    margin:8,
    fontSize:16,
    maxWidth:180,
    lineHeight:22,
  },
  infoLabel:{
    flex:1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',
  },
  infoText:{
    maxWidth:200,
    alignItems:'flex-end',
  },
  infoList:{
    flexDirection:'row', 
    alignSelf:'stretch',
    borderBottomWidth:.55, 
    borderBottomColor:'#e6e6e6'
  },
  infoListLast:{
    flexDirection:'row', 
    alignSelf:'stretch',    
  },
  downIcon:{
    position:'absolute',
    right:10,
    padding:0,
    fontSize:14,
    color:'#070706', 
    lineHeight:25,
  },
  browseIcon:{
    marginTop: -5,
    position:'absolute',
    right:40,
    backgroundColor: "#5cc8ca",
    padding: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#5cc8ca",
    maxWidth:140,
  },
  browseText:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center'
  },
  rightIcon:{
    fontSize:14,
    marginRight:5,
    marginTop:2
  },
  yellowCard:{
    width: '95%', 
    ...Platform.select({
      web:{
        marginLeft: 'auto', 
        marginRight: 'auto',
        display: 'block', 
        aspectRatio: 1050 / 131,
      },
      android:{
        marginLeft: 'auto', 
        marginRight: 'auto',
        height:45,
      },
      ios:{
        marginLeft: 'auto', 
        marginRight: 'auto',
        height:45,
      }
    })
  },
});