import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView, StyleSheet, KeyboardAvoidingView, Dimensions, Platform } from 'react-native';
import { FormTextInputBorder } from '../../components/form/FormInput';
import { Formik } from 'formik'
import Validation from '../../constants/Validation';
import styles from '../../styles/FormStyles';
import HeaderOptions from '../../constants/HeaderOptions';
import GoBackIcon from '../../components/common/GoBackIcon';
import CommonFunction from '../../utils/CommonFunction';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import Reminder20Api from '../../services/api/Reminder20Api';

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
let keyboardVerticalOffset = 85;
if(screenWidth<=375 && screenHeight<=667) keyboardVerticalOffset = 64;

export default class ActiveReminderScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.initialValues = {
      activeCode : '',
      newPassword : '',
      confirmPassword : '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  setHeader(){
    this.navigation?.setOptions({
      headerStyle: HeaderOptions.HeaderNoBorder2,
      headerTitle: '',
      headerLeft: () => <GoBackIcon navigation={this.navigation} />
    });
  }

  onSubmit = async (values) => {
    try{
      CommonFunction.overLay();
      const token = this.props?.route?.params?.token;
      const mail = this.props?.route?.params?.mail;
      Reminder20Api.setCheckAccessFlg(false);
      const changePassResponse = await Reminder20Api.PasswordPutVerificationCode(
        token, 
        values['activeCode'],
        mail,
        values['newPassword']
      );
      Reminder20Api.resetCheckAccessFlg();
      if(changePassResponse!=undefined){
        if(changePassResponse.status==1){
          this.popup?.showPopup();
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    return (
      <KeyboardAvoidingView 
      style={[styles.container,{flexGrow:1}]}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      >
      <ScrollView style={[styles.ScrollContainer,{backgroundColor:'#fff'}]}>
        <View style={[styles.HeadFormSection, {paddingTop:60, paddingLeft:30, paddingRight:30,}]}>
          <Text style={[styles.txtHeadForm, {marginBottom:18,}]}>パスワード再設定</Text>
          <Text style={[styles.labelText, {fontWeight:'normal', lineHeight:22,letterSpacing:-1,}]}>
            ご登録のメールアドレスに認証コードを{"\n"}送信しました。{"\n"}認証コードと新しいパスワードを入力してください。</Text>
        </View>
        <Formik
          initialValues={this.initialValues}
          validationSchema={Validation.SettingPassword}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.onSubmit(values) }
        >
        {({ values, handleChange, errors, setFieldTouched, touched, dirty ,isValid, handleSubmit }) => (
          <Fragment>
            <View style={[styles.BodyContent, {padding:30,}]}>
              <View style={[styles.labelView, {marginTop:0, marginBottom:5,}]}><Text style={styles.labelText}>コードを入力してください</Text></View>
              <FormTextInputBorder fieldName="activeCode" placeholder=""/>

              <View style={[styles.labelView, {marginTop:45, marginBottom:5}]}>
                <View style={{marginTop:15, marginBottom:5,}}>
                  {errors.newPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12,}]}>※{errors.newPassword}</Text>) : null}
                  {errors.confirmPassword ? (<Text style={[styles.labelText, {fontWeight:'normal', color:'#f04c4c', fontSize:12,}]}>※{errors.confirmPassword}</Text>) : null}
                </View>
                <Text style={styles.labelText}>新しいパスワード</Text>
                <Text style={{fontSize:12,}}>※4～8文字の英数字で設定してください</Text>
              </View>
              <FormTextInputBorder fieldName="newPassword" secureTextEntry={true} showerror={false} placeholder=""/>

              <View style={[styles.labelView, {marginTop:30, marginBottom:5,}]}><Text style={styles.labelText}>新しいパスワード（確認）</Text></View>
              <FormTextInputBorder fieldName="confirmPassword" secureTextEntry={true} showerror={false} placeholder=""/>

              <View style={[styles.labelView, {marginTop:30,}]}>
                <Text style={{fontSize:12, fontWeight:'normal', lineHeight: 19, color:'#484848'}}>※「1111」のようなゾロ目のパスワード、{"\n"}連番のパスワードはご利用いただけません。</Text>
              </View>

              <View style={[styles.buttonPanelSetting, {marginTop:40,}]}>
                <TouchableOpacity 
                  style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} 
                  disabled={!(dirty)}
                  onPress={handleSubmit}>
                  <Text style={styles.loginText}>設定する</Text>
                </TouchableOpacity>
              </View>

            </View>
          </Fragment>
        )}          
        </Formik>
      </ScrollView>
      <Popup ref={ref=>this.popup=ref} modal={true}>
        <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
        }} />
        <View style={innerStyles.popupContainer}>
          <View style={innerStyles.inner}>
            <Text style={innerStyles.innerTxt}>新しいパスワードを認定しました。</Text>
          </View>
          <View style={innerStyles.btnContainer}>
            <TouchableOpacity style={innerStyles.okBtn} onPress={()=>{ 
              this.popup.hidePopup();
              this.navigation.navigate('Login');
            }}>
              <Text style={innerStyles.txtOkBtn}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Popup>
      </KeyboardAvoidingView>
    );
  }
}

const innerStyles = StyleSheet.create({
  popupContainer:{
    justifyContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    alignContent:'center', 
    padding: 0, 
    width:'75%', 
    backgroundColor:'#fff', 
    borderRadius: 12,
  },
  inner:{
    paddingTop:25, 
    paddingBottom:18, 
    paddingLeft:20, 
    paddingRight:20, 
    width:'100%',
  },
  innerTxt:{
    fontSize:14, 
    fontWeight:'normal', 
    color:'#484848', 
    lineHeight:20, 
    textAlign:'center',
  },
  btnContainer:{
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
    width:'100%'
  },
  okBtn:{
    width:'50%', 
    marginBottom:20, 
    padding:5, 
    backgroundColor:'#ebebeb', 
    borderRadius:999
  },
  txtOkBtn:{
    fontSize:14, 
    fontWeight:'normal', 
    color:'#484848', 
    lineHeight:20, 
    textAlign:'center'
  }
});