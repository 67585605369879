import React, { Component } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MeetScreen from '../../screens/MeetScreen';
import MeetCalendarScreen from '../../screens/MeetCalendarScreen';
import UserInfoSwiperScreen from '../../screens/UserInfoSwiperScreen';
import ReportScreen from '../../screens/ReportScreen';
import ChatHideSettingScreen from '../../screens/chat/ChatHideSettingScreen';
import UserInfoScreen from '../../screens/UserInfoScreen';
import ChatRoomScreen from '../../screens/chat/ChatRoomScreen';
import PointShoppingStack from './PointShoppingStack';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import CommonFunction from '../../utils/CommonFunction';
import UserSettingImageScreen from '../../screens/UserSettingImageScreen';
import TransitionSpec from '../../constants/TransitionSpec';

const Stack = createStackNavigator();

export default class MeetStack extends Component {
  render() {
    return (
      <ErrorBoundary {...this.props}>
      <Stack.Navigator initialRouteName="MeetList" screenOptions={{
        gestureEnabled: CommonFunction.gestureEnabled(),
        animationEnabled: CommonFunction.animationEnabled(),
        transitionSpec: TransitionSpec
      }}>
        <Stack.Screen 
          name="MeetList" 
          children={(props)=><MeetScreen {...props} setBadge={this.props?.setBadge} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="MeetCalendar" 
          children={(props)=><MeetCalendarScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
         <Stack.Screen 
          name="Profile" 
          children={(props)=><UserInfoSwiperScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="SameProfile" 
          children={(props)=><UserInfoScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatRoom" 
          children={(props)=><ChatRoomScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="Report" 
          children={(props)=><ReportScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen 
          name="ChatHideSetting" 
          children={(props)=><ChatHideSettingScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
        <Stack.Screen name="PointShoppingStack" component={PointShoppingStack} options={{headerShown: false}}/>
        <Stack.Screen 
          name="UserSettingImage" 
          children={(props)=><UserSettingImageScreen {...props} BottomTabNavigation={this.props.navigation} />} 
          options={{headerShown: true}}
        />
      </Stack.Navigator>
      </ErrorBoundary>
    );
  }
}