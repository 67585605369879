import React,{ useState} from 'react';
import {Platform, StyleSheet, Text, View, TextInput, Modal, TouchableOpacity} from 'react-native';
import {useField, useFormikContext, Field } from 'formik';
import DatePickerInput from './DatePickerInput';
import RadioInput from './RadioInput';
import DialogListInput from './DialogListInput';
import ProfileImagePicker from './ProfileImagePicker';
import UploadImagePicker from './UploadImagePicker';
import UploadProfileImagePicker from './UploadProfileImagePicker';
import FreeIine from '../../screens/iine/FreeIine';
import ListInput from '../../components/form/ListInput';
import ListInputNew from '../../components/form/ListInputNew';
import UserListInput from '../../components/form/UserListInput';
import { Entypo } from '@expo/vector-icons'; 
import { CheckBox } from 'react-native-elements';
import { Switch } from 'react-native-paper';
import UploadChatImagePicker from './UploadChatImagePicker';
import UploadInquiryImagePicker from './UploadInquiryImagePicker';
import SwitchToggle from "react-native-switch-toggle";
import {Picker} from '@react-native-picker/picker';
import UploadCameraPicker from './UploadCameraPicker';

const inputAccessoryViewID = "inputAccessoryView1";

export function DialogGroupInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);

  return (    
    <View style={{width:'100%'}}>
      <DialogListInput 
        value={field.value}
        onChange={field.onChange(fieldName)}
        onBlur={field.onBlur(fieldName)}
        title="選択してください"
        text_ok="OK"
        text_cancel="キャンセル"
        {...props}
      />
      {!props.NotdisplayError && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function RadioGroupInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);

  return (    
    <View style={{width:'100%'}}>
      <RadioInput 
        onChange={field.onChange(fieldName)}
        onBlur={field.onBlur(fieldName)}
        {...props}
      />
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FreeIineInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <FreeIine 
        onChange={field.onChange(fieldName)}
        {...props}
      />
  );
}

export function ProfileImageInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <ProfileImagePicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref)}
    />
  );
}

export function UploadImageInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <UploadImagePicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref)}
    />
  );
}

export function UploadInquiryImageInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <UploadInquiryImagePicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref)}
    />
  );
}

export function UploadProfileImageInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <UploadProfileImagePicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref, fieldName)}
    />
  );
}

export function UploadChatImageInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <UploadChatImagePicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref)}
    />
  );
}

export function FormDateInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);

  return (
    
    <View style={{width:'100%'}}>
      <DatePickerInput
        value={field.value}
        onChangeText={field.onChange(fieldName)}
        onBlur={field.onBlur(fieldName)}
        {...props}
      />
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormTextInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);

  return (
    <View style={{width:'100%'}}>
      <View style={styles.textView}>
        <TextInput
          style={styles.textInput}
          value={field.value}
          onChangeText={field.onChange(fieldName)}
          onBlur={field.onBlur(fieldName)}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
          {...props}
        />
      </View>
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormTextInputWithStyle({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  let showerror = true;
  if(props.showerror!=undefined) showerror = props.showerror;

  return (
    <View style={{width:'100%'}}>
      <View style={styles.textView}>
        <TextInput
          style={styles.textInput}
          value={field.value}
          onChangeText={field.onChange(fieldName)}
          onBlur={field.onBlur(fieldName)}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
          {...props}
        />
      </View>
      {showerror && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormTextInputBorder({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  let showerror = true;
  if(props.showerror!=undefined) showerror = props.showerror;

  return (
    <View style={{width:'100%'}}>
      <View style={styles.textViewBorder}>
        <TextInput
          style={styles.textInputBorder}
          value={field.value}
          onChangeText={field.onChange(fieldName)}
          onBlur={field.onBlur(fieldName)}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
          {...props}
        />
      </View>
      {showerror && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormSelectInput({fieldName, ...props}) {
  const [field, meta, helpers] = useField(fieldName);
  const { setFieldValue } = useFormikContext();
  const { value } = meta;
  const { setValue } = helpers;
  const pickerItems = props.items.map( (s, i) => {
    return <Picker.Item label={s.value} value={s.id} key={s.id} />
  });

  return (
    <View>
      <Picker
        testID={fieldName}
        style={
        Platform.OS === 'ios'
              ? styles.inputIOS
              : styles.inputAndroid
            }
        selectedValue={field.value}
        //onValueChange={setFieldValue(fieldName,value)}
        onValueChange={setValue}
        onBlur={field.onBlur(fieldName)}
        mode='dialog'
      >
        {pickerItems}
      </Picker>
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormSwitchInput({fieldName, ...props}) {
  const [field, meta, helpers] = useField(fieldName);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <View style={{width:'80%'}}>
        <Switch
        style={{paddingTop:0}}
        value={field.value}
        onValueChange={setValue}
        onBlur={field.onBlur(fieldName)}
        color="#5cc8ca"
      />
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormSwitchToggleInput({fieldName, ...props}) {
  const [field, meta, helpers] = useField(fieldName);
  const { value } = meta;
  const { setValue } = helpers;
  const disabled = props.disabled;

  const getValue = (value)=>{
    if(disabled===true) return false;
    if(value===false || value==0 || value==undefined) return false;
    else return true;
  }
  
  let [isOn, setIsOn] = useState(getValue(field.value));
  isOn = getValue(value);

  const handleOnPress = ()=>{
    if(disabled===true) {
      props.settingNone();
      return;
    }
    const value = !isOn;
    setValue(value);
    setIsOn(value);
  }

  return (
    <View style={{width:'80%'}}>
      <SwitchToggle
        switchOn={isOn}
        onPress={() => handleOnPress()}
        circleColorOn='#fefefe'
        circleColorOff='#fefefe'
        backgroundColorOn='#5cc8ca'
        backgroundColorOff='#fdfdfd'
        containerStyle={{
          marginTop: 0,
          width: 47,
          height: 29,
          borderRadius: 999,
          padding: isOn? 2 : 0,
          borderWidth: isOn? 1 : 2,
          borderColor: isOn? '#5cc8ca' : '#e4e4e4'
        }}
        circleStyle={{
          width: isOn? 26 : 29,
          height: isOn? 26 : 29,
          borderRadius: 999,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: isOn? 0.22 : 0.48,
          shadowRadius: isOn? 2.22 : 4.8,
          elevation: isOn? 3 : 3,
        }}
      />
    </View>
  );
}

export function PickerListInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  
  return (    
    <View style={{width:'100%'}}>
      <ListInput 
      value={field.value}
      onChange={field.onChange(fieldName)}
      onBlur={field.onBlur(fieldName)}
      {...props} />

      {!props.NotdisplayError && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function PickerListInputNew({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  const { setFieldValue } = useFormikContext();
  return (    
    <View style={{width:'100%'}}>
      <ListInputNew 
      fieldName={fieldName}
      value={field.value}
      onChange={setFieldValue}
      {...props} />
    </View>
  );
}

export function PickerUserListInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  
  return (    
    <View style={{width:'100%'}}>
      <UserListInput 
      value={field.value}
      onChange={field.onChange(fieldName)}
      onBlur={field.onBlur(fieldName)}
      {...props} />

      {!props.NotdisplayError && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

/*
export function CheckboxInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  const [checked, setChecked] = React.useState(false);
  const { setFieldValue } = useFormikContext();

  return (   
    <View>
      <Checkbox.Android
        status={checked ? 'checked' : 'unchecked'}
        onPress={() => {
          setChecked(!checked);
          setFieldValue(fieldName, !checked);
        }}
      />
    
      {!props.NotdisplayError && meta.error && meta.touched && (
        <Text style={{color: '#DA4453',marginTop:10}}>{meta.error}</Text>
      )}
    </View>
     
  );
}
*/

export function CheckboxInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  const [checked, setChecked] = React.useState(field.value);
  const { setFieldValue } = useFormikContext();

  return (   
    <View>
      <CheckBox
        checked={checked}
        checkedColor='#5cc8ca'
        onPress={() => {
          setChecked(!checked);
          setFieldValue(fieldName, !checked);
        }}
        {...props}
      />
    
      {!props.NotdisplayError && meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function FormPasswordInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  const [icon, setIcon] = React.useState('eye');
  const [password, setPassword] = React.useState(true);

  const _changeIcon = () => {
    setPassword(!password);
    if(icon==='eye'){
      setIcon('eye-with-line');
    }else{
      setIcon('eye');
    }
  }

  return (
    <View style={{width:'100%'}}>
      <View style={[styles.textView,{flexDirection:'row'}]}>
        <View style={{flex:1,height:'100%',justifyContent:'center'}}>
        <TextInput
          style={styles.textInput}
          value={field.value}
          onChangeText={field.onChange(fieldName)}
          onBlur={field.onBlur(fieldName)}
          secureTextEntry={password}
          placeholderTextColor="#9aa5a7"
          inputAccessoryViewID={inputAccessoryViewID}
          {...props}
        />
        </View>
         <View style={{alignItems:'flex-end',height:'100%',justifyContent:'center',marginRight:10}}>
            <Entypo name={icon} size={24} color="black" onPress={()=>_changeIcon()} style={{paddingTop:15,paddingBottom:15,lineHeight:20}} />
        </View>
      </View>
      {meta.error && meta.touched && (
        <Text style={styles.errorMsg}>{meta.error}</Text>
      )}
    </View>
  );
}

export function SelectButtonInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  const { setFieldValue } = useFormikContext();
  let defaultValue = props.values?props.values: false;
  const [selected, setSelected] = React.useState(defaultValue);

  if(props.values!==selected) setSelected(props.values);

  return (
    <View>
    {selected===true ?
      <View style={[styles.formSelected, {flexDirection:'row'}]}>
        <TouchableOpacity style={[styles.btn,styles.btnUnselected]} onPress={()=>{ 
          setFieldValue(fieldName, false);
          setSelected(false);
          }}>
          <Text style={styles.btnUnselectedText}>未登録</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.btn,styles.btnSelected]} onPress={()=>{
          setFieldValue(fieldName, true);
          setSelected(true);
          }}>
          <Text style={styles.btnSelectedText}>登録済</Text>
        </TouchableOpacity>
      </View>
      :
      <View style={[styles.formSelected, {flexDirection:'row'}]}>
        <TouchableOpacity style={[styles.btn,styles.btnSelected]} onPress={()=>{ 
          setFieldValue(fieldName, false);
          setSelected(false);
          }}>
          <Text style={styles.btnSelectedText}>未登録</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.btn,styles.btnUnselected]} onPress={()=>{
          setFieldValue(fieldName, true);
          setSelected(true);
          }}>
          <Text style={styles.btnUnselectedText}>登録済</Text>
        </TouchableOpacity>
      </View>
    }
    </View> 
  );
}

export function UploadCameraInput({fieldName, ...props}) {
  const [field, meta] = useField(fieldName);
  return (    
    <UploadCameraPicker 
      value={field.value}
      onChange={field.onChange(fieldName)}
      {...props}
      ref={(ref)=>props.setRef(ref)}
    />
  );
}

const styles=StyleSheet.create({
  select: {
    justifyContent:"center",
    width:"100%",
    height:50,
    backgroundColor:"#eef1f2",
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  textView:{
    justifyContent:"center",
    width:"100%",
    height:50,
    backgroundColor:"#eef1f2",
    borderBottomColor:'#5cc8ca',
    borderBottomWidth:1,
    borderTopLeftRadius:5,
    borderTopRightRadius:5,
  },
  textInput:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    color:"#000",
    ...Platform.select({
      web:{
        lineHeight:20,
      }
    })
  },
  textViewBorder:{
    justifyContent:"center",
    width:"100%",
    height:50,
    backgroundColor:"#fff",
    borderColor:'#9aa5a7',
    borderWidth:1,
    borderRadius:5,
  },
  textInputBorder:{
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:16,
    paddingRight:16,
    color:"#000",
    ...Platform.select({
      web:{
        lineHeight:20,
      }
    })
  },
  inputIOS: {
    backgroundColor: '#eef1f2',
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: '#5cc8ca',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    backgroundColor: '#eef1f2',
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: '#5cc8ca',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  errorMsg:{
    fontSize:12,
    color:'#da4453',
    marginTop:10
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca'
  },
  btn:{
    marginTop:5,
    marginBottom:5,
    marginLeft:3,
    paddingTop: 10,
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15,
    fontSize:14,
    lineHeight:19,
    letterSpacing:-0.4,
    borderWidth: 1,
    borderRadius: 1,
    justifyContent:'center'
  },
  btnSelected:{
    backgroundColor:'#5cc8ca',
    borderColor:'#5cc8ca',
  },
  btnSelectedText:{
    color:'#fff',
  },
  btnUnselected:{
    backgroundColor:'#fff',
    borderColor:'#9aa5a7'
  },
  btnUnselectedText:{
    color:'#9aa5a7',
  },
});