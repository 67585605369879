import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, StatusBar
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import { Portal } from 'react-native-paper';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';

export default class PointShoppingDetailScreen extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      showAlertConfirm: false
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      this.setHeader();
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader(){
    this.navigation.setOptions({
      headerStyle: HeaderOptions.HeaderSetting,
      headerTitleAlign:'center',
      headerTitle: () => (
        <View style={{width:275}}><Text style={HeadStyle.headerTitlePage} numberOfLines={1}>ポイント購入の申し込み</Text></View>
      ),
      headerLeft: () => (
        <AntDesign name={'left'} style={HeadStyle.icClose} onPress={ () => this.navigation.goBack() } />
      ),
      headerRight: () => (
        <View/>
      )
    });
  }

  render() {
  
    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            
            <View>
              <Text style={styles.titleHeader}>申し込みフォーム</Text>
            </View>

            <View style={styles.txtDetail}>
              <Text style={{flex:1, flexDirection:'row',}}>
                <Text style={styles.txt12}>
                お申し込み内容に問題がなければ、</Text>
                <Text style={[styles.txt12, {fontWeight:'bold',}]}>この内容で申し込む</Text>
                <Text style={styles.txt12}>ボタンを押してください。</Text>
              </Text>
              <Text style={[styles.txt12, {marginBottom:20,}]}>お支払いを完了することで、ポイントを取得します。</Text>
              <Text style={styles.txt12}>※ ポイントは購入から180日間有効となります。</Text>            
            </View>

            <View style={styles.blocDetail}>
              <Text style={styles.titleBlocDetail}>プラン</Text>
              <Text style={styles.txtBlocDetail}>100ポイントプラン</Text>
            </View>
            <View style={styles.blocDetail}>
              <Text style={styles.titleBlocDetail}>取得ポイント</Text>
              <Text style={styles.txtBlocDetail}>100ポイント</Text>
            </View>
            <View style={styles.blocDetail}>
              <Text style={styles.titleBlocDetail}>利用料金</Text>
              <Text style={styles.txtBlocDetail}>10,400円(税込)</Text>
            </View>
            <View style={styles.blocDetail}>
              <Text style={styles.titleBlocDetail}>お支払い方法</Text>
              <Text style={styles.txtBlocDetail}>銀行振込</Text>
            </View>
            <View style={{marginTop:15,padding:15,}}>
              <TouchableOpacity style={styles.btnApply} onPress={()=>{
                //CommonFunction.overLay();
                this.setState({showAlertConfirm: true });
              }}>
                <Text style={styles.txtBtnApply}>この内容で申し込む</Text>                 
              </TouchableOpacity>
            </View>
          </View>         

          {this.state.showAlertConfirm &&
          <Portal style={styles.portal}>
          <View style={styles.portalContainer}>
            <View style={styles.overlay}
            onStartShouldSetResponder={() => {
             this.setState({showAlertConfirm: false});
            }} />
           <View style={styles.blocPopup}>
             <View style={styles.blocPopupInner}>
               <Text style={styles.txtPopup}>この内容でお申し込み手続きを行いますが、よろしいですか？</Text>
               <View style={styles.btnArea}>
                 <TouchableOpacity style={styles.btnPopupCancel} onPress = {()=>{ 
                   this.setState({showAlertConfirm: false});
                   this.navigation.navigate('PointShoppingComplete') 
                   }}>
                   <Text style={styles.txtBtnPopupCancel}>はい</Text>
                 </TouchableOpacity>
                 <TouchableOpacity style={styles.btnPopupConfirm}>
                   <Text style={styles.txtBtnPopupConfirm}>いいえ</Text>
                 </TouchableOpacity>
               </View>
             </View>
           </View>
         </View>
         </Portal>
 
          }

        </ScrollView>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    flex:1,
    height:500,
    backgroundColor:'#ffffff',
  },
  blogInner: {
    paddingTop:16,
    paddingBottom:20,
  },
  titleHeader: {
    paddingLeft:15,
    textAlign:'left',
    fontSize:18,
    fontWeight:'bold',
    lineHeight:36,
  },
  txtDetail: {
    flex:1,
    flexDirection:'column',
    padding:15,
  },
  txt12: {
    fontSize:12,
    lineHeight:18,
  },
  blocDetail: {
    paddingTop:10,
    paddingRight:15,
    paddingLeft:15,
  },
  titleBlocDetail: {
    paddingTop:8,
    paddingRight:12,
    paddingLeft:12,
    fontSize:12,
    fontWeight:'bold',
    lineHeight:12,
  },
  txtBlocDetail: {
    paddingTop:8,
    paddingRight:12,
    paddingLeft:12,
    fontSize:12,
    lineHeight:12,
  },
  btnApply:{
    height:50,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
    alignItems:"center",
    justifyContent:"center",
    textAlign:'center',
  },
  txtBtnApply:{
    fontSize:12,
    fontWeight:'bold',
    lineHeight:50,
    color:'#fff',
    letterSpacing:1,
  },portal:{
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  portalContainer:{
    position:'absolute',
    top:0,
    left:0,
    height:'100%',
    width:'100%',
    justifyContent:'center'
  },
  overlay:{
    height: '100%',
    width:'100%',
    backgroundColor:'#000000',
    opacity:0.5,
    position:'absolute',
    top:0,
    left:0,
  },
  blocPopup: {
    alignItems:'center',
    width:'100%',
  },
  blocPopupInner: {
    padding:20,
    width:'90%',
    backgroundColor:'#ffffff',
    borderRadius:8,
  },
  txtPopup: {
    padding:10,
    textAlign:'center',
    fontSize:12, 
    lineHeight:14,
  },
  btnArea: {
    display:'flex',
    flexDirection:'row',
    flexWrap:'nowrap',
    borderColor:'#ccc',
    borderWidth:1,
    textAlign:'center',
  },  
  btnPopupCancel: {
    flex:1,
    margin:1,
    paddingTop:15,
    paddingBottom:15,
    backgroundColor:'#f5f5f5',
  },
  txtBtnPopupCancel: {
    color:'#000000',
    fontSize:14,
    lineHeight:16,
    textAlign:'center',
  },
  btnPopupConfirm: {
    flex:1,
    margin:1,
    paddingTop:15,
    paddingBottom:15,
    backgroundColor:'#f5f5f5',
    borderLeftColor:'#cccccc',
    borderLeftWidth:1,
  },
  txtBtnPopupConfirm: {
    color:'#000000',
    fontSize:14,
    lineHeight:16,
    textAlign:'center',
  },
});

