import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import ConfirmPopupStyles from '../../styles/ConfirmPopupStyles';

export default class GoodUsePoint extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      message: 'いいねがありません。1pt消費していいねを送信しますか？'
    }
    this.PopUp = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation, modal, sendForceSubtractGood } = this.props;
      this.navigation = navigation;
      this.modal = (modal!=undefined)?modal:true;
      this.sendForceSubtractGood = sendForceSubtractGood;
      this.setState({loading: false});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = ()=>{
    this.PopUp?.showPopup();
  }

  hide = ()=>{
    this.PopUp?.hidePopup();
  }

  isShow = ()=>{
    return this.PopUp?.isShow();
  }

  setMessage = (message)=>{
    this.setState({message: message});
  }

  render(){
    if(this.state.loading===true) return null;

    return (
      <Popup ref={ref=>this.PopUp=ref} modal={this.modal}>
        <View style={PortalStyle.overlay} />
        <View style={[ConfirmPopupStyles.popupContainer,{ width:'85%'}]}>
          <View style={ConfirmPopupStyles.popupTextItem}>
            <Text style={ConfirmPopupStyles.popupText}>{this.state.message}</Text>
          </View>
          <View style={ConfirmPopupStyles.btnContainer}>
            <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderRight]} onPress={()=>{
              this.hide();
            }}>
              <Text style={ConfirmPopupStyles.btnText}>キャンセル</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderLeft]} onPress={()=>{
              this.hide();
              if(this.sendForceSubtractGood!=undefined){
                this.sendForceSubtractGood();
              }
            }}>
              <Text style={ConfirmPopupStyles.btnText}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Popup>
    );
  }
}