// disable animation for android
const animationConfig = {
  animation: 'timing',
  config: {
    duration: 0
  },
};
const TransitionSpec = {
  open: animationConfig,
  close: animationConfig
}

export default TransitionSpec;
