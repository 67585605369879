import { StyleSheet, Text, View, Image } from 'react-native';
//import Moment from 'moment';

export default function BlockUserRowFormat(props) {
  const { item } = props;
  let mainImageUrl = '';
  let onlineId = 0;
  //let messageDate = item?.messageDatetime?.replaceAll("/","-");

  if(item.mainImageUrl!=undefined) mainImageUrl = item.mainImageUrl;
  if(item.online!=undefined) onlineId = item.online?.id;

  return (
    <View style={styles.profileContainer}>
      <View>
        <Image style={styles.myProfileThumbnail} source={{uri : mainImageUrl}} />
      </View>
      <View style={styles.rightProfile}>
        <View style={{flexDirection:'row'}}>
          <Text style={styles.name}>{item.name}</Text>
        </View>
        <View style={{flexDirection:'row'}}>
          <Text style={styles.profileDesc}>{item.age} {item.currentPref}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  profileContainer: {
    flexDirection:'row',
    flex:1,
    paddingTop:10,
    paddingRight:16,
    paddingBottom:10,
    paddingLeft:16,
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  myProfileThumbnail:{
    marginRight:12,
    width:80,
    height:80,
    borderRadius:10,
  },
  rightProfile:{
    width: '70%',
  },
  rightRow: {
    position:'relative',
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },  
  name: {
    marginRight:20,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
  },
  profileDesc: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
  },
  btnGray:{
    width:'100%',
    marginTop: 10,
    padding:8,
    height:36,
    backgroundColor:'#9ca4a6',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnGray:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#fff',
    textAlign:'center',
  }
});
