import React, { Component } from 'react';
import { Dimensions, Text, View, TouchableOpacity, StyleSheet, SafeAreaView, Image, Platform } from 'react-native';
import ChatMatching from './chat/ChatMatching';
import ChatConversation from './chat/ChatConversation';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import TabbarOptions from '../constants/TabbarOptions';
import HeaderOptions from '../constants/HeaderOptions';
import HeadStyle from '../styles/HeadStyles';
import TabBadge from '../components/common/TabBadge';
import CommonFunction from '../utils/CommonFunction';
import Storage from '../utils/StorageHelper2';
import Logo from '../components/common/Logo';
import FavoriteSearch from '../components/common/FavoriteSearch';
import FavoriteStatus from '../components/common/FavoriteStatus';
import FavoriteNameSetting from '../components/common/FavoriteNameSetting';
import MailBoxApi from "../services/api/MailBox05Api";

const Tab = createMaterialTopTabNavigator();
const windowWidth = Dimensions.get('window').width;

export default class ChatScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.FavoriteSearchRef = React.createRef();
    this.FavoriteNameSettingRef = React.createRef();
    this.tab = { 
      num: 2, 
      width: 100, 
    };
    this.tabBarItemWidth = windowWidth / this.tab.num;
    this.left = ((windowWidth / this.tab.num) - this.tab.width) / 2;
    this.sortOrder = { matching: 'Matching', conversation: 'Conversation' };
    this.mailSortOrder = { matching: 0, conversation: 1 };
    this.namesId = { single: 1, double: 2, triple: 3 };
    this.tab1 = 'マッチング中';
    this.tab2 = 'やりとり中';
    this.search = '絞り込み中';
    this.searchNonRes = '絞り込み中(未返信)';
    this.title = 'Chat'; // web title page
    this.favoriteNone = 0;
    this.state = {
      badge: {
        unreadMatchCount: 0, 
        unreadReplyCount: 0
      },
      parentState: {},
      borderBottomWidth: 1,
      show: false
    }
    this.currentOrder = 0,
    this.favoriteSearch = 0;
    this.keyFavoriteSearch = 'FavoriteSearch';
    this.favoriteNames = {
      single: '',
      double: '',
      triple: '',
   };
  }

  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      this.navigation = this.props?.navigation;
      this.setHeader();
      this.getSearchSetting();

      // focus screen
      this.subscribeFocus = this.navigation.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        // show bottom tab
        CommonFunction.showBottomTab(this.navigation);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }

  setHeader = () =>{
    let dispTitle = '';
    if (this.favoriteSearch>this.favoriteNone) {
      if (this.favoriteSearch>=4) {
        dispTitle = this.searchNonRes;
      } else {
        dispTitle = this.search;
      }
    } else {
      if(this.currentOrder == this.mailSortOrder.matching){
        dispTitle = this.tab1;
      }else{
        dispTitle = this.tab2;
      }
    }

    this.props.navigation?.setOptions({
      header:()=>(        
        <View style={[HeaderOptions.HeaderIine, HeadStyle.appHeaderTop, { flexDirection:'row'}]}>
          <View style={{height:'100%',justifyContent:'center'}}><Logo/></View>
          <View style={styles.headerTitleCenter}>
            <Text style={{fontSize:windowWidth<375? 13:15}}>{dispTitle}</Text>
            <FavoriteStatus favorite={this.favoriteSearch} />
          </View>
          <View style={styles.favoriteImgBase}>
            <TouchableOpacity onPress={() => {
              this.setFavoriteFilterSearch();
            }}>
              <Image style={styles.favoriteBtnImg} source={require('../assets/images/favorite/favorite_4.png')}/>
            </TouchableOpacity>
          </View>
        </View>
      )
    });
  }
  
  getSearchSetting = async()=>{
    let mailSortOrder = await Storage.getData('mailSortOrder');
    if (mailSortOrder!=undefined) {
      this.currentOrder =  Number(mailSortOrder);
    } else {
      Storage.storeData('mailSortOrder', this.currentOrder);
    }

    let favoriteSearch = await Storage.getData(this.keyFavoriteSearch);
    if(favoriteSearch != undefined){
      this.favoriteSearch = Number(favoriteSearch);
    } else {
      Storage.storeObjectData(this.keyFavoriteSearch, this.favoriteNone);
      this.favoriteSearch = this.favoriteNone;
    }
    this.setState({ show: true });
    this.setHeader();
  }

  setActiveTab = async(sortOrder)=>{
    await Storage.storeData('mailSortOrder', sortOrder);
    this.currentOrder = sortOrder;
    this.setHeader();
  }

  setTopBadge = (badge)=>{
    this.setState({badge: badge});
  }

  getMatchingTabRef = ()=>{
    return this.tabMatchingRef;
  }

  getConversationTabRef = ()=>{
    return this.tabConversationRef;
  }

  parentState = (state=null)=>{
    if(state){
      this.setState({parentState: state});
    }else{
      return this.state.parentState;
    }
  }

  setTabBottomWidth = (borderBottomWidth=1)=>{
    this.setState({borderBottomWidth: borderBottomWidth});
  }

  setFavoriteFilterSearch = async()=>{
    const favoriteNameListResponse = await MailBoxApi.FavoriteNameList();
    if(!CommonFunction.checkResponseStatus(favoriteNameListResponse)) return;    
    if(favoriteNameListResponse?.status==1){
      const favoriteItems = favoriteNameListResponse?.response?.favorite.list;
      this.favoriteNames.single = favoriteItems[0].name;
      this.favoriteNames.double = favoriteItems[1].name;
      this.favoriteNames.triple = favoriteItems[2].name;
      const params = {selected: this.favoriteSearch, favoriteNames: this.favoriteNames};
      this.FavoriteSearchRef?.show(params);
    }
  }

  favoriteFilterUpdata = (params)=>{
    let favoriteFilter = params.favoriteFilter;
    Storage.storeObjectData(this.keyFavoriteSearch, favoriteFilter);
    this.favoriteSearch = favoriteFilter;
    this.setState({show: false});
    this.chatListRef();
  }

  favoriteNameSetting = (getParams)=>{
    const params = {selected: getParams.selected, slectNameChange: getParams.slectNameChange, favoriteNames: this.favoriteNames};
    this.FavoriteNameSettingRef?.show(params);
  }

  favoriteNameUpdata = (getParams)=>{
    if (getParams.slectNameChange!=undefined) {
      if (getParams.slectNameChange == this.namesId.single) {
        this.favoriteNames.single = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.double) {
        this.favoriteNames.double = getParams.updataFavoriteName;
      } else if(getParams.slectNameChange == this.namesId.triple) {
        this.favoriteNames.triple = getParams.updataFavoriteName;
      }
    }
    const params = {selected: this.favoriteSearch, favoriteNames: this.favoriteNames};
    this.FavoriteSearchRef?.show(params);
  }

  chatListRef() {
    this.getSearchSetting()
  }

  render() {
    if(!this.state.show) return null;
    let order;
    if (this.currentOrder==this.mailSortOrder.conversation) {
      order = this.sortOrder.conversation;
    }else{
      order = this.sortOrder.matching;
    }

    return (
      <SafeAreaView style={styles.container}>
      <Tab.Navigator
        initialRouteName= {order}
        screenOptions={{
          //title: this.title,
          lazy: true,
          swipeEnabled: false,
          tabBarLabelStyle: TabbarOptions.labelStyle,
          tabBarStyle: [TabbarOptions.style2, {borderBottomWidth: this.state.borderBottomWidth}],
          tabBarItemStyle:{ width: this.tabBarItemWidth },
          tabBarIndicatorStyle: [TabbarOptions.indicatorStyle, {width: this.tab.width, left: this.left}],
          tabBarActiveTintColor: TabbarOptions.activeTintColor,
          tabBarInactiveTintColor: TabbarOptions.inactiveTintColor,
          animationEnabled: false
        }}
        >
        <Tab.Screen name="Matching" 
          children={()=><ChatMatching ref={ref=>this.tabMatchingRef=ref} {...this.props} setBadge={this.props?.setBadge} setTopBadge={this.setTopBadge} parentState={this.parentState} otherTabRef={this.getConversationTabRef} setTabBottomWidth={this.setTabBottomWidth} />} 
          options={{tabBarLabel: this.tab1, tabBarBadge:()=> 
            <View style={{position:'absolute', top:4, right:30}}>
              <TabBadge badge={this.state.badge?.unreadMatchCount}/>
            </View>
          }} 
          listeners={{
            tabPress: (e) => {
              this.setActiveTab(this.mailSortOrder.matching);
            }
          }} 
        />
        <Tab.Screen name="Conversation" 
          children={()=><ChatConversation ref={ref=>this.tabConversationRef=ref} {...this.props} setBadge={this.props?.setBadge} setTopBadge={this.setTopBadge} parentState={this.parentState} otherTabRef={this.getMatchingTabRef} setTabBottomWidth={this.setTabBottomWidth} />} 
          options={{tabBarLabel: this.tab2, tabBarBadge:()=>
            <View style={{position:'absolute', top:4, right:37}}>
              <TabBadge badge={this.state.badge?.unreadReplyCount}/>  
            </View>            
          }} 
          listeners={{
            tabPress: (e) => {
              const tabRef = this.getMatchingTabRef();
              let checkSMS = true;
              if(tabRef){
                checkSMS = tabRef?.checkSMS();
                if(!checkSMS) e.preventDefault();
              }
              if(checkSMS) this.setActiveTab(this.mailSortOrder.conversation);
            }
          }} 
        />
      </Tab.Navigator>
      <FavoriteSearch ref={ref=>this.FavoriteSearchRef=ref} filterUpdata={this.favoriteFilterUpdata} nameSetting={this.favoriteNameSetting} />
      <FavoriteNameSetting ref={ref=>this.FavoriteNameSettingRef=ref} filterUpdata={this.favoriteNameUpdata} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({ 
  container:{
    flex:1,
    width:'100%',
    height:'100%',
    backgroundColor:'#ffffff'
  },
  favoriteImgBase:{
    flexBasis:windowWidth<375? 95:115,
    flexShrink:0,
    flexGrow:0,
    justifyContent:'center',
    marginRight:windowWidth<375? 2:4,
  },
  favoriteBtnImg:{
    width:windowWidth<375? 95:115,
    height:windowWidth<375? 28:34,
  },
  headerTitleCenter:{
    flexBasis:'auto',
    flexShrink:0,
    flexGrow:1,
    flexDirection:'row',
    alignSelf:'center',
    alignItems:'center',
    justifyContent:'center',
    height:'100%',
  }
});