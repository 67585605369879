import React,{ Component } from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import CommonFunction from '../../utils/CommonFunction';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import ListStyles from '../../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';

export default class TermsScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '利用規約';
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }

  setHeader = () =>{
    if(this.props.setHeader){
      this.props?.setHeader(this.title,this.navigation);
      return;
    }
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
            <View>
              <View style={styles.pMargin}><Text style={styles.titleInfo}>会員規約</Text></View>
              <View style={{marginRight:10, marginLeft:10}}>
                <Text style={[styles.font12, styles.padding5]}>
                Pappy（以下、Pappyといいます。）では、サービスに容易にアクセスしていただけるように、Pappyおよび関連サイトにて、シングル・ログイン機能（Pappyにログインすることでその他サービスへアクセスできる機能）を含む会員サービス（以下、本サービスといいます。）を提供します。{"\n"}
                お客様は、Pappyが定める会員規約（以下「本規約」といいます。）に基づいてアカウント（以下「アカウント」といいます。）を作成し、会員サービスをご利用いただくことができます。{"\n"}
                本サービスは「インターネット異性紹介事業を利用して児童を誘引する行為の規制等に関する法律」における「インターネット異性紹介事業」に該当します。同法律の定める児童(18歳未満)の利用を禁止します。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１条（Pappy会員）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>お客様は、Pappyが定める条件に従い、会員サービスを利用することができます。なお、お客様は、アカウントを通じて、Pappyが提供する各種サービスを利用することができますが、当該サービスの中には別途定める事項の登録を要する場合があり、また、あらかじめ当該サービスの利用規約等に同意していただく必要があります。</Text>
              </View>
            </View>
            
            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第２条（会員登録）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                  本規約に同意のうえ所定の登録手続きを完了すると、お客様とPappyとの間で本規約の定めを内容とする契約（以下「本契約」といいます。）が成立し、会員としての資格を得ることができます。会員登録手続きは、会員となるご本人が行ってください。本条に基づいて、会員資格を得たお客様のことを、以下「会員」といいます。{"\n"}
                  Pappyは、過去に会員資格が取り消された方やその他Pappyがふさわしくないと判断した方からの会員申込についてはお断りする場合があります。{"\n"}
                  登録手続きの際には、入力上の注意をよくご確認いただき、所定のフォームに必要事項を入力してください。ご登録は真実かつ正確な情報をもって行ってください。Pappyは、当該情報に虚偽、誤り、または記入漏れがあったことによりお客様に損害が生じたとしても、Pappyの責めに帰すべき事由がある場合を除き、責任を負わないものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第３条（会員資格および年齢確認）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、18歳以上でなければ全てのサービスをご利用いただけません。{"\n"}
                また、18歳以上であっても高校生など、就学中である場合にはご利用いただけない場合があります。{"\n"}
                会員登録申し込み後、「年齢または生年月日、証明書名、 発行元が明示された各種証明書」を提示（画像送信可）した後でなければ、本サービスをご利用いただけません。{"\n"}
                「年齢または生年月日、証明書名、 発行元が明示された各種証明書」とは、運転免許証、国民健康保険被保険者証のほか、健康保険被保険者証、共済組合員証、年金手帳、旅券、外国人登録証明書等の書面をいい、官公庁、会社、大学等が発行する身分証明書を指します。年齢確認が完了次第、お送りいただいた情報は速やかに破棄いたします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第４条（IDおよびパスワードの管理）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                アカウントを利用するためのIDおよびパスワードは、他人に知られることがないよう定期的に変更する等、会員本人が責任をもって管理してください。Pappyは、入力または利用されたIDおよびパスワードの組合せが会員の登録したものと一致することを所定の方法により確認した場合、会員による利用があったものとして会員サービスを提供するものとします。Pappyは、盗用、不正利用その他の事情により会員のアカウントを当該会員以外の第三者が利用している場合であっても、Pappyの責めに帰すべき事由がある場合を除き、それにより生じた損害について責任を負わないものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第５条（登録情報のサービスによる利用）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、会員がアカウントにて利用可能な全てのサイトおよびサービスにおいて、会員が登録したサービス上で掲載可能な情報の利用が可能であるものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第６条（会員のサービス上でやりとり）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員がアカウントを利用して行う、各サービス上でのメッセージ等のやりとりは、システムを介して会員とその相手方との間で直接に行われます。Pappyは、当該のやりとりについてPappyの責めに帰すべき事由がある場合を除き、取引に関する責任を負わないものとします。したがって、かかる場合を除き、やりとりに際し万一トラブルが生じた場合には、会員とその相手方との間で解決していただくことになります。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第７条（登録情報の変更）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員として登録した情報（以下「登録情報」といいます。）に変更が生じた場合は、速やかに登録情報の変更をお願いいたします。登録情報の変更がなされなかったことにより会員に生じた損害について、Pappyは、Pappyの責めに帰すべき事由がある場合を除き、責任を負わないものとします。また、変更がなされた場合でも、変更前にすでに手続きがなされた取引は、変更前の情報に基づいて行われます。登録情報の変更内容を当該取引の相手方に通知する必要がある場合には、会員より当該相手方に直接ご連絡下さい。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第８条（Pappyによるお知らせ）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員サービスに関するPappyから会員への通知等は、最新の登録情報におけるメールアドレス宛てへのメールの送信、Pappyおよび関連サイト上への掲示、または、その他Pappyが適当と判断する方法によって行います。{"\n"}
                会員は、Pappyがメールの送信による通知を行った場合、前項のメールアドレス宛てにPappyがメールを送信し会員が当該メールを閲覧可能になった時またはPappyがメールを送信してから24時間後のいずれか早い時点にPappyからの通知が到達したとみなされることに、あらかじめ同意し、前条の登録情報の変更を行うものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第９条（個人情報の取扱い）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、会員による会員サービスの利用に関して取得する個人情報を、Pappyの個人情報保護方針に従い、適切に取扱います。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１０条（禁止事項）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員サービスの利用に際して、会員は、以下の各号に定める行為またはそのおそれのある行為を行ってはならないものとします。会員が下記のいずれかの行為を行った場合は、事前の通知・承諾なく、本サービスの利用制限または退会処分などの必要措置を講じることができるものとします。その場合、当該措置理由の開示、受領済みの代金の返還は行わないものとします。{"\n"}
                ・法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為{"\n"}
                ・公の秩序または善良の風俗を害する行為{"\n"}
                ・他の会員に対しての詐欺行為、迷惑行為、脅迫・恫喝する行為（本サービスを通して知り得た本サービス外でのやりとりも対象とする）{"\n"}
                ・反社会的勢力に対する利益供与その他の協力行為{"\n"}
                ・Pappyおよび関連サイト、または第三者の権利、利益、名誉等を侵害する行為{"\n"}
                ・第三者になりすます行為または意図的に虚偽の情報を送信する行為{"\n"}
                ・第三者の個人情報その他のプライバシーに関する情報を不正に収集、開示、または提供する行為{"\n"}
                ・不正アクセス行為、第三者のアカウントを利用する行為、複数のアカウントを作成しまたは保有する行為、その他これらに類する行為{"\n"}
                ・会員サービスの誤作動を誘引する行為{"\n"}
                ・会員サービスが通常意図しないバグを利用する動作を生じさせ、または、通常意図しない効果を及ぼす外部ツールの利用、作成、または頒布を行う行為{"\n"}
                ・会員サービスまたはPappyのサーバーに過度の負担をかける行為{"\n"}
                ・コンピュータウイルス等の有害なプログラムをPappyまたは第三者に送信し、または流布する行為{"\n"}
                ・本規約に違反し、または、会員サービスの趣旨目的に反する行為{"\n"}
                ・その他、Pappyが合理的に不適切と判断する行為{"\n"}
                会員は、アカウントおよび本契約の地位等の一切の権利および義務を第三者に譲渡、貸与、売却、担保差入およびその他の処分をしてはならないものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１１条（利用停止、会員資格の取り消し等）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、会員が次の各号のいずれかに該当すると判断した場合には、事前に通知することなく当該会員による会員サービスの利用停止、アカウントの停止、IDおよびパスワードの変更、または当該会員の会員資格の取り消し等の措置を講じることができるものとします。これにより会員に何らかの不利益または損害が生じたとしても、Pappyの責めに帰すべき事由がある場合を除き、Pappyは責任を負わないものとします。{"\n"}
                ・会員に法令や本規約に違反する行為があった場合{"\n"}
                ・会員に会員サービス利用に関して不正行為があった場合{"\n"}
                ・一定回数以上のパスワードの入力ミスがあるなど会員のセキュリティを確保するために必要な場合{"\n"}
                ・その他、Pappyが合理的に不適切と判断した場合{"\n"}
                前項のほか、会員がPappyの定める一定の期間内に一定回数のログインを行わなかった場合は、Pappyは、事前に通知することなく前項所定の措置を講じることができるものとします。措置を講じた場合におけるPappyの責任の範囲は前項と同様です。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１２条（会員の退会および本契約の終了）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員は、Pappy所定の退会手続きにより、いつでも本契約を終了させることができます。{"\n"}
                前項退会手続きにより、または、事由のいかんを問わず本契約が終了した場合、会員は、アカウントおよび会員サービスについて、即時に一切利用できなくなります。当該時点において会員サービスに関連して有していた会員の権利は、Pappyが各サービスについて別途定める場合を除いて全て消滅し、Pappyの責めに帰すべき事由がある場合を除き、会員は、Pappyに対して何らの請求を行えません。ただし、本契約終了以前において会員がPappyに対して債務を負っていた場合、当該債務は消滅せず、会員は、Pappyが各サービスについて別途定める場合を除き、Pappyに対して本契約終了後ただちに全ての当該債務について弁済しなければならないものとします。{"\n"}
                会員は、退会手続きを行った場合、Pappyにログインして提供される全てのアカウントが同時に退会、削除され、Pappy以外の関連サービスが提供する会員サービスについても利用できなくなる場合があることを、あらかじめ承諾するものとし、手続きを終了する前に、注意事項等確認のうえで退会するものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１３条（会員サービスの変更、中断、終了等）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、会員サービスの内容の全部もしくは一部を変更もしくは追加し、または会員サービスの提供を終了することができるものとします。{"\n"}
                前項の場合、Pappyは、その影響および会員サービスの運営状況などに照らし、適切な時期および適切な方法により、会員に情報提供を行うものとします。{"\n"}
                Pappyは、以下の各号に定める場合、会員に事前に通知することなく、会員サービスの全部または一部を一時的に中断することができるものとします。{"\n"}
                ・システムのメンテナンスまたは修理を定期的にまたは緊急に行う場合{"\n"}
                ・アクセス過多等によって、システムに負荷が集中した場合{"\n"}
                ・会員、Pappy、または第三者のセキュリティを確保する必要が生じた場合{"\n"}
                ・通信回線の停止、天災、火災、停電、その他の不慮の事故または戦争、紛争、動乱、暴動、労働争議等の不可抗力により会員サービスの提供が困難な場合{"\n"}
                ・その他Pappyが各会員サービスに関する状況に照らして合理的に中断が必要と判断した場合{"\n"}
                Pappyは、Pappyが本条に従ってとった措置に起因して会員に生じた損害について、Pappyの責めに帰すべき事由がある場合を除き、責任を負わないものとします
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１４条（保証の否認および免責）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                会員は、Pappyが会員サービスを通じて提供するインセンティブプログラム、コンテンツその他一切の情報について、①エラー、バグ、不具合またはセキュリティ上の欠陥が存在しないこと、②第三者の権利を侵害しないこと、会員が期待する性質を有することおよび会員に適用ある法令に適合的であることについて、Pappyがいかなる保証も行わないこと、ならびに、③会員サービスが会員サービス提供時における現状有姿において提供されることを理解し、これを承諾するものとし、自己の責任において会員サービスを利用するものとします。{"\n"}
                Pappyは、会員のアカウントで利用できるサービスに関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、および第三者の権利の不侵害に ついて、本規約においていかなる保証も行わないものとします。{"\n"}
                Pappyは、会員による会員サービスの利用に関連して、会員に対する責任を負う場合には、Pappyの故意または重大な過失による場合を除き、会員に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償するものとし、特別な事情から生じた損害（損害の発生を予見し、または、予見し得た場合を含みます。）については、責任を負わないものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１５条（本規約の改定等）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                Pappyは、本規約を任意にいつでも改定することができるものとし、本規約に追加の規定、条件等を定めることができます。この場合、Pappyは、その影響および会員サービスの運営状況などに照らし、適切な時期および適切な方法により、会員に情報提供を行うものとします。{"\n"}
                Pappyは、会員サービスにかかる事業を第三者に譲渡し、または合併もしくは会社分割等により承継させ、本契約上の地位ならびに権利および義務を当該譲渡等の譲受人等に承継させることができるものとします。会員は、かかる譲渡等について、あらかじめ同意するものとします。
                </Text>
              </View>
            </View>

            <View>
              <View style={styles.pMargin}>
                <Text style={styles.titleInfo}>第１６条（準拠法、合意管轄）</Text>
              </View>
              <View>
                <Text style={styles.txtInfo}>
                本規約は日本法に基づき解釈されるものとし、本規約に関し訴訟の必要が生じた場合には、大阪地方裁判所または大阪簡易裁判所を第一審の専属的合意管轄裁判所といたします。
                </Text>
              </View>
            </View>

          </View>
        </ScrollView>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:15,
  },
  padding5: {
    paddingTop:5,
    paddingBottom:5,
  },
  pMargin: {
    marginTop:20,
    marginBottom:10,
  },
  titleInfo: {
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  txtInfo: {
    fontSize:14,
    lineHeight:16,
    color:'#444444',
  },
});
