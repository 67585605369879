import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Image, Dimensions, FlatList
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import { AppContext } from '../AppContext';
import MyPage13Api from '../services/api/MyPage13Api';
import Point24Api from '../services/api/Point24Api';
import PopupWrapper from '../components/common/PopupWrapper';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyles from '../styles/ConfirmPopupStyles';
import formStyles from '../styles/FormStyles';
import ActivityIndicator from '../components/common/ActivityIndicator';
import ListStyles from '../styles/ListStyles';
import ErrorCode from '../constants/ErrorCode';
import { RefreshControl } from 'react-native-web-refresh-control';
import OverlayWrapper from '../components/common/OverlayWrapper';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const windowWidth = Dimensions.get('window').width;
const { checkUndefined } = CommonFunction;

export default class IinePlanScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isRefreshing: false,
      data: [],
      item: {},
      point: 0,
      good: 0
    };
    this.confirmPopup = React.createRef();
    this.errorPopup = React.createRef();
    this.notEnoughErrorCode = ErrorCode.ProfileGetSafetyInfo.NoPoint;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      
      this.subscribeFocus = this.navigation?.addListener('focus', () => {
        CommonFunction.checkAccessTime();
        this.getDataFromApi();
        CommonFunction.hideBottomTab(this.navigation);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    CommonFunction.showBottomTab(this.navigation);
    if(this.subscribeFocus !=undefined){
      this.subscribeFocus();
    }
  }
  
  setHeader(){
    this.title = 'いいね！交換';
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  getDataFromApi = async()=>{
    if(!this.state.isRefreshing){
      this.setState({ loading: true });
    }

    try{
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;
      
      let point = 0;
      let good = 0;
      if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
        point = checkMemberStatResponse?.point;
        good = checkMemberStatResponse?.good;
      }

      //iine plan
      const data = [
        { id:1, good: 5, point: 5 },
        { id:2, good: 10, point: 10 },
        { id:3, good: 20, point: 20 },
        { id:4, good: 30, point: 30 },
        { id:5, good: 50, point: 50 },
        { id:6, good: 100, point: 100 }
      ];

      this.setState({
        data: data,
        point: point,
        good: good
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }
  }

  complete = (response)=>{
    const good = response?.good;
    const point = response?.point;
    const beforeGood = this.state.good;
    const message = `交換が完了しました。\n${beforeGood}いいね！→${good}いいね！`;
    PopupWrapper.setOptions({modal:true, onStartShouldSetResponder: true});
    PopupWrapper.showMessage(message, null, ()=>{
      this.setState({
        good: good,
        point: point
      });
      setTimeout(() => {
        OverlayWrapper.hide();
      }, 1000);
      
    });
  }

  fail = ()=>{
    this.errorPopup?.showPopup();
  }

  conformExchangeGood = (item)=>{
    if(!checkUndefined(item)) return;

    this.setState({item: item},()=>{
      this.confirmPopup?.showPopup();
    });
  }

  exchangeGood = async()=>{
    const item = this.state.item;
    if(!checkUndefined(item.point)) return;

    try{
      OverlayWrapper.show(true);
      const point = item.point;
      const showError = 0;
      const checkExchangeGoodResponse = await Point24Api.putExchangeGood(point, showError);
      if(!CommonFunction.checkResponseStatus(checkExchangeGoodResponse)) return;

      if(checkUndefined(checkExchangeGoodResponse) && checkExchangeGoodResponse?.status==1){
        // complete
        this.complete(checkExchangeGoodResponse);
      }else{
        OverlayWrapper.hide();
        // error
        const response = checkExchangeGoodResponse?.response;
        const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
        if(errorCode==this.notEnoughErrorCode){
          this.fail();
        }else{
          const errorMessage = response?.errorMessage;
          if(errorMessage) PopupWrapper?.showMessage(errorMessage);
        }
      }
    }catch(e){
      OverlayWrapper.hide();
      if(__DEV__) console.log(e);
    }finally{
      ;
    }
  }

  renderItem = ({item, index}) => (
    <View key={index} style={styles.planItem}>
      <View style={styles.planItemLeft}>
        <View style={{marginRight:5, width:16, height:16, backgroundColor:'#5cc8ca', borderRadius:999, justifyContent:'center', alignItems:'center'}}>
          <Image resizeMode={'contain'} style={{width:10, height:10}} source={require('../assets/images/icon/ic-iine-new.png')} />
        </View>        
        <Text style={styles.txtPlanItem}><Text style={styles.NumPlanItem}>{item.good}</Text>いいね</Text>
      </View>
      <View style={styles.planItemRight}>
        <TouchableOpacity style={styles.btnExchangeLike} onPress={()=>{
          this.conformExchangeGood(item);
        }}>
          <Text style={styles.txtBtnExchangeLike}>
            <Text style={styles.txtNumBtnExchangeLike}>{item.point}</Text>ポイントで交換
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
  
  noItemDisplay = () => {
    return <></>;
  }

  getItemLayout = (data, index) => {
    const length = 56; // row height
    const offset = length * index;
    return {length, offset, index}
  }

  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
          <View style={styles.blogInner}>
            <View style={styles.paddingRL20}>
              <Text style={styles.txtTitleBorderBottom}>ポイントを「いいね！」に交換して{"\n"}お相手にアプローチしましょう</Text>
              <Text style={{marginTop:5, fontSize:10, lineHeight:16, color:'#484848'}}>「いいね！」は、ポイントでまとめて交換が可能です。{"\n"}積極的にお相手に「いいね！」してアプローチをしましょう。</Text>
            </View>
            <View style={styles.blocLikePoint}>
              <View style={styles.blocLikePointInside}>
                <View style={{alignContent:'center', justifyContent:'center',alignItems:'center', width:'50%', padding: 16}}>
                  <View style={{flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center', height:20}}>
                    <View style={{marginRight:5, width:18, height:18, backgroundColor:'#5cc8ca', borderRadius:999, justifyContent:'center', alignItems:'center'}}>
                      <Image resizeMode={'contain'} style={{width:12, height:12}} source={require('../assets/images/icon/ic-iine-new.png')} />
                    </View>
                    <Text style={{fontSize:12,fontWeight:'bold',color:'#9c9c9c'}}>いいね！</Text>
                  </View>
                  <View style={{marginTop:3,}}>
                    <Text style={{fontSize:26, fontWeight:'bold', color:'#434343', lineHeight:30}}>{this.state?.good}</Text>
                  </View>
                </View>
                <View style={{alignContent:'center', justifyContent:'center',alignItems:'center', width:'50%', padding: 16}}>
                  <View style={{flexDirection:'row', alignContent:'center', justifyContent:'center', alignItems:'center', height:20}}>
                    <View style={{marginRight:5, width:18, height:18, backgroundColor:'#ffd306', borderRadius:999, textAlign:'center'}}>
                      <Text style={{fontSize:14, fontWeight:'bold', color:'#ffffff', lineHeight:18, textAlign:'center'}}>P</Text>
                    </View>
                    <Text style={{fontSize:12, fontWeight:'bold',color:'#9c9c9c'}}>ポイント</Text>
                  </View>
                  <View style={{marginTop:3}}>
                    <Text style={{fontSize:26, fontWeight:'bold', color:'#434343', lineHeight:30}}>{this.state?.point}</Text>
                  </View>
                </View>  
              </View>
            </View>             
            <View style={[styles.padding48, {paddingTop:0, paddingBottom:0,}]}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#333'}}>まとめて「いいね！」に交換する</Text>
              <View style={{marginTop:12, marginBottom:15,}}>
                <FlatList
                  scrollEnabled={false}
                  data={this.state.data}
                  renderItem={this.renderItem}
                  ListEmptyComponent={this.noItemDisplay}
                  keyExtractor={(item,index) => item.id}
                  getItemLayout={this.getItemLayout}
                  scrollIndicatorInsets={{ right: 1 }}
                  initialNumToRender={this.state.data?.length}
                />
              </View>
              <Text style={styles.txtNote}>
              ※「いいね！」をポイントに交換することはできません。{"\n"}
              ※一度交換した「いいね！」は取り消しできません。
              </Text>
            </View>
          </View>
        </ScrollView>

        <Popup ref={ref=>this.confirmPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <View style={[ConfirmPopupStyles.popupContainer,{ width:'85%'}]}>
            <View style={ConfirmPopupStyles.popupTextItem}>
              <Text style={ConfirmPopupStyles.popupText}>{this.state.item?.point}ポイントを消費して{this.state.item?.good}いいね！に交換しますか？</Text>
            </View>
            <View style={ConfirmPopupStyles.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderRight]} onPress={()=>{
                this.confirmPopup?.hidePopup();
                this.setState({item:{}});
              }}>
                <Text style={ConfirmPopupStyles.btnText}>キャンセル</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[ConfirmPopupStyles.btnItem, ConfirmPopupStyles.borderLeft]} onPress={()=>{
                this.confirmPopup?.hidePopup();
                this.exchangeGood();
              }}>
                <Text style={ConfirmPopupStyles.btnText}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>

        <Popup ref={ref=>this.errorPopup=ref} modal={true}>
          <View style={PortalStyle.overlay} />
          <View style={[ConfirmPopupStyles.popupContainer,{width:'85%'}]}>
            <View style={[ConfirmPopupStyles.popupTextItem,{paddingTop:30, borderBottomWidth:0}]}>
              <Text style={ConfirmPopupStyles.popupText}>ポイントが不足しています。ポイント購入の上、再度お試しください</Text>
            </View>
            <View style={styles.areaErrorBtn}>
              <TouchableOpacity style={formStyles.submitBtn} onPress={()=>{
                this.errorPopup?.hidePopup();
                this.navigation.navigate('PointShopping');
              }}>
                <Text style={{color:'#ffffff',fontWeight:'bold'}}>ポイントを購入する</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.modalButtonWhite} onPress={()=>{ 
                this.errorPopup?.hidePopup();
              }}>
                <Text style={styles.textModalButtonWhite}>キャンセル</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Popup>

      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

let style = {
  blogInner: {
    paddingTop:20,
    paddingBottom:20,
  },
  marginB15:{
    marginBottom:15,
  },
  padding48:{
    padding:windowWidth<375? 40 : 48,
  },
  paddingRL20: {
    paddingRight:windowWidth<375? 12 : 20,
    paddingLeft:windowWidth<375? 12 : 20,
  },
  planItem: {
    flex:1,
    flexDirection:'row',
    paddingTop:windowWidth<375? 7 : 8,
    paddingBottom:windowWidth<375? 7 : 8,
    justifyContent:'center',
  },
  planItemLeft: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignContent:'center',
    alignItems:'center',
    flex:1,
    height:windowWidth<375? 34 : 40,
  },
  NumPlanItem: {
    fontSize:windowWidth<375? 18 : 21,
  },
  txtPlanItem: {
    fontSize:windowWidth<375? 11 : 13,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22 : 24,
  },
  planItemRight: {
    width:windowWidth<375? 142 : 166,
  },
  btnExchangeLike: {
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    height:windowWidth<375? 34 : 40,
    backgroundColor:'#5cc8ca',
    borderRadius:8,
    textAlign:'center',
  },
  txtBtnExchangeLike: {
    fontSize:windowWidth<375? 11 : 13,
    fontWeight:'bold',
    color:'#ffffff',
    textAlign:'center',
  },
  txtNumBtnExchangeLike: {
    fontSize:windowWidth<375? 14 : 17,
  },
  txtTitleBorderBottom: {
    fontSize:17,
    fontWeight:'bold',
    lineHeight:24,
    color:'#484848',
  },
  blocLikePoint: {
    flex:1,
    marginTop:windowWidth<375? 12 : 20,
    marginBottom:windowWidth<375? 12 : 20,
    backgroundColor:'#f2f2f2',
    paddingTop:windowWidth<375? 12 : 18,
    paddingBottom:windowWidth<375? 12 : 18,
    paddingLeft:windowWidth<375? 12 : 20,
    paddingRight:windowWidth<375? 12 : 20,
  },
  blocLikePointInside: {
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:8,
    shadowColor: "#afc9b1",
    shadowOffset: {width: 0,height: 0,},
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 5,
  },
  bloc50: {
    width:'50%',
    textAlign:'right',
    justifyContent:'flex-end',
    alignSelf:'flex-end',
  },
  numBloc25: {
    marginBottom:5,
    fontSize:22,
    fontWeight:'bold',
    color:'#484848',
    textAlign:'center',
  },
  txtBloc25: {
    fontSize:10,
    color:'#484848',
    textAlign:'center',
  },
  txtNote: {
    fontSize:windowWidth<375? 9 : 10,
    lineHeight:windowWidth<375? 16 : 18,
    color:'#484848',
  },
  areaErrorBtn:{
    width:'100%',
    paddingBottom:10,
    justifyContent:'center', 
    alignContent:'center', 
    alignItems:'center', 
    alignSelf:'center', 
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
};


const getPadding=()=>{
  if(windowWidth>=390 && windowWidth<414) return 44;
  return 54;
}

if(windowWidth>375 && windowWidth<=414){
  style.padding48 = {
    padding: getPadding(),
  };  
  style.planItem = {
    ...style.planItem,
    paddingTop:9,
    paddingBottom:9,
  };
  style.planItemLeft = {
    ...style.planItemLeft,
    height:45,
  };
  style.NumPlanItem = {
    fontSize:23,
  };
  style.txtPlanItem = {
    ...style.txtPlanItem,
    fontSize:15,
    lineHeight:26,
  };
  style.planItemRight = {
    width:185,
  };
  style.btnExchangeLike = {
    ...style.btnExchangeLike,
    height:45,
  };
  style.txtBtnExchangeLike = {
    ...style.txtBtnExchangeLike,
    fontSize:15,
  };
  style.txtNumBtnExchangeLike = {
    fontSize: 19,
  };
}

const styles = StyleSheet.create(style);