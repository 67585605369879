import React,{Component } from 'react';
import { View, StyleSheet, SafeAreaView, TouchableOpacity, Text, Platform } from 'react-native';
import ActivityIndicator from '../components/common/ActivityIndicator';
import CommonFunction from '../utils/CommonFunction';
import { WebView } from 'react-native-webview';
import ListStyles from '../styles/ListStyles';
import ApiConfig from '../constants/ApiConfig';
import HeadStyle from '../styles/HeadStyles';
import { Entypo } from '@expo/vector-icons';
import LogoNoMargin from '../components/common/LogoNoMargin';
import ExpoConstants from 'expo-constants';

export default class InquiryNonMemberScreen extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      contactUrl: '',
      userAgent:'',
      loading: true
    }
  }

  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      this.setHeader();
      this.setWebviewParams();
    }
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  setWebviewParams = async ()=>{
    try{
      const contactUrl = ApiConfig.contact.url+'contact-nonmember';
      let userAgent = '';
      await ExpoConstants.getWebViewUserAgentAsync().then(ua => {
        userAgent = ua;
      });
      userAgent += '/PAPPY';
  
      this.setState({
        contactUrl:contactUrl,
        userAgent: userAgent, 
        loading: false
      });
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  setHeader(){
    this.props.navigation?.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={{position:'absolute',left:0,zIndex:1,elevation:1,flexDirection:'row'}}>
            <TouchableOpacity onPress={()=>{
              this.props?.navigation?.goBack();
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack}/>
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, alignItems:'stretch'}}><Text style={HeadStyle.headerTitlePage}>お問い合わせ</Text></View>
        </View>
      )
    });
  }

  INJECTED_JAVASCRIPT = `(function() {
      window.ReactNativeWebView.postMessage(JSON.stringify(window.location));
  })();`;

  onMessage = (event)=>{
    const {
      nativeEvent: {data},
    } = event;
    
    switch(data){
      case 'Index':
        this.props.navigation?.navigate('Index');
        break;
    }
  }

  render() {
    if(this.state.loading) return <ActivityIndicator />

    return (
      <SafeAreaView style={ListStyles.container}>
       <WebView
          androidLayerType={'hardware'}
          style={styles.webViewContainer}
          source={{ uri: this.state.contactUrl }}
          userAgent={this.state.userAgent}
          injectedJavaScript={this.INJECTED_JAVASCRIPT}
          onMessage={this.onMessage}
        /> 
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  webViewContainer:{
    flex:1,
    width:'100%',
    height:'100%',
    opacity: 0.99
  }
});
