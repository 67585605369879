import React, { Fragment, Component } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FormTextInputWithStyle } from '../../components/form/FormInput';
import { Formik } from 'formik'
import Validation from '../../constants/Validation';
import styles from '../../styles/FormStyles';
import HeaderOptions from '../../constants/HeaderOptions';
import GoBackIcon from '../../components/common/GoBackIcon';
import Reminder20Api from '../../services/api/Reminder20Api';
import CommonFunction from '../../utils/CommonFunction';

export default class ReminderScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      mail : ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  setHeader(){
    this.navigation?.setOptions({
      headerStyle: HeaderOptions.HeaderNoBorder2,
      headerTitle: '',
      headerLeft: () => <GoBackIcon navigation={this.navigation} />
    });
  }

  onSubmit = async (values) => {
    try{
      CommonFunction.overLay();
      Reminder20Api.setCheckAccessFlg(false);
      const postVerificationResponse = await Reminder20Api.PasswordPostSendVerificationCode(
        values['mail']
      );
      Reminder20Api.resetCheckAccessFlg();
      if(postVerificationResponse!=undefined && postVerificationResponse.status==1){
        const token = postVerificationResponse?.response?.token;
        this.navigation.navigate('ActiveReminder', { token: token, mail:values['mail']} );
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  render() {
    return (
      <View style={styles.container}>
      <ScrollView style={styles.ScrollContainer}>
        <View style={[styles.HeadFormSection, {paddingTop:60, paddingLeft:30, paddingRight:30,}]}>
          <Text style={[styles.txtHeadForm, {marginBottom:18,}]}>メールアドレスで確認</Text>
          <Text style={[styles.labelText, {fontWeight:'normal', lineHeight:19,letterSpacing:-1,}]}>この登録のメールアドレスにパスワード再設定用の{"\n"}認証コードを送信します</Text>
        </View>
        <Formik
          initialValues={{mail:this.state.mail}}
          validationSchema={Validation.SettingEmailNotmailSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => this.onSubmit(values) }
        >
        {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
          <Fragment>
            <View style={[styles.BodyContent, {padding:30,}]}>
              <View style={{marginTop:25,}}>
                <FormTextInputWithStyle fieldName="mail" type="email" placeholder="メールアドレスを入力してください"/>
              </View>
              <View style={[styles.buttonPanelSetting, {marginTop:35,}]}>
                <TouchableOpacity style={[styles.submitBtn, {opacity:(dirty) ?1:0.4, width:'90%'}]} disabled={!(dirty)} onPress={handleSubmit}>
                  <Text style={styles.loginText}>送信</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Fragment>
        )}          
        </Formik>
        </ScrollView>
      </View>
    );
  }
}
