import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform, StatusBar
} from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';

export default class PointShoppingCompleteScreen extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      this.setHeader();
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader(){
    this.navigation.setOptions({
      headerStyle: HeaderOptions.HeaderSetting,
      headerTitleAlign:'center',
      headerTitle: () => (
        <View><Text style={HeadStyle.headerTitlePage}>ポイント購入の申し込み</Text></View>
      ),
      headerLeft: () => (
        <AntDesign name={'left'} style={HeadStyle.icClose} onPress={()=> this.navigation.navigate('MyPage')} />
      ),
      headerRight: () => (
        <View/>
      )
    });
  }

  render() {
  
    return (
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            
            <View>
              <Text style={styles.titleHeader}>ポイント購入の申し込み完了</Text>
            </View>

            <View style={styles.txtDetail}>
              <Text style={styles.txt12}>ポイント購入の申し込みが完了しました。</Text>
              <Text style={[styles.txt12, {marginBottom:20,}]}>お支払いを行う場合は、
              <TouchableOpacity style={{}} onPress={()=>{}}><Text>コチラ</Text></TouchableOpacity>
              のお申し込み詳細からお願いします。</Text>
            </View>
          </View>
          <View style={{padding:15,}}>
            <TouchableOpacity style={styles.btnConfirm} onPress={()=>{ 
              //CommonFunction.overLay();
              this.navigation.navigate('MyPage');
               }}>
              <Text style={styles.txtBtnConfirm}>マイページに戻る</Text>
            </TouchableOpacity>
          </View>
         
        </ScrollView>
      
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    flex:1,
    height:500,
    backgroundColor:'#ffffff',
  },
  blogInner: {
    paddingTop:16,
    paddingBottom:20,
  },
  titleHeader: {
    paddingLeft:15,
    textAlign:'left',
    fontSize:18,
    fontWeight:'bold',
    lineHeight:36,
  },
  txtDetail: {
    flex:1,
    flexDirection:'column',
    padding:15,
  },
  txt12: {
    fontSize:12,
    lineHeight:18,
  },
  btnConfirm: {
    height:50,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
    alignItems:"center",
    justifyContent:"center",
    textAlign:'center',
  },
  txtBtnConfirm: {
    fontSize:12,
    fontWeight:'bold',
    lineHeight:50,
    color:'#fff',
    letterSpacing:1,
  }
});

