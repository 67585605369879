import AsyncStorage from '@react-native-async-storage/async-storage';

const storeData = async (key, value) => {
  try {
    value = value?.toString();
    await AsyncStorage.setItem(key, value)
  } catch (e) {
    if(__DEV__) console.log(e);
  }
}

const storeObjectData = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (e) {
    if(__DEV__) console.log(e);
  }
}

const getData = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key)
    if(value !== null) {
      return value;
    }
  } catch(e) {
    if(__DEV__) console.log(e);
  }
}

const getObjectData = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key)
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch(e) {
    if(__DEV__) console.log(e);
  }
}

const removeItems = async (keys) => {
  try{
    await AsyncStorage.multiRemove(keys);
    return true;
  }catch(e) {
    if(__DEV__) console.log(e);
    return false;
  }
}

const  clearAll = async () => {
  try {
    await AsyncStorage.clear();
    return true;
  } catch(e) {
    if(__DEV__) console.log(e);
    return false;
  }
}

const removeItem = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
    return true;
  } catch(e) {
    if(__DEV__) console.log(e);
    return false;
  }
}

export default {
  storeData, 
  storeObjectData, 
  getData, 
  getObjectData, 
  clearAll, 
  removeItem, 
  removeItems
};