import React,{ Component, Fragment } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, TextInput, Platform, Image, TouchableOpacity, Dimensions
} from 'react-native';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { Formik } from 'formik';
import RightSlidePopupWithConfirm from '../components/common/RightSlidePopupWithConfirm';
import OptionData  from '../constants/option/OptionData.json';
import UserSetting  from '../components/common/UserSetting';
import PortalStyle from '../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { Entypo } from '@expo/vector-icons'; 
import { CheckboxInput } from '../components/form/FormInput';
import HeadStyle from '../styles/HeadStyles';
import ReportApi from '../services/api/Report09Api';
import Profile04Api from '../services/api/Profile04Api';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';
import ListStyles from '../styles/ListStyles';
import LogoNoMargin from '../components/common/LogoNoMargin';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

//const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;
const inputAccessoryViewID = "inputAccessoryView1";

export default class ReportScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.initialValues = {
      categoryId: '',
      contents: '',
      accept: false
    };

    this.state = {
      loading: true,
      profileInfo: {},
      isComplete: false,
      categoryItem:[]
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();
      this.memberId = this.props?.route?.params?.memberId;
      this.getListFromApi();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHeader = ()=>{
    this.navigation.setOptions({
      header: () => (
        <View style={HeadStyle.customHeader}>
          <View style={{flexDirection:'row',flex:1, zIndex:1, elevation: 1,position:'absolute',left:0}}>
            <TouchableOpacity onPress={() => this.navigation.goBack()}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{width:'100%',justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
            <Text style={[HeadStyle.headerTitlePage,{fontWeight:'normal'}]}>通報</Text>
          </View>
        </View>
      )
    });
  }

  async getListFromApi(page){
    if(this._isMounted){
      try{
        const getListResponse = await Profile04Api.GetList(this.memberId);
        //check login
        if(!CommonFunction.checkResponseStatus(getListResponse)) return;

        if(getListResponse!=undefined && getListResponse?.status==1){
          const profileInfo = getListResponse?.response?.profileList;

          const getCategoryListResponse = await ReportApi.GetContactCategoryList();
          const categoryList = getCategoryListResponse?.response?.categoryList;
          let categoryOption = [];
          if(categoryList){
            categoryList?.forEach((element) => { categoryOption.push({id:element.id,value:element.label}); });
          }

          if(profileInfo?.count>0 && profileInfo?.list[0]!=undefined){
            this.setState({
              profileInfo: profileInfo?.list[0], 
              categoryItem: categoryOption 
            });
          }
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.setState({ loading: false });
      }
    }
  }

  validateForm = (values)=>{
    let contents = CommonFunction.checkMessage(values.contents);
    if(values.categoryId=='' || values.categoryId.id===undefined || contents=='' || values.accept===false) {
      return false;
    }
    return true;
  }

  handleSubmit = async(values)=>{
    try{
      CommonFunction.overLay();
      let categoryArray = ['',''];
      if(values.categoryId?.id){
        categoryArray = values.categoryId?.id?.split("|"); 
      }

      const parameter = {
        targetMemberId: this.memberId, 
        categoryId: categoryArray[0],
        subCategoryId: categoryArray[1], 
        contents: values.contents
      };
      
      const response = await ReportApi.PostReport(parameter);
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(response!=undefined && response?.status==1){ // complete
        this.setState({isComplete: true});
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  onPressOk = ()=>{
    // hide popup
    this.setState({isComplete: false});
    
    // goback
    const routes = this.navigation.getState()?.routes;
    if(routes!=undefined){
      let length = routes.length - 3;
      if(length <0) length = 0
      this.navigation.navigate(routes[length]);
    }
  }

  render() {
    if (this.state.loading) return <ActivityIndicator />

    let profileImg = this.state.profileInfo?.mainImage?.url;
    if(!profileImg){
      profileImg = this.state.profileInfo?.mainImage?.noImageUrl;
    }
    return (
      <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer}>
          <View style={styles.blogInner}>
          <Formik
              initialValues={this.initialValues}
              onSubmit={values => this.handleSubmit(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit, setFieldValue }) => (
            <Fragment>
              <View style={{padding:15,width:'100%'}}>
                <Text style={{fontSize:16, fontWeight:'bold', lineHeight:24, color:'#484848'}}>通報対象ユーザー</Text>
              </View>

              <View style={{padding: 15, width: '100%'}}>
                <View style={{flexDirection: 'row', justifyContent:'flex-start', alignContent:'center', alignItems:'flex-start', alignSelf:'flex-start', padding: 15, width: '100%', backgroundColor: '#f3fbfe', borderRadius: 10}}>
                  <View style={{marginRight: 10}}>
                    <Image style={{width: 48, height: 48, borderRadius: 7}} source={{uri:profileImg}}/>
                  </View>
                  <View style={{}}>
                    <Text style={{fontSize: 16, fontWeight:'bold', lineHeight: 24, color:'#353535'}}>{this.state.profileInfo.name}</Text>
                    <Text style={{fontSize: 12, fontWeight:'normal', lineHeight: 18, color:'#353535'}}>
                    {this.state.profileInfo.age} {this.state.profileInfo.currentPref}</Text>
                  </View>
                </View>
                <View style={{marginTop: 15, borderBottomColor: '#ddd', borderBottomWidth: 1}}></View>
              </View>

              <View style={{}}>
                <View style={{padding:15, paddingTop: 5}}>
                  <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center', alignSelf: 'flex-start', marginBottom: 5}}>
                    <View style={{paddingLeft:10}}>
                      <Text style={styles.title}>違反の種類</Text>
                    </View>
                    <Require/>
                  </View>
                  <View style={{marginBottom:20}}>
                    <RightSlidePopupWithConfirm fieldName={'categoryId'} title={'違反の種類'} items={this.state.categoryItem} onChange={setFieldValue} values={values.categoryId} single={true} showChild={true} childItems={OptionData.Master.ViolationsSubCategory}>
                      <UserSetting.CustomSelected values={values.categoryIdTxt}/>
                    </RightSlidePopupWithConfirm>        
                  </View>
          
                  <View style={{flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', marginBottom: 5, width:'100%'}}>
                    <View style={{flex:1,paddingLeft:10,flexDirection:'row'}}>
                      <Text style={styles.title}>具体的な理由</Text>
                      <Require/>
                    </View>
                    
                    <View style={{flex:1}}>
                      <Text style={{fontSize:12, fontWeight:'normal', lineHeight:20, color:'#9ea8aa', textAlign:'right'}}> ※500文字まで </Text>
                    </View>
                  </View>
                  
                  <View style={{paddingTop:8, paddingBottom:8, paddingRight:12, paddingLeft:12, backgroundColor:'#eff1f2', borderBottomColor:'#6ec1da', borderBottomWidth:2, borderTopLeftRadius:5, borderTopRightRadius:5}}>
                    <TextInput
                      editable
                      multiline
                      numberOfLines={9}
                      onChangeText={handleChange('contents')}
                      onBlur={() => setFieldTouched('contents')}
                      value={values.contents}
                      style={{marginBottom:5, marginRight:2, marginLeft:2, padding:5, width: '100%', height:120, ...Platform.select({ ios: { }, android: { textAlignVertical:'top', }, default: { }, }) }}
                      placeholder="通報理由を入力してください"
                      placeholderTextColor="#9aa5a7"
                      inputAccessoryViewID={inputAccessoryViewID}
                    />
                  </View>   
                </View>
                <View style={{marginBottom:30,padding:15,paddingTop:0}}>
                  <View style={{marginBottom:10}}>
                    <Text style={{color:'#ef926c'}}>注意事項</Text>
                  </View>
                  <View>
                    <View style={{flexDirection:'row'}}><Entypo name="dot-single" style={{fontSize:12, lineHeight:20, color:'#000'}} /><Text style={{fontSize:12, lineHeight:20,}}>ご連絡いただいた内容に対して処置を講じることを保証するものでありません。</Text></View>
                    <View style={{flexDirection:'row'}}><Entypo name="dot-single" style={{fontSize:12, lineHeight:20, color:'#000'}} /><Text style={{fontSize:12, lineHeight:20,}}>ご連絡いただいた内容に個別にお答えすることはありません。</Text></View>
                    <View style={{flexDirection:'row'}}><Entypo name="dot-single" style={{fontSize:12, lineHeight:20, color:'#000'}} /><Text style={{fontSize:12, lineHeight:20,}}>虚偽、嫌がらせ目的の通報、または何度も同じお相手を報告し続ける場合、お客様の報告内容に対する信用が損なわれる場合がございますのでご注意ください</Text></View>
                    <View style={{flexDirection:'row'}}><Entypo name="dot-single" style={{fontSize:12, lineHeight:20, color:'#000'}} /><Text style={{fontSize:12, lineHeight:20,}}>お客様の生活に危険が及ぶ恐れがある場合や、何らかの実被害のに遭われた場合などは、速やかに最寄りの警察にご相談ください</Text></View>
                  </View>
                </View>           

                <View style={{flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
                  <CheckboxInput fieldName="accept" NotdisplayError={true} containerStyle={{paddingRight:0, marginRight:0}} />
                  <Text>上記事項全てに同意する</Text>
                </View>

                <View style={{marginBottom:20, paddingTop:15, paddingBottom:15, paddingLeft:50, paddingRight:50}}>
                {this.validateForm(values) ?
                  <TouchableOpacity onPress={()=>handleSubmit()} style={styles.btnBlue}>
                    <Text style={styles.txtBtnBlue}>通報する</Text>
                  </TouchableOpacity>
                  :
                  <View style={[styles.btnBlue, {opacity:0.5}]}><Text style={styles.txtBtnBlue}>通報する</Text></View>
                }
                </View>
              </View>
            </Fragment>
            )}  
          </Formik>
        </View>

        {this.state.isComplete &&
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {
              this.onPressOk();
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12,}}>
              <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center'}}>通報しました</Text>
              </View>
              <TouchableOpacity onPress={()=>{
                this.onPressOk();
              }} style={{padding:15, width:'100%', textAlign:'center'}}>
                <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Portal>
      }

      </ScrollView>
    </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

class Require extends Component{
  render(){
    return (
      <View style={{marginLeft: 5, width: 25, height: 18, backgroundColor: '#ef926c', borderRadius: 3, textAlign: 'center'}}>
        <Text style={{fontSize: 10, fontWeight: 'normal', lineHeight: 18, color: '#fff'}}> 必須 </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:0,
  },
  ImageBackground:{
    flex:1,
    width:'100%',
    height:40,
  },
  btnBlue: {
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    textAlign:'center',
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:50,
    color:'#ffffff',
    textAlign:'center',
  },
  modalButton: {
    marginTop:10, 
    width:'100%', 
    height:50, 
    backgroundColor:'#eeeeee', 
    borderRadius:8, 
    textAlign:'center'
  },
  modalButtonText: {
    fontSize:14, 
    lineHeight:50, 
    color:'#aaaaaa', 
    textAlign:'center',
    fontWeight:'bold'
  },
  title:{
    fontSize:12, 
    fontWeight:'bold', 
    lineHeight:20, 
    color:'#484848'
  }
});
