import Storage from '../utils/StorageHelper2';
import Constants from '../constants/Constants';
import PopupWrapper from "../components/common/PopupWrapper";
import ContextWrapper from "../components/common/ContextWrapper";
import * as RootNavigation from '../navigation/RootNavigation';
import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import PaymentWrapper from "../components/common/PaymentWrapper";
import Msg from "../constants/option/Msg.json";
import { Platform } from "react-native";

export default class AuthHelper {
  constructor() {
    this.props = {};
  }

  static async logout(){
    this.clearLoginData();
    if(Platform.OS=='web'){
      RootNavigation?.navigate('NonAuth', { screen: 'Index' });
    }else{
      RootNavigation?.reset('NonAuth', { screen: 'Index' });
    }
  }

  static async goTopPage(){
    setTimeout(() => {
      if(Platform.OS=='web'){
        RootNavigation?.navigate('NonAuth', { screen: 'Index' });
      }else{
        RootNavigation?.reset('NonAuth', { screen: 'Index' });
      }
    }, 1000);
  }

  static async clearLoginData(){
    if(SMSWrapper!=undefined) SMSWrapper?.hideSMS();
    if(AgeWrapper!=undefined) AgeWrapper?.hideAge();
    if(PaymentWrapper!=undefined) PaymentWrapper?.hide();
    
    Storage?.removeItems(Constants.RemoveStorageKey);
    if(ContextWrapper!=undefined){
      ContextWrapper?.getContext(({appState, setAppState})=>{
        if(setAppState!=undefined){
          setAppState({});
        }
      });
    }

    // clear contact cookie
    if(Platform.OS==='web'){
      document.cookie = 'sess=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }

  static showNetworkError(){
    PopupWrapper.showMessage(Msg.NetworkError,'warning');
  }

  static showError(errorMessage, icon='warning'){
    if(errorMessage !=undefined){
      PopupWrapper.showMessage(errorMessage, icon);
    }
  }

  static showModalError(errorMessage, icon='warning'){
    if(errorMessage !=undefined){
      PopupWrapper.showModalMessage(errorMessage, icon);
    }
  }
}