import ApiConfig from '../../constants/ApiConfig';
import ApiPappy from '../../utils/ApiPappy';
import CommonFunction from '../../utils/CommonFunction';
import AuthHelper from '../../utils/AuthHelper';
import ResponseConstants from '../../constants/ResponseConstants';
import ErrorCode from '../../constants/ErrorCode';
//import Constants from '../../constants/Constants';
import Storage from '../../utils/StorageHelper2';
import { Platform } from 'react-native';

export default class Setting17Api extends ApiPappy{
  //1.1.1.通知受信設定取得
  static async GetNoticeReceive(){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Get/NoticeReceive/",
      {},
      true
      );
      if(__DEV__) console.log('/Setting/Get/NoticeReceive/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField.concat(ResponseConstants.SettingGetNoticeReceive));
            console.log('/Setting/Get/NoticeReceive/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            const res = data?.response;
            const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
            if(errorCode!=ErrorCode.GetNoticeReceive.NoMailAddress){
              CommonFunction.checkErrorCode(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.5.通知受信設定
  // static async PutNoticeReceive(values
  //   ,footmarkPush, goodPush, matchingPush, messagePush, noticePush){
  static async PutNoticeReceive(values){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/NoticeReceive/",
      values,
      true
      );
      if(__DEV__) console.log('/Setting/Put/NoticeReceive/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/NoticeReceive/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.4.パスワード変更
  static async PutResetPassword(pass, newPass){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/ResetPassword/",
      { newPass: newPass, pass:pass },
      true
      );
      if(__DEV__) console.log('/Setting/Put/ResetPassword/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/ResetPassword/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.メールアドレス取得
  static async GetMail(){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Get/Mail/",
      {},
      true
      );
      if(__DEV__) console.log('/Setting/Get/Mail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField.concat(ResponseConstants.SettingGetMail));
            console.log('/Setting/Get/Mail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.メールアドレス変更チェック
  static async GetUpdateCheckMail(values){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Get/UpdateCheckMail/",
      values,
      true
      );
      if(__DEV__) console.log('/Setting/Get/UpdateCheckMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
                ResponseConstants.CommonResponseField);
            console.log('/Setting/Get/UpdateCheckMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            const res = data?.response;
            const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
            if(errorCode!=ErrorCode.MailInput.registMailMismatch){
              CommonFunction.checkErrorCode(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.2.メールアドレス変更認証コード送信
  static async PostSendVerificationCodeMail(values){
    try{  
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Post/SendVerificationCodeMail/",
      values,
      true
      );
      if(__DEV__) console.log('/Setting/Post/SendVerificationCodeMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Post/SendVerificationCodeMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            const res = data?.response;
            const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
            if(errorCode!=ErrorCode.MailInput.registMailMismatch){
              CommonFunction.checkErrorCode(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.3.メールアドレス変更認証コードチェック
  static async PutVerificationCode(token, mail, code){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/VerificationCode/",
      { 
        token: token,
        mail: mail,
        code: code
      },
      true
      );
      if(__DEV__) console.log('/Setting/Put/VerificationCode/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/VerificationCode/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    } 
  }

  //1.1.3.プライベートモード設定取得
  static async GetPrivate(){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Get/Private/",
      {},
      true
      );
      if(__DEV__) console.log('/Setting/Get/Private/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.SettingGetPrivate));
            console.log('/Setting/Get/Private/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.6.プライベートモード設定更新
  static async PutPrivate(flag){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/Private/",
      { flag: flag },
      true
      );
      if(__DEV__) console.log('/Setting/Put/Private/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/Private/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  // 1.3.7.デバイストークン設定
  static async PutDeviceToken(deviceToken){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/DeviceToken/",
      { deviceToken: deviceToken },
      true
      );
      if(__DEV__) console.log('/Setting/Put/DeviceToken/',response);
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/DeviceToken/', responseField?.join(','));
          }
          $return = data;
          if(data?.status==1){
            // APIが成功の時にdeviceTokenを保持する
            if(Platform.OS === 'web'){
              if(CommonFunction.isAndroidUA()){
                Storage.storeData('deviceToken_web', deviceToken);
              }
            } else{
              Storage.storeData('deviceToken', deviceToken);
            }

          }else{
          }
        }
      }      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //シークレットモード設定取得
  static async GetSecret(){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Get/Secret/",
      {},
      true
      );
      if(__DEV__) console.log('/Setting/Get/Secret/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.SettingGetSecret));
            console.log('/Setting/Get/Secret/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //シークレットモード設定更新
  static async PutSecret(flag){
    try{
      const response = await this.post(
      ApiConfig.pappy_api_url+"/Setting/Put/Secret/",
      { flag: flag },
      true
      );
      if(__DEV__) console.log('/Setting/Put/Secret/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Setting/Put/Secret/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}