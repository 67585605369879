import React,{ Component } from 'react';
import { Image, StyleSheet, Text, View, ScrollView, Platform, StatusBar, ImageBackground, Dimensions, SafeAreaView } from 'react-native';
import HeadStyle from '../styles/HeadStyles';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';

const windowWidth = Dimensions.get('window').width;
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;

export default class IinePointScreen extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.navigation = this.props?.navigation;
      this.setHeader();

      // hide bottom tab
      CommonFunction.hideBottomTab(this.navigation);
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this._isMounted = false;
    CommonFunction.showBottomTab(this.navigation);
  }

  setHeader(){
    this.title = 'いいね！について';
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }
  
  render() {
    return (
      <SafeAreaView style={[styles.container, HeadStyle.customHeaderTop]}>
        <ScrollView style={styles.scrollContainer} ref={scroller => { this.scroller = scroller; }} scrollToOverflowEnabled={true}>
          <View style={styles.blogInner}>
            <View style={styles.blogInside}>
              <ImageBackground source={require('../assets/images/point/bg-orange-1.png')} resizeMode="stretch" style={styles.imgBGOrange1}>
                <View style={{paddingTop:6,}}>
                  <Text style={styles.txtImgBGOrange}>気になるお相手を見つけたら{"\n"}いいね！を送ってアプローチ！</Text>
                </View>
              </ImageBackground>
              <View style={styles.blogImg}>
                <Image style={styles.img01} source={require('../assets/images/point/iine-img01.png')} resizeMode="contain"/>
              </View>
              <View style={[styles.blogPadding, {marginTop: 15, marginBottom: 15,}]}>
                <Text style={styles.txt18}>今なら<Text style={styles.txtBlue}>1日10人に無料でいいね！</Text>が可能{"\n"}積極的なアタックがマッチングの鍵</Text>
              </View>
              <View style={[styles.blogPadding, {marginBottom:30}]}>
                <View style={{padding:10, width:'100%', backgroundColor:'#f2f2f2', }}>
                  <Text style={{fontSize:12, lineHeight:23,}}>現在は、公開キャンペーンとしていいね！数を{"\n"}消費せずにお相手にいいね！を送ることができます</Text>
                </View>
              </View>              
            </View>
            <View style={styles.blogInside}>
              <ImageBackground source={require('../assets/images/point/bg-orange-1.png')} resizeMode="stretch" style={styles.imgBGOrange1}>
                <View style={{paddingTop:6,}}>
                  <Text style={styles.txtImgBGOrange}>1ポイント＝1いいね！{"\n"}に交換できる</Text>
                </View>
              </ImageBackground>
              <View style={[styles.blogImg]}>
                <Image style={styles.img02} source={require('../assets/images/point/iine-img02.png')} resizeMode="contain"/>
              </View>
              <View style={[styles.blogPadding, {marginBottom:15,}]}>
                <Text style={styles.txt18}>手持ちのいいね数が足りない時は{"\n"}<Text style={styles.txtBlue}>1ポイント＝1いいねに交換</Text>できます</Text>
              </View>
              <View style={[styles.blogPadding, {marginBottom:60}]}>
                <View style={{padding:10, width:'100%', backgroundColor:'#f2f2f2', }}>
                  <Text style={{fontSize:12, lineHeight:23,}}>ポイント交換機能は近日公開予定となります</Text>
                </View>
              </View>
            </View>


          </View>         

        </ScrollView>
      
    </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  scrollContainer: {
    //flex:1,
    //height:0,
    height: scrollHeight,
    backgroundColor:'#ffffff',
  },
  blogInner:{
    flex:1,
  },
  imgBGOrange1: {
    marginBottom:15,
    width:windowWidth<375? 320 : 375,
    height:windowWidth<375? 70 : 82,
    textAlign:'center',
  },
  imgBGOrange2: {
    width:windowWidth<375? 273 : 320,
    height:windowWidth<375? 68 : 80,
    textAlign:'center',
  },
  blogTitle: {
    width:'100%',
    height:46,
    textAlign:'center',
  },
  bgBlue: {
    backgroundColor:'#f6fcff',
  },
  bgOrange: {
    backgroundColor:'#fcf5f2',
  },
  txtTitle: {
    fontSize:windowWidth<375? 19 : 22,
    fontWeight:'bold',
    lineHeight:46,
    color:'#484848',
  },
  blogInside: {
    flexDirection:'column',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    paddingTop:30, 
    width:'100%',
  },
  txtImgBGOrange: {
    fontSize:windowWidth<375? 16 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 26 : 30,
    color:'#fff',
  },
  blogImg:{
    width:'100%',
  },
  paddingT10:{
    paddingTop:10,
  },
  paddingT15:{
    paddingTop:15,
  },
  paddingB25:{
    paddingBottom:25,
  },
  img01:{
    width:'100%',
    height:windowWidth<375? 134 : 158,
  },
  img02:{
    width:'100%',
    height:windowWidth<375? 115 : 135,
  },
  img03:{
    width:'100%',
    height:windowWidth<375? 62 : 73,
  },
  txt18:{
    fontSize:windowWidth<375? 15 : 18,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 27 : 31,
    color:'#484848',
  },
  txtBlue:{
    color:'#61bdd8',
  },
  txtOrange:{
    color:'#fe895b',
  },
  blogPadding: {
    paddingLeft:26,
    paddingRight:26,
    width:'100%',
    textAlign:'center',
  }
});

