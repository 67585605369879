import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import styles from '../../styles/FormStyles';
import PortalStyle from '../../styles/PortalStyles';
import AgeManager from "./AgeManager";
import { Portal } from 'react-native-paper';

export default class AgeVerify extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      partnerMsg: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      AgeManager.register(this);
      this.navigation = this.props?.navigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    AgeManager.unregister(this);
  }

  show = (navigation, hideAction)=>{
    if(navigation!=undefined)
      this.navigation = navigation;
    
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }

    this.setState({ show: true});
  }

  showPartner = (navigation, hideAction)=>{
    if(navigation!=undefined)
      this.navigation = navigation;
    
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }

    this.setState({ show: true, partnerMsg: true});
  }

  hide = ()=>{
    this.setState({ show: false, partnerMsg: false });
  }

  render() {
    if(!this.state.show) return null;

    if(this.state.partnerMsg){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} 
            onStartShouldSetResponder={() => {
              this.hide();
              if(this.hideAction!=null && this.hideAction!=undefined){
                this.hideAction();
                this.hideAction = null;
              }
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', width:'80%', backgroundColor:'#fff', borderRadius:12}}>
            <View style={{paddingTop:25, paddingBottom:18, paddingLeft:15, paddingRight:15, width:'100%', borderBottomWidth:1, borderBottomColor:'#e6e6e9', textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848',textAlign:'center'}}>
              お相手の年齢確認がお済みでないためメッセージを送ることができません</Text>
            </View>
            <TouchableOpacity onPress={()=>{
              this.hide();
              if(this.hideAction!=null && this.hideAction!=undefined){
                this.hideAction();
                this.hideAction = null;
              }
            }} style={{padding:15, width:'100%',textAlign:'center'}}>
              <Text style={{fontSize:14, fontWeight:'bold', color:'#5888f7',textAlign:'center'}}>OK</Text>
            </TouchableOpacity>
          </View>
          </View>
        </Portal>
      );
    }

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            //this.hide();
          }} />
          <View style={PortalStyle.blocPopup}>
            <View style={[PortalStyle.blocPopupInner, {paddingTop:25, paddingRight:15, paddingBottom:15, paddingLeft:15, width:'80%'}]}>
              <View style={styles.popupInside}>
                <Text style={{fontSize:14, fontWeight:'bold', lineHeight:24, color:'#484848', textAlign:'center'}}>
                ご利用には年齢確認が必要です
                </Text>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', width:'100%'}}>
                  <TouchableOpacity style={inlineStyles.modalButtonBlue} onPress={()=>{ 
                    this.hide();
                    this.navigation.navigate('Auth',{ screen:'Identification'});
                  }}>
                    <Text style={inlineStyles.textModalButtonBlue}>年齢確認する</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={inlineStyles.modalButtonWhite} onPress={()=>{ 
                    this.hide();
                    if(this.hideAction!=null && this.hideAction!=undefined){
                      this.hideAction();
                      this.hideAction = null;
                    }
                  }}>
                    {/*<Text style={inlineStyles.textModalButtonWhite}>今はしない</Text>*/}
                    <Text style={inlineStyles.textModalButtonWhite}>閉じる</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const inlineStyles = StyleSheet.create({
  modalButtonBlue: {
    marginTop:30,
    padding:8,
    width:'70%',
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  textModalButtonBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
});