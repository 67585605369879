
const Constants = {
  FEMALE: 0,
  MALE: 1,
  WOMEN: '女性',
  MEN: '男性',
  NOTSETTEXT: ['こだわらない','未設定'],
  notSetLabel: '未設定',
  CommonResponseField: ['status','accessToken','verified','activated','response','ikuyoStatus'],
  ProfileGetCheck: ['memberId','name','introduction','tweet','profileImage','income','assets','height','vibe','style','personality','currentPref','birthCity','dateHope','meetHope','education','occupation','alcohol','cigarette','holiday','sex'],
  DefaultProfileGetCheck: {
    memberId: null,
    name: '',
    introduction: { body: '', certify: 0},
    tweet: { body: '', certify: 0},
    profileImage: { count: 0, noImageUrl: '', list:[]},
    income: { id: '', label: ''},
    assets: { id: '', label: ''},
    height: 0,
    vibe: { id: '', label: ''},
    style: { id: '', label: ''},
    personality: { id: '', label: ''},
    currentPref: { id: '', label: ''},
    birthCity: { id: '', label: ''},
    dateHope: { id: '', label: ''},
    meetHope: { id: '', label: ''},
    education: { id: '', label: ''},
    occupation: { id: '', label: ''},
    alcohol: { id: '', label: ''},
    cigarette: { id: '', label: ''},
    holiday: { id: '', label: ''},
    sex: null
  },
  ProfileGetList: ['memberId','verified','name','sex','age','introduction','tweet','matchFlg','goodFlg','goodCount','online','mainImage','subImage','currentPref','birthCity','income','height','vibe','personality','style','dateHope','meetHope','registReason','education','occupation','holiday','alcohol','cigarette'],
  SearchProfileGetList: ['memberId','verified','name','sex','age','introduction','tweet','goodFlg','goodCount','online','mainImageUrl','imageCount','currentPref','birthCity','income','height','vibe','personality','style','dateHope','meetHope','registReason','education','occupation','holiday','alcohol','cigarette','futureDream','newFlag'],
  LoginPutLoginByFirstLoginKey:['status','accessToken','response'],
  EntryPostEntryMail:['firstLoginKey'],
  EntryPutVerificationCode:['result'],
  EntryPostSendVerificationCodeMail:['token'],
  EntryGetEntryCheckMail:['status','response'],
  ActivateGetCheckTel:['status','response'],
  ActivatePostSendVerificationCodeSMS:['status','response','token'],
  ActivatePutVerificationCode:['status','response'],
  GoodGetList:['memberId','newFlag','mainImageUrl','imageCount','leaveFlag','online','age','currentPref','name','introduction','tweet','message','messageDaysAgo'],
  GoodPostSendGood:['matching','verified','partnerImageUrl','ownImageUrl'],
  EntryGetListNotMember:['memberId','mainImageUrl','age','currentPref','sex','introduction','tweet'],
  ProfileGetBadge:['footmark','good','message','notification'],
  MailBoxGetMailBoxList:['mailbox','nextVal','type','count','unreadMatchCount','unreadReplyCount','subscription','status','list','messageBoxId','memberId','mainImageUrl','leaveImageUrl','replyStat','receiveDate','nickName','sex','age','currentPref','online','msgText','messageStatus','unreadCount'],
  MailBoxGetMailBoxDetail:['profile','memberId','verified','nickName','sex','mainImageUrl','deleteFlag','count','nextVal','list','messageBoxId','messageId','messageOwner','messageType','dateTime','alreadyType','message','stamp','imageLimitFlag','attacheImageUrl'],
  HideGetList:['footmark','nextVal','list','memberId','name','age','introduction','tweet','mainImageUrl','currentPref'],
  onlineStatus: {
    noDisplay:0,
    online:1,
    onlineColor: '#24eb25',
    online12Hr:2,
    online12HrColor: '#ebba24',
    online24Hr:3,
    online24HrColor: '#eb7524',
    online3Day:4,
    online3DayColor: '#aeaeae',
    onlineOver3Day:5,
    onlineOver3DayColor: '',
  },
  MsgIine: 'いいねを\n送信しました',
  countMatchComplete: 3,
  numColumnsFemale:3,
  numColumnsMale:2,
  //NotShowErrorCode:['99-00003','99-00004','99-00008','99-00009','99-00010','99-00011','99-00016'],
  NotShowErrorCode:['99-00010','99-00011'],
  InvalidAccessTokenCode:['99-00002','99-00003','99-00004'],
  InvalidAccount:['99-00008','99-00009','99-00016'],
  MaintenanceCode:['99-00018'],
  RemoveStorageKey:['accessToken','ikuyoStatus','userProfile','NAVIGATION_STATE_V1','registProfile','searchResult','sortOrder', 'mailSortOrder', 'FavoriteSearch', 'iineList','searchMeet','hideList','blockList','viewIndex','trialPlan','iineViewIndex','searchInfo','recommendList'],
  RemainGoodCountCode:['04-00005','09-00007'],
  AgeVerficationStatus:{ notApprove:0, pending:1, approve:2 },
  ActivatedStatus:{ notApprove:0, approve:1 },
  SubscriptionStatus:{ notApprove:0, approve:1 },
  ExchangeMessageStatus:{ notApprove:0, approve:1 },
  Level:{ restrict:2 },
  CheckTelCode:['05-00001']
};

export default Constants;
