import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

export default class RadioInput extends Component {  

    constructor(props){
        super(props);
        this.state = {
          radioSelected: 1
        }
      }
    
      radioClick(id) {
        this.props.onChange(id);  
        this.setState({
          radioSelected: id
        })
      }
    
      render() {
    
        return (
            this.props.items.map( (s, i) =>  {
            return (
              <TouchableOpacity 
                key={s.id}
                onPress={this.radioClick.bind(this, s.id)}
                style={styles.list_select}
                >
                <View style={styles.radio}>
                  {
                    s.id == this.state.radioSelected ?
                      <View style={styles.selected_radio} />
                      : null
                  }
                </View>
                <Text style={styles.label}>{s.value}</Text>
              </TouchableOpacity>
            )
          })
        );
      }
    
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
    }, 
    list_select:{
      padding: 10,
      paddingTop: 15,
      paddingBottom: 15,
      margin: 3,
      flex: 1,
      flexDirection: 'row',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: '#CCCCCC',  
    },
    label:{
        color: '#484848',   
        height: 20,
        marginLeft: 3,
    },
    radio:{
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#9aa5a7',
        alignItems: 'center',
        justifyContent: 'center',
        margin:3
    },
    selected_radio:{
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: '#5cc8ca',
    }
    
  });