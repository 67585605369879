import Iine from "./Iine";
import IineApi from '../services/api/Iine10Api';
import ProfileApi from '../services/api/Profile04Api';
import SMSWrapper from "../components/common/SMSWrapper";
import PopupWrapper from "../components/common/PopupWrapper";
import Storage from '../utils/StorageHelper2';
import Constants from "../constants/Constants";

export default class IineUtil{
  
  static sendGood = (props)=>{
    let {activated, targetMemberId, messageType, navigation} = props;
    activated = parseInt(activated);
    if(activated==1){
      const parameter = {
        targetMemberId: targetMemberId,
        messageType: messageType
      };

      return Iine.sendGood(parameter);
    }else{
      SMSWrapper.showSMS(navigation);
      return 'notactivated';
    }
  }

  static sendGoodWithCompletePopup = async(props)=>{
    const keyname = 'matchComplete';
    const countMatchComplete = Constants.countMatchComplete;
    let showPopupComplete = false;

    let {activated, verified, targetMemberId, messageType, navigation, matchCompleteRef} = props;
    activated = parseInt(activated);
    if(activated==1){
      const parameter = {
        targetMemberId: targetMemberId,
        messageType: messageType
      };

      const responseSendGood = await IineApi.sendGood(parameter);
      if(responseSendGood?.status==1){ // success
        const response = responseSendGood?.response;
        if(response?.matching==1){ // matching
          // get profile 
          let profileGetListResponse = await ProfileApi.GetList(targetMemberId);
          let profileInfo = [];
          if(profileGetListResponse?.response?.profileList?.count>0){
            profileInfo = profileGetListResponse?.response?.profileList?.list[0];
          }
  
          let matchComplete = await Storage.getData(keyname);
          matchComplete = parseInt(matchComplete);
  
          const receiveMessageFlg = profileInfo.receiveMessageFlg;
          const sendMessageFlg = profileInfo.sendMessageFlg;
          if(matchComplete ==0 || (matchComplete%countMatchComplete)==0){
            // show popup congratulations
            const options = {
              verified: verified, 
              partnerVerified: response.verified, 
              partnerImageUrl: response.partnerImageUrl, 
              ownImageUrl: response.ownImageUrl,
              receiveMessageFlg: receiveMessageFlg,
              sendMessageFlg: sendMessageFlg,
              targetMemberId: targetMemberId
            };
            matchCompleteRef?.setOptions(options);
            matchCompleteRef?.show();
            return 'popupMatching';
          }else{
            showPopupComplete = true
          }
        }else{
          showPopupComplete = true;
        }

        if(showPopupComplete){
          PopupWrapper.showMessage(Constants.MsgIine); // complete popup
          return 'popupComplete';
        }
      }
      
    }else{
      SMSWrapper.showSMS(navigation);
      return 'notactivated';
    }
  }
}