import React, { Component } from 'react';
import { Animated, View, StyleSheet, Dimensions, TouchableOpacity, Text, Platform }  from 'react-native';
import { Portal } from 'react-native-paper';
import AdvanceSearchListScreen from '../../screens/AdvanceSearchListScreen';
import AdvanceSearchListSingleScreen from '../../screens/AdvanceSearchListSingleScreen';
import HeadStyle from '../../styles/HeadStyles';
import ExpoConstants from 'expo-constants';
import HeaderOptions from '../../constants/HeaderOptions';

const screenWidth = Dimensions.get('window').width;

export default class RightSlidePopupWithConfirm extends Component {
  _isMounted = false;

  constructor(){
    super();
    this.state = {
      LeftPosition: new Animated.Value(screenWidth),
      visible: false
    }
    this.ref = React.createRef();
  }
  
  componentDidMount(){
    this._isMounted = true;
  }
  
  componentWillUnmount(){
    this._isMounted = false;
  }

  ShowPopUp = (show) => {
    if(show){ 
      this.setState({visible:true});
    }

    Animated.timing(this.state.LeftPosition, {
      //duration: 350,
      duration: 0,
      toValue: show ? 0 : screenWidth,
      useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){
        if(!show){
          this.setState({visible: false});
        }
      }
    });
  }

  render(){
    if(this.state.visible){
      return (
        <View style={styles.touch}>
          <Portal>
            <Animated.View style={[styles.container,{
              left:this.state.LeftPosition
            }]}>
              <View style={styles.header}>
                <View style={{flex:1, alignItems:'stretch',justifyContent:'center'}}>
                  <Text style={HeadStyle.headerTitlePage}>{this.props.title}</Text>
                </View>
                <TouchableOpacity style={{position:'absolute', justifyContent:'center', height:'100%', left:20, top:0, zIndex:1}} onPress={async()=>{
                  if(this.ref.handleCancel !=undefined){
                    await this.ref.handleCancel();
                  }
                  this.ShowPopUp(false);
                }}>
                  <Text>キャンセル</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{position:'absolute', justifyContent:'center', height:'100%', right:20, top:0, zIndex:1}} onPress={async()=>{
                  let $return;
                  if(this.ref.handleBack !=undefined){
                    $return = await this.ref.handleBack();
                  }
                  if($return.id!=undefined){
                    this.ShowPopUp(false);
                  }
                }}>
                  <Text>完了</Text>
                </TouchableOpacity>
              </View>
              {this.props.component!=null?
                this.props.component
                : 
                (this.props.single===true?
                  <AdvanceSearchListSingleScreen {...this.props} ref={ref=>this.ref=ref} />  
                :
                  <AdvanceSearchListScreen {...this.props} ref={ref=>this.ref=ref} />  
                )
              }
            </Animated.View>
          </Portal>
        {this.props.showChild===true && this.props.children }
        </View>
      );
    }
    
    return (
      <TouchableOpacity onPress={() => {this.ShowPopUp(true)}} style={styles.touch}>
        {this.props.children}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width:'100%',
    height:'100%'
  },
  touch:{
    flex:1,
    width:'100%',
    alignItems:'stretch',
    justifyContent:'center',
    alignContent:'center',
    alignSelf:'center',
  },
  header:{
    flexDirection:'row',
    width:'100%',
    backgroundColor:'#fff',
    borderBottomWidth:1,
    borderBottomColor:'#b2b2b2',
    shadowColor: 'transparent',
    elevation: 0,
    zIndex:0,
    height: HeaderOptions.headerTopMenuHeight,
    ...Platform.select({
      ios: {
        marginTop:ExpoConstants.statusBarHeight||0,
       },
      android: { 
        marginTop:0,
      },
      default: {
      }
    })
  }
});