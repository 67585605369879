import StorageHelper from '../utils/StorageHelper2';
import ProfileSetting12Api from '../services/api/ProfileSetting12Api';

const checkAccessToken = async(navigation, context=null) => {
  let userProfile = {};
  const accessToken = await StorageHelper.getData('accessToken');
  if(!accessToken) return;

  try{
    ProfileSetting12Api.setCheckAccessFlg(false);
    userProfile = await ProfileSetting12Api.ProfileGetCheck(context, false);
    if(userProfile){
      if(Object.keys(userProfile).length >0){
        // goto search screen
        navigation?.replace('Auth', {screen:'Home', params: {screen: 'Search', params: {screen:'SearchList'}}});
      }
    }
  }catch(e){
    if(__DEV__) console.log(e);
  }
}

export default { 
  checkAccessToken
}
